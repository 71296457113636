import ball1Instructions from "./ball/ball1/instructions";
import ball2Instructions from "./ball/ball2/instructions";
import ball3Instructions from "./ball/ball3/instructions";

const letterThingsInstructions = (
  letter: string,
  xOffset: number,
  yOffset: number,
): Record<string, string[][]> => {
  return {
    Ball1: ball1Instructions(xOffset, yOffset),
    Ball2: ball2Instructions(xOffset, yOffset),
    Ball3: ball3Instructions(xOffset, yOffset),
  };
};

export default letterThingsInstructions;
