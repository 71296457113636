import { useEffect, useState } from "react";
import LinkIcon from "@mui/icons-material/Link";
import Box from "../Unknown/Box";
import TextField from "../Unknown/TextField";
import Button from "../Unknown/Button";
import supabaseClient from "../../common/supabaseClient";
import { PoemType } from "./Poem";
import { Link, Tooltip } from "@mui/material";
import CopyStoryIdButton from "../Unknown/CopyStoryIdButton";
import CopyNewWordsForGemini from "../Admin/CopyNewWordsForGemini";
import LoadingButton from "@mui/lab/LoadingButton";
import checkNewWords from "../../common/checkNewWords";
import getSentenceWords from "../../common/getSentenceWords";
import { skipWords } from "../../common/constants";
import HoverDetails from "../Word/HoverDetails";
import removePunctuationFromString from "../../common/removePunctuationFromString";
import { Word } from "../../types/supabase";
import { useLessonsStore } from "../../store/lessonsStore";

const PoemTab = () => {
  const [poemId, setPoemId] = useState<number | null>(null);
  const [poem, setPoem] = useState<PoemType | null>(null);
  const [poemText, setPoemText] = useState("");
  const [poemWords, setPoemWords] = useState<string[]>([]);
  const [poemStory, setPoemStory] = useState<{ en: string; ua: string }[]>([]);
  const [poemFormattedStory, setPoemFormattedStory] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [newWords, setNewWords] = useState<string[]>([]);
  const [level, setLevel] = useState<string>();
  const [loadWords, setLoadWords] = useState(false);
  const [curerntModuleWordsMap, setCurrentModuleWordsMap] = useState<Record<string, Word>>({});
  const globalWords = useLessonsStore((state) => state.globalWords);
  const setGlobalWords = useLessonsStore((state) => state.setGlobalWords);

  // useEffect(() => {
  //   const words = poemWords;

  // }, [globalWords, poemWords]);

  useEffect(() => {
    if (poemFormattedStory) {
      const words = getSentenceWords(poemFormattedStory);

      const map = globalWords
        .filter(
          (w) =>
            words.includes(w.name) ||
            words.includes(w.name.toLowerCase()) ||
            words.includes(w.name.toUpperCase()),
        )
        .reduce((acm, w) => {
          return {
            ...acm,
            [w.name]: w,
            [w.name.toLowerCase()]: w,
            [w.name.toUpperCase()]: w,
          };
        }, {});

      setCurrentModuleWordsMap(map);
    }
  }, [globalWords, poemFormattedStory]);

  useEffect(() => {
    if (poem) {
      setPoemText(poem.text);
      setPoemWords(poem.words);
      if (poem.story) {
        setPoemStory(poem.story);
      }
      if (poem.level) {
        setLevel(poem.level);
      }
    }
  }, [poem]);

  useEffect(() => {
    setPoemFormattedStory(
      poemStory.reduce((prev, curr) => {
        if (!curr.en) return `${prev}\n`;
        return `${prev} ${curr.en}`;
      }, ""),
    );
  }, [poemStory]);

  return (
    <Box>
      <TextField
        fullWidth
        color="primary"
        value={poemId}
        multiline={true}
        onChange={(event) => {
          setPoemId(+event.target.value);
        }}
        type="text"
        variant="outlined"
      />
      <Button
        variant="outlined"
        onClick={async () => {
          const { data } = await supabaseClient.from("poems").select().eq("id", poemId).single();

          setPoem(data);
        }}
      >
        Find
      </Button>
      <br />
      {poem && (
        <Box>
          <CopyStoryIdButton id={poem.id.toString()} />
          <Button
            color="info"
            variant="contained"
            component={Link}
            target="_blank"
            href={poem.link}
          >
            <Tooltip title="open external story in a new tab">
              <LinkIcon />
            </Tooltip>
          </Button>
          <TextField
            fullWidth
            color="primary"
            value={poemText}
            multiline={true}
            onChange={(event) => {
              setPoemText(event.target.value);
            }}
            type="text"
            variant="outlined"
          />
          <TextField
            fullWidth
            color="primary"
            value={level}
            placeholder="Level"
            multiline={true}
            onChange={(event) => {
              setLevel(event.target.value);
            }}
            type="text"
            variant="outlined"
          />
          <TextField
            fullWidth
            color="primary"
            value={poemWords.join(",")}
            multiline={true}
            onChange={(event) => {
              setPoemWords(event.target.value.split(","));
            }}
            type="text"
            variant="outlined"
          />
          <TextField
            fullWidth
            color="primary"
            value={JSON.stringify(poemStory, null, 2)}
            multiline={true}
            onChange={(event) => {
              try {
                setPoemStory(JSON.parse(event.target.value));
              } catch (err: any) {
                console.error(err.message);
              }
            }}
            type="text"
            variant="outlined"
          />

          {poemStory && (
            <Button
              onClick={() => {
                navigator.clipboard.writeText(poemStory.map((sentence) => sentence.en).join(" "));
              }}
            >
              Copy story text
            </Button>
          )}
          {!!newWords.length && <CopyNewWordsForGemini words={newWords} />}
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="info"
            onClick={async () => {
              if (!poemStory) return;
              setIsLoading(true);
              const { words: returnWords, newWords } = await checkNewWords(
                getSentenceWords(poemStory.map((sentence) => sentence.en).join(" ")),
              );
              console.log("🚀 ~ newWords:", newWords);
              console.log("🚀 ~ returnWords:", returnWords);

              setNewWords(newWords.filter((w) => !skipWords.includes(w)));
              setIsLoading(false);
            }}
          >
            Check new words
          </LoadingButton>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="info"
            onClick={async () => {
              setIsLoading(true);

              const storyToUpdate: Record<string, any> = {
                ...poem,
              };

              if (poemStory) {
                storyToUpdate.story = poemStory;
              }

              await supabaseClient.from("poems").update(storyToUpdate).eq("id", poem.id);

              setIsLoading(false);
            }}
          >
            update
          </LoadingButton>
          <Button
            variant="outlined"
            color="error"
            onClick={async () => {
              const words = getSentenceWords(poemFormattedStory);

              const { data } = await supabaseClient
                .from("words")
                .select()
                .in("name", words)
                .returns<Word[]>();

              if (!data) return;

              setGlobalWords(data);

              setLoadWords(!loadWords);
            }}
          >
            load words
          </Button>
          {loadWords &&
            poemFormattedStory.split("\n").map((line) => {
              return (
                <HoverDetails
                  // isCenter
                  words={
                    line.split(" ").map((w: any, index: number) => {
                      const cleanWord = removePunctuationFromString(w).replace("__", " ");

                      const word =
                        curerntModuleWordsMap[cleanWord] ||
                        curerntModuleWordsMap[cleanWord.toLowerCase()];
                      // console.log("🚀 ~ cleanWord:", w, cleanWord, word);

                      return {
                        ...(word ? word : {}),
                        word: word?.name || cleanWord,
                        label: w.replace("__", " "),
                      };
                    }) as any
                  }
                />
              );
            })}
        </Box>
      )}
    </Box>
  );
};

export default PoemTab;
