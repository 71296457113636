import lowerIInstructions from "./lowerI/instructions";
import lowerLInstructions from "./lowerL/instructions";
import upperAInstructions from "./upperA/instructions";
import upperEInstructions from "./upperE/instructions";
import upperFInstructions from "./upperF/instructions";
import upperHInstructions from "./upperH/instructions";
import upperIInstructions from "./upperI/instructions";
import upperLInstructions from "./upperL/instructions";
import upperNInstructions from "./upperN/instructions";
import upperTInstructions from "./upperT/instructions";

const lettersInstructions = (
  letter: string,
  xOffset: number,
  yOffset: number,
): Record<string, string[][]> => {
  return {
    A: upperAInstructions(xOffset, yOffset),
    I: upperIInstructions(xOffset, yOffset),
    L: upperLInstructions(xOffset, yOffset),
    l: lowerLInstructions(xOffset, yOffset),
    i: lowerIInstructions(xOffset, yOffset),
    F: upperFInstructions(xOffset, yOffset),
    E: upperEInstructions(xOffset, yOffset),
    H: upperHInstructions(xOffset, yOffset),
    T: upperTInstructions(xOffset, yOffset),
    N: upperNInstructions(xOffset, yOffset),
  };
};

export default lettersInstructions;
