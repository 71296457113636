import Box from "@mui/material/Box";

const lesson27: any = {
  letters: [],
  number: 27,
  storyIds: [
    1955, // I like to move it
    1035, // about name
    1036, // about name (contructions)
    1860, // one little finger
    1853, // follow the leader
    // 1942, // what are you doing?
    // 952, // running, jumping
    // 1113, // nod, shake your head,
    // 1855, // head, shoulders, knees and toes
    // 1712, // weather
    // 1864, // clean up
    // 1845, // put your scarf
    // 969, // hospital,
    // 970, // tall, short,
    // 1699, // then he had no fan
    // 1753, // then he had no fan
    // 1861, // time to make a circle
    // 1698, // mat, rat, cat
    // 1863, // The dog and the log
    // 1850, // thank you, you're welcome
    // 1846, // spin around
    // 1868, 1751, 1600,
    // 1698, // cat sat
    // 1589, // daddy finger
    // 1725, // jingle bells
    // 1699, // dad had a fan
    // 1753,
    // 1752, // I like to hop
  ],
  poemIds: [
    1955, // I like to move it
    1035, // about name
    1036, // about name (contructions)
    1860, // one little finger
    1853, // follow the leader
    // 1113, // nod, shake your head,
    // 1855, // head, shoulders, knees and toes
    // 1712, // weather
    // 1864, // clean up
    // 1845, // put your scarf
    // 969, // hospital,
    // 970, // tall, short,
    // 1699, // then he had no fan
    // 1753, // then he had no fan
    // 1861, // time to make a circle
    // 1698, // mat, rat, cat
    // 1863, // The dog and the log
    // 1850, // thank you, you're welcome
    // 1846, // spin around
    // 1861, // make a circle
    // 1752, // I like to hop
    // 1599, // rain, go away
    // 1751,
    // 1868,
    // 1826,
    // 1828,
    // 1589,
    // 1600,
  ],
  newLettersCount: 0,
  newSounsCount: 0,
  title: <Box>What is your name? / One little finger / Follow the leader</Box>,
  sounds: [],
  newSounds: [],
  words: [
    "hurt",
    // "hurts",
    "quiet",
    "equal",
    "equals",
    "queen",
    "square",
    "squash",
    "equator",
    "move",
    "slowly",
    "side",
    "leader",
    "line up",
    "toe",
    "toes",
    "head",
    "hand",
    "arm",
    "mouth",
    "full",
    "put",
    "tap",
    "point",
    "tummy",
    "wave",
    "goodbye",
    "what's",
    "your",
    "name",
    "name's",
    "teacher",
    "from",
    "Canada",
    "United States",
    "his",
    "her",
    "dog's",
    "cat's",
    "friend",
    "friend's",
    "teacher",
    "teacher's",
    "tell",
    "they",
    "live",
    "walk",
  ],
  speakingWords: [],
};

export default lesson27;
