function applyTransformToPoint(
  x: number,
  y: number,
  transformStr: string,
): { x: number; y: number } {
  if (!transformStr) return { x, y };

  let newX = x;
  let newY = y;

  // matrix(...)
  const matrixMatch = transformStr.match(/matrix\(([^)]+)\)/);
  if (matrixMatch) {
    const nums = matrixMatch[1].split(/[\s,]+/).map(Number);
    if (nums.length === 6) {
      // matrix(a, b, c, d, e, f)
      newX = x * nums[0] + y * nums[2] + nums[4];
      newY = x * nums[1] + y * nums[3] + nums[5];
      return { x: newX, y: newY };
    }
  }

  // translate(...)
  const translateMatch = transformStr.match(/translate\(([^)]+)\)/);
  if (translateMatch) {
    const nums = translateMatch[1].split(/[\s,]+/).map(Number);
    if (nums.length >= 1) {
      newX += nums[0];
      newY += nums.length >= 2 ? nums[1] : 0;
    }
  }

  // scale(...)
  const scaleMatch = transformStr.match(/scale\(([^)]+)\)/);
  if (scaleMatch) {
    const nums = scaleMatch[1].split(/[\s,]+/).map(Number);
    if (nums.length >= 1) {
      newX *= nums[0];
      newY *= nums.length >= 2 ? nums[1] : nums[0];
    }
  }

  return { x: newX, y: newY };
}

export default applyTransformToPoint;
