import Box from "../../../Box";

const CoordsGrid: React.FC<{ light?: boolean }> = ({ light }) => {
  return (
    <Box sx={{ position: "relative", height: "1180px", overflow: "hidden" }}>
      <Box
        component="svg"
        sx={{ position: "relative", pointerEvents: "none" }}
        width="1500px"
        height="1500px"
        // viewBox="0 0 200 200"
        // viewBox="0 0 317 317"
        viewBox="0 0 396 396"
        style={{ border: "1px solid black" }}
      >
        <defs>
          <pattern id="notebookGrid" width="10" height="10" patternUnits="userSpaceOnUse">
            {/* Малюємо лінії, що утворюють клітинки */}
            <path
              d="M10 0 L0 0 L0 10"
              fill="none"
              stroke={light ? "rgba(0,0,0,0.08)" : "#d1d5db"}
              strokeWidth="1"
            />
          </pattern>
        </defs>
        {/* Прямокутник, заповнений патерном */}
        <rect width="396" height="396" fill="url(#notebookGrid)" />
      </Box>
    </Box>
  );
};

export default CoordsGrid;
