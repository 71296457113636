import LastPageIcon from "@mui/icons-material/LastPage";
import { Checkbox, Drawer, Grid, Paper, TextField } from "@mui/material";
import { PropsWithChildren, useEffect, useMemo, useRef, useState } from "react";
import supabaseClient from "../../../common/supabaseClient";
import { useLessonsStore } from "../../../store/lessonsStore";
import { Word as SpWord } from "../../../types/supabase";
import LinearProgress from "../../User/LinearProgress";
import WordTranscription from "../../Word/WordTranscription";
import WordTranslationSection from "../../Word/WordTranslationSection";
import Box from "../Box";
import Button from "../Button";
import FullScreenLoader from "../FullScreenLoader";
import HStack from "../HStack";
import ModalCloseButton from "../ModalCloseButton";
import Typography from "../Typography";
import VStack from "../VStack";
import WordExample from "./WordExample";
import ReactPlayer from "react-player";
import { readToLearnCourseWords } from "../../LearnToRead/LearnToReadScreen";
import { userHomeWorkCurrentDay, wordsWithoutImages } from "../../../common/constants";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import isAdmin from "../../../common/isAdmin";
import AdminUsers from "../../AdminUsers";
import { useLocalStorage, usePrevious, useWindowSize } from "react-use";
import parsed from "../../../common/parsed";
import { format } from "date-fns";
import ShowCurrentHomeWorkNumbersInDrawer from "../ShowCurrentHomeWorkNumbersInDrawer";
import useIsHomeWork from "../../../hooks/useIsHomeWork";
import ControllingStudentPage from "../Root/ControllingStudentPage";
import ItalicTitle from "../../Title/ItalicTitle";
import removePunctuationFromString from "../../../common/removePunctuationFromString";
import Settings from "./Settings";

const isTouchDevice = require("is-touch-device");

export interface PartWord {
  word: string;
  normalizedWord: string;
}

const ItemImage: React.FC<{ image: string; size?: "big"; folderName?: string }> = ({
  image,
  size,
  folderName,
}) => {
  return (
    <Box
      width="100%"
      maxWidth={320}
      // height={size ? 250 : 100}
      height="auto"
      overflow="hidden"
      component="img"
      src={`/read-to-learn-images/${image}.jpg`}
      // sx={{
      //   backgroundImage: folderName
      //     ? `url(/read-to-learn-images/${image}.jpg)`
      //     : `url(food-items-images/${image}.jpg)`,
      //   backgroundSize: "cover",
      //   backgroundPosition: "center",
      //   backgroundRepeat: "no-repeat",
      // }}
    />
  );
};

export const ChangePositionButton: React.FC<
  PropsWithChildren & {
    isPrimary?: boolean;
    isDisabled?: boolean;
    color?: string;
    onClick: () => void;
  }
> = ({ children, isPrimary, isDisabled, color, onClick }) => {
  return (
    <Box
      component={Paper}
      elevation={2}
      borderRadius={2}
      borderColor="#efefef"
      alignItems="center"
      display="flex"
      px={4}
      py={2}
      gap={2}
      sx={{
        pointerEvents: isDisabled ? "none" : "auto",
        opacity: isDisabled ? 0.3 : 1,
        padding: "5px 13px",
        fontSize: "24px",
        textAlign: "center",
        alignItems: "center",
        cursor: isTouchDevice() ? "auto" : "pointer",
        outline: "none",
        backgroundColor: color || "#fff",
        border: "3px solid #efefef",
        borderRadius: "15px",
        boxShadow: "0 5px #e8e8e8",
        transition: "border .3s ease",
        "&:active, &:focus": {
          outline: "none",
          backgroundColor: "#fff",
          boxShadow: "0 5px #e8e8e8",
          transform: "translateY(2px)",
        },
      }}
      onClick={onClick}
    >
      <Typography variant="h3" color={isPrimary ? "primary" : "default"} fontWeight={500}>
        {children}
      </Typography>
    </Box>
  );
};

interface LearnWordsSectionProps {
  isOpen?: boolean;
  lessonId: string;
  currentWords: string[];
  withoutButton?: boolean;
  withoutExamples?: boolean;
  isReadToLearn?: boolean;
  withTracking?: boolean;
  lessonType?: string;
  withoutWordsNumber?: boolean;
  buttonLabel?: string;
  onDrawerClose?: () => void;
  onUpdateWords: (newWords: string[]) => void;
}

const LearnWordSection: React.FC<LearnWordsSectionProps> = ({
  isOpen: inputIsOpen,
  lessonId,
  buttonLabel,
  currentWords,
  withTracking,
  lessonType,
  withoutButton,
  withoutWordsNumber,
  isReadToLearn,
  withoutExamples,
  onDrawerClose,
  onUpdateWords,
}) => {
  const lessonModalOpen = useLessonsStore((state) => state.lessonModalOpen === "learn-words");
  const setLessonModalOpen = useLessonsStore((state) => state.setLessonModalOpen);

  useEffect(() => {
    if (inputIsOpen === false || inputIsOpen) setLessonModalOpen(inputIsOpen ? "learn-words" : "");
  }, [inputIsOpen]);

  const handleDrawerClose = () => {
    setLessonModalOpen("");
    if (onDrawerClose) onDrawerClose();
  };

  const authUser = useLessonsStore((state) => state.authUser);
  const [studentUserUuid, setStudentUserUuid] = useState<string | null>(null);
  // const [users, setUsers] = useState<any[]>([]);

  // useMountedWaitAsyncEffect(async () => {
  //   if (isAdmin(authUser?.id) && !users.length) {
  //     const { data } = await supabaseClient.from("users").select();
  //     if (data) setUsers(data);
  //   }
  // }, [users, authUser]);

  const currentWord = useMemo(() => {
    const [currentWord] = currentWords;
    return currentWord;
  }, [currentWords]);

  const buttonText = useMemo(() => {
    if (withoutWordsNumber && buttonLabel) return buttonLabel;
    if (buttonLabel) return `${buttonLabel} (${currentWords?.length})`;
    if (withoutWordsNumber) return `Вчити слова`;
    return `Вчити слова (${currentWords?.length})`;
  }, [withoutWordsNumber, buttonLabel, currentWords]);

  const isHomeWork = useIsHomeWork({ lessonId: +lessonId, lessonType: lessonType || "" });

  return (
    <>
      {!withoutButton && (
        <Button variant="outlined" onClick={() => setLessonModalOpen("learn-words")}>
          {buttonText}
        </Button>
      )}

      <Drawer
        open={lessonModalOpen}
        onClose={handleDrawerClose}
        anchor="bottom"
        sx={{
          zIndex: 9,
        }}
      >
        {/* {isHomeWork && <ShowCurrentHomeWorkNumbersInDrawer type="learn-words" />} */}
        <DrawerContent
          lessonId={lessonId}
          currentWord={currentWord}
          currentWords={currentWords}
          isReadToLearn={isReadToLearn}
          withTracking={withTracking}
          lessonType={lessonType}
          withoutExamples={withoutExamples}
          studentUserUuid={studentUserUuid || undefined}
          onClose={handleDrawerClose}
          onUpdateWords={onUpdateWords}
        >
          {/* {" "}
          {isAdmin(authUser?.id) && (
            <AdminUsers
              users={users}
              onChange={(user) => {
                setStudentUserUuid(user.uuid);
              }}
            />
          )} */}
        </DrawerContent>
      </Drawer>
    </>
  );
};

const drawerContentStyle = {
  height: "100vh",
  width: "100vw",
  overflowX: "hidden",
};

const DrawerContent: React.FC<
  {
    lessonId: string;
    currentWord: string;
    currentWords: string[];
    withTracking?: boolean;
    isReadToLearn?: boolean;
    studentUserUuid?: string;
    lessonType?: string;
    withoutExamples?: boolean;
    onClose: () => void;
    onUpdateWords: (newWords: string[]) => void;
  } & PropsWithChildren
> = ({
  children,
  lessonId,
  currentWord: currentRowWord,
  withTracking,
  currentWords,
  lessonType,
  isReadToLearn,
  studentUserUuid,
  withoutExamples,
  onClose,
  onUpdateWords,
}) => {
  const [repeatedWords, setRepeatedWords] = useState<string[]>([]);
  const [isTranslated, setIsTranslated] = useState(false);
  const authUser = useLessonsStore((state) => state.authUser);
  const lessonStoreWords = useLessonsStore((state) => state.personalLessonsWords[lessonId]);
  const setPersonalLessonsWords = useLessonsStore((state) => state.setPersonalLessonsWords);
  const drawerContentRef = useRef<any>(null);
  const updateHomeWork = useLessonsStore((state) => state.updateHomeWork);
  const wordsToRepeat = useLessonsStore((state) => state.homeWork.wordsToRepeat);
  const [wordsRepeatMap, setWordsRepeatMap] = useState<Record<string, boolean | undefined>>({});

  const [possibleTranslation, setPossibleTranslation] = useState("");
  const [isPossibleTranslationTrue, setIsPossibleTranslationTrue] = useState(false);
  const [possibleTranslationError, setPossibleTranslationError] = useState(false);
  const isTeacherLesson = useLessonsStore((state) => state.isTeacherLesson);

  useEffect(() => {
    setPossibleTranslation("");
    setIsPossibleTranslationTrue(false);
    setPossibleTranslationError(false);
  }, [currentRowWord]);

  useEffect(() => {
    if (lessonStoreWords?.length && currentWords.length !== lessonStoreWords.length) {
      const rowWords = lessonStoreWords.map((word) => word.name);

      alert(`Not existing words: ${currentWords.filter((w) => !rowWords.includes(w)).join(", ")}`);
    }
  }, [lessonStoreWords, currentWords]);

  const currentWord = useMemo(() => {
    if (!lessonStoreWords) return null;

    return lessonStoreWords.find((w) => w.name === currentRowWord) || null;
  }, [lessonStoreWords, wordsToRepeat, currentRowWord]);

  const wordExamples = useMemo(() => {
    if (!currentWord?.examples.length || withoutExamples) return [];

    return <WordExample currentWord={currentWord} currentRowWord={currentRowWord} />;
  }, [currentWord, currentRowWord, withoutExamples]);

  const [userExistingWordsStorage, setUserExistingWordsStorage] = useLocalStorage(
    "user-existing-words",
    JSON.stringify([]),
  );

  useEffect(() => {
    try {
      if (userExistingWordsStorage && !Array.isArray(JSON.parse(userExistingWordsStorage)))
        setUserExistingWordsStorage(JSON.stringify([]));
    } catch (e) {}
  }, [userExistingWordsStorage]);
  // useEffect(() => {
  //   try {
  //     if (
  //       hmWordsTracking &&
  //       (typeof JSON.parse(hmWordsTracking) !== "object" ||
  //         Array.isArray(JSON.parse(hmWordsTracking)))
  //     )
  //       setHmWordsTracking(JSON.stringify({}));
  //   } catch (e) {}
  // }, [hmWordsTracking]);
  const isHomeWork = useIsHomeWork({ lessonId: +lessonId, lessonType: lessonType || "" });

  const moveToTheEnd = () => {
    if (!authUser) return;
    const newWords = [...currentWords];
    const firstWord = newWords.shift();
    if (firstWord) newWords.push(firstWord);

    console.log("------");
    if (isHomeWork) {
      const hmWordsTracking = localStorage.getItem(userHomeWorkCurrentDay);
      let newHmWordsTracking: Record<string, any> = { ...parsed(hmWordsTracking, {}) };
      const currentDate = format(new Date(), "yyyy-MM-dd");
      console.log("------ 2");

      if (!newHmWordsTracking[currentDate]) {
        newHmWordsTracking = {
          ...newHmWordsTracking,
          [currentDate]: {
            date: currentDate,
            repeatedWords: [],
          },
        };
      }
      console.log("------ 3");
      if (!newHmWordsTracking[currentDate]?.repeatedWords) {
        newHmWordsTracking[currentDate].repeatedWords = [];
      }
      console.log("------ 4");

      const updateRepeatedWords = Array.from(
        new Set([...newHmWordsTracking[currentDate].repeatedWords, currentWord?.name]),
      );
      console.log("------ 5");
      console.log("12345 newHmWordsTracking[currentDate]", newHmWordsTracking[currentDate]);
      const updateWordsToRepeat = newHmWordsTracking[currentDate].wordsToRepeat?.filter(
        (w: number) => !updateRepeatedWords.includes(w),
      );
      console.log("------ 6");
      newHmWordsTracking[currentDate].repeatedWords = updateRepeatedWords;
      newHmWordsTracking[currentDate].wordsToRepeat = updateWordsToRepeat;

      console.log("------ 7");
      localStorage.setItem(userHomeWorkCurrentDay, JSON.stringify(newHmWordsTracking));
      updateHomeWork({ repeatedWords: updateRepeatedWords, wordsToRepeat: updateWordsToRepeat });
    }

    console.log("------ 8");
    setTimeout(async () => {
      setIsTranslated(false);
      onUpdateWords(newWords);
      const updatedRepeatedWords = Array.from(
        new Set([...repeatedWords, currentWord?.name]),
      ).filter(Boolean) as string[];
      console.log("------ 9");
      setRepeatedWords(updatedRepeatedWords);
      if (updatedRepeatedWords.length === currentWords.length) {
        setRepeatedWords([]);
      } else {
        setRepeatedWords(updatedRepeatedWords);
      }
      console.log("------ 10");

      if (!currentWord) return;

      if (!userExistingWordsStorage) {
        // something is wrong
        return;
      }
      console.log("------ 11");
      let userExistingWordsStorageParsed: string[] = [];
      try {
        userExistingWordsStorageParsed = JSON.parse(userExistingWordsStorage);
      } catch (err: any) {
        console.error(err.message);
      }
      console.log("------ 12");

      if (
        !userExistingWordsStorageParsed?.length ||
        !userExistingWordsStorageParsed.includes(currentWord.name)
      ) {
        const { count: wordExists } = await supabaseClient
          .from("user-words")
          .select("*", { count: "exact", head: true })
          .eq("id", `${authUser.uuid}-${currentWord.name}`);
        console.log("------ 13");
        if (!wordExists) {
          await supabaseClient.rpc("add_user_word", {
            userprop: authUser.id,
            word: currentWord.name,
          });
        }
        console.log("------ 14");
        const newStorageWords = [currentWord.name, ...userExistingWordsStorageParsed];
        if (newStorageWords.length > 100) {
          newStorageWords.length = 100;
        }

        console.log("------ 15");
        setUserExistingWordsStorage(JSON.stringify(newStorageWords, null, 2));
      }
    }, 200);
  };

  const moveBy3 = () => {
    const newWords = [...currentWords];
    const firstWord = newWords.shift();
    if (firstWord) newWords.splice(2, 0, firstWord);
    setTimeout(() => {
      setIsTranslated(false);
      onUpdateWords(newWords);
    }, 200);
  };

  const moveBy5 = () => {
    const newWords = [...currentWords];
    const firstWord = newWords.shift();
    if (firstWord) newWords.splice(4, 0, firstWord);
    setTimeout(() => {
      setIsTranslated(false);
      onUpdateWords(newWords);
    }, 200);
  };

  const moveBy12 = () => {
    const newWords = [...currentWords];
    const firstWord = newWords.shift();
    if (firstWord) newWords.splice(11, 0, firstWord);
    setTimeout(() => {
      setIsTranslated(false);
      onUpdateWords(newWords);
    }, 200);
  };

  // const moveBy20 = () => {
  //   const newWords = [...currentWords];
  //   const firstWord = newWords.shift();
  //   if (firstWord) newWords.splice(19, 0, firstWord);
  //   setTimeout(() => {
  //     setIsTranslated(false);
  //     onUpdateWords(newWords);
  //   }, 200);
  // };

  const adminControlRepeatingIndex = useLessonsStore((state) => state.adminControlRepeatingIndex);
  const prevAdminControlRepeatingIndex = usePrevious(adminControlRepeatingIndex);

  useMountedWaitAsyncEffect(
    () => {
      if (
        authUser &&
        adminControlRepeatingIndex &&
        prevAdminControlRepeatingIndex !== adminControlRepeatingIndex &&
        !isAdmin(authUser?.id)
      ) {
        const period = Math.floor(adminControlRepeatingIndex);
        if (period === 3) {
          moveBy3();
        } else if (period === 5) {
          moveBy5();
        } else if (period === 12) {
          moveBy12();
        } else if (period === currentWords.length) {
          moveToTheEnd();
        }
        // if (period) updateSentences(period);
      }
    },
    [adminControlRepeatingIndex, prevAdminControlRepeatingIndex, authUser],
    0,
  );

  // useEffect(() => {
  //   const user = authUser as any;

  //   if (user && user.uuid === user.studentUserUuid) {
  //     if (user.type === "word_is_translated") {
  //       setIsTranslated(true);
  //     } else if (user.type === "3") {
  //       moveBy3();
  //     } else if (user.type === "5") {
  //       moveBy5();
  //     } else if (user.type === "12") {
  //       moveBy12();
  //       // } else if (user.type === "20") {
  //       //   // moveBy20();
  //     } else if (user.type === "end") {
  //       moveToTheEnd();
  //     }
  //     if (drawerContentRef.current) {
  //       drawerContentRef.current.scrollTo({ top: 0 });
  //     }
  //   }
  // }, [authUser]);

  useEffect(() => {
    if (!lessonStoreWords) {
      (async () => {
        const { data: spWords, error } = await supabaseClient
          .from("words")
          .select()
          .in("name", currentWords)
          .returns<SpWord[]>();

        if (!spWords) {
          if (error) {
            throw error;
          } else {
            throw new Error("Something went wrong. No Sp words found");
          }
        }
        setPersonalLessonsWords(lessonId, spWords);
      })();
    }
  }, [lessonStoreWords, currentWords, lessonId, setPersonalLessonsWords]);

  const { width: windowWidth } = useWindowSize();

  const adminControl = async (type: string) => {
    const randomNumber = Math.floor(Math.random() * Math.random() * 10000000) / 10000000;
    const [, randomNumberDecimal] = randomNumber.toString().split(".");

    if (authUser && isAdmin(authUser.id)) {
      let period = +type;
      if (!type || Number.isNaN(period)) period = currentWords.length;

      await supabaseClient
        .from("admin_control")
        .update({
          // user: studentUserUuid,
          new_repeat_index: `${period}.${randomNumberDecimal}`,
        })
        .eq("id", 0);
      if (studentUserUuid && studentUserUuid !== authUser.uuid) {
        // await supabaseClient
        //   .from("dialog-admin")
        //   .update({
        //     user: studentUserUuid,
        //     value: (Math.random() * Math.random()).toString(),
        //     type,
        //   })
        //   .eq("id", "words_moving");
      } else if (!studentUserUuid) {
        // await supabaseClient
        // .from("dialog-admin")
        // .update({
        //   user: authUser.uuid,
        //   type: "",
        // })
        // .eq("id", "words_moving");
      }
    }
  };

  const [isUserAbleToGoAhead, setIsUserAbleToGoAhead] = useState(false);

  const playerMaxWidth = useMemo(() => {
    if (windowWidth > 500) {
      return 420;
    }
    return windowWidth - 50;
  }, [windowWidth]);

  const drawer = useMemo(() => {
    if (!currentWord)
      return (
        <Box sx={drawerContentStyle}>
          <FullScreenLoader />
        </Box>
      );

    return (
      <Box sx={drawerContentStyle} ref={drawerContentRef}>
        <LinearProgress
          elementsCount={currentWords.length - 1}
          currentValue={repeatedWords.length}
        />
        {children}
        <ModalCloseButton onClose={onClose} />
        <Settings
          isUserAbleToGoAhead={isUserAbleToGoAhead}
          setIsUserAbleToGoAhead={setIsUserAbleToGoAhead}
        />
        <VStack
          px={5}
          justifyContent="center"
          position="relative"
          width="100vw"
          flexWrap="nowrap"
          pt={20}
          pb={10}
          sx={{
            overflowX: "hidden",
            // backgroundColor: "red",
          }}
        >
          <ControllingStudentPage isNested />
          <Typography variant="h2" color="primary.main" gutterBottom>
            {currentWord.name}
          </Typography>

          <VStack position="relative" sx={{ width: "100%", overflowX: "hidden", pb: 5 }}>
            {!isTranslated && (
              <Box
                position="absolute"
                width="calc(100% + 10px)"
                height="100%"
                minWidth={280}
                left="50%"
                zIndex={3}
                alignItems="flex-start"
                pt={15}
                justifyContent="center"
                textAlign="center"
                fontSize={14}
                onClick={async () => {
                  // if (wordsRepeatMap[currentWord.name] === false) {
                  //   setWordsRepeatMap((prev) => ({ ...prev, [currentWord.name]: undefined }));
                  // }

                  setIsTranslated(true);
                }}
                display={!isTranslated ? "flex" : "none"}
                sx={{
                  transform: "translate(-50%)",
                  backdropFilter: "blur(35px)",
                  cursor: "pointer",
                }}
              >
                {/* натисни щоб побачити переклад */}
                <Button variant="contained">перекласти</Button>
              </Box>
            )}
            <WordTranscription
              isReadToLearn={isReadToLearn}
              word={currentWord}
              play={isTranslated}
              autoplay
              small
            />
            <WordTranslationSection
              translation={currentWord.translation || ""}
              short
              sentences={[]}
              isVisible
              examples={false}
            />

            <VStack py={3} justifyContent="center" alignItems="center" width={300}>
              {
                <>
                  <ChangePositionButton
                    color="#ffa0a0"
                    onClick={async () => {
                      moveBy3();
                      await adminControl("3");
                    }}
                  >
                    {/* 3 */}
                    не знаю
                  </ChangePositionButton>
                  <ChangePositionButton
                    color={"#ffd3a0"}
                    onClick={async () => {
                      moveBy5();
                      await adminControl("5");
                    }}
                  >
                    погано знаю
                  </ChangePositionButton>
                  {currentWords.length > 15 && (
                    <ChangePositionButton
                      color="#f7ffa0"
                      onClick={async () => {
                        moveBy12();
                        await adminControl("12");
                      }}
                    >
                      добре знаю
                    </ChangePositionButton>
                  )}
                </>
              }
              <>
                <ChangePositionButton
                  color="#bdffa0"
                  // isDisabled
                  onClick={async () => {
                    if (!authUser) return;

                    moveToTheEnd();
                    await adminControl("end");
                  }}
                >
                  дуже добре знаю
                </ChangePositionButton>
              </>
            </VStack>
            {/* <ItemImage image={getImageName(row.en_name)} /> */}
            {/* {((currentWord.image_name && !wordsWithoutImages.includes(currentWord.name)) ||
              (!wordsWithoutImages.includes(currentWord.name) &&
                readToLearnCourseWords.includes(currentWord.name))) && (
              <VStack pt={5}>
                <ItemImage
                  size="big"
                  image={currentWord.image_name || currentWord.name}
                  folderName="read-to-learn-images"
                />
              </VStack>
            )} */}
            {currentWord.pronounce_video_links && (
              <Box py={5}>
                <ReactPlayer
                  controls
                  style={{ maxWidth: playerMaxWidth, width: "100%", maxHeight: "290px" }}
                  url={currentWord.pronounce_video_links[0]}
                />
              </Box>
            )}
            {wordExamples}
          </VStack>
        </VStack>
      </Box>
    );
  }, [
    authUser,
    currentWord,
    currentWords,
    wordExamples,
    isTranslated,
    repeatedWords,
    currentRowWord,
    wordsRepeatMap,
    isUserAbleToGoAhead,
    possibleTranslationError,
    possibleTranslation,
    isPossibleTranslationTrue,
    onClose,
    onUpdateWords,
  ]);

  return drawer;
};

export default LearnWordSection;
