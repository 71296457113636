import AddIcon from "@mui/icons-material/Add";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import RemoveIcon from "@mui/icons-material/Remove";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { makeAbsolute, parseSVG } from "svg-path-parser";
import getSentenceWords from "../../../../common/getSentenceWords";
import supabaseClient from "../../../../common/supabaseClient";
import { useLessonsStore } from "../../../../store/lessonsStore";
import ItalicTitle from "../../../Title/ItalicTitle";
import Box from "../../Box";
import Button from "../../Button";
import HStack from "../../HStack";
import IconButton from "../../IconButton";
import Typography from "../../Typography";
import { createUserLesson, getUserLesson, updateUserLesson } from "../ControllingStudentPage";
import instructions from "./shapes/instructions";
import getLetter from "./shapes/letters/letters";
import pathSegments from "./shapes/pathSegments";
import applyTransformToPoint from "./utils/applyTransformToPoint";
import getLetterThing from "./shapes/letterThings/letterThings";
import getFlags from "./shapes/flags/flags";

// Функція для застосування трансформації до точки (x, y)
// function applyTransformToPoint(
//   x: number,
//   y: number,
//   transformStr: string,
// ): { x: number; y: number } {
//   if (!transformStr) return { x, y };

//   let newX = x;
//   let newY = y;

//   // matrix(...)
//   const matrixMatch = transformStr.match(/matrix\(([^)]+)\)/);
//   if (matrixMatch) {
//     const nums = matrixMatch[1].split(/[\s,]+/).map(Number);
//     if (nums.length === 6) {
//       // matrix(a, b, c, d, e, f)
//       newX = x * nums[0] + y * nums[2] + nums[4];
//       newY = x * nums[1] + y * nums[3] + nums[5];
//       return { x: newX, y: newY };
//     }
//   }

//   // translate(...)
//   const translateMatch = transformStr.match(/translate\(([^)]+)\)/);
//   if (translateMatch) {
//     const nums = translateMatch[1].split(/[\s,]+/).map(Number);
//     if (nums.length >= 1) {
//       newX += nums[0];
//       newY += nums.length >= 2 ? nums[1] : 0;
//     }
//   }

//   // scale(...)
//   const scaleMatch = transformStr.match(/scale\(([^)]+)\)/);
//   if (scaleMatch) {
//     const nums = scaleMatch[1].split(/[\s,]+/).map(Number);
//     if (nums.length >= 1) {
//       newX *= nums[0];
//       newY *= nums.length >= 2 ? nums[1] : nums[0];
//     }
//   }

//   return { x: newX, y: newY };
// }

const calculatePathLength = (d: string) => {
  const commands = makeAbsolute(parseSVG(d));
  let length = 0;
  // перебір команд та обчислення довжини (тільки для прямих ліній, як приклад)
  for (let i = 1; i < commands.length; i++) {
    if (
      "x" in commands[i] &&
      "y" in commands[i] &&
      "x" in commands[i - 1] &&
      "y" in commands[i - 1]
    ) {
      const dx = commands[i].x - commands[i - 1].x;
      const dy = commands[i].y - commands[i - 1].y;
      length += Math.sqrt(dx * dx + dy * dy);
    }
  }
  return length;
};

type SvgViewerProps = {
  // svgComponent: ReactElement;
  svgComponents: any;
  letter: string;
  top: number;
  left: number;
  onRemoveShape: (svgComponents: any, toastId: number) => void;
  // Наприклад, step - це номер поточного кроку
  // step?: number;
};

const getInstructions = (letter: string, xOffset: number, yOffset: number) => {
  return instructions(letter, xOffset, yOffset);
};

const letterOffset: Record<string, { top: number; left?: number }> = {
  Azerbaijan: {
    top: 113,
  },
  Austria: {
    top: 113,
  },
  PolandUkraine: {
    top: 113,
  },
  Ball1: {
    top: 305,
  },
  Ball2: {
    top: 150,
  },
  Ball3: {
    top: 188,
  },
  A: {
    top: 303,
  },
  I: {
    top: 303,
  },
  L: {
    top: 116,
  },
  l: {
    top: 115,
  },
  i: {
    top: 303,
  },
  E: {
    top: 113,
  },
  F: {
    top: 114,
  },
  H: {
    top: 303,
  },
  T: {
    top: 113,
  },
  N: {
    top: 118,
  },
};

const SVGWithPoints: React.FC<SvgViewerProps> = ({
  letter,
  top,
  left,
  svgComponents,
  onRemoveShape,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [step, setStep] = useState(0);
  const [isComplete, setIsComplete] = useState(false);
  const [showCorrectResultStep, setShowCorrectResultStep] = useState(0);
  const [instructions, setInstructions] = useState<string[]>([]);
  // const [showFullPath, setShowFullPath] = useState(true);
  const [xOffset, setXOffset] = useState(0);
  const [yOffset, setYOffset] = useState(0);
  const studentUser = useLessonsStore((state) => state.studentUser);
  const studentWords = useLessonsStore((state) => state.studentWords);
  const setStudentWords = useLessonsStore((state) => state.setStudentWords);
  const [newWordsForUser, setNewWordsForUser] = useState<string[]>([]);
  // const [showDots, showDot] = useState<string[]>([]);
  const [currentLetterWords, setCurrentLetterWords] = useState<string[]>([]);
  const newUserLesson = useLessonsStore((state) => state.newUserLesson);
  const setNewUserLesson = useLessonsStore((state) => state.setNewUserLesson);

  const checkUserWords = useCallback(async () => {
    if (letter && studentUser) {
      const currentLetterWords = getSentenceWords(getInstructions(letter, 0, 0).flat().join(" "));
      const wordsToFind = currentLetterWords.filter((w) => !studentWords.includes(w));

      if (!wordsToFind.length) return;

      const { data: userWords } = await supabaseClient
        .from("user-words")
        .select()
        .in("word", wordsToFind)
        .eq("user", studentUser.uuid);

      const userRowWords = userWords?.map((w) => w.word) || [];
      if (userRowWords.length) setStudentWords(userRowWords);
      setNewWordsForUser(wordsToFind.filter((w) => !userRowWords.includes(w)));
      setCurrentLetterWords(currentLetterWords);

      console.log("🚀 ~ userWords:", userWords);
    }
  }, [letter, setStudentWords, studentUser, studentWords]);
  // useMountedWaitAsyncEffect(async () => {
  // }, [letter, studentUser, studentWords]);

  const svgComponent = useMemo(() => {
    const updatedLetter = getLetter(letter, step);
    const updatedLetterThing = getLetterThing(letter, step);
    const updatedFlag = getFlags(letter, step);

    return updatedLetter || updatedLetterThing || updatedFlag;
  }, [letter, step]);
  console.log("🚀 ~ step:", step);

  useEffect(() => {
    setInstructions(getInstructions(letter, xOffset, yOffset)[step] || []);
  }, [letter, step, xOffset, yOffset]);

  const toastIdRef = useRef<any>(null);

  // Створюємо тост лише один раз при монтуванні
  useEffect(() => {
    if (!toastIdRef.current) {
      toastIdRef.current = toast(
        <div>
          <ItalicTitle text={`Shape: ${letter}`} />
          {/* <Typography fontSize={22}>{instructions.join(" ")}</Typography> */}
          {/* Ваші кнопки */}
        </div>,
        {
          position: "top-right",
          autoClose: false,
          closeOnClick: false,
          draggable: true,
          closeButton: true,
          className: "persistent-toast",
          style: { zIndex: 9999 },
        },
      );
    }
  }, []); // порожній масив залежностей – тост створиться лише один раз

  // Окремий useEffect для оновлення вмісту тоста, якщо це потрібно
  useEffect(() => {
    if (toastIdRef.current) {
      toast.update(toastIdRef.current, {
        render: (
          <div>
            <ItalicTitle text={`Shape: ${letter}`} />
            {/* <Button
              variant="contained"
              onClick={() => {
                if (step > pathSegments[letter]) {
                  setIsComplete(false);
                } else {
                  setStep(step + 1);
                }
              }}
            >
              Продовжити
            </Button> */}
            <br />

            {Boolean(step) && (
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  if (step > 0) setStep(step - 1);
                  // if (showCorrectResultStep > 0)
                  //   setShowCorrectResultStep(showCorrectResultStep - 1);
                }}
              >
                повернутися назад
              </Button>
            )}
            <Box>
              {/* <Typography>Change position</Typography> */}
              {!studentUser && <Typography>The user is not selected</Typography>}
              <HStack>
                <Button
                  color="error"
                  onClick={() => {
                    console.log("click remove");
                    onRemoveShape(svgComponents, toastIdRef.current);
                  }}
                >
                  Remove
                </Button>
                <Button onClick={checkUserWords}>Check user words</Button>
              </HStack>
              {studentUser &&
                currentLetterWords.map((word) => {
                  return (
                    <Button
                      color={newUserLesson?.words?.includes(word) ? "primary" : "inherit"}
                      sx={{
                        borderBottom: newWordsForUser.includes(word)
                          ? "3px solid red"
                          : "3px solid green",
                      }}
                      onClick={async () => {
                        const userLesson = await getUserLesson(
                          studentUser.id,
                          "english_user_lessons",
                        );
                        console.log("🚀 ~ userLesson:", userLesson);

                        if (userLesson) {
                          const newUserLesson = {
                            user: studentUser.id,
                            words: Array.from(new Set([...(userLesson?.words || []), word])),
                          };
                          setNewUserLesson(newUserLesson);
                          await updateUserLesson(newUserLesson, "english_user_lessons");
                        } else {
                          const newUserLesson = {
                            user: studentUser.id,
                            video_ids: [],
                            words: [word],
                          };
                          setNewUserLesson(userLesson);
                          await createUserLesson(newUserLesson, "english_user_lessons");
                        }

                        await supabaseClient.from("user-words").insert({
                          id: `${studentUser.uuid}-${word}`,
                          user: studentUser.uuid,
                          word,
                        });
                      }}
                    >
                      {word}
                    </Button>
                  );
                })}
              <HStack>
                {/* <IconButton
                  // variant="contained"
                  onClick={() => {
                    setShowFullPath((prev) => !prev);
                  }}
                >
                  <ControlCameraIcon color="primary" sx={{ fontSize: 40 }} />
                </IconButton> */}
                {
                  <>
                    <ItalicTitle text="Y:" />
                    <IconButton
                      onClick={() => {
                        setYOffset((prev) => prev + 1);
                      }}
                    >
                      <RemoveIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        if (yOffset > 0) {
                          setYOffset((prev) => prev - 1);
                        }
                      }}
                    >
                      <AddIcon />
                    </IconButton>

                    <ItalicTitle text="X:" />
                    <IconButton
                      onClick={() => {
                        if (xOffset > 0) {
                          setXOffset((prev) => prev - 1);
                        }
                      }}
                    >
                      <RemoveIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setXOffset((prev) => prev + 1);
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </>
                }
              </HStack>
            </Box>
          </div>
        ),
      });
    }
  }, [
    letter,
    step,
    instructions,
    onRemoveShape,
    checkUserWords,
    currentLetterWords,
    newUserLesson,
    newWordsForUser,
    setNewUserLesson,
    studentUser,
    svgComponents,
    xOffset,
    yOffset,
  ]);

  // useEffect(() => {
  //   // Create a persistent toast for this component when it mounts
  //   toastIdRef.current = toast();

  //   return () => {
  //     if (toastIdRef.current) {
  //       toast.dismiss(toastIdRef.current);
  //     }
  //   };
  // }, []);

  useEffect(() => {
    if (!containerRef.current) return;

    // Отримуємо svg без клонування (прямо з JSX)
    let svgElement: SVGSVGElement | null = containerRef.current.querySelector("svg");
    if (!svgElement) return;

    // Якщо вже є група з точками, видаляємо її (щоб не додавались дублі)
    const oldPointsGroup = svgElement.querySelector('[data-points-group="true"]');
    if (oldPointsGroup) {
      oldPointsGroup.remove();
    }

    // Отримуємо всі path елементи з SVG
    const allPaths = Array.from(svgElement.querySelectorAll("path"));

    // Створюємо новий g елемент для точок
    const pointsGroup = document.createElementNS("http://www.w3.org/2000/svg", "g");
    pointsGroup.setAttribute("data-points-group", "true");

    allPaths.forEach((pathElem) => {
      const d = pathElem.getAttribute("d");
      if (!d) return;

      // Парсимо path data
      const commands = makeAbsolute(parseSVG(d));

      // Отримуємо трансформацію шляху
      const pathTransform = pathElem.getAttribute("transform") || "";
      const className = pathElem.getAttribute("class") || "";
      const needLabel = className.includes("label");

      if (needLabel) {
        // Проходимо по всіх сегментах шляху
        for (let i = 1; i < commands.length; i++) {
          // Перевіряємо, чи команди мають координати
          if (
            "x" in commands[i] &&
            "y" in commands[i] &&
            "x" in commands[i - 1] &&
            "y" in commands[i - 1]
          ) {
            const dx = commands[i].x - commands[i - 1].x;
            const dy = commands[i].y - commands[i - 1].y;
            const length = Math.sqrt(dx * dx + dy * dy);

            // Додаємо текст тільки якщо довжина достатня
            if (length > 5) {
              // Можна зменшити поріг для відображення більшої кількості розмірів
              // Розраховуємо середню точку сегмента
              let midX = (commands[i - 1].x + commands[i].x) / 2;
              let midY = (commands[i - 1].y + commands[i].y) / 2;

              // Додаємо зміщення для кращої видимості тексту
              // Визначаємо напрямок лінії для правильного зміщення
              const isHorizontal = Math.abs(dx) > Math.abs(dy);

              if (isHorizontal) {
                midY -= 20; // Зміщення вверх для горизонтальних ліній
              } else {
                midX += 35; // Зміщення вправо для вертикальних ліній
              }

              // Створюємо текстовий елемент
              const textElem = document.createElementNS("http://www.w3.org/2000/svg", "text");

              // Важливо: для path з трансформацією scale(-1,1) треба застосувати зворотну трансформацію до тексту
              // щоб він не був дзеркальним
              if (pathTransform.includes("scale(-1,1)")) {
                // Застосовуємо трансформацію до координат
                const transformedMidX = -midX; // Відзеркалюємо X
                textElem.setAttribute("x", transformedMidX.toString());
                textElem.setAttribute("y", midY.toString());
                // Застосовуємо зворотну трансформацію до самого тексту щоб він читався правильно
                textElem.setAttribute("transform", "scale(-1,1)");
              } else {
                textElem.setAttribute("x", midX.toString());
                textElem.setAttribute("y", midY.toString());
              }

              textElem.setAttribute("fill", "blue");
              textElem.setAttribute("font-size", "12");
              textElem.setAttribute("text-anchor", "middle");
              // textElem.setAttribute("dominant-baseline", "middle");

              // Форматуємо довжину в міліметрах (28.4 - це приблизне значення для конвертації)
              const squares = Math.round(length / 28.4);
              const lengthInCm = (length / 28.4 / 2).toFixed(1);
              textElem.textContent = `${squares} - ${lengthInCm}см`;

              // Додаємо текстовий елемент до SVG
              svgElement?.appendChild(textElem);
            }
          }
        }
      }
    });

    // Додаємо точки для кожного path
    allPaths.forEach((pathElem) => {
      const d = pathElem.getAttribute("d");
      if (!d) return;

      try {
        // Парсимо path data і перетворюємо відносні координати на абсолютні
        const commands = makeAbsolute(parseSVG(d));
        if (commands.length === 0) return;

        // Отримуємо першу та останню команду
        const startCommand = commands[0];
        let endCommand = commands[commands.length - 1];

        // Якщо остання команда - Z (замкнутий шлях), використовуємо першу точку як кінцеву
        if (endCommand.code === "Z" && commands.length > 1) {
          for (let i = commands.length - 2; i >= 0; i--) {
            if ("x" in commands[i] && "y" in commands[i]) {
              endCommand = commands[i];
              break;
            }
          }
        }

        // Отримуємо трансформацію групи
        const mainGroup = svgElement?.querySelector("g");
        const groupTransform = mainGroup ? mainGroup.getAttribute("transform") : null;

        let pointRadius = "50";
        if (!groupTransform || !groupTransform.includes("matrix(.1 0 0")) {
          // Якщо немає трансформації, як у другому SVG, використовуємо менший радіус
          pointRadius = "5";
        }

        const className = pathElem.getAttribute("class") || "";
        const needDots = className.includes("dots");
        // Перевіряємо, чи перша команда має координати
        if (needDots && "x" in startCommand && "y" in startCommand) {
          // Отримуємо transform з атрибута path
          const pathTransformStr = pathElem.getAttribute("transform") || "";
          // Застосовуємо трансформацію до першої точки
          const { x: adjustedX, y: adjustedY } = applyTransformToPoint(
            startCommand.x,
            startCommand.y,

            pathTransformStr,
          );

          // Створюємо точку на початку path
          const startCircle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
          startCircle.setAttribute("cx", adjustedX.toString());
          startCircle.setAttribute("cy", adjustedY.toString());
          startCircle.setAttribute("r", pointRadius);
          startCircle.setAttribute("fill", "red");
          pointsGroup.appendChild(startCircle);
        }

        // Перевіряємо, чи кінцева команда має координати
        if (needDots && "x" in endCommand && "y" in endCommand) {
          const pathTransformStr = pathElem.getAttribute("transform") || "";
          // Застосовуємо трансформацію до кінцевої точки
          const { x: adjustedX, y: adjustedY } = applyTransformToPoint(
            endCommand.x,
            endCommand.y,
            pathTransformStr,
          );

          // Створюємо точку в кінці path
          const endCircle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
          endCircle.setAttribute("cx", adjustedX.toString());
          endCircle.setAttribute("cy", adjustedY.toString());
          endCircle.setAttribute("r", pointRadius);
          endCircle.setAttribute("fill", "red");
          pointsGroup.appendChild(endCircle);
        }
      } catch (error) {
        console.error("Помилка при обробці path:", d, error);
      }
    });

    // Додаємо трансформацію до групи точок, щоб узгодити з основною трансформацією SVG
    const mainGroup = svgElement.querySelector("g");
    if (mainGroup && mainGroup.getAttribute("transform")) {
      pointsGroup.setAttribute("transform", mainGroup.getAttribute("transform")!);
    }

    // Додаємо групу з точками безпосередньо до SVG
    svgElement.appendChild(pointsGroup);
  }, [step, svgComponent, letter]);
  console.log("letterOffset[letter]", letterOffset, letterOffset[letter], letter);
  return (
    <Box ref={containerRef}>
      <Box
        sx={{
          position: "absolute",
          // backgroundColor: "red",
          marginLeft: 11.7,
          marginTop: 4.1,
          top: (letterOffset[letter]?.top || 0) - yOffset * 37.9,
          left: (letterOffset[letter]?.left || 0) + xOffset * 37.9,
          pointerEvents: "none",
          opacity: 1,
          "& svg path": {
            fill: isComplete ? "black" : "transparent",
          },
        }}
      >
        {svgComponent}
      </Box>
    </Box>
  );
};

export default SVGWithPoints;
