const svgPolandUkraine = (step: number) => (
  <svg
    width="36.156cm"
    height="26.138cm"
    preserveAspectRatio="xMidYMid"
    version="1.0"
    viewBox="0 0 1024.9 740.92"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" stroke="#000" stroke-width="2.8346">
      <path d="m14.749 721.85-0.37501 9.2e-4 1.4994-565.82" />
      <path d="m212.1 721.36-197.35 0.48676" />
      <path d="m210.05 156.07 1.8574 0.00247 1.0103 565.28-0.81738 0.00201" />
      <path d="m15.873 156.03 5.63e-4 -0.21242 194.17 0.25783" />
      <path d="m440.36 719.86 0.00392 2.1944-198.54 0.48969 7.2e-4 -0.27247" />
      <path d="m439.06 156.78 0.2915 3.9e-4 1.0064 563.09" />
      <path d="m243.32 156.91 0.00105-0.39549 195.74 0.25991" />
      <path d="m241.82 722.28 1.4982-565.36" />
      <path d="m666.15 719.83 0.00398 2.228-198.54 0.48969 0.00287-1.0825" />
      <path d="m663.81 156.78 1.3257 0.00176 1.0063 563.05" />
      <path d="m470.77 156.52 193.05 0.25634" />
      <path d="m467.61 721.47 1.4971-564.95 1.6567 0.0022" />
      <path d="m891.45 721.48 0.00104 0.58007-198.54 0.48969 0.00111-0.41747" />
      <path d="m890.44 158.15 1.0068 563.33" />
      <path d="m694.41 157.13 0.00163-0.61378 196.03 0.2603 0.00246 1.3682" />
      <path d="m692.91 722.13 1.4973-565" />
    </g>
  </svg>
);

export default svgPolandUkraine;
