import Box from "../../../Box";

const RightAxis = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 1136,
        left: 30,
        height: 2,
        width: "100%",
        backgroundColor: "red",
        zIndex: 1,
        "& div": {},
      }}
    >
      {[
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
        26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
      ].map((n, index) => {
        return (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: index * 37.9 + 22,
              fontSize: 16,
              width: 25,
              fontWeight: 600,
              borderRight: "solid 2px red",
            }}
          >
            <Box
              sx={{
                position: "relative",
                left: "80%",
                top: 15,
              }}
            >
              {n}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default RightAxis;
