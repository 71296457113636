import Box from "@mui/material/Box";

const lesson25: any = {
  letters: [],
  number: 26,
  storyIds: [
    1942, // what are you doing?
    952, // running, jumping
    // 1113, // nod, shake your head,
    // 1855, // head, shoulders, knees and toes
    // 1712, // weather
    // 1864, // clean up
    // 1845, // put your scarf
    // 969, // hospital,
    // 970, // tall, short,
    // 1699, // then he had no fan
    // 1753, // then he had no fan
    // 1861, // time to make a circle
    // 1698, // mat, rat, cat
    // 1863, // The dog and the log
    // 1850, // thank you, you're welcome
    // 1846, // spin around
    // 1868, 1751, 1600,
    // 1698, // cat sat
    // 1589, // daddy finger
    // 1725, // jingle bells
    // 1699, // dad had a fan
    // 1753,
    // 1752, // I like to hop
  ],
  poemIds: [
    1942, // what are you doing?
    952, // running, jumping
    // 1113, // nod, shake your head,
    // 1855, // head, shoulders, knees and toes
    // 1712, // weather
    // 1864, // clean up
    // 1845, // put your scarf
    // 969, // hospital,
    // 970, // tall, short,
    // 1699, // then he had no fan
    // 1753, // then he had no fan
    // 1861, // time to make a circle
    // 1698, // mat, rat, cat
    // 1863, // The dog and the log
    // 1850, // thank you, you're welcome
    // 1846, // spin around
    // 1861, // make a circle
    // 1752, // I like to hop
    // 1599, // rain, go away
    // 1751,
    // 1868,
    // 1826,
    // 1828,
    // 1589,
    // 1600,
  ],
  newLettersCount: 0,
  newSounsCount: 0,
  title: <Box>What are you doing?</Box>,
  sounds: [],
  newSounds: [],
  words: [
    // "head",
    // "heads",
    "step", // !
    "steps", // !
    "end",
    "shoulders", // !
    "from", // !
    "shoulder", // !
    "running",
    "knee", // !
    "knees", // !
    "toe", // !
    "toes", // !
    "washing", // !
    "eating", // !
    // "dance",
    // "dancing",
    // "sleep",
    // "sleeping",
    "run",
    "jumping",
    "jump",
    // "walk",
    // "walking",
    // "drawing", // !
    "doing", // !
    "going", // !
    "know", // !
    "her", // !
    // "hamster",
    // "hot",
    "flower", // !
    // "fox",
    // "fan",
    "frog",
    "fog",
    // "fish",
    // "foot",
    // "feet",
    // "house",
    // "second",
    // "seconds",
    "heart",
    "next",
    // "cow",
    // "now",
    // "vertical",
    // "horizontal",
    "letter",
    "connect",
    // "mark",
    // "draw",
    "between",
    // "twenty",
    // "form",
    "shape",
    "arm", // !
    "hand", // !
    "knees",
    // "eleven",
    "dot",
    "dots",
    "line",
    "night",
    "nine",
    // "eye",
    // "eyes",
    // "ear",
    // "ears",
    "mouth", // !
    // "nose",
    // "monster",
    // "at",
    // "in",
    // "say",
    // "shake",
    "nod",
    // "body",
    // "part",
    // "parts",
    "if",
    "or",
    "want",
    "agree",
    "disagree",
    "someone",
    "mean",
    "means",
    "feel",
    "pain",
    // "feel",
    // "pain",
  ],
  speakingWords: [],
};

export default lesson25;
