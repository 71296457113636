import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import supabaseClient from "../../../common/supabaseClient";
import { useLessonsStore } from "../../../store/lessonsStore";
import AdminComponentIndicator from "../../Unknown/AdminComponentIndicator";
import Button from "../../Unknown/Button";
import HStack from "../../Unknown/HStack";
import { useState } from "react";
import getTextFromEditorState from "../../../common/getTextFromEditorState";
import {
  createUserLesson,
  getUserLesson,
  updateUserLesson,
} from "../../Unknown/Root/ControllingStudentPage";

const AdminAddReadingWordsToCurrentUserLesson = () => {
  const studentUser = useLessonsStore((state) => state.studentUser);
  const [isLoading, setIsLoading] = useState(false);
  const setNewUserLesson = useLessonsStore((state) => state.setNewUserLesson);
  const setEditorState = useLessonsStore((state) => state.setEditorState);
  const editorState = useLessonsStore((state) => state.editorState);
  const studentWordsToRead = useLessonsStore((state) => state.studentWordsToRead);
  const newUserLesson = useLessonsStore((state) => state.newUserLesson);

  const getStudentUser = async () => {
    if (!studentUser) return;
    const { data: studentDbUser } = await supabaseClient
      .from("users")
      .select()
      .eq("id", studentUser.id)
      .single();

    return studentDbUser;
  };

  if (!studentUser) return null;

  return (
    <HStack alignItems="center">
      <AdminComponentIndicator title="AdminAddReadingWordsToCurrentUserLesson" />
      <LoadingButton
        loading={isLoading}
        variant="contained"
        color={"error"}
        onClick={async () => {
          if (!studentUser) {
            alert("no user found");
            return;
          }
          setIsLoading(true);
          const studentDbUser = await getStudentUser();

          if (!studentDbUser) return;

          // today user lesson
          const userLesson = await getUserLesson(studentUser.id, "english_user_lessons");
          const words = getTextFromEditorState(editorState)
            .split("   ")
            .map((w) => w.trim());

          if (userLesson) {
            const result = await updateUserLesson(
              {
                user: studentUser.id,
                // video_ids: [...(userLesson.video_ids || []), storyId].map((id) => Number(id)),
                reading_words: Array.from(
                  new Set([
                    ...(userLesson?.reading_words || []),
                    ...studentWordsToRead.filter(Boolean),
                  ]),
                ),
              },
              "english_user_lessons",
            );
            console.log("🚀 ~ result:", result);
          } else {
            await createUserLesson(
              {
                user: studentUser.id,
                video_ids: [],
                reading_words: studentWordsToRead.filter(Boolean),
              },
              "english_user_lessons",
            );
          }

          let updatedOrCreatedUserLesson = await getUserLesson(
            studentUser.id,
            "english_user_lessons",
          );
          console.log("🚀 ~ updatedOrCreatedUserLesson:", updatedOrCreatedUserLesson);
          setNewUserLesson(updatedOrCreatedUserLesson);

          setEditorState("");
          setIsLoading(false);
        }}
      >
        {/* <Tooltip title="Add to user video ids">
          <PersonAddIcon />
        </Tooltip> */}
        Add all reading words to today's user lesson
        {/* {type === "prev" ? "Add to user video ids" : "Add to ignore video ids"} */}
      </LoadingButton>
    </HStack>
  );
};

export default AdminAddReadingWordsToCurrentUserLesson;
