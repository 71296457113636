import React, { useState, useEffect, useRef, useMemo } from "react";
import Box from "../../Box";
import { Button, TextField, Typography } from "@mui/material";
import VStack from "../../VStack";
import ItalicTitle from "../../../Title/ItalicTitle";
import SVGWithPoints from "./SvgWithPoints";
import RightAxis from "./coordinates/RightAxis";
import LeftAxis from "./coordinates/LeftAxis";
import CoordsGrid from "./coordinates/CoordsGrid";
import useMountedWaitAsyncEffect from "../../../../hooks/useMountedWaitAsyncEffect";
import { rowLetters } from "./shapes/letters/letters";
import { ToastContainer, toast } from "react-toastify";
import { rowLetterThings } from "./shapes/letterThings/letterThings";
import AdminAddReadingWordsToCurrentUserLesson from "../../../EnglishKidsLessons/EnglishKidsLessonsScreen/AdminAddReadingWordsToCurrentUserLesson";
import AdminAddWordsToTheLastLesson from "../../../AdminWordsControl/AdminAddWordsToTheLastLesson";
import AdminFindAndUpdateWord from "../../../AdminWordsControl/AdminFindAndUpdateWord";
import { useLessonsStore } from "../../../../store/lessonsStore";
import HStack from "../../HStack";
import supabaseClient from "../../../../common/supabaseClient";
import { rowFlags } from "./shapes/flags/flags";
// import parseSVG from "svg-path-parser";
const { parseSVG } = require("svg-path-parser");

const pathSegmentsForLowerA = [
  [
    "m 1564.9852,-6701.0909 v -443.8551 c -92.3117,7.5873 -192.2107",
    [5.92, 3, 5.92, 4.5],
    [6, 3, 6, 4.5],
  ],
  ["18.3359 -299.697,32.2459", [4.9, 4.45], [5, 4.5]],
  ["-155.4079,18.2834 -349.61185,56.0392 -417.12505,132.8644", [3.4, 4], [3.5, 4]],
  ["-61.74083,83.1677 -38.47708,193.1745 -11.72996,284.348 20.84523,76.4324", [3.4, 3], [3.5, 3]],
  [
    "123.25188,132.2028 161.22939,144.0707 41.95732,11.8005 193.18932,31.1396",
    [3.95, 2.5],
    [4, 2.5],
  ],
  ["276.43462,-2.1586 70.8145,-27.8199", [5, 2.5], [5, 2.5]],
  ["237.7771,-101.9917 290.888,-147.5153", [], [6, 3]],
  [
    "z m -15,412.546 c -36.6718,27.82 -67.1954,38.8304 -121.5708,78.0313 -54.3754,39.2009 -105.5894,55.1822 -153.6421,77.944",
    [5.9, 1.5, 4.95, 1],
    [6, 1.5, 5, 1],
  ],
  ["-177.4684,30.8365 -373.80658,34.4543 -558.25182,6.6504 -10.30046,-1.5527", [3, 1], [3, 1]],
  [
    "-420.42684,-64.6747 -573.87582,-294.2122 -52.565704,-78.6307 -55.702205,-349.5697",
    [1, 2],
    [1, 2],
  ],
  ["-9.04837,-561.9425 77.60808,-319.1313", [0.95, 4], [1, 4]],
  ["286.26904,-363.9748 571.68412,-447.729", [3, 5.55], [3, 5.5]],
  [
    "236.84131,-56.9045 379.02319,-83.2341 566.17579,-107.2605 187.1525,-24.0263",
    [5, 5.95],
    [5, 6],
  ],
  ["96.2601,3.2701 297.3226,-8.1108", [6, 6], [6, 6]],
  ["v -11.3809 c 0,-61.0622 29.3962,-93.8834 -7.8008,-154.7766", [5.95, 6.5], [6, 6.5]],
  ["-39.2308,-64.2225 -240.1521,-96.7372 -286.3572,-118.0159", [4.95, 6.95], [5, 7]],
  ["-187.6843,-13.1647 -379.39079,-18.1401 -567.78199,-7.5873", [3, 7], [3, 7]],
  ["-187.28274,34.9619 -362.0296,89.1704 -562.63329,143.5472", [1, 6.5], [1, 6.5]],
  ["l -7.49999,-581.5372 c 72.07902,-18.9682", [0.95, 8.5], [1, 8.5]],
  ["414.04933,-101.0977 575.91099,-126.3886", [3, 9], [3, 9]],
  ["163.12621,-26.5554 393.75238,-9.8331 556.87868,-9.8331 388.215,0", [5, 9], [5, 9]],
  ["414.0812,74.2017 570.2896,135.1975 70.2093,48.1728 238.8032,217.4886", [7, 8.45], [7, 8.5]],
  ["289.5232,293.953 74.4324,112.2129 158.6393,265.5891", [7.95, 7.45], [8, 7.5]],
  ["157.8407,417.5047", [8.5, 6], [8.5, 6]],
  ["l -7.5,1426.6677", [8.45, 1], [8.5, 1]],
  ["-722.1635,7.5", [5.94, 1], [6, 1]],
  ["z", [], [6, 1.5]],
];
const pathSegmentsForUpperA = [
  [
    "m 3268.1988 -6134.0137 h -825.0361",
    [11.9, 1, 9, 1.04],
    [12, 1, 9, 1],
    [
      ["Постав", "Mark"],
      ["точку", "a dot"],
      ["в координатах", "at"],
      ["(12,1)", "(twelve,one)"],
      ["і", "and"],
      ["(9,1).", "(nine,one)."],
      ["Проведи", "Draw"],
      ["лінію", "a line"],
      ["між", "between"],
      ["цими", "these"],
      ["двома", "two"],
      ["точками.", "dots."],
    ],
  ],
  [
    "l -142.8721 -570.9417",
    [8.5, 3],
    [8.5, 3],
    [
      ["Постав", "Mark"],
      ["точку", "a dot"],
      ["в координатах", "at"],
      ["(8.5,3).", "(eight and a half,three)."],
      ["Проведи", "Draw"],
      ["лінію", "a line"],
      ["до", "to"],
      ["попередньої", "the previous"],
      ["точки.", "dot."],
    ],
  ],
  [
    "-1107.0426 7.5 -172.87209",
    [4.59, 3],
    [4.5, 3],
    [
      ["Постав", "Mark"],
      ["точку", "a dot"],
      ["в координатах", "at"],
      ["(4.5,3).", "(four and a half,three)."],
      ["Проведи", "Draw"],
      ["лінію", "a line"],
      ["до", "to"],
      ["попередньої", "the previous"],
      ["точки.", "dot."],
    ],
  ],
  [
    "555.9417 -846.56794",
    [4, 1.05],
    [4, 1],
    [
      ["Постав", "Mark"],
      ["точку", "a dot"],
      ["в координатах", "at"],
      ["(4,1).", "(four,one)."],
      ["Проведи", "Draw"],
      ["лінію", "a line"],
      ["до", "to"],
      ["попередньої", "the previous"],
      ["точки.", "dot."],
    ],
  ],
  [
    "7.5 1134.22013",
    [1, 1.05],
    [1, 1],
    [
      ["Постав", "Mark"],
      ["точку", "a dot"],
      ["в координатах", "at"],
      ["(1,1).", "(one,one)."],
      ["Проведи", "Draw"],
      ["лінію", "a line"],
      ["до", "to"],
      ["попередньої", "the previous"],
      ["точки.", "dot."],
    ],
  ],
  [
    "-2837.2379 852.4217",
    [5, 11],
    [5, 11],
    [
      ["Постав", "Mark"],
      ["точку", "a dot"],
      ["в координатах", "at"],
      ["(5,11).", "(five,eleven)."],
      ["Проведи", "Draw"],
      ["лінію", "a line"],
      ["між", "between"],
      ["новою", "the new"],
      ["і", "and"],
      ["попередньої", "the previous"],
      ["точками.", "dots."],
    ],
  ],
  [
    "-2.1217",
    [8, 11],
    [8, 11],
    [
      ["Постав", "Mark"],
      ["точку", "a dot"],
      ["в координатах", "at"],
      ["(8,11).", "(eight,eleven)."],
      ["Проведи", "Draw"],
      ["лінію", "a line"],
      ["до", "to"],
      ["попередньої", "the previous"],
      ["точки.", "dot."],
    ],
  ],
  [
    "z m -1147.3121",
    [],
    [12, 1],
    [
      ["Проведи", "Draw"],
      ["лінію", "a line"],
      ["між", "between"],
      ["останньою", "the last"],
      ["точкою", "dot"],
      ["і", "and"],
      ["першою", "the first dot"],
      ["в координатах", "at"],
      ["(12,1).", "(twelve,one)."],
    ],
    true,
  ], // true means the end
  [
    "-1133.7726 -429.6174 -1005.3999 -414.6174",
    [7.9, 5, 6.4, 8.5],
    [8, 5, 6.5, 8.5],
    [
      ["Постав", "Mark"],
      ["точку", "a dot"],
      ["в координатах", "at"],
      ["(8,5)", "(eight,five)"],
      ["і", "and"],
      ["(6.5,8.5).", "(six and a half, eight and a half)."],
      ["Проведи", "Draw"],
      ["лінію", "a line"],
      ["між", "between"],
      ["цими", "these"],
      ["двома", "two"],
      ["точками.", "dots."],
    ],
  ],
  [
    "1005.3999 z",
    [4.95, 5],
    [5, 5, 8, 5],
    [
      ["Постав", "Mark"],
      ["точку", "a dot"],
      ["в координатах", "at"],
      ["(5,5).", "(five,five)."],
      ["Проведи", "Draw"],
      ["лінію", "lines"],
      ["до", "to"],
      ["попередніх", "the previous"],
      ["двох", "two"],
      ["точок.", "dots."],
    ],
  ],
];
const pathSegmentsForUpperB = [
  [
    "m 2567.6078,-6979.8872 c 0,136.5707 -55.8961,291.3872 -150.96,418.5855 -93.7993,103.5439 -82.2125,96.3486 -150.7211,147.4511",
    [9.45, 4, 8.43, 2],
    [9.5, 4, 8.5, 2],
    [
      ["Постав", "Mark"],
      ["дві", "two"],
      ["точки", "dots"],
      ["в координатах", "at"],
      ["(9.5,4).", "(nine and a half, four)"],
      ["i", "and"],
      ["(8.5,2).", "(eight and a half, two)."],
      ["Проведи", "Draw"],
      ["дугу", "an arc"],
      ["між", "between"],
      ["цими", "these"],
      ["точками.", "dots."],
    ],
  ],
  [
    "-231.7344,157.4706 -445.3023,255.8234 -708.3882,274.5132 -406.6435,28.8881",
    [5.93, 1.05],
    [6, 1],
    [
      ["Постав", "Mark"],
      ["точку", "a dot"],
      ["в координатах", "at"],
      ["(6,1).", "(six, one)."],
      ["Проведи", "Draw"],
      ["дугу", "an arc"],
      ["до", "to"],
      ["попередньої", "the previous"],
      ["точки.", "dot."],
    ],
  ],
  [
    "-956.68274,6.971 -1413.97479,9.3984",
    [1, 1.02],
    [1, 1],
    [
      ["Проведи", "Draw"],
      ["горизонтальну", "a horizontal"],
      ["лінію", "line"],
      ["з", "from"],
      ["останньої", "the last"],
      ["точки", "dot"],
      ["до", "to"],
      ["точки", "the dot"],
      ["(1,1).", "(one, one)."],
    ],
  ],
  [
    "v -2839.3595 c 603.46099,0.1374 974.84589,-1.9066",
    [0.95, 11],
    [1, 11],
    [
      ["Проведи", "Draw"],
      ["вертикальну", "a vertical"],
      ["лінію", "line"],
      ["з", "from"],
      ["останньої", "the last"],
      ["точки", "dot"],
      ["до", "to"],
      ["точки", "the dot"],
      ["(1,11).", "(one, eleven)."],
    ],
  ],
  [
    "1415.22259,7.7618 257.5721,29.1431 392.3626,41.1506",
    [6, 10.95],
    [6, 11],
    [
      ["Проведи", "Draw"],
      ["горизонтальну", "a horizontal"],
      ["лінію", "line"],
      ["з", "from"],
      ["останньої", "the last"],
      ["точки", "dot"],
      ["до", "to"],
      ["точки", "the dot"],
      ["(6,11).", "(six, eleven)."],
    ],
  ],
  [
    "568.8704,133.0619 210.8064,98.932 274.6662,315.3721 274.6662,432.9747 0,136.5708 -58.5395,302.3348 -130.6185,407.2919 -72.079,103.6927 -158.8748,229.6235 -290.3874,287.7925 v 0.1746 c 105.8092,21.0168",
    [8, 10.45, 8.91, 9, 8.45, 7.5, 7.45, 6.5],
    [8, 10.5, 9, 9, 8.5, 7.5, 7.5, 6.5],
    [
      ["Постав", "Mark"],
      ["точки", "a dot"],
      ["в координатах", "at"],
      ["(8,10.5).", "(eight, ten and a half)."],
      ["Далі", "Then"],
      ["в координатах", "at"],
      ["(9,9).", "(nine, nine)."],
      ["Наступна", "The next"],
      ["точка", "dot"],
      ["в координатах", "at"],
      ["(8.5,7.5).", "(eight and a half, seven and a half)."],
      ["Остання", "The last"],
      ["точка", "dot"],
      ["в координатах", "at"],
      ["(7.5,6.5).", "(seven and a half, six and a half)."],
      ["Зʼєднай", "Connect"],
      ["за допомогою", "with"],
      ["дуги", "an arc"],
      ["всі", "all"],
      ["ці", "these"],
      ["чотири", "four"],
      ["точки", "dots"],
      ["і", "and"],
      ["попередню", "the previous"],
      ["точку", "dot."],
    ],
  ],

  [
    "196.487,82.1165 274.857,145.7103 58.3756,47.3693 109.9225,92.7836 155.8076,141.9076 108.7508,115.0735 155.6262,244.3189 155.6262,432.736",
    [8.45, 6],
    [8.5, 6, 9.5, 4],
    [
      ["Постав", "Mark"],
      ["точки", "a dot"],
      ["в координатах", "at"],
      ["(8.5,6).", "(eight and a half, six)"],
      ["і", "and"],
      ["зʼєднай", "connect"],
      ["точки", "dots"],
      ["за допомогою", "with"],
      ["дуги,", "an arc"],
      ["щоб", "to"],
      ["створити", "form"],
      ["фігуру", "a shape."],
    ],
  ],
  [
    "z m -873.2337,-1145.8517 c 0,-132.648 -72.341,-291.4316 -418.4983,-291.9351",
    [6.45, 8, 4.95, 9],
    [6.5, 8, 5, 9],
    [
      ["Постав", "Mark"],
      ["дві", "two"],
      ["точки", "dots"],
      ["в координатах", "at"],
      ["(6.5,8).", "(six and a half, eight)"],
      ["і", "and"],
      ["(5,9).", "(five, nine)."],
      ["Проведи", "Draw"],
      ["дугу", "an arc"],
      ["між", "between"],
      ["цими,", "these"],
      ["точками,", "dots"],
      ["щоб", "to"],
      ["почати", "start"],
      ["формувати", "forming"],
      ["верхню", "the upper"],
      ["внутрішню", "inner"],
      ["частину", "part"],
      ["літери", "of the letter."],
    ],
  ],
  ["-109.6354,-3.5366 -243.7662,11.2063 -407.72824,11.2063", [3.5, 9], [3.5, 9]],
  ["l -7.5,567.4972 c 103.82336,-7.1786", [3.45, 7], [3.5, 7]],
  ["265.17404,10.5682 413.05614,-1.7862 201.2909,-6.4929", [5, 7], [5, 7]],
  ["405.5669,-47.2236 420.6704,-284.9822", [], [6.5, 8]],
  ["z m -267.7552,716.26 -561.46415,4.4058", [5.5, 5.5, 3.5, 5.5], [5.5, 5.5, 3.5, 5.5]],
  ["-12.00719,704.8033 c 322.67444,-1.3754", [3.47, 3], [3.5, 3]],
  ["353.61024,33.3433 555.77104,-1.8969", [5.5, 3], [5.5, 3]],
  ["159.3294,-27.7739 275.6042,-135.14 305.397,-272.3995 10.9205,-50.3127", [6.45, 4], [6.5, 4]],
  ["11.2291,-117.6264 -0.4745,-168.0161", [6.47, 4.52], [6.5, 4.5]],
  ["-26.4582,-113.9157 -121.8069,-217.998 -287.2222,-266.8966 z", [], [5.5, 5.5]],
];

// const pointsUpperA = [
// [9, 5],
// ];

// Function to extract endpoint coordinates from SVG path segments

// This is a reusable component that can animate any SVG path

// Example usage with your letter A
{
  /* <SVGLetterAnimator
  // svgPath={letterAPath}
  letter={letter}
  viewBox={letterAViewBox}
  width={1808}
  height={1533}
/> */
}
// const letterAViewBox = "0 -8000 12000 8000";
const DrawingLetter: React.FC<{ onlyCoords?: boolean }> = ({ onlyCoords }) => {
  const [svgComponents, setSvgComponents] = useState<
    Record<string, { left: number; top: number; shape: string }>
  >({});
  const studentUser = useLessonsStore((state) => state.studentUser);
  const setStudentUser = useLessonsStore((state) => state.setStudentUser);
  const [newUserShapes, setNewUserShapes] = useState("");

  const letterOffset: Record<string, { top: number; left: number }> = {
    // Ball3: {
    //   top: 175,
    //   left: 68,
    // },
    // A: {
    //   top: 0,
    //   left: 0,
    // },
    // I: {
    //   top: 0,
    //   left: 0,
    // },
  };

  if (onlyCoords) {
    return (
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          transform: "scale(0.8)",
          transformOrigin: "50% 0%",
        }}
      >
        <LeftAxis />
        <RightAxis />
        <CoordsGrid light />
      </Box>
    );
  }

  return (
    <Box sx={{ position: "relative", backgroundColor: "redd", overflow: "hidden" }}>
      <Box sx={{ position: "relative", overflow: "hidden" }}>
        <LeftAxis />
        <RightAxis />
        <CoordsGrid />
      </Box>
      <ToastContainer
        position="top-right"
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
      />
      {studentUser && (
        <HStack>
          <TextField
            color="primary"
            value={newUserShapes}
            placeholder="Add new shapes to user"
            multiline={true}
            onChange={(event) => {
              setNewUserShapes(event.target.value);
            }}
            type="text"
            variant="outlined"
          />
          <Button
            variant="contained"
            onClick={async () => {
              await supabaseClient
                .from("users")
                .update({
                  drawing_shapes: Array.from(
                    new Set([
                      ...(studentUser.drawing_shapes || []),
                      ...newUserShapes
                        .split(",")
                        .map((s) => s.trim())
                        .filter(Boolean),
                    ]),
                  ),
                })
                .eq("id", studentUser.id);

              const { data: user } = await supabaseClient
                .from("users")
                .select()
                .eq("id", studentUser.id)
                .single();

              if (user) setStudentUser(user);
            }}
          >
            Update
          </Button>
        </HStack>
      )}
      {Object.values(svgComponents).map((component) => {
        return (
          <SVGWithPoints
            key={component.shape}
            svgComponents={svgComponents}
            onRemoveShape={(svgComponents: any, toastId: number) => {
              setSvgComponents((prevState) => {
                // Create a new object without the shape we want to remove
                const newState = { ...prevState };
                delete newState[component.shape];
                return newState;
              });
              if (toastId) {
                toast.dismiss(toastId);
              }
            }}
            letter={component.shape}
            left={component.left}
            top={component.top}
          />
        );
      })}

      <Box>
        <ItalicTitle text="Letters" />
        {rowLetters.map((shape) => {
          return (
            <Button
              color={studentUser?.drawing_shapes?.includes(shape) ? "primary" : "error"}
              onClick={() => {
                if (!svgComponents[shape]) {
                  setSvgComponents((prev) => {
                    return {
                      ...prev,
                      [shape]: {
                        left: letterOffset[shape]?.left || 0,
                        top: letterOffset[shape]?.top || 0,
                        shape,
                      },
                    };
                  });
                }
              }}
            >
              {shape}
            </Button>
          );
        })}
      </Box>
      <Box>
        <ItalicTitle text="Letter things" />
        {rowLetterThings.map((shape) => {
          return (
            <Button
              color={studentUser?.drawing_shapes?.includes(shape) ? "primary" : "error"}
              onClick={() => {
                if (!svgComponents[shape]) {
                  setSvgComponents((prev) => {
                    return {
                      ...prev,
                      [shape]: {
                        left: letterOffset[shape]?.left || 0,
                        top: letterOffset[shape]?.top || 0,
                        shape,
                      },
                    };
                  });
                }
              }}
            >
              {shape}
            </Button>
          );
        })}
      </Box>
      <Box>
        <ItalicTitle text="Flags" />
        {rowFlags.map((shape) => {
          return (
            <Button
              color={studentUser?.drawing_shapes?.includes(shape) ? "primary" : "error"}
              onClick={() => {
                if (!svgComponents[shape]) {
                  setSvgComponents((prev) => {
                    return {
                      ...prev,
                      [shape]: {
                        left: letterOffset[shape]?.left || 0,
                        top: letterOffset[shape]?.top || 0,
                        shape,
                      },
                    };
                  });
                }
              }}
            >
              {shape}
            </Button>
          );
        })}
      </Box>
      <AdminAddWordsToTheLastLesson />
      <AdminFindAndUpdateWord />
    </Box>
  );
};

export default DrawingLetter;
