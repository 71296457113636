const lowerI = (step: number) => (
  <svg
    width="23.156cm"
    height="21.138cm"
    preserveAspectRatio="xMidYMid"
    version="1.0"
    viewBox="0 0 656.39 599.19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" stroke="#000" stroke-width="3.7629">
      {/* {(showFullPath || step > 0) && ( */}
      <path d="m156.22 580.4 7.3e-4 0.38818-142.62 0.75-0.003263-1.7271" />
      {/* {(showFullPath || step > 1) && */}
      <path d="m13.022 184.48 142.45-0.74908 5e-4 0.26075" />
      {/* {(showFullPath || step > 2) && */}
      <path d="m155.37 126.57v1.021h-142.42v-1.4194" />
      {/* // } */}
      {/* {(showFullPath || step > 3) && */}
      <path d="m13.239 41.466h141.37" />
      {/* // } */}
      {/* {(showFullPath || step > 4) && ( */}
      {/* <> */}
      <path d="m154.61 41.466h0.75879v85.103" />
      <path d="m12.949 126.17v-84.705h0.29004" />
      <path d="m155.47 183.99 0.74877 396.41" />
      <path d="m13.594 579.81-0.74674-395.33 0.17432-9.2e-4" />
      {/* </> */}
      {/* )} */}
    </g>
  </svg>
);

export default lowerI;
