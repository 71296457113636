import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Button, Link, Slider } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useWindowSize } from "react-use";
import isAdmin from "../../../common/isAdmin";
import { useLessonsStore } from "../../../store/lessonsStore";
import Box from "../Box";
import HStack from "../HStack";
import IconButton from "../IconButton";
import Loader from "../Loader";

const YouTubePlayer: React.FC<{
  id: string;
  start: number;
  duration: number;
  width?: number;
  height?: number;
  inputPlaying?: boolean;
  withoutDelay?: boolean;
  fullAdminWidth?: boolean;
  forceReload?: number;
  image?: string;
  // modalId?: string;
}> = ({
  id,
  start,
  withoutDelay,
  fullAdminWidth,
  width,
  height,
  duration: inputDuration,
  inputPlaying,
  image,
}) => {
  const playerRef = useRef<any>(null);
  const [duration, setDuration] = useState<number>(inputDuration || 10.5);

  useEffect(() => {
    setDuration(inputDuration);
  }, [id, inputDuration]);

  const [playing, setPlaying] = useState(false);
  const { width: windowSize } = useWindowSize();
  const authUser = useLessonsStore((state) => state.authUser);
  const [totalDuration, setTotalDuration] = useState<number>();
  useEffect(() => {
    if (playerRef.current) {
      const videoDuration = playerRef.current.getDuration();
      if (videoDuration && totalDuration !== videoDuration) {
        setTotalDuration(videoDuration);
      }
    }
  }, [playerRef, inputDuration, totalDuration]);

  const [currentTime, setCurrentTime] = useState(start);
  const textPartVideoByStartAndEndProps = useLessonsStore(
    (state) => state.textPartVideoByStartAndEndProps,
  );
  const [muted, setMuted] = useState(inputPlaying !== undefined);
  const globalVideoPreparingMap = useLessonsStore((state) => state.globalVideoPreparingMap);
  const setGlobalVideoPreparingMap = useLessonsStore((state) => state.setGlobalVideoPreparingMap);

  const [firstVideoLoading] = useState(true);
  //setFirstVideoLoading

  // useEffect(() => {
  // if (onUpdatePreparing && preparePlaying !== null && inputPlaying === true && !muted) {
  //   onUpdatePreparing(preparePlaying);
  // }
  // }, [preparePlaying, inputPlaying, muted]);
  // useEffect(() => {
  //   if (inputPreparing !== null) {
  //     setPreparePlaying(false);
  //     setMuted(false);
  //   }
  // }, [globalVideoPreparingMap]);

  const [showLoader, setShowLoader] = useState(true);
  useEffect(() => {
    if (fullAdminWidth) {
      setShowLoader(false);
      setMuted(false);
    }
  }, [fullAdminWidth, showLoader]);
  const [videoLoading, setVideoLoading] = useState(true);
  useEffect(() => {
    let timer: any = null;
    const videoId = `${id}`;
    // const videoId = `${modalId}_${id}`;

    if (!videoLoading) {
      if (globalVideoPreparingMap[videoId] === undefined && inputPlaying) {
        setMuted(true);
        setGlobalVideoPreparingMap(videoId, true);
        if (!fullAdminWidth) {
          setShowLoader(true);
        }
      } else if (globalVideoPreparingMap[videoId] && inputPlaying) {
        timer = setTimeout(() => {
          setMuted(false);
          setShowLoader(false);
          setGlobalVideoPreparingMap(videoId, false);
          if (inputPlaying === undefined) setPlaying(true);
          if (inputPlaying) {
            setCurrentTime(Math.ceil(start));
            (playerRef.current as any).seekTo(Math.ceil(start));
            setPlaying(true);
          }
        }, 2000);
        // setMuted(false);
        // setShowLoader(false);
      } else if (globalVideoPreparingMap[videoId] === false && inputPlaying) {
        setMuted(false);
        setShowLoader(false);
        setCurrentTime(Math.ceil(start));
        (playerRef.current as any).seekTo(Math.ceil(start));
        setPlaying(true);
      }
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [
    globalVideoPreparingMap,
    id,
    inputPlaying,
    videoLoading,
    fullAdminWidth,
    setGlobalVideoPreparingMap,
    start,
  ]);

  useEffect(() => {
    // reset the video to the start if there is an inputPlaying prop
    if (inputPlaying) {
      setCurrentTime(Math.ceil(start));
      (playerRef.current as any).seekTo(Math.ceil(start)); // Go back to the start time
      setPlaying(true);
    }
    if (!inputPlaying) setPlaying(false);
  }, [inputPlaying, start, globalVideoPreparingMap, id]);

  useEffect(() => {
    setCurrentTime(start);
  }, [start]);

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  const { videoScale, videoWidth, videoHeight, videoContainerWidth } = useMemo(() => {
    const fullAdminWidth = true;
    if (windowSize < 400 || fullAdminWidth) {
      return {
        // videoWidth: 9,
        // videoHeight: 5,
        videoWidth: "100%",
        videoHeight: "100%",
        videoContainerWidth: fullAdminWidth ? "100%" : 280,
        // videoContainerHight: fullAdminWidth ? "100" : 154,
        videoScale: 1,
      };
    }
    if (windowSize < 500) {
      return {
        // videoWidth: 12,
        // videoHeight: 7,
        videoWidth: "100%",
        videoHeight: "100%",
        videoContainerWidth: 355,
        // videoContainerHight: 210,
        videoScale: 1,
      };
    }
    return {
      // videoWidth: 16,
      // videoHeight: 9,
      videoWidth: 7,
      videoHeight: 6,
      // videoWidth: 11,
      // videoHeight: 4,
      videoContainerWidth: 455,
      // videoContainerHight: 277,
      videoScale: 69,
    };
  }, [windowSize]);

  const handleSliderChange = (e: any) => {
    const newTime = parseFloat(e.target.value);
    setCurrentTime(Math.ceil(newTime * 1000) / 1000);
    (playerRef.current as any).seekTo(Math.ceil(newTime * 1000) / 1000);
  };

  const timeUpdateLoop = useCallback(() => {
    // if (playerRef.current && !firstVideoLoading) {
    if (playerRef.current) {
      const newCurrentTime = (playerRef.current as any).getCurrentTime();

      if (newCurrentTime > start + duration) {
        setCurrentTime(Math.ceil(start));
        if (!textPartVideoByStartAndEndProps.endless) {
          setPlaying(false);
        }
        // if (inputPlaying === undefined) setPlaying(false);
        (playerRef.current as any).seekTo(Math.ceil(start)); // Go back to the start time
      } else if (newCurrentTime < start) {
        setCurrentTime(Math.ceil(start));
        (playerRef.current as any).seekTo(Math.ceil(start)); // Go back to the start time
      } else {
        setCurrentTime(newCurrentTime);
      }
    }
  }, [textPartVideoByStartAndEndProps.endless, start, duration]);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    timer = setInterval(() => {
      if (playing) {
        timeUpdateLoop();
      }
    }, 500);
    // }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [currentTime, playing, timeUpdateLoop]);

  if (!authUser) return null;

  if (fullAdminWidth) {
    return (
      <>
        <Box
          position="relative"
          pt={`52.5%`}
          // width={width || "100%"}
          width={videoContainerWidth}
          // mb={4}
          sx={{
            display: "flex",
            overflow: "hidden",
            border: "2px solid #dbdada",
          }}
        >
          <Box position="absolute" top={0} left={0} width="100%" height="100%">
            <Box
              component={ReactPlayer}
              controls={false}
              light={image || ""}
              ref={playerRef}
              playing={playing}
              width="100%"
              height={height || "100%"}
              // muted={muted}
              onReady={() => {
                setVideoLoading(false);
              }}
              onPlay={() => {
                if (firstVideoLoading) {
                  if (inputPlaying === undefined) setPlaying(false);
                }
              }}
              onPause={() => setPlaying(false)}
              sx={{
                width: "100% !important",
                height: "100% !important",
                position: "absolute",
                top: 0,
                left: 0,
                pointerEvents: !isAdmin(authUser?.id) ? "none" : "auto",
                display: "flex",
                justifyContent: "center",
                "& div": {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                },
                "& iframe": {
                  width: videoWidth,
                  height: videoHeight,
                  // transform: "scale(31)",
                  transform: `scale(${videoScale})`,
                  transformOrigin: "center center",
                  position: "relative",
                },
              }}
              config={{
                youtube: {
                  playerVars: {
                    controls: false,
                    start, // Set the start time
                    cc_load_policy: 0, // Disable subtitles
                    iv_load_policy: 3, // Hide annotations
                    modestbranding: 1,
                  },
                },
              }}
              url={`https://youtu.be/${id}`}
            />
            {!playing && !isAdmin(authUser?.id) && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 2,
                  backgroundColor: "red",
                  backgroundPosition: "center",
                  backgroundSize: "100%",
                  backgroundImage: `url(https://img.youtube.com/vi/${id}/0.jpg)`,
                }}
              ></Box>
            )}
          </Box>
        </Box>
        {
          <HStack
            width={videoContainerWidth}
            sx={{
              backgroundColor: "white",
              // position: "absolute",
              // bottom: -40,
              left: 0,
              // width: "100%",
              zIndex: 5,
              flexWrap: "nowrap",
              alignItems: "center",
              // borderTop: "2px solid gray",
            }}
          >
            <IconButton onClick={handlePlayPause}>
              {playing ? <PauseIcon /> : <PlayArrowIcon />}
            </IconButton>

            <Box position="relative" width="100%" display="flex">
              <Slider
                aria-label="Volume"
                value={showLoader ? 0 : currentTime}
                min={start}
                max={start + duration}
                size="small"
                sx={{ width: "100%" }}
                // step="any" // Allow for smooth seeking
                onChange={handleSliderChange}
              />
            </Box>

            <Button>
              {Math.floor((currentTime - start) / 60)}.{Math.floor((currentTime - start) % 60)}
            </Button>
          </HStack>
        }
        {isAdmin(authUser?.id) && (
          <Button
            onClick={() => {
              navigator.clipboard.writeText(totalDuration?.toString() || "");
            }}
          >
            {totalDuration}
          </Button>
        )}
      </>
    );
  }

  return (
    <>
      <Box
        position="relative"
        // pt={`${videoContainerHight}px`}
        // pt={`calc(52.5% + 20px)`}
        pt={`calc(52.5%)`}
        // width={width || "100%"}
        // width={videoContainerWidth}
        width="100%"
        // mb={4}
        sx={{
          display: "flex",
          overflow: "hidden",
          border: "2px solid #dbdada",
        }}
      >
        {(showLoader || (videoLoading && !withoutDelay)) && (
          <Box
            position="absolute"
            top={0}
            left={0}
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundColor: "white",
            }}
            zIndex={4}
          >
            <Loader />
          </Box>
        )}
        <Box position="absolute" top={0} left={0} width="100%" height="100%">
          <Box
            component={ReactPlayer}
            controls={false}
            ref={playerRef}
            playing={playing}
            width="100%"
            height={height || "100%"}
            muted={muted}
            onReady={() => {
              setVideoLoading(false);
            }}
            onPlay={() => {
              if (firstVideoLoading) {
                if (inputPlaying === undefined) setPlaying(false);
              }
            }}
            onPause={() => setPlaying(false)}
            sx={{
              width: "100% !important",
              height: "100% !important",
              position: "absolute",
              top: 0,
              left: 0,
              pointerEvents: "none",
              display: "flex",
              justifyContent: "center",
              "& div": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              },
              "& iframe": {
                // width: videoWidth,
                // height: videoHeight,
                // width: "1%",
                // height: "1%",
                // transform: "scale(100)",
                // transform: "scale(31)",
                // transform: `scale(${videoScale})`,
                // transformOrigin: "center center",
                position: "relative",
              },
            }}
            config={{
              youtube: {
                playerVars: {
                  controls: false,
                  start, // Set the start time
                  cc_load_policy: 1, // Disable subtitles
                  // iv_load_policy: 3, // Hide annotations
                  // modestbranding: 1,
                  cc_lang_pref: "en", // Set subtitle language
                  // cc_load_policy: 1,      // Force subtitles to load
                },
              },
            }}
            url={`https://youtu.be/${id}`}
          />
          {!playing && !isAdmin(authUser?.id) && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 2,
                backgroundColor: "red",
                backgroundPosition: "center",
                backgroundSize: "100%",
                backgroundImage: `url(https://img.youtube.com/vi/${id}/0.jpg)`,
              }}
            >
              {/* <IconButton
                sx={{
                  position: "relative",
                  zIndex: 5,
                  // backgroundColor: "white",
                  "&::before": {
                    content: "' '",
                    position: "absolute",
                    top: 32,
                    left: 35,
                    backgroundColor: "white",
                    width: 20,
                    height: 20,
                  },
                  "&:hover": {},
                }}
                onClick={() => {
                  if (preparePlaying === null) {
                    setPreparePlaying(true);
                    setPlaying(true);
                  } else {
                    setPlaying((prev) => !prev);
                  }
                }}
              >
                <YouTubeIcon sx={{ fontSize: 70, color: "red", position: "relative", zIndex: 1 }} />
              </IconButton> */}
            </Box>
          )}
        </Box>
      </Box>
      {/* {!isAdmin(authUser?.id) && ( */}
      {
        <HStack
          // width={videoContainerWidth}
          width={`calc(100vw - 30px)`}
          maxWidth="100%"
          sx={{
            backgroundColor: "white",
            // position: "absolute",
            // bottom: -40,
            left: 0,
            // width: "100%",
            zIndex: 5,
            flexWrap: "nowrap",
            alignItems: "center",
            // borderTop: "2px solid gray",
          }}
        >
          <IconButton onClick={handlePlayPause}>
            {playing ? <PauseIcon /> : <PlayArrowIcon />}
          </IconButton>

          <Box position="relative" width="100%" display="flex">
            <Slider
              aria-label="Volume"
              value={showLoader ? 0 : currentTime}
              min={start}
              max={start + duration}
              size="small"
              sx={{ width: "100%" }}
              // step="any" // Allow for smooth seeking
              onChange={handleSliderChange}
            />
          </Box>

          <IconButton
            onClick={() => {
              setPlaying(false);
            }}
            component={Link}
            target="_blank"
            href={`https://youtu.be/${id}`}
          >
            <OpenInNewIcon />
          </IconButton>
          <Button
            sx={{
              pointerEvents: "none",
            }}
            // onClick={() => {
            //   setDuration(currentTime - start);
            // }}
          >
            {Math.floor((currentTime - start) / 60)}.{Math.floor((currentTime - start) % 60)}
          </Button>
        </HStack>
      }
    </>
  );
};

export default YouTubePlayer;
