import { Box } from "@mui/material";
import "draft-js/dist/Draft.css";
import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useLessonsStore } from "../../../store/lessonsStore";
import "./root.css";

import "react-quill/dist/quill.snow.css";

// import Quill from "quill";
import isAdmin from "../../../common/isAdmin";
import useHomeWorkDetection from "../../../hooks/useHomeWorkDetection";
import AdminScreen from "../../Admin/AdminScreen";
import AdminAddVerse from "../../AdminAddVerse";
import AdminCheckUserQuotes from "../../AdminCheckUserQuotes";
import AdminCheckUserStories from "../../AdminCheckUserStories";
import AdminCreateDifferenceLesson from "../../AdminCreateDifferenceLesson";
import AdminCreateGrammarCourse from "../../AdminCreateGrammarCourse";
import AdminFindPhrasesInPdf from "../../AdminFindPhrasesInPdf";
import AdminLearnPoems from "../../AdminLearnPoems";
import AdminUploadVideo from "../../AdminUploadVideo";
import CreatePersonalLesson from "../../CreatePersonalLesson";
import Declarations from "../../Declarations";
import DifferenceSubject from "../../Difference/DifferenceSubject";
import Difference from "../../Difference/Page";
import EnglishKidsLessonsLesson from "../../EnglishKidsLessons/EnglishKidsLessonsLesson";
import EnglishKidsLessonsScreen from "../../EnglishKidsLessons/EnglishKidsLessonsScreen";
import EnglishLessonsLesson from "../../EnglishLessons/EnglishLessonsLesson";
import EnglishLessonsScreen from "../../EnglishLessons/EnglishLessonsScreen";
import GrammarLesson from "../../Grammar/GrammarLesson";
import { GrammarScreen } from "../../Grammar/GrammarScreen";
import Home from "../../Home/Page";
import LearnToReadLesson from "../../LearnToRead/LearnToReadLesson";
import { LearnToReadScreen } from "../../LearnToRead/LearnToReadScreen";
import PersonalDialogs from "../../PersonalDialogs";
import PersonalLessons from "../../PersonalLessons";
import HarryPotterChapter from "../../Reading/HarryPotterChapter";
import HarryPotterScreen from "../../Reading/HarryPotterScreen";
import HarryPotterPhilosophersStone from "../../Reading/HarryPotterScreen/HarryPotterPhilosophersStone";
import Reading from "../../Reading/ReadingScreen";
import RepetitionUserSentenceScreen from "../../Sentence/RepetitionUserSentenceScreen";
import RepetitionUserWordsScreen from "../../Word/RepetitionUserWordsScreen";
import NotFound from "../NotFound";
import PageTranslation from "../PageTranslation";
import TextPartVideoByStartAndEnd from "../TextPartVideoByStartAndEnd";
import GrammarFeed from "../grammar/Feed/GrammarFeed";
import ControllingStudentPage from "./ControllingStudentPage";
import DisplayScreenForStudent from "./DisplayScreenForStudent";
import useAdminControlRealtime from "./hooks/realtime/useAdminControlRealtime";
import useUserUpdate from "./hooks/realtime/useUserUpdate";
import useFetchIsPersonalLesson from "./hooks/useFetchIsPersonalLesson";
import useIsTeacherLesson from "./hooks/useIsTeacherLesson";
import usePageBeforeAuthNavigate from "./hooks/usePageBeforeAuthNavigate";
import usePageReloadOnHomeWorkOldDate from "./hooks/usePageReloadOnHomeWorkOldDate";
import DrawingBoard from "./DrawingBoard";

// const Color = Quill.import("formats/color");

// Quill.register(Color, true);

const Root: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const session = useLessonsStore((state) => state.session);
  const authUser = useLessonsStore((state) => state.authUser);
  const isPersonalLesson = useLessonsStore((state) => state.isPersonalLesson);
  const shareScreenOptions = useLessonsStore((state) => state.shareScreenOptions);

  useHomeWorkDetection();
  useFetchIsPersonalLesson();
  useIsTeacherLesson();
  usePageReloadOnHomeWorkOldDate();
  usePageBeforeAuthNavigate();
  useAdminControlRealtime();

  // realtime
  useUserUpdate();

  useEffect(() => {
    if (location.pathname === "/#") {
      navigate("/");
    }
  }, [location.pathname, navigate]);

  const editorText = useLessonsStore((state) => state.editorState);
  const shareLocalScreen = useLessonsStore((state) => state.shareLocalScreen);

  useEffect(() => {
    if (isAdmin(authUser?.id) && shareLocalScreen) {
      localStorage.setItem("editorText", JSON.stringify(editorText));
    }
  }, [authUser, editorText, shareLocalScreen]);

  if (!authUser) {
    return (
      <Box>
        <Routes>
          <Route path="/" element={<Home session={session} />} />
        </Routes>
      </Box>
    );
  }

  // if (isAdmin(" " as any) as any) return <ClickableMap />;
  // if (isAdmin("shevsky1488" as any) as any) return <DrawingLetters />;
  // if (isAdmin("sheiks1488" as any) as any) return <DrawingLowerLetterA />;

  return (
    <Box>
      <ControllingStudentPage />
      {/* <GrammarFeed /> */}
      {/* {shareScreenOptions.drawingBoard && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 99999999999999,
          }}
        >
          <Box sx={{ width: "100vw", height: "100vh", position: "absolute", top: 0, left: 0 }}>
            <DrawingBoard transparent={shareScreenOptions.drawingBackgroundColor} />
          </Box>
        </Box>
      )} */}
      {isAdmin(authUser?.id) && <DisplayScreenForStudent />}
      {/* {isAdmin(authUser?.id) && <ClickableMap />} */}
      {isAdmin(authUser?.id) && <PageTranslation />}

      {/* {isAdmin(authUser?.id) && <VideoCollageGenerator />} */}
      {/* {isAdmin(authUser?.id) && <DrawingCanvas />} */}
      {/* {authUser.uuid === "19c7ac10-110d-43e3-ad86-5e425aef49a5" && <SignInScreen />} */}
      <TextPartVideoByStartAndEnd />
      <Routes>
        <Route path="/learn-to-read" element={<LearnToReadScreen />} />
        <Route path="/learn-to-read/:lessonId" element={<LearnToReadLesson />} />
        {/* <Route path="/grammar" element={<GrammarScreen />} />
        <Route path="/grammar/grammar-lesson/:lessonId/:lessonType" element={<GrammarLesson />} /> */}
        <Route path="/" element={<Home session={session} />} />
        <Route path="/sentences" element={<RepetitionUserSentenceScreen />} />
        {isAdmin(authUser?.id) && <Route path="/lessons" element={<EnglishLessonsScreen />} />}
        {/* <Route path="/lessons/:date" element={<EnglishLessonsLesson />} /> */}
        <Route path="/english-lessons" element={<EnglishKidsLessonsScreen />} />
        <Route path="/english-lessons/:date" element={<EnglishKidsLessonsLesson />} />
        <Route path="/words" element={<RepetitionUserWordsScreen />} />

        {isPersonalLesson && (
          <>
            {/* <Route path="/difference" element={<Difference />}>
              <Route path=":subject" element={<DifferenceSubject />} />
            </Route> */}
            <Route path="/personal-lessons" element={<PersonalLessons />} />
            <Route path="/personal-dialogs" element={<PersonalDialogs />} />
            <Route path="/reading" element={<Reading />}>
              <Route path="/reading/harry-potter" element={<HarryPotterScreen />}>
                <Route
                  path="/reading/harry-potter/:bookId"
                  element={<HarryPotterPhilosophersStone />}
                ></Route>
                <Route
                  path="/reading/harry-potter/:bookId/:chapterId"
                  element={<HarryPotterChapter />}
                />
              </Route>
            </Route>
          </>
        )}
        {/* <Route path="/login10" element={<SignInScreen />} /> */}

        {(authUser?.uuid === "19c7ac10-110d-43e3-ad86-5e425aef49a5" ||
          authUser?.uuid === "86523831-4881-4b89-91b5-def185deba6e") && (
          <>
            <Route path="/declarations" element={<Declarations />} />
          </>
        )}
        {authUser?.uuid === "19c7ac10-110d-43e3-ad86-5e425aef49a5" && (
          <>
            {/* <Route path="/sanskrit" element={<SanskritRepetitionWordsScreen />} /> */}
            {/* <Route path="/bangla-sentences" element={<BanglaRepetitionSentencesScreen />} /> */}
            {/* <Route path="/previews" element={<Preview />} /> */}
            <Route path="/admin" element={<AdminScreen />} />
            <Route path="/learn-poems" element={<AdminLearnPoems />} />
            <Route path="/add-verse" element={<AdminAddVerse />} />
            <Route path="/upload-video" element={<AdminUploadVideo />} />
            <Route path="/create-personal-lesson" element={<CreatePersonalLesson />} />
            <Route path="/check-user-quotes" element={<AdminCheckUserQuotes />} />
            <Route path="/check-user-stories" element={<AdminCheckUserStories />} />
            <Route path="/create-difference-lesson" element={<AdminCreateDifferenceLesson />} />
            <Route path="/create-grammar-course" element={<AdminCreateGrammarCourse />} />
            <Route path="/find-phrases-in-pdf" element={<AdminFindPhrasesInPdf />} />
            {/* <Route
              path="/create-bangla-grammar-course"
              element={<AdminCreateBanglaGrammarCourse />}
            /> */}
            {/* <Route path="/add-bangla-sentence" element={<AdminAddBanglaSentence />} /> */}
            {/* <Route path="/bangla-grammar" element={<BanglaGrammarScreen />} /> */}
            {/* <Route
              path="/bangla-grammar/bangla-grammar-lesson/:lessonId/:lessonType"
              element={<BanglaGrammarLesson />}
            /> */}
          </>
        )}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Box>
  );
};

export default Root;
