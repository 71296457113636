const upperA = (step: number) => (
  <svg
    width="23.156cm"
    height="21.138cm"
    preserveAspectRatio="xMidYMid"
    version="1.0"
    viewBox="0 0 656.39 599.19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="matrix(.1 0 0 -.1 0 911)" fill="none" stroke="#000" strokeWidth="55.08">
      {/* {(showFullPath || step > 5) && (
        <path
          transform="scale(.97842 -1.0221)"
          d="m3628.1-5720.3 0.5689-8e-4 -588.05-1669.1-2.5543 7.383"
        />
      )}
      {(showFullPath || step > 7) && (
        <path transform="scale(.97842 -1.0221)" d="m2466.9-5731-4.2886 12.396 1165.5-1.7031" />
      )}
      {(showFullPath || step > 6) && (
        <path transform="scale(.97842 -1.0221)" d="m3038.1-7382.1-571.22 1651" />
      )}
      {(showFullPath || step > 1) && (
        <path transform="scale(.97842 -1.0221)" d="m3908.4-8771h3.1141l2026.1 5544-0.8382 2e-4" />
      )}
      {(showFullPath || step > 4) && (
        <path transform="scale(.97842 -1.0221)" d="m2182.4-8771h1726" />
      )}
      {(showFullPath || step >= 1) && (
        <path transform="scale(.97842 -1.0221)" d="m142.77-3228.7 2033.2-5542.3h6.4977" />
      )}
      {(showFullPath || step > 4) && (
        <path
          transform="scale(.97842 -1.0221)"
          d="m1591.8-3230.5-0.2782 0.6617-1449.4 2.8853 0.6307-1.7193"
          // stroke={(showFullPath || step < 6) ? "red" : "yellow"}
        />
      )}
      {(showFullPath || step > 2) && (
        <path transform="scale(.97842 -1.0221)" d="m2184.3-4618.2-9.1026-0.0185-583.38 1387.7" />
      )}
      {(showFullPath || step > 4) && (
        <path transform="scale(.97842 -1.0221)" d="m3914.1-4609.1-2.2837-5.5492-1727.5-3.5028" />
      )}
      {(showFullPath || step > 3) && (
        <path transform="scale(.97842 -1.0221)" d="m4480.2-3233.5-566.11-1375.6" />
      )}
      {(showFullPath || step > 4) && (
        <path transform="scale(.97842 -1.0221)" d="m5936.9-3227-1454-2e-4 -2.678-6.5075" />
      )} */}
      <path
        transform="scale(.97842 -1.0221)"
        d="m3628.1-5720.3 0.5689-8e-4 -588.05-1669.1-2.5543 7.383"
      />
      <path transform="scale(.97842 -1.0221)" d="m2466.9-5731-4.2886 12.396 1165.5-1.7031" />
      <path transform="scale(.97842 -1.0221)" d="m3038.1-7382.1-571.22 1651" />
      <path transform="scale(.97842 -1.0221)" d="m3908.4-8771h3.1141l2026.1 5544-0.8382 2e-4" />
      <path transform="scale(.97842 -1.0221)" d="m2182.4-8771h1726" />
      <path transform="scale(.97842 -1.0221)" d="m142.77-3228.7 2033.2-5542.3h6.4977" />
      <path
        transform="scale(.97842 -1.0221)"
        d="m1591.8-3230.5-0.2782 0.6617-1449.4 2.8853 0.6307-1.7193"
      />
      <path transform="scale(.97842 -1.0221)" d="m2184.3-4618.2-9.1026-0.0185-583.38 1387.7" />
      <path transform="scale(.97842 -1.0221)" d="m3914.1-4609.1-2.2837-5.5492-1727.5-3.5028" />
      <path transform="scale(.97842 -1.0221)" d="m4480.2-3233.5-566.11-1375.6" />
      <path transform="scale(.97842 -1.0221)" d="m5936.9-3227-1454-2e-4 -2.678-6.5075" />
    </g>
  </svg>
);

export default upperA;
