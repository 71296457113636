import convertCoordinatesNumbersToWords from "../../../../../../../common/convertCoordinatesNumbersToWords";

const upperNInstructions = (xOffset: number, yOffset: number): string[][] => [
  [
    `${convertCoordinatesNumbersToWords([
      1 + xOffset,
      1 + yOffset,
    ])}, ${convertCoordinatesNumbersToWords([21 + xOffset, 1 + yOffset], true)} 20 to the right.`,
  ],
  [
    `${convertCoordinatesNumbersToWords([
      1 + xOffset,
      19 + yOffset,
    ])}, ${convertCoordinatesNumbersToWords([21 + xOffset, 1 + yOffset], true)} 20 to the right.`,
  ],

  // ["Draw two lines to connect up and down lines."],
];

export default upperNInstructions;
