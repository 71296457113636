const ball1 = (step: number) => (
  <svg
    width="23.156cm"
    height="21.138cm"
    preserveAspectRatio="xMidYMid"
    version="1.0"
    viewBox="0 0 656.39 599.19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" stroke="#000" stroke-width="3.8268">
      <path d="m326.39 158.01-0.89221 0.7396 86.25 28.5 0.67792-0.50285" />
      <path d="m496.77 17.82-0.27251-0.82469-170.11 141.01" />
      <path d="m524.38 103.71 0.61981-0.45972-28.227-85.425" />
      <path d="m412.43 186.75 111.95-83.037" />
    </g>
    <g stroke="#000" stroke-width="3">
      <path d="m299.89 382.51 55.872-28.033 0.0783 0.26934" color="#000000" />
      <path
        d="m326.97 524.14c-6.6232-48.639-13.631-73.646-27.254-141.55l0.16863-0.0846"
        color="#000000"
      />
      <path
        d="m412.56 553.2-85.541-28.721c-0.0156-0.11508-0.0313-0.23002-0.0469-0.34483"
        color="#000000"
      />
      <path d="m355.84 354.74 57.775 198.81-1.053-0.35356" color="#000000" />
    </g>
    <g stroke="#000" stroke-width="3">
      <path d="m240.75 581.71v0.2915l-199.5-29.25 0.69593-0.67807" />
      <path d="m240.75 524.86v56.852" />
      <path d="m101.84 496.17 138.91 28.078v0.60645" />
      <path d="m41.946 552.07 57.804-56.322 2.0874 0.42193" />
    </g>
    <g fill="none" stroke="#000">
      <g stroke-width="3">
        <>
          <path d="m41.702 353.85 85.048 112.65 0.13617-0.069" />
          <path d="m97.542 239.18-56.292 114.07 0.45243 0.59927" />
          <path d="m208.87 265.96-110.62-28.212-0.70769 1.434" />
          <path d="m240.54 408.85 0.20512-0.10391-30.75-142.5-1.1295-0.28806" />
          <path d="m126.89 466.43 113.66-57.577" />
        </>
        <>
          <path d="m409.35 325.53-141.6 27.72-0.23742-0.68258" />
          <path d="m436.86 210.65 0.38951 0.0987-27.75 114.75-0.15083 0.0295" />
          <path d="m325.87 182.53 110.99 28.118" />
          <path d="m238.19 266.57 86.562-84.324 1.119 0.28347" />
          <path d="m267.51 352.57-29.763-85.567 0.43779-0.42648" />
        </>
        <>
          <path d="m213.93 241.15-0.18101 0.35042-142.5-28.5-0.15467-0.67983" />
          <path d="m272.04 127.98 0.20516 0.26654-58.319 112.9" />
          <path d="m183.93 14.473 0.56836-0.22266 87.545 113.73" />
          <path d="m39.062 71.521-0.061647-0.27096 144.93-56.777" />
          <path d="m71.095 212.32-32.034-140.8" />
        </>
      </g>
    </g>
  </svg>
);

export default ball1;
