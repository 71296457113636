import { Button, IconButton, Typography } from "@mui/material";
import Box from "../../../Unknown/Box";
import { learnToRead } from "../CourseProgram";
import HStack from "../../../Unknown/HStack";
import AddIcon from "@mui/icons-material/Add";
import { useLessonsStore } from "../../../../store/lessonsStore";
import supabaseClient from "../../../../common/supabaseClient";
import { Word } from "../../../Admin/SPTranslationItem";

const AdminLearnToRead = () => {
  const studentWordsToRead = useLessonsStore((state) => state.studentWordsToRead);
  const setStudentWordsToRead = useLessonsStore((state) => state.setStudentWordsToRead);
  const setGlobalWords = useLessonsStore((state) => state.setGlobalWords);
  const studentUser = useLessonsStore((state) => state.studentUser);

  const addWords = async (words: string[] | string[][]) => {
    const flatWords = Array.from(new Set([...words.flat(), ...studentWordsToRead]));
    console.log("🚀 ~ flatWords:", flatWords);

    const { data: spWords } = await supabaseClient
      .from("words")
      .select("name, translation, transcription")
      .in("name", words.flat());

    await supabaseClient
      .from("admin_control")
      .update({
        words_to_read: flatWords,
      })
      .eq("id", 0);
    setStudentWordsToRead(flatWords);
    if (spWords) setGlobalWords(spWords as Word[]);
  };

  return (
    <Box width="600px">
      <Button
        variant="contained"
        color="error"
        onClick={async () => {
          await supabaseClient
            .from("admin_control")
            .update({
              words_to_read: [],
            })
            .eq("id", 0);

          setStudentWordsToRead([]);
        }}
      >
        Reset reading words
      </Button>
      {Object.entries(learnToRead).map(([lesson, words]) => {
        return (
          <Box>
            <HStack>
              <IconButton color="primary" onClick={async () => addWords(words)}>
                <AddIcon />
              </IconButton>
              <Typography
                color={(studentUser?.english_lesson || 0) === +lesson ? "red" : "black"}
                variant="h2"
              >
                Lesson {lesson}
              </Typography>
            </HStack>
            {words.map((nextWords) => {
              return (
                <Box>
                  <hr />
                  <IconButton sx={{}} size="small" onClick={async () => addWords(nextWords)}>
                    <AddIcon />
                  </IconButton>
                  {nextWords.map((w) => (
                    <Typography
                      sx={{
                        display: "inline-block",
                        px: 1,
                        color: studentWordsToRead.includes(w) ? "green" : "black",
                      }}
                    >
                      {w}
                    </Typography>
                  ))}
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
};

export default AdminLearnToRead;
