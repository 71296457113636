import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { EnglishStory } from "../../../types/supabase";
import Box from "../Box";
import TextField from "../TextField";
import { Badge, Button, IconButton } from "@mui/material";
import supabaseClient from "../../../common/supabaseClient";
import { useLessonsStore } from "../../../store/lessonsStore";
import HStack from "../HStack";
import AdminComponentIndicator from "../AdminComponentIndicator";

const AdminTimeCorrection: React.FC<{
  story: EnglishStory;
  onStartChange: (start: number) => void;
  onEndChange: (duration: number) => void;
  onUpdate: () => void;
}> = ({ story, onStartChange, onEndChange, onUpdate }) => {
  const [start, setStart] = useState(+(story.clip_start || 0));
  const [end, setEnd] = useState(+(story.clip_start || 0) + +(story.duration || 0));
  const stories = useLessonsStore((state) => state.stories);
  const setStories = useLessonsStore((state) => state.setStories);

  return (
    <HStack>
      <AdminComponentIndicator title="AdminTimeCorrection" />
      <HStack width="100px">
        <TextField
          color="primary"
          fullWidth
          value={start}
          // sx={{ width: "70px" }}
          multiline={true}
          onChange={(event) => {
            if (+event.target.value) {
              setStart(+event.target.value);
            }
          }}
          type="text"
          variant="outlined"
        />
        <IconButton
          onClick={(event) => {
            const newStart = Math.round((start - 0.1) * 10) / 10;
            setStart(newStart);
            onStartChange(newStart);
          }}
        >
          <RemoveIcon />
        </IconButton>
        <IconButton
          onClick={(event) => {
            const newStart = Math.round((start + 0.1) * 10) / 10;
            setStart(newStart);
            onStartChange(newStart);
          }}
        >
          <AddIcon />
        </IconButton>
      </HStack>
      <HStack>
        <TextField
          color="primary"
          value={end}
          sx={{ width: "100px" }}
          multiline={true}
          onChange={(event) => {
            if (+event.target.value) {
              setEnd(+event.target.value);
            }
          }}
          type="text"
          variant="outlined"
        />
        <IconButton
          onClick={(event) => {
            const duration = Math.round(start - Math.round((end - 0.1) * 10) / 10);
            // const duration = Math.round((end - 0.1) * 10) / 10;
            setEnd(duration);
            onEndChange(duration);
          }}
        >
          <RemoveIcon />
        </IconButton>
        <IconButton
          onClick={(event) => {
            // const duration = Math.round(start - Math.round((end + 0.1) * 10) / 10);
            const duration = Math.round((end + 0.1) * 10) / 10;
            setEnd(duration);
            onEndChange(duration);
          }}
        >
          <AddIcon />
        </IconButton>
      </HStack>
      <Button
        variant="contained"
        color="error"
        onClick={async () => {
          await supabaseClient
            .from("english_verses")
            .update({
              clip_start: start,
              duration: end - start,
            })
            .eq("id", (story as any).story_id || (story as any).id);

          const { data: updatedStory } = await supabaseClient
            .from("english_verses")
            .select()
            .eq("id", (story as any).story_id || (story as any).id)
            .single();
          if (updatedStory) {
            setStories(
              stories.map((s) => {
                if (s.id === updatedStory) return updatedStory;
                return s;
              }),
            );
          }
          onUpdate();
        }}
      >
        update
      </Button>
    </HStack>
  );
};

export default AdminTimeCorrection;
