import LoadingButton from "@mui/lab/LoadingButton";
import { useLessonsStore } from "../../../../store/lessonsStore";
import { useState } from "react";
import {
  createUserLesson,
  getUserLesson,
  updateUserLesson,
} from "../../../Unknown/Root/ControllingStudentPage";
import supabaseClient from "../../../../common/supabaseClient";
import getTextFromEditorState from "../../../../common/getTextFromEditorState";
import AdminComponentIndicator from "../../../Unknown/AdminComponentIndicator";
import HStack from "../../../Unknown/HStack";
import VStack from "../../../Unknown/VStack";

const AddWordsToCurrentUserLesson: React.FC<{ words?: string[] }> = ({ words }) => {
  const [isLoading, setIsLoading] = useState(false);
  const studentUser = useLessonsStore((state) => state.studentUser);
  const studentWordsToRead = useLessonsStore((state) => state.studentWordsToRead);
  const newUserLesson = useLessonsStore((state) => state.newUserLesson);
  const setNewUserLesson = useLessonsStore((state) => state.setNewUserLesson);
  const setEditorState = useLessonsStore((state) => state.setEditorState);
  const editorState = useLessonsStore((state) => state.editorState);

  const getStudentUser = async () => {
    if (!studentUser) return;
    const { data: studentDbUser } = await supabaseClient
      .from("users")
      .select()
      .eq("id", studentUser.id)
      .single();

    return studentDbUser;
  };

  return (
    <HStack>
      <AdminComponentIndicator title="AddWordsToCurrentUserLesson" />
      <LoadingButton
        loading={isLoading}
        variant="contained"
        color={"primary"}
        onClick={async () => {
          if (!studentUser) {
            alert("no user found");
            return;
          }
          setIsLoading(true);
          const studentDbUser = await getStudentUser();

          if (!studentDbUser) return;

          // today user lesson
          const userLesson = await getUserLesson(studentUser.id, "english_user_lessons");
          const words = getTextFromEditorState(editorState)
            .split("  ")
            .map((w) => w.split("\n"))
            .flat()
            .map((w) => w.trim());

          if (userLesson) {
            const result = await updateUserLesson(
              {
                user: studentUser.id,
                // video_ids: [...(userLesson.video_ids || []), storyId].map((id) => Number(id)),
                reading_words: Array.from(
                  new Set([...(userLesson?.reading_words || []), ...words.filter(Boolean)]),
                ),
              },
              "english_user_lessons",
            );
            console.log("🚀 ~ result:", result);
          } else {
            await createUserLesson(
              { user: studentUser.id, video_ids: [], reading_words: words.filter(Boolean) },
              "english_user_lessons",
            );
          }

          let updatedOrCreatedUserLesson = await getUserLesson(
            studentUser.id,
            "english_user_lessons",
          );
          console.log("🚀 ~ updatedOrCreatedUserLesson:", updatedOrCreatedUserLesson);
          setNewUserLesson(updatedOrCreatedUserLesson);

          setEditorState("");
          setIsLoading(false);
        }}
      >
        Add reading words from editor state
      </LoadingButton>
    </HStack>
  );
};

export default AddWordsToCurrentUserLesson;
