import lowerI from "./lowerI/upperL";
import lowerL from "./lowerL/upperL";
import upperA from "./upperA/upperA";
import upperE from "./upperE/upperE";
import upperF from "./upperF/upperF";
import upperH from "./upperH/upperH";
import upperI from "./upperI/upperI";
import upperL from "./upperL/upperL";
import upperN from "./upperN/upperN";
import upperT from "./upperT/upperT";

export const rowLetters = ["A", "I", "L", "l", "i", "F", "E", "H", "T", "N"];
const getLetter = (letter: string, step: number) => {
  if (letter === "A") return upperA(step);
  if (letter === "I") return upperI(step);
  if (letter === "L") return upperL(step);
  if (letter === "l") return lowerL(step);
  if (letter === "i") return lowerI(step);
  if (letter === "F") return upperF(step);
  if (letter === "E") return upperE(step);
  if (letter === "H") return upperH(step);
  if (letter === "T") return upperT(step);
  if (letter === "N") return upperN(step);
};

export default getLetter;
