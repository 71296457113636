import convertCoordinatesNumbersToWords from "../../../../../../../common/convertCoordinatesNumbersToWords";

const upperAInstructions = (xOffset: number, yOffset: number): string[][] => [
  [
    `Mark a dot at ${convertCoordinatesNumbersToWords([
      1 + xOffset,
      1 + yOffset,
    ])} and ${convertCoordinatesNumbersToWords(
      [8 + xOffset, 21 + yOffset],
      true,
    )} Draw a line between these two dots.`,
  ],
  [
    `Mark a dot at ${convertCoordinatesNumbersToWords([
      21 + xOffset,
      1 + yOffset,
    ])} and ${convertCoordinatesNumbersToWords(
      [14 + xOffset, 21 + yOffset],
      true,
    )} Draw a line between these two dots`,
  ],
  [
    `Mark a dot at ${convertCoordinatesNumbersToWords([
      6 + xOffset,
      1 + yOffset,
    ])} and ${convertCoordinatesNumbersToWords(
      [8 + xOffset, 6 + yOffset],
      true,
    )} Draw a line between these two dots`,
  ],
  [
    `Mark a dot at ${convertCoordinatesNumbersToWords([
      16 + xOffset,
      1 + yOffset,
    ])} and ${convertCoordinatesNumbersToWords(
      [14 + xOffset, 6 + yOffset],
      true,
    )} Draw a line between these two dots`,
  ],
  [`Connect all lines with horizontal lines to form a shape.`],
  [
    `Mark a dot at ${convertCoordinatesNumbersToWords([
      13 + xOffset,
      10 + yOffset,
    ])} and ${convertCoordinatesNumbersToWords(
      [11 + xOffset, 16 + yOffset],
      true,
    )} Draw a line between these two dots`,
  ],
  [
    `Mark a dot at ${convertCoordinatesNumbersToWords([
      9 + xOffset,
      10 + yOffset,
    ])} and draw a line to ${convertCoordinatesNumbersToWords([11 + xOffset, 16 + yOffset], true)}`,
  ],
  [`Connect the last two lines with a horizontal line to form a closed shape.`],
];

export default upperAInstructions;
