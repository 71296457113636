import Box from "../../../Box";

const LeftAxis = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 38,
        width: 2,
        height: "100%",
        backgroundColor: "red",
        zIndex: 1,
        "& div": {},
      }}
    >
      {[
        28, 27, 26, 25, 24, 23, 22, 21, 20, 19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5,
        4, 3, 2, 1, 0,
      ].map((n, index) => {
        return (
          <Box
            sx={{
              position: "absolute",
              left: -25,
              top: index * 37.9 + 51,
              fontSize: 16,
              width: 25,
              fontWeight: 600,
              borderBottom: "solid 2px red",
            }}
          >
            <Box
              sx={{
                position: "relative",
                top: 5,
              }}
            >
              {n}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default LeftAxis;
