import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { EnglishStory } from "../../../types/supabase";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { useEffect, useMemo, useState } from "react";
import YouTubePlayer from "../YouTubePlayer";
import { useLessonsStore } from "../../../store/lessonsStore";
import ModalCloseButton from "../ModalCloseButton";
import isAdmin from "../../../common/isAdmin";
import AdminTimeCorrection from "../YouTubePlayer/AdminTimeCorrection";
import { useWindowSize } from "react-use";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  minWidth: 300,
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  // zIndex: 99999999999999999999,
  zIndex: 100000000000000000000,
};

export const TextPartVideoByStartAndEndButton: React.FC<{
  start: number;
  end: number;
  story: EnglishStory;
  withButton?: boolean;
  endless?: boolean;
  showVideoWithoutButton?: boolean;
  onUpdate?: () => void;
  onStartChange?: (start: number) => void;
  onEndChange?: (end: number) => void;
}> = ({
  start,
  end,
  story,
  withButton,
  endless,
  showVideoWithoutButton,
  onUpdate,
  onEndChange,
  onStartChange,
}) => {
  const setTextPartVideoByStartAndEndProps = useLessonsStore(
    (state) => state.setTextPartVideoByStartAndEndProps,
  );
  const textPartVideoByStartAndEndProps = useLessonsStore(
    (state) => state.textPartVideoByStartAndEndProps,
  );
  const [forceReload, setForceReload] = useState(0);
  const authUser = useLessonsStore((state) => state.authUser);
  // const [currentVideoOpen, setCurrentVideoOpen]
  const handleOpen = () => {
    let id = "";
    let isYouTube = false;

    if (!story.link?.includes("shorts") && story.youtube_id) {
      id = story.youtube_id;
      isYouTube = true;
    }
    setTextPartVideoByStartAndEndProps({ end, start, id, isYouTube, endless, image: story.image });
    setTimeout(() => {
      setTextPartVideoByStartAndEndProps({ isOpen: true });
    }, 150);
  };

  if (showVideoWithoutButton) {
    return story.youtube_id ? (
      <>
        <YouTubePlayer
          // withoutDelay
          image={story.image}
          id={story.youtube_id}
          start={story.clip_start || 0}
          // modalId="part_video_line_by_line"
          inputPlaying={false}
          duration={story.duration || 0}
          forceReload={forceReload}
          fullAdminWidth
        />
        {isAdmin(authUser?.id) && (
          <AdminTimeCorrection
            story={story}
            onStartChange={(start) => {
              if (onStartChange) onStartChange(start);
            }}
            onEndChange={(end) => {
              if (onEndChange) onEndChange(end);
            }}
            onUpdate={() => {
              // setForceReload((prev) => prev + 1);
              if (onUpdate) onUpdate();
            }}
          />
        )}
      </>
    ) : null;
  }

  return (
    <Box position="relative">
      {withButton && (
        <IconButton
          color={
            textPartVideoByStartAndEndProps.isOpen &&
            textPartVideoByStartAndEndProps.id === story.youtube_id
              ? "primary"
              : "default"
          }
          size="large"
          onClick={handleOpen}
        >
          <YouTubeIcon sx={{ fontSize: 40 }} />
          {/* подивитися відео */}
        </IconButton>
      )}
      {!withButton && (
        <IconButton onClick={handleOpen} sx={{ position: "absolute", top: -4, left: -35 }}>
          <PlayCircleOutlineIcon />
        </IconButton>
      )}
    </Box>
  );
};

const TextPartVideoByStartAndEnd = () => {
  const { width: windowWidth } = useWindowSize();
  const setTextPartVideoByStartAndEndProps = useLessonsStore(
    (state) => state.setTextPartVideoByStartAndEndProps,
  );
  const textPartVideoByStartAndEndProps = useLessonsStore(
    (state) => state.textPartVideoByStartAndEndProps,
  );
  const minModalWidth = useMemo(() => {
    if (windowWidth < 500) return 300;
    if (windowWidth < 800) return 400;
    if (windowWidth < 1200) return 500;
    if (windowWidth < 1600) return 700;
    if (windowWidth >= 1600) return 1100;
    return 300;
  }, [windowWidth]);
  console.log("🚀 ~ minModalWidth:", minModalWidth);
  const handleClose = () => {
    setTextPartVideoByStartAndEndProps({ isOpen: false });
  };

  return (
    <Modal
      open={textPartVideoByStartAndEndProps.isOpen}
      keepMounted
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style, minWidth: minModalWidth, width: minModalWidth, backgroundColor: "red" }}>
        <ModalCloseButton onClose={handleClose} />
        {/* {minModalWidth} */}
        {textPartVideoByStartAndEndProps.isYouTube && (
          <YouTubePlayer
            // withoutDelay
            id={textPartVideoByStartAndEndProps.id}
            image={textPartVideoByStartAndEndProps.image}
            start={textPartVideoByStartAndEndProps.start}
            // modalId="part_video_line_by_line"
            inputPlaying={textPartVideoByStartAndEndProps.isOpen}
            duration={textPartVideoByStartAndEndProps.end - textPartVideoByStartAndEndProps.start}
          />
        )}
      </Box>
    </Modal>
  );
};

export default TextPartVideoByStartAndEnd;
