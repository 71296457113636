const upperN = (step: number) => (
  <svg
    width="36.156cm"
    height="26.138cm"
    preserveAspectRatio="xMidYMid"
    version="1.0"
    viewBox="0 0 1024.9 740.92"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" stroke="#000" stroke-width="3.9024">
      <path d="m15.599 721.95 5.3e-4 0.0996h565.4" />
      <path d="m582.57 210.38-567.72 0.74962-1e-5 0.65772" />

      <path d="m582.86 350.27v-139.89l-0.28857 3.8e-4" />
      <path d="m274.17 352.58 308.69-0.74129v-1.5703" />
      <path d="m582.86 552.33 4e-3 -0.9917-312.32-198.75 3.627-9e-3" />
      <path d="m581 722.05h1.1089l0.74564-169.72" />

      <path d="m14.85 579.87 0.74929 142.08" />
      <path d="m382.19 578.48 2.187 1.3534h-369.53l1.8e-4 0.0322" />
      <path d="m14.849 350.63v0.52295l367.34 227.33" />
      <path d="m14.849 211.79 1e-5 138.85" />
    </g>
  </svg>
);

export default upperN;
