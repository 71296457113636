const upperH = (step: number) => (
  <svg
    width="23.156cm"
    height="21.138cm"
    preserveAspectRatio="xMidYMid"
    version="1.0"
    viewBox="0 0 656.39 599.19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" stroke="#000" stroke-width="3.9255">
      <path d="m523.77 581.26 2.5e-4 0.19043-142.73 0.75 0.00281-0.95361" />
      <path d="m155.76 582.17-0.00229 0.77783-141.98-0.75v-0.86718" />
      <path d="m381.79 14.801v-0.58154l140.57 0.74651" />
      <path d="m13.781 14.743v-0.52296h141.98l0.00174 0.23144" />
      <path d="m157.25 212.19 0.01254 1.6684h224.1" />
      <path d="m381.71 327.47-225.2 0.74974-7.7e-4 0.26368" />

      <>
        <path d="m522.36 14.966 0.65772 0.003493 0.74975 566.29" />
        <path d="m381.36 213.86h0.43213v-199.06" />
        <path d="m155.76 14.451 1.4857 197.74" />

        <path d="m13.781 581.33v-566.59" />
        <path d="m156.51 328.48-0.74694 253.69" />
        <path d="m381.04 581.25 0.74719-253.78-0.07911 2.6e-4" />
      </>
      {/* {(showFullPath || step > 0) && (
        <path d="m523.77 581.26 2.5e-4 0.19043-142.73 0.75 0.00281-0.95361" />
      )}
      {(showFullPath || step > 1) && (
        <path d="m155.76 582.17-0.00229 0.77783-141.98-0.75v-0.86718" />
      )}
      {(showFullPath || step > 2) && <path d="m381.79 14.801v-0.58154l140.57 0.74651" />}
      {(showFullPath || step > 3) && <path d="m13.781 14.743v-0.52296h141.98l0.00174 0.23144" />}
      {(showFullPath || step > 4) && <path d="m157.25 212.19 0.01254 1.6684h224.1" />}
      {(showFullPath || step > 5) && <path d="m381.71 327.47-225.2 0.74974-7.7e-4 0.26368" />}

      {(showFullPath || step > 6) && (
        <>
          <path d="m522.36 14.966 0.65772 0.003493 0.74975 566.29" />
          <path d="m381.36 213.86h0.43213v-199.06" />
          <path d="m155.76 14.451 1.4857 197.74" />

          <path d="m13.781 581.33v-566.59" />
          <path d="m156.51 328.48-0.74694 253.69" />
          <path d="m381.04 581.25 0.74719-253.78-0.07911 2.6e-4" />
        </>
      )} */}
    </g>
  </svg>
);

export default upperH;
