import { useEffect, useMemo, useState } from "react";
import LinkIcon from "@mui/icons-material/Link";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useLessonsStore } from "../../store/lessonsStore";
import HStack from "../Unknown/HStack";
import Typography from "../Unknown/Typography";
import WordAudio from "../Audio/WordAudio";
import HoverDetails from "../Word/HoverDetails";
import removePunctuationFromString from "../../common/removePunctuationFromString";
import CopyStoryIdButton from "../Unknown/CopyStoryIdButton";
import { Box, Button, Link, Tooltip } from "@mui/material";
import supabaseClient from "../../common/supabaseClient";
import { Word } from "../../types/supabase";
import CopyNewWordsForGemini from "../Admin/CopyNewWordsForGemini";
import LoadingButton from "@mui/lab/LoadingButton";
import { format } from "date-fns";
import NotificationsPausedIcon from "@mui/icons-material/NotificationsPaused";
import ItalicTitle from "../Title/ItalicTitle";
import AdminComponentIndicator from "../Unknown/AdminComponentIndicator";

export type PoemType = {
  row_new_words: string[];
  words: string[];
  link: string;
  text: string;
  id: number;
  author: string;
  word_count: number;
  all_intersection_count: number;
  new_words_count: number;
  story?: {
    en: string;
    ua: string;
  }[];
  level?: string;
};

const Poem: React.FC<{ poem: PoemType }> = ({ poem }) => {
  const [renderWords, setRenderWords] = useState(false);
  const [notExistingWords, setNotExistingWords] = useState<string[]>([]);
  const [curerntModuleWordsMap, setCurrentModuleWordsMap] = useState<Record<string, Word>>({});
  const globalWords = useLessonsStore((state) => state.globalWords);
  console.log("🚀 ~ globalWords:", globalWords);
  const [isLoading, setIsLoading] = useState(false);
  const authUser = useLessonsStore((state) => state.authUser);
  const setGlobalWords = useLessonsStore((state) => state.setGlobalWords);

  useEffect(() => {
    const words = poem.words?.map((w) => w.replace("–", "-"));

    const map = globalWords
      .filter(
        (w) =>
          words.includes(w.name) ||
          words.includes(w.name.toLowerCase()) ||
          words.includes(w.name.toUpperCase()),
      )
      .reduce((acm, w) => {
        return {
          ...acm,
          [w.name]: w,
          [w.name.toLowerCase()]: w,
          [w.name.toUpperCase()]: w,
        };
      }, {});

    setCurrentModuleWordsMap(map);
  }, [globalWords, renderWords]);

  const poemText = useMemo(() => {
    if (!renderWords) {
      return <pre>{poem.text}</pre>;
    }

    return poem.text.split("\n").map((line, index) => {
      let audio = "";

      return (
        <HStack key={index} sx={{ flexWrap: "nowrap", width: "100%" }} pl={6}>
          {audio && (
            <Typography component="span">
              <WordAudio url={audio} small />
            </Typography>
          )}
          <HoverDetails
            // isCenter
            words={
              line.split(" ").map((w: any, index: number) => {
                const cleanWord = removePunctuationFromString(w).replace("__", " ");

                const word =
                  curerntModuleWordsMap[cleanWord] ||
                  curerntModuleWordsMap[cleanWord.toLowerCase()];
                // console.log("🚀 ~ cleanWord:", w, cleanWord, word);

                return {
                  ...(word ? word : {}),
                  word: word?.name || cleanWord,
                  label: w.replace("__", " "),
                };
              }) as any
            }
          />
        </HStack>
      );
    });
  }, [renderWords, curerntModuleWordsMap]);

  return (
    <Box mb={25}>
      <HStack>
        <AdminComponentIndicator title={"AdminLearnPoems - Poem"} />
        <CopyStoryIdButton id={poem.id.toString()} />
        <Button color="info" variant="contained" component={Link} target="_blank" href={poem.link}>
          <Tooltip title="open external story in a new tab">
            <LinkIcon />
          </Tooltip>
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={async () => {
            const { data } = await supabaseClient
              .from("words")
              .select()
              .in("name", poem.words)
              .returns<Word[]>();

            if (data) {
              const dataRowWords = data.map((w) => w.name);
              const notExisting = poem.words.filter((w) => !dataRowWords.includes(w));
              console.log("🚀 ~ notExisting:", notExisting);
              setNotExistingWords(notExisting);
              setGlobalWords(data);
            }
            setRenderWords(true);
          }}
        >
          Load words
        </Button>
        {!!notExistingWords.length && <CopyNewWordsForGemini words={notExistingWords} />}
        <Button
          variant="outlined"
          onClick={() => {
            navigator.clipboard.writeText(`
        Текст вірша: ${poem.text}
        Автор вірша: ${poem.author}

        Зроби аналіз цього вірша.
        Я хочу створити урок англійської мови на основі цього вірша.
        Я хочу записати відео де я буду читати цього вірша на початку а потім прозповідати про цей віш більш детально.
        Я буду говорити речення за реченням українською перекладаючи його відразу на англійську.
        Таким чином глядачі матимуть змогу перекладати самі і звіряти з моїм перекладом.

        Наприклад початок:
        Цей вірш про природу. - This poem is about nature.
        Тут говориться про... - ....

        Головна ідея уроку це філософський нахил та навчання дітей повчальними прикладами. Урок повинен бути мотиваційним.
        В історії не повинно бути інших людей чи імен. Це моє спілкування з глядачами. Я задаю риторичні питання, та відповідаю на деякі з них. Це така собі рефлексія.

        Допоможи мені створити історію яка би повністю розкривала тему цього вірша. Давай почнемо тільки з української версії. Дай мені текст без лишніх питань. Урок буде у вигляді однієї історії без взаємодії чи питань учнів.
        Лексика має бути якомога простішою і неформальною, так я це для навчання дітей.
        
`);
          }}
        >
          Ask ai to create a poem
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            navigator.clipboard.writeText(`
        Текст вірша: ${poem.text}
        Автор вірша: ${poem.author}

        Зроби аналіз цього вірша:
        1. Про що цей вірш?
        2. Яку ідею несе цей вірш?
        3. Що означає кожен рядок даного вірша? Переклади та поясни кожне слово даного рядка.
        4. Як правильно читати цей віш? З якою інтонацією? Дай загальні рекомендації.
`);
          }}
        >
          Ask ai about poem
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            navigator.clipboard.writeText(`
        Текст вірша: ${poem.text}
        Автор вірша: ${poem.author}

        Зроби аналіз цього вірша:
        1. Мені потрібно підготувати урок англійської мови по даному віршу.
        2. Я хочу пояснити цей вірш а також словниковий запас за допомогою однієї історії.
        3. Історія повинна складатися з якомога більшої кількості речень. Приблизно 30-50 речень.
        4. Формат: речення українською - переклад англійською.
        5. Проведення уроку: я кажу речення українською - студент намагається перекласти англійською - далі я говорю речення англійською - студент перевіряє себе
        6. Приклад речень:
            - що ти думаєш про цей вірш?
            - я думаю що цей вірш дуже глибокий.
            - у цьому вірші головна думка...
            - що означає перший рядок цього вірша?
            - що означає слово...
        7. Речення повинні відповідати темі вірша і розкривати його тонкі грані.
        8. Формат відповіді у JSON. Приклад:
        [{
          "ua": "речення українською",
          "en": "речення англійською"
        },
        ...]

`);
          }}
        >
          Ask ai about story
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            navigator.clipboard.writeText(poem.text);
          }}
        >
          Copy poem text
        </Button>
        <Button variant="outlined" onClick={async () => {}}>
          Add to my poems
        </Button>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          color={"error"}
          onClick={async () => {
            setIsLoading(true);
            if (!authUser) return;

            const addPoemToUserResult = await supabaseClient.from("user_poems").insert({
              id: `${authUser.id}-${poem.id}`,
              poem: poem.id,
              user: authUser.id,
              repeat: format(new Date(), "yyyy-MM-dd"),
            });
            console.log("🚀 ~ addPoemToUserResult:", addPoemToUserResult);

            setIsLoading(false);
          }}
        >
          <Tooltip title="Add to user poems">
            <PersonAddIcon />
          </Tooltip>
        </LoadingButton>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          color={"error"}
          onClick={async () => {
            setIsLoading(true);
            if (!authUser) return;

            await supabaseClient
              .from("poems")
              .insert({
                ignore: true,
              })
              .eq("id", poem.id);

            setIsLoading(false);
          }}
        >
          <Tooltip title="Ignore this poems">
            <NotificationsPausedIcon />
          </Tooltip>
        </LoadingButton>
        <LoadingButton
          loading={isLoading}
          variant="contained"
          color={"error"}
          onClick={async () => {
            setIsLoading(true);
            if (!authUser) return;

            const result1 = await supabaseClient.from("user_poems").delete().eq("poem", poem.id);
            console.log("🚀 ~ result1:", result1);
            const result = await supabaseClient.from("poems").delete().eq("id", poem.id);
            console.log("🚀 ~ poem:", poem);
            console.log("🚀 ~ result:", result);

            setIsLoading(false);
          }}
        >
          <Tooltip title="Delete this poems">
            <RemoveCircleOutlineIcon />
          </Tooltip>
        </LoadingButton>
      </HStack>
      <Box pt={4}>
        <Typography>
          <ItalicTitle text={poem.author} />
          {poemText}
          {/* {!renderWords && <pre>{poem.text}</pre>} */}
        </Typography>
      </Box>
    </Box>
  );
};

export default Poem;
