import convertCoordinatesNumbersToWords from "../../../../../../../common/convertCoordinatesNumbersToWords";

const upperEInstructions = (xOffset: number, yOffset: number): string[][] => [
  [
    `Let's draw six vertical lines. Mark a dot at ${convertCoordinatesNumbersToWords([
      15 + xOffset,
      1 + yOffset,
    ])} and ${convertCoordinatesNumbersToWords(
      [15 + xOffset, 5 + yOffset],
      true,
    )} Draw a line between these two dots.`,
  ],
  [
    `Mark a dot at ${convertCoordinatesNumbersToWords([
      6 + xOffset,
      5 + yOffset,
    ])} and ${convertCoordinatesNumbersToWords(
      [6 + xOffset, 9 + yOffset],
      true,
    )} Draw a line between these two dots`,
  ],
  [
    `Mark a dot at ${convertCoordinatesNumbersToWords([
      14 + xOffset,
      9 + yOffset,
    ])} and ${convertCoordinatesNumbersToWords(
      [14 + xOffset, 13 + yOffset],
      true,
    )} Draw a line between these two dots`,
  ],
  [
    `Mark a dot at ${convertCoordinatesNumbersToWords([
      6 + xOffset,
      13 + yOffset,
    ])} and ${convertCoordinatesNumbersToWords(
      [6 + xOffset, 17 + yOffset],
      true,
    )} Draw a line between these two dots`,
  ],
  [
    `Mark a dot at ${convertCoordinatesNumbersToWords([
      15 + xOffset,
      17 + yOffset,
    ])} and ${convertCoordinatesNumbersToWords(
      [15 + xOffset, 21 + yOffset],
      true,
    )} Draw a line between these two dots`,
  ],
  [
    `Mark a dot at ${convertCoordinatesNumbersToWords([
      1 + xOffset,
      1 + yOffset,
    ])} and ${convertCoordinatesNumbersToWords(
      [1 + xOffset, 21 + yOffset],
      true,
    )} Draw a line between these two dots`,
  ],
  [`Connect all lines with horizontal lines to form a closed shape.`],
];

export default upperEInstructions;
