import { Box, Button, Link as MuiLink, Typography } from "@mui/material";

import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import getRandomGreetings from "../../../common/getRandomGreetings";
import isAdmin from "../../../common/isAdmin";
import removePunctuationFromString from "../../../common/removePunctuationFromString";
import supabaseClient from "../../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../../store/lessonsStore";
import {
  EnglishQuote,
  EnglishStory,
  Module,
  Sentence,
  UserSentence,
  Word,
} from "../../../types/supabase";
import AudioPlayer from "../../Audio/AudioPlayer";
import WordAudio from "../../Audio/WordAudio";
import MenuLayout from "../../Layout/MenuLayout";
import MicSentences from "../../Mic/MicSentences";
import SentencePazleItem from "../../Sentence/SentencePazleItem";
import UkrainianWord from "../../Sentence/UkrainianWord";
import HStack from "../../Unknown/HStack";
import Loader from "../../Unknown/Loader";
import VStack from "../../Unknown/VStack";
import HoverDetails from "../../Word/HoverDetails";
import WordLetterPazleItem from "../../Word/WordLetterPazleItem";
import useGlobalWords from "../../hooks/getGlobalWords";
import { initialLessons as lessons } from "../LearnToReadScreen";
import BuildWords from "../BuildWords";
import WordsCarousel from "../../Grammar/GrammarLesson/tabs/WordsCarousel";
import LettersCarousel from "../LettersCarousel";
import Stories from "../../Grammar/Stories";
import ReactPlayer from "react-player";
import Description from "../Description";
import getSentenceWords from "../../../common/getSentenceWords";
import ImageCarouselModal from "../../ImageCarouselModal";
import LessonTasks from "../LessonTasks";
import { TrainersConfig } from "../UkTrainers/constants";
import TrainersModalWithButton from "../UkTrainers/TrainersModalWithButton";
import LetterA1 from "../../Grammar/GrammarLesson/tabs/ReadBlocks/LetterA1";
import BuildWordWithLetters from "../BuildWordWithLetters";
import LetterWordsCompare from "../LetterWordsCompare";
import SimpleLessonTasks from "../SimpleLessonTasks";
import EnglishSoundsGrid from "../UkTrainers/EnglishSoundsGrid";
import LearnToReadSpeaking from "../LearnToReadSpeaking";
import ItalicTitle from "../../Title/ItalicTitle";
import Reading from "../Reading";
import Poems from "../Poems";
import ControllingStudentPage from "../../Unknown/Root/ControllingStudentPage";

const WithDZ: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box sx={{ position: "relative" }}>
      <Typography
        variant="caption"
        sx={{
          position: "absolute",
          left: -22,
          top: 7,
          borderRadius: "5px",
          border: "1px solid red",
          transform: "rotate(-90deg)",
          transformOrigin: "50% 50%",
          backgroundColor: "red",
          zIndex: 2,
          color: "white",
          height: 15,
          py: 0,
          lineHeight: 1,
          fontFamily: "Helvetica, Times New Roman, Monaco, monospace, serif",
          px: 1,
        }}
      >
        дз
      </Typography>
      {children}
    </Box>
  );
};
const LearnToReadLesson: React.FC = () => {
  const { lessonId } = useParams();
  const lessonNumber = useLessonsStore((state) => state.lessonNumber);
  const setLessonNumber = useLessonsStore((state) => state.setLessonNumber);

  useEffect(() => {
    if (lessonId) setLessonNumber(+lessonId);
  }, [lessonId]);

  const lesson = useMemo(() => {
    return lessonId ? lessons[lessonId] : null;
  }, [lessonId]);

  const globalWords = useLessonsStore((state) => state.globalWords);
  const [curentModuleWordsMap, setCurrentModuleWordsMap] = useState<Record<string, Word>>({});
  const setLessonData = useLessonsStore((state) => state.setLessonData);
  const [stories, setStories] = useState<EnglishStory[]>([]);
  const authUser = useLessonsStore((state) => state.authUser);
  const [lessonType, setLessonType] = useState<"home-work" | "teacher">("home-work");

  const {
    lessonWords,
    lessonContent,
    lessonTitle,
    newLessonSounds,
    speakingWords,
    lessonSounds,
    allLessonWords,
    lessonPoemIds,
    lessonLetters,
  } = useMemo(() => {
    const initial = {
      lessonContent: lessonId && lessons[lessonId]?.content,
      lessonTitle: lessonId && lessons[lessonId]?.title,
      speakingWords: lessonId && lessons[lessonId]?.speakingWords,
      lessonSounds: (lessonId && lessons[lessonId]?.sounds) || [],
      newLessonSounds: (lessonId && lessons[lessonId]?.newSounds) || [],
      lessonPoemIds: (lessonId && lessons[lessonId]?.poemIds) || [],
      lessonWords: Array.from(new Set([...((lessonId && lessons[lessonId]?.words) || [])])),
      allLessonWords: Array.from(
        new Set([
          ...((lessonId && lessons[lessonId]?.words) || []),
          ...(
            (lessonId &&
              lessons[lessonId].letters?.map((letter) => [letter.letter, letter.word]).flat()) ||
            []
          ).filter(Boolean),
        ]),
      ),
      lessonLetters: (lessonId && lessons[lessonId]?.letters) || [],
    };

    if (lessonId && lessons[lessonId]?.storyWithText) {
      initial.allLessonWords = Array.from(
        new Set([...initial.allLessonWords, ...stories.map((s) => s.words).flat()]),
      );
    }

    return initial;
  }, [stories]);

  // check if the user has poems
  // useMountedWaitAsyncEffect(async () => {
  //   if (!lessonPoemIds.length) return;
  // }, [lessonPoemIds])

  const wordsGreeting = useMemo(() => {
    return getRandomGreetings("Всі слова складені.");
  }, []);

  const [wordIndex, setWordIndex] = useState(0);

  const { prevWords, currentWord } = useMemo(() => {
    const words = [...lessonWords];

    words.length = wordIndex;

    const currentWord = lessonWords[wordIndex];

    return { prevWords: words, currentWord };
  }, [lessonWords, wordIndex]);

  const areThereStories = !!(lessonId && lessons[lessonId].storyIds.length);
  const storyWithText = !!(lessonId && lessons[lessonId].storyWithText);

  const options = useMemo(() => {
    return { words: allLessonWords };
  }, [allLessonWords]);

  useGlobalWords(options);

  useEffect(() => {
    if (allLessonWords?.length) {
      const map = globalWords
        .filter(
          (w) => allLessonWords.includes(w.name) || allLessonWords.includes(w.name.toLowerCase()),
        )
        .reduce((acm, w) => {
          return {
            ...acm,
            [w.name]: w,
            [w.name.toLowerCase()]: w,
          };
        }, {});

      setCurrentModuleWordsMap(map);
    }
  }, [globalWords, lessonWords]);

  useMountedWaitAsyncEffect(async () => {
    if (!lessonId) return;

    let stories = lessons[lessonId].storyIds;
    if (!stories?.length) return;
    if (stories && typeof stories[0] === "object") {
      stories = stories.map((story: any) => story.id);
    }

    const { data } = await supabaseClient.from("english_verses").select().in("id", stories);

    if (data) setStories(data);
  }, [lessons, lessonId]);

  const playerRef = useRef(null);

  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    if (stories.length) {
      const [story] = stories;
      if (story.clip_start) setCurrentTime(story.clip_start);
    }
  }, [stories]);

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  const timeUpdateLoop = () => {
    const [story] = stories;
    if (playerRef.current && story?.clip_start && story?.duration) {
      const start = story.clip_start;
      const duration = story.duration;
      const newCurrentTime = (playerRef.current as any).getCurrentTime();

      if (newCurrentTime > start + duration) {
        setCurrentTime(Math.ceil(start));
        setPlaying(false); // Pause after the duration
        (playerRef.current as any).seekTo(Math.ceil(start)); // Go back to the start time
      } else if (newCurrentTime < start) {
        // setPlaying(false); // Pause after the duration
        setCurrentTime(Math.ceil(start));
        (playerRef.current as any).seekTo(Math.ceil(start)); // Go back to the start time
      } else {
        setCurrentTime(newCurrentTime);
      }
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    timer = setInterval(() => {
      if (playing) {
        timeUpdateLoop();
      }
    }, 500);
    // }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [currentTime, stories, playing]);

  const topLessonElements = (
    <VStack>
      <MuiLink color="secondary.dark" component={Link} to={`/learn-to-read`}>
        <Typography>Усі уроки</Typography>
      </MuiLink>
      {lessonTitle && (
        <Typography gutterBottom variant="h3">
          {lessonTitle}
        </Typography>
      )}
    </VStack>
  );

  if (!lessonWords.length) {
    return (
      <MenuLayout isFluid>
        <Box width="100%" overflow="hidden" position="relative">
          {topLessonElements}
          {lessonContent}
        </Box>
      </MenuLayout>
    );
  }

  if (!lessonType) {
    return (
      <MenuLayout isFluid>
        {topLessonElements}
        <VStack py={10}>
          <ItalicTitle size={25} text="Вибери тип уроку" />
          <Button
            variant="outlined"
            onClick={() => {
              setLessonType("home-work");
            }}
          >
            Самостійна робота
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              setLessonType("teacher");
            }}
          >
            З вчителем
          </Button>
        </VStack>
      </MenuLayout>
    );
  }

  return (
    <MenuLayout isFluid>
      <Box width="100%" overflow="hidden" position="relative">
        <Box sx={{ backgroundColor: "white" }} py={3} px={3} mb={2} width="100%">
          <VStack gap={1}>
            {topLessonElements}
            <VStack>
              {/* {isAdmin(authUser?.id) && lessonId && Number(lessonId) > 1 && (
                <Description curentModuleWordsMap={curentModuleWordsMap} />
              )} */}
              <ControllingStudentPage isNested poemIds={lessonPoemIds} />
              {/* {isAdmin(authUser?.id) && (
                <LettersCarousel
                  letters={lessonLetters}
                  newLettersCount={lesson?.newLettersCount}
                  newSounsCount={lesson?.newSounsCount}
                />
              )} */}
              {isAdmin(authUser?.id) && lessonId && Number(lessonId) > 1 && (
                <>
                  <Typography>{lessonWords.join(", ")}</Typography>
                  <Button
                    onClick={() => {
                      navigator.clipboard.writeText(
                        JSON.stringify(Array.from(new Set(lessonWords))),
                      );
                    }}
                  >
                    Copy unique words
                  </Button>
                </>
              )}
              {/* <WithDZ> */}
              <WordsCarousel words={lessonWords || []} isReadToLearn lessonType={lessonType} />
              {/* </WithDZ> */}
              {/* <WordsCarousel currentWord={currentWord} words={lessonWords || []} isReadToLearn /> */}
              {/* {lessonId && lessonId === "7" && (
                <WordsCarousel
                buttonLabel="Повторити слова"
                currentWord={currentWord}
                words={[
                  
                ]}
                isReadToLearn
                />
              )} */}
              {lessonId && ["1", "2", "4"].includes(lessonId) && (
                <LetterWordsCompare words={lessonWords || []} />
              )}
              {lessonId && ["1", "2"].includes(lessonId) && (
                <BuildWordWithLetters words={lessonWords || []} />
              )}
              {/* <WithDZ> */}
              <BuildWords
                curentModuleWordsMap={curentModuleWordsMap}
                wordsGreeting={wordsGreeting}
                moduleWords={lessonWords || []}
                lessonType={lessonType}
                wordsLength={(lessonWords || [])?.length}
              />
              {/* </WithDZ> */}

              {/* <TrainersModalWithButton
                trainer={TrainersConfig.UA_SORT_VOWELS_AND_CONSONANTS}
                buttonLabel="Тренажери"
              /> */}
              {/* {lessonId && lessonId === "7" && (
                <LetterA1 />
              )} */}

              <EnglishSoundsGrid sounds={lessonSounds} newSounds={newLessonSounds} />
              {/* {lessonId && lessonId !== "7" && (
              )} */}
              {lessonId && lessonId === "100000" && (
                <>
                  <TrainersModalWithButton trainer={TrainersConfig.UA_SORT_VOWELS_AND_CONSONANTS} />
                  <TrainersModalWithButton
                    trainer={TrainersConfig.EN_SORT_VOWELS_AND_CONSONANTS}
                    consonants={[
                      "n",
                      "t",
                      "p",
                      "r",
                      "d",
                      "s",
                      "b",
                      "l",
                      "g",
                      "c(k)",
                      "m",
                      "h",
                      "ng(ŋ)",
                      // "y(й)",
                      "y(j)",
                      "f",
                      "w",
                      "k",
                      "th(ð)",
                    ]}
                    vowels={[
                      "a(æ)",
                      "a(ɑː)",
                      "a(eɪ)",
                      "o(əʊ)",
                      "o(ɒ)",
                      "oo(ʊ)",
                      "oo(uː)",
                      "o(uː)",
                      "ou(uː)",
                      "ow(aʊ)",
                      "e(ɪ)",
                      "e(e)",
                      "i(i)",
                      "i(aɪ)",
                      "y(i)",
                      "u(ʌ)",
                    ]}
                    // buttonLabel="Тренажери"
                  />
                </>
              )}

              <SimpleLessonTasks />
              {/* {authUser?.uuid !== "74aeedab-4b23-4b3f-b398-63c987775dc7" && <LessonTasks />} */}

              {Boolean(lesson?.bookImages?.length) && lesson?.bookImages && (
                <ImageCarouselModal images={lesson.bookImages} />
              )}
              {Boolean(lesson?.sentenceImagesNames?.length) && lesson?.sentenceImagesNames && (
                <ImageCarouselModal sentenceImages={lesson.sentenceImagesNames} />
              )}
              <Reading />
              <Poems />

              {/* {lessonWords &&
                authUser &&
                authUser.uuid !== "74aeedab-4b23-4b3f-b398-63c987775dc7" && (
                  <LearnToReadSpeaking words={speakingWords || lessonWords} />
                )} */}
              {areThereStories && isAdmin(authUser?.id) && (
                <Stories
                  stories={stories}
                  buttonLabel="відео"
                  buttonSize="large"
                  withoutLink
                  // isReadingVideo
                />
              )}
              {/* {areThereStories && (
                <Box py={3} width="100%" maxWidth={500}>
                  {stories.map((story) => {
                    if (!story.youtube_id) return null;

                    return (
                      <>
                        <YouTubePlayer
                          id={story.youtube_id}
                          start={story.clip_start || 0}
                          duration={story.duration || 10}
                        />
                        {storyWithText &&
                          story.verse.split("\n").map((line, index) => (
                            <HoverDetails
                              // isCenter
                              key={index}
                              words={
                                line.split(" ").map((w: any, index: number) => {
                                  const cleanWord = removePunctuationFromString(w).replace(
                                    "__",
                                    " ",
                                  );

                                  const word =
                                    curentModuleWordsMap[cleanWord] ||
                                    curentModuleWordsMap[cleanWord.toLowerCase()];


                                  return {
                                    ...(word ? word : {}),
                                    word: word?.name || cleanWord,
                                    label: w.replace("__", " "),
                                  };
                                }) as any
                              }
                            />
                          ))}
                      </>
                    );
                  })}
                </Box>
              )} */}

              {/* {areThereStories && storyWithText && (
                <Stories stories={stories} buttonLabel="Відео" />
              )} */}
            </VStack>
          </VStack>
        </Box>
      </Box>
    </MenuLayout>
  );
};

export default LearnToReadLesson;
