const upperF = (step: number) => (
  <svg
    width="36.156cm"
    height="26.138cm"
    preserveAspectRatio="xMidYMid"
    version="1.0"
    viewBox="0 0 1024.9 740.92"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" stroke="#000" stroke-width="3.9255">
      <path d="m411.82 721.85v-111.9l-1.6099 1e-5" />
      <path d="m14.615 723.14v0.19336h397.2v-1.4883" />
      <path d="m15.299 156.47-0.68408 4e-3v566.66" />
      <path d="m156.29 157.43 4e-3 -1.7022-140.99 0.74638" />
      <path d="m155.8 383 0.48562-225.57" />
      <path d="m381.35 383.36-2e-3 -0.17725h-225.55l4.1e-4 -0.18897" />
      <path d="m382.86 496.23-1.5066-112.87" />
      <path d="m155.54 496.36-7.9e-4 -0.12012 227.34 1.6366-0.0219-1.6436" />
      <path d="m156.99 609.94h-0.70313l-0.74921-113.58" />
      <path d="m410.21 609.94-253.21-1e-5" />
    </g>
  </svg>
);

export default upperF;
