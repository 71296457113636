import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { EditText, EditTextarea } from "react-edit-text";
import { useFirebaseApp } from "reactfire";
import LoadingButton from "@mui/lab/LoadingButton";
import { Word } from "../../types/supabase";
import { useLessonsStore } from "../../store/lessonsStore";
import useMountedWaitAsyncEffect from "../../hooks/useMountedWaitAsyncEffect";
import supabaseClient from "../../common/supabaseClient";
import getGCRandomVoice from "../../common/getGCRandomVoice";
import HStack from "../Unknown/HStack";
import WordAudio from "../Audio/WordAudio";
import removePunctuationFromString from "../../common/removePunctuationFromString";

const AdminFindAndUpdateWord = () => {
  const [word, setWord] = useState<any>([]);

  const app = useFirebaseApp();
  const functions = getFunctions(app, "europe-west3");

  const textToSpeechFunction = httpsCallable(
    functions,
    "recordEnglishTextToSpeechAndReturnStorageUrl",
  );

  const createAudio = async (word: string) => {
    try {
      const result = await textToSpeechFunction({
        // en: word.replace("(verb) ", ""),
        en: word,
        voiceName: getGCRandomVoice(),
      });
      return result;
    } catch (err) {
      console.log("🚀 try/catch create autio error", err);
    }
  };

  const [loading, setLoading] = useState(false);
  const [wordName, setWordName] = useState("");
  const [wordTranscription, setWordTranscription] = useState("");
  const [wordTranslation, setWordTranslation] = useState("");
  const [wordDescription, setWordDescription] = useState("");
  const [imageName, setImageName] = useState("");
  const [wordOtherTranslations, setWordOtherTranslations] = useState("");

  const addGenderEndings = (str: string) => {
    const withEndings = str.includes("(-ий/-а/-е)") || str.includes("(-ий/-а)");
    return str
      .split(",")
      .map((w) => w.trim())
      .map((w) => {
        if (removePunctuationFromString(w).endsWith("ий") && !withEndings) {
          return str.replace(/ий$/, "(-ий/-а/-е)");
        }
        return w;
      })
      .join(", ");
  };

  const updateInputs = () => {
    if (!word) return;

    setWordName(word.name);
    setWordTranscription(addGenderEndings(word.transcription || ""));
    setWordTranslation(addGenderEndings(word.translation || ""));
    setWordDescription(word.description || "");
    setImageName(word.image_name || "");
    setWordOtherTranslations(word.other_translation || "");
  };

  useEffect(() => {
    updateInputs();
  }, [word]);

  const loadNext = async () => {
    setLoading(true);

    try {
      const { data: newWord } = await supabaseClient
        .from("words")
        .select()
        // .is("verified", false)
        // .limit(1)
        .eq("name", wordName)
        .maybeSingle()
        .throwOnError();

      console.log("🚀 ~ newWord:", newWord);
      setWord(newWord);
    } catch (err: any) {
      console.log("🚀 ~ err:", err);
      alert(err.message);
    }

    updateInputs();
    setLoading(false);
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Typography component="span">Find word in db</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Box>
            <HStack>
              <TextField
                fullWidth
                color="primary"
                value={wordName}
                multiline={false}
                onChange={(event) => {
                  setWordName(event.target.value);
                }}
                type="text"
                variant="outlined"
              />
              <LoadingButton
                loading={loading}
                variant="contained"
                color="info"
                onClick={async () => {
                  await loadNext();
                }}
              >
                find
              </LoadingButton>
              <HStack width="100%" flexWrap="nowrap">
                {/* <LoadingButton
                sx={{ minWidth: 0, padding: 0, fontSize: 40 }}
                loading={loading}
                onClick={async () => {
                  if (!word) return;
                  setLoading(true);

                  try {
                    const audioResponse: any = await createAudio(word.name);

                    await supabaseClient
                      .from("words")
                      .update({
                        audio_url: audioResponse?.data?.url,
                      })
                      .eq("name", word.name);

                    const updatedWord = await supabaseClient
                      .from("words")
                      .select()
                      .eq("name", word.name)
                      .single();

                    setWord(updatedWord);
                  } catch (err: any) {
                    console.log("🚀 ~ err:", err);
                    alert(err.message);
                  }
                  setLoading(false);
                }}
              >
                🆕
              </LoadingButton> */}
                {word?.audio_url && <WordAudio url={word.audio_url} />}
                <TextField
                  fullWidth
                  color="primary"
                  value={wordTranscription}
                  multiline={false}
                  onChange={(event) => {
                    setWordTranscription(event.target.value);
                  }}
                  type="text"
                  variant="outlined"
                />
              </HStack>
              <TextField
                fullWidth
                color="primary"
                value={wordTranslation}
                multiline={false}
                onChange={(event) => {
                  setWordTranslation(event.target.value);
                }}
                type="text"
                variant="outlined"
              />
              <TextField
                fullWidth
                color="primary"
                value={wordOtherTranslations}
                multiline={true}
                placeholder="Other translations"
                onChange={(event) => {
                  setWordOtherTranslations(event.target.value);
                }}
                type="text"
                variant="outlined"
              />
              <TextField
                fullWidth
                color="primary"
                value={wordDescription}
                multiline={true}
                placeholder="Description"
                onChange={(event) => {
                  setWordDescription(event.target.value);
                }}
                type="text"
                variant="outlined"
              />
              {imageName && (
                <Button
                  color="error"
                  variant="outlined"
                  onClick={() => {
                    setImageName("");
                  }}
                >
                  Remove image name
                </Button>
              )}
              <LoadingButton
                loading={loading}
                variant="contained"
                color="error"
                onClick={async () => {
                  if (!word) return;
                  setLoading(true);

                  // setWord(updatedWord);
                  try {
                    await supabaseClient
                      .from("words")
                      .update({
                        name: wordName,
                        transcription: wordTranscription,
                        translation: wordTranslation,
                        other_translation: wordOtherTranslations || "",
                        description: wordDescription || "",
                        image_name: imageName,
                        verified: true,
                      })
                      .eq("name", word.name)
                      .throwOnError();

                    const updatedWord = await supabaseClient
                      .from("words")
                      .select()
                      .eq("name", word.name)
                      .limit(1)
                      .single()
                      .throwOnError();

                    setWord(updatedWord);
                    setTimeout(async () => {
                      // setWord(null);

                      await loadNext();
                      // const newWord = await supabaseClient
                      //   .from("words")
                      //   .select()
                      //   .is("verified", false)
                      //   .limit(1)
                      //   .single()
                      //   .throwOnError();

                      // setWord(newWord);
                    }, 500);
                  } catch (err: any) {
                    console.log("🚀 ~ err:", err);
                    alert(err.message);
                  }
                  setLoading(false);
                }}
              >
                Update the word
              </LoadingButton>
            </HStack>
            {/* ); */}

            {/* })} */}
          </Box>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(AdminFindAndUpdateWord);
