import svgAzerbaijan from "./flags/Azerbaijan/svgAzerbaijan";
import svgAustria from "./flags/Austria/svgAustia";
import svgPolandUkraine from "./flags/PolandUkraine/svgPolandUkraine";

export const rowFlags = ["PolandUkraine", "Austria", "Azerbaijan"];

const getFlags = (letter: string, step: number) => {
  if (letter === "PolandUkraine") return svgPolandUkraine(step);
  if (letter === "Austria") return svgAustria(step);
  if (letter === "Azerbaijan") return svgAzerbaijan(step);
  // if (letter === "Ball2") return ball2(step);
  // if (letter === "Ball3") return ball3(step);
};

export default getFlags;
