import deepEqual from "deep-equal";
import { useEffect, useMemo, useState } from "react";
import { usePrevious } from "react-use";
import firstLetterUppercase from "../../common/firstLetterUppercase";
import supabaseClient from "../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../store/lessonsStore";
import { Word } from "../../types/supabase";

const useGlobalWords = ({ words: iW }: { words: string[] }): Word[] => {
  const setGlobalWords = useLessonsStore((state) => state.setGlobalWords);
  const globalWords = useLessonsStore((state) => state.globalWords);
  const [inputWords, setInputWords] = useState(
    iW
      .filter(Boolean)
      .map((w) => w.replace("–", "-"))
      .filter(Boolean),
  );

  useEffect(() => {
    setInputWords(iW.filter(Boolean).map((w) => w.replace("–", "-")));
  }, [iW]);

  const words = useMemo(() => {
    return globalWords.filter((word) => {
      try {
        const searchWords =
          (inputWords.length && inputWords.includes(word.name)) ||
          inputWords.map((w) => w.toLowerCase()).includes(word.name) ||
          inputWords.map((w) => w.toLowerCase()).includes(word.name.toLowerCase());
        return searchWords;
      } catch (err) {
        console.log("🚀 ~ err:", err);
        return [];
      }
    });
  }, [inputWords, globalWords]);

  const [notExistingWords, setNotExistingWords] = useState<string[]>([]);

  const prevInputWords = usePrevious(inputWords);

  useMountedWaitAsyncEffect(
    async (mounted) => {
      try {
        if (
          inputWords.filter(Boolean).length &&
          inputWords.join("") !== words.map((w) => w.name).join("") &&
          words.length < inputWords.length
        ) {
          const rowWords = words.map((w) => w.name.toLowerCase()).filter(Boolean);
          if (!rowWords.length) return;

          const nEW = inputWords
            .map((w) => w.replace("–", "-"))
            .filter(Boolean)
            .filter((w) => !rowWords.includes(w.toLowerCase()));

          console.log("🚀 ~ notExistingWords:", notExistingWords);
          console.log("🚀 ~ nEW:", nEW);
          if (notExistingWords.join("") === nEW.join("")) {
            return;
          }
          if (nEW.length && notExistingWords.join("") !== nEW.join("")) {
            console.error(nEW);
            setNotExistingWords(nEW);
          }

          if (!notExistingWords.length) return;
          console.log("🚀 ~ saved notExistingWords:", notExistingWords);
          console.log("🚀 ~ new notExistingWords:", nEW);

          console.log("🚀 ~ inputWords:", inputWords);
          const wordsToSearch = Array.from(
            new Set([
              ...inputWords,
              ...inputWords.map((w) => w.toLowerCase()),
              ...inputWords.map((w) => firstLetterUppercase(w)),
            ]),
          );

          if (!mounted) return;
          const { data } = await supabaseClient
            .from("words")
            .select()
            .in("name", wordsToSearch)
            .returns<Word[]>();

          console.log("🚀 ~ data:", wordsToSearch, data);
          if (!mounted) return;
          if (data) setGlobalWords(data);
        }
      } catch (err) {
        console.log("🚀 ~ err:", err);
      }
    },
    [words, inputWords, prevInputWords],
  );

  useEffect(() => {
    (async () => {
      try {
        if (
          inputWords.filter(Boolean).length &&
          inputWords.join("") !== words.map((w) => w.name).join("") &&
          !deepEqual(prevInputWords, inputWords)
        ) {
          const wordsToSearch = Array.from(
            new Set([...inputWords, ...inputWords.map((w) => w.toLowerCase())]),
          );

          const { data } = await supabaseClient
            .from("words")
            .select()
            .in("name", wordsToSearch)
            .returns<Word[]>();
          console.log("🚀 ~ data:", wordsToSearch, data);
          if (data) setGlobalWords(data);
        }
      } catch (err) {
        console.log("🚀 ~ err:", err);
      }
    })();
  }, []);

  return words;
};

export default useGlobalWords;
