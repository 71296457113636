import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { useCallback, useEffect, useMemo, useState } from "react";
import { EnglishStory, Word } from "../../../types/supabase";
import VStack from "../../Unknown/VStack";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import HoverDetails from "../../Word/HoverDetails";
import removePunctuationFromString from "../../../common/removePunctuationFromString";
import useLessonWords from "../../../hooks/useLessonWords";
import { useLessonsStore } from "../../../store/lessonsStore";
import useGlobalWords from "../../hooks/getGlobalWords";
import WordAudio from "../../Audio/WordAudio";
import HStack from "../../Unknown/HStack";
import FullScreenLoader from "../../Unknown/FullScreenLoader";
import ModalCloseButton from "../../Unknown/ModalCloseButton";
import { useParams } from "react-router-dom";
import isAdmin from "../../../common/isAdmin";
import ReactPlayer from "react-player";
import { useWindowSize } from "react-use";
import useIsHomeWork from "../../../hooks/useIsHomeWork";
import ShowCurrentHomeWorkNumbersInDrawer from "../../Unknown/ShowCurrentHomeWorkNumbersInDrawer";
import { userHomeWorkCurrentDay } from "../../../common/constants";
import parsed from "../../../common/parsed";
import { format } from "date-fns";
import ControllingStudentPage from "../../Unknown/Root/ControllingStudentPage";
import ExternalLinkVideo from "../../Unknown/ExternalLinkVideo";
import timeToSeconds from "../../../common/timeToSeconds";
import { TextPartVideoByStartAndEndButton } from "../../Unknown/TextPartVideoByStartAndEnd";
import CopyStoryIdButton from "../../Unknown/CopyStoryIdButton";
import CopySupabaseStoryLink from "../../Unknown/CopySupabaseStoryLink";
import StoryText from "./StoryText";
import ItalicTitle from "../../Title/ItalicTitle";
import AdminControlUserEnglishLesson from "../../EnglishKidsLessons/EnglishKidsLessonsScreen/AdminControlUserEnglishLesson";
import AdminComponentIndicator from "../../Unknown/AdminComponentIndicator";
import supabaseClient from "../../../common/supabaseClient";
import Loader from "../../Unknown/Loader";

export const Story: React.FC<{ story: EnglishStory; withoutLink?: boolean }> = ({
  story,
  withoutLink,
}) => {
  console.log("🚀 ~ story:", story);
  const [isTranslated, setIsTranslated] = useState(false);
  const authUser = useLessonsStore((state) => state.authUser);
  const globalWords = useLessonsStore((state) => state.globalWords);
  const setGlobalWords = useLessonsStore((state) => state.setGlobalWords);

  const { width: windowWidth } = useWindowSize();
  const videosToRepeat = useLessonsStore((state) => state.homeWork.videosToRepeat);
  const repeatedVideos = useLessonsStore((state) => state.homeWork.repeatedVideos);
  const updateHomeWork = useLessonsStore((state) => state.updateHomeWork);
  const [showText, setShowText] = useState(false);
  const [wordsLoading, setWordsLoading] = useState(false);
  // const options = useMemo(() => {
  //   return { words: showText ? story.words : [] };
  // }, [story, showText]);
  // useEffect(() => {

  // }, [])
  // console.log("🚀 [Story] lesson words to fetch:", options);
  // useGlobalWords(options);

  // const phrases = ["United States"]

  // const isHomeWork = useIsHomeWork({ lessonId: +(lessonId || 0), lessonType: lessonType || "" });
  const isHomeWork = videosToRepeat.includes(story.id);

  const handleOnEnded = () => {
    if (isHomeWork && !repeatedVideos.includes(story.id)) {
      updateHomeWork({ repeatedVideos: [...repeatedVideos, story.id] });

      const localStorageHomeWorkTracking = localStorage.getItem(userHomeWorkCurrentDay);
      let newHomeWorkTracking: Record<string, any> = {
        ...parsed(localStorageHomeWorkTracking, {}),
      };
      const currentDate = format(new Date(), "yyyy-MM-dd");

      if (!newHomeWorkTracking[currentDate]) return;

      if (!newHomeWorkTracking[currentDate].repeatedVideos) {
        newHomeWorkTracking[currentDate].repeatedVideos = [];
      }

      if (newHomeWorkTracking[currentDate].repeatedVideos.includes(story.id)) return;

      const updateRepeatedVideos = [...newHomeWorkTracking[currentDate].repeatedVideos, story.id];
      newHomeWorkTracking[currentDate].repeatedVideos = updateRepeatedVideos;

      localStorage.setItem(userHomeWorkCurrentDay, JSON.stringify(newHomeWorkTracking));
      updateHomeWork({
        repeatedVideos: updateRepeatedVideos,
      });
    }
  };

  const videoWidth = useMemo(() => {
    if (windowWidth < 400) {
      return 300;
    } else if (windowWidth < 500) {
      return 380;
    } else if (windowWidth < 600) {
      return 480;
    }
    return 580;
  }, [windowWidth]);

  const showWordsButton = useMemo(() => {
    if (wordsLoading) {
      return (
        <Box width="54px" height="54px">
          <CircularProgress />
        </Box>
      );
    }
    return (
      <IconButton
        size="large"
        color={showText ? "primary" : "default"}
        onClick={async () => {
          const rowGlobalWords = globalWords.map((w) => w.name);
          const wordsToSearch = Array.from(new Set(story.words)).filter(
            (w) => !rowGlobalWords.includes(w),
          );

          if (wordsToSearch.length) {
            setWordsLoading(true);
            const { data } = await supabaseClient
              .from("words")
              .select()
              .in("name", wordsToSearch)
              .returns<Word[]>();

            if (data) setGlobalWords(data);
          }

          setShowText((prev) => !prev);

          setTimeout(() => {
            setWordsLoading(false);
          }, 300);
        }}
      >
        <FormatAlignLeftIcon sx={{ fontSize: 30 }} />
      </IconButton>
    );
  }, [showText, globalWords, wordsLoading]);

  const title = useMemo(() => {
    const init = story.verse.split("\n")[0];
    if (init.startsWith("_time")) {
      return init
        .split(" ")
        .filter((s) => !s.startsWith("_time"))
        .join(" ");
    }
    return init;
  }, [story.verse]);

  return (
    <Box width="100%">
      <VStack alignItems="flex-start" width="100%" px={4}>
        {!story.youtube_id && story.link && (
          <>
            <ItalicTitle text="це відео можна подивитися тільки на сайті автора" />
            <Button
              color="info"
              variant="contained"
              component={Link}
              target="_blank"
              href={story.link}
            >
              Подивитися відео
            </Button>
            {showWordsButton}
          </>
        )}
        {story.youtube_id && story.link?.includes("shorts") && (
          <>
            <Box
              margin="0 auto"
              sx={{
                position: "relative",
                width: "280px",
                height: "500px",
              }}
            >
              <ReactPlayer
                controls
                width="100%"
                height="500px"
                onEnded={() => {
                  handleOnEnded();
                }}
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
                url={`${story.link}`}
              />
            </Box>
            {showWordsButton}
          </>
        )}

        {!story.link?.includes("shorts") && story.youtube_id && (
          <>
            <AdminComponentIndicator title={"Stories/index.tsx/Story"} />
            {story.image && <Box width="230px" component="img" src={story.image} />}
            <HStack
              gap={0}
              sx={{
                position: "relative",
                // pt: 10
                py: 0,
                width: "100%",
                // width: videoWidth,
                // pb: "52.5%",
                // height: "500px",
              }}
            >
              {showWordsButton}
              <TextPartVideoByStartAndEndButton
                start={+(story.clip_start || 0)}
                end={+(story.clip_start || 0) + +(story.duration || 10)}
                story={story}
                withButton
              />
            </HStack>
          </>
        )}
        {/* <ItalicTitle text={title} /> */}
        {/* <VideoFrameCapture
        url={`https://www.youtube.com/watch?v=${story.youtube_id}`}
        time={story.clip_start || 0 + 1}
      /> */}

        {/* {!showText && (
        <>
          <Button
            variant="outlined"
            color="info"
            onClick={() => {
              setShowText(true);
            }}
          >
            показати текст
          </Button>
        </>
      )} */}
        {story.storage_link && !story.youtube_id && (
          <VStack
            sx={{
              position: "relative",
              minWidth: "280px",
              maxWidth: 600,
              width: "100%",
              overflow: "hidden",
            }}
          >
            <ReactPlayer
              controls
              width="100%"
              height="100%"
              onEnded={() => {
                handleOnEnded();
              }}
              style={{
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
              }}
              url={`${story.storage_link}`}
            />
          </VStack>
        )}
        <Box>
          {/* <Box mt={-10}>
          <ControllingStudentPage isNested />
        </Box> */}
          {story.audio && <WordAudio url={story.audio} />}
          {/* {story.title && (
          <Box>
            <Typography color="secondary.dark" fontSize={22} fontWeight={700}>
              {story.title}
            </Typography>
          </Box>
        )} */}

          {showText && <StoryText story={story} />}
          {!withoutLink && (
            <Box mb={10}>
              {!story.is_external && (
                <Link href={story.link} target="_blank">
                  {story.link.startsWith("https://www.eslfast.com")
                    ? "прослухати кращий запис"
                    : "повне відео"}
                </Link>
              )}
              {story.is_external && (
                <Link href={story.link} target="_blank">
                  подивитися відео
                </Link>
              )}
            </Box>
          )}
        </Box>
      </VStack>
      <hr />
    </Box>
  );
};

const Stories: React.FC<{
  stories: EnglishStory[];
  buttonSize?: "small" | "medium" | "large";
  buttonLabel?: string;
  isReadingVideo?: boolean;
  withoutLink?: boolean;
  withoutDrawer?: boolean;
}> = ({ stories, withoutLink, isReadingVideo, buttonSize, buttonLabel, withoutDrawer }) => {
  // const [isOpen, setIsOpen] = useState(false);
  const lessonModalOpen = useLessonsStore((state) => state.lessonModalOpen === "videos");
  const setLessonModalOpen = useLessonsStore((state) => state.setLessonModalOpen);
  const activeVideoId = useLessonsStore((state) => state.activeVideoId);
  const { lessonId, lessonType } = useParams();
  const lessonData = useLessonsStore((state) => state.lessonData);

  const handleDrawerClose = useCallback(() => {
    // setIsOpen(false)
    setLessonModalOpen("");
    // if (onDrawerClose) onDrawerClose();
  }, []);

  const content = useMemo(() => {
    if (withoutDrawer) {
      return (
        <DrawerContent
          withoutDrawer={withoutDrawer}
          withoutLink={withoutLink}
          stories={stories}
          onClose={handleDrawerClose}
        />
      );
    }

    return (
      <Drawer
        open={lessonModalOpen}
        onClose={handleDrawerClose}
        anchor="bottom"
        sx={{
          zIndex: 999,
          // opacity: 0,
          // backgroundColor: "rgba(0, 0, 0, 1) !important",
        }}
      >
        <DrawerContent
          withoutDrawer={withoutDrawer}
          withoutLink={withoutLink}
          stories={stories}
          onClose={handleDrawerClose}
        />
      </Drawer>
    );
  }, [withoutDrawer, withoutLink, lessonModalOpen, stories, handleDrawerClose, activeVideoId]);

  return (
    <>
      {!withoutDrawer && (
        <Button
          variant="outlined"
          size={buttonSize || "medium"}
          onClick={() => setLessonModalOpen("videos")}
        >
          {(lessonId && lessonData?.[lessonId]?.module.is_video) || isReadingVideo
            ? buttonLabel || "Відео"
            : buttonLabel || "Історія"}
        </Button>
      )}
      {content}
      {/* <Drawer
        open={lessonModalOpen}
        onClose={handleDrawerClose}
        anchor="bottom"
        sx={{
          zIndex: 999,
          // opacity: 0,
          // backgroundColor: "rgba(0, 0, 0, 1) !important",
        }}
      >
        <DrawerContent withoutLink={withoutLink} stories={stories} onClose={handleDrawerClose} />
      </Drawer> */}
    </>
  );
};

const drawerContentStyle = {
  height: "100vh",
  width: "100vw",
  overflowX: "hidden",
  // backgroundColor: "rgba(0, 0, 0, 0) !important",
};

const DrawerContent: React.FC<{
  stories: EnglishStory[];
  withoutLink?: boolean;
  withoutDrawer?: boolean;
  onClose: () => void;
}> = ({ stories, withoutLink, withoutDrawer, onClose }) => {
  console.log("🚀 ~ stories:", stories);
  const videosToRepeat = useLessonsStore((state) => state.homeWork.videosToRepeat);
  const [isHomeWork] = stories.map((s) => videosToRepeat.includes(s.id)).filter(Boolean);

  const drawer = useMemo(() => {
    return (
      <Box sx={withoutDrawer ? {} : drawerContentStyle} pt={isHomeWork ? 5 : 0} width="100%">
        {/* {isHomeWork && (
          <Box pt={14}>
            <ShowCurrentHomeWorkNumbersInDrawer type="videos" />
          </Box>
        )} */}
        {!withoutDrawer && <ModalCloseButton onClose={onClose} />}
        <VStack py={10} gap={10} width="100%">
          {stories.map((story, index) => (
            <Box mb={3} key={story.id} maxWidth={600} width="100%">
              <Story key={story.id} withoutLink={withoutLink} story={story} />
            </Box>
          ))}
        </VStack>
      </Box>
    );
  }, [stories, onClose]);

  return drawer;
};

export default Stories;
