const storiesData = [
  // {
  //   row_new_words: null,
  //   words: [],
  //   link: null,
  //   pdf_name: "book-image-3",
  //   type: "pdf",
  //   youtube_id: null,
  //   storage_link: null,
  //   id: "I am Pam.\nI can hop.\nI am big.\nA dog can run.\nI can run.\nI am in bed.",
  //   story_id: 1911,
  //   word_count: null,
  //   all_intersection_count: 0,
  //   new_words_count: null,
  // },
  // {
  //   row_new_words: ["I", "dig", "sit", "mix", "it", "zip", "am", "on", "top", "in", "the", "end"],
  //   words: ["I", "dig", "sit", "mix", "it", "zip", "am", "on", "top", "in", "the", "end"],
  //   link: null,
  //   pdf_name: "book-image-1",
  //   type: "pdf",
  //   youtube_id: null,
  //   storage_link: null,
  //   id: "I dig.\nI sit.\nI mix it.\nI zip it.\nI am on top.\nI am in it.\nThe end.",
  //   story_id: 1755,
  //   word_count: 12,
  //   all_intersection_count: 0,
  //   new_words_count: 12,
  // },
  // {
  //   row_new_words: [
  //     "I",
  //     "am",
  //     "wet",
  //     "hot",
  //     "Rex",
  //     "and",
  //     "sit",
  //     "can",
  //     "get",
  //     "in",
  //     "yes",
  //     "not",
  //     "the",
  //     "end",
  //   ],
  //   words: [
  //     "I",
  //     "am",
  //     "wet",
  //     "hot",
  //     "Rex",
  //     "and",
  //     "sit",
  //     "can",
  //     "get",
  //     "in",
  //     "yes",
  //     "not",
  //     "the",
  //     "end",
  //   ],
  //   link: null,
  //   pdf_name: "book-image-2",
  //   type: "pdf",
  //   youtube_id: null,
  //   storage_link: null,
  //   id: "I am wet.\nI am hot.\nRex and I sit.\nCan I get in?\nYes, I am in.\nI am wet!\nI am not hot.\nThe end.",
  //   story_id: 1756,
  //   word_count: 14,
  //   all_intersection_count: 0,
  //   new_words_count: 14,
  // },
  // {
  //   row_new_words: [
  //     "we",
  //     "go",
  //     "jim",
  //     "and",
  //     "dad",
  //     "pam",
  //     "love",
  //     "to",
  //     "nap",
  //     "sit",
  //     "tim",
  //     "can",
  //     "on",
  //     "pet",
  //     "the",
  //     "dog",
  //     "end",
  //   ],
  //   words: [
  //     "we",
  //     "go",
  //     "jim",
  //     "and",
  //     "dad",
  //     "pam",
  //     "love",
  //     "to",
  //     "nap",
  //     "sit",
  //     "tim",
  //     "can",
  //     "on",
  //     "pet",
  //     "the",
  //     "dog",
  //     "end",
  //   ],
  //   link: null,
  //   pdf_name: "book-image-4",
  //   type: "pdf",
  //   youtube_id: null,
  //   storage_link: null,
  //   id: "We go\nJim and dad go.\nWe go.\nDad and Pam\nlove to nap.\nWe love to sit.\nTim can sit on Dad.\nWe pet the dog.\nThe end.",
  //   story_id: 1758,
  //   word_count: 17,
  //   all_intersection_count: 0,
  //   new_words_count: 17,
  // },
  // {
  //   row_new_words: [
  //     "the",
  //     "jet",
  //     "he",
  //     "gets",
  //     "on",
  //     "has",
  //     "gas",
  //     "can",
  //     "go",
  //     "up",
  //     "loves",
  //     "it",
  //     "she",
  //     "a",
  //     "cup",
  //     "is",
  //     "wet",
  //     "was",
  //     "so",
  //     "fun",
  //     "end",
  //   ],
  //   words: [
  //     "the",
  //     "jet",
  //     "he",
  //     "gets",
  //     "on",
  //     "has",
  //     "gas",
  //     "can",
  //     "go",
  //     "up",
  //     "loves",
  //     "it",
  //     "she",
  //     "a",
  //     "cup",
  //     "is",
  //     "wet",
  //     "was",
  //     "so",
  //     "fun",
  //     "end",
  //   ],
  //   link: null,
  //   pdf_name: "book-image-5",
  //   type: "pdf",
  //   youtube_id: null,
  //   storage_link: null,
  //   id: "The jet\nHe gets on.\nThe jet has gas.\nThe jet can go up.\nHe loves it!\nShe has a cup.\nIt is wet.\nIt was so fun!\nThe end.",
  //   story_id: 1759,
  //   word_count: 21,
  //   all_intersection_count: 0,
  //   new_words_count: 21,
  // },
  // {
  //   row_new_words: [
  //     "pray",
  //     "it",
  //     "is",
  //     "day",
  //     "rick",
  //     "may",
  //     "look",
  //     "she",
  //     "can",
  //     "play",
  //     "and",
  //     "the",
  //     "kids",
  //     "to",
  //     "god",
  //     "ann",
  //     "ray",
  //     "stay",
  //     "end",
  //     "of",
  //     "jill",
  //     "will",
  //   ],
  //   words: [
  //     "pray",
  //     "it",
  //     "is",
  //     "day",
  //     "rick",
  //     "may",
  //     "look",
  //     "she",
  //     "can",
  //     "play",
  //     "and",
  //     "the",
  //     "kids",
  //     "to",
  //     "god",
  //     "ann",
  //     "ray",
  //     "stay",
  //     "end",
  //     "of",
  //     "jill",
  //     "will",
  //   ],
  //   link: null,
  //   pdf_name: "book-image-14",
  //   type: "pdf",
  //   youtube_id: null,
  //   storage_link: null,
  //   id: "Pray\nIt is day\nRick may pray\nLook! She can play, and she can pray.\nThe kids pray to God.\nAnn and Ray\nstay and pray.\nIt is the end of the day.\nJill will pray.\nThe end.",
  //   story_id: 1768,
  //   word_count: 22,
  //   all_intersection_count: 0,
  //   new_words_count: 22,
  // },
  // {
  //   row_new_words: [
  //     "big",
  //     "red",
  //     "is",
  //     "a",
  //     "dog",
  //     "he",
  //     "not",
  //     "sad",
  //     "can",
  //     "you",
  //     "wag",
  //     "sit",
  //     "yes",
  //     "go",
  //     "we",
  //     "let",
  //     "it",
  //     "nap",
  //     "and",
  //     "jen",
  //     "love",
  //     "to",
  //     "get",
  //     "fed",
  //     "the",
  //     "end",
  //   ],
  //   words: [
  //     "big",
  //     "red",
  //     "is",
  //     "a",
  //     "dog",
  //     "he",
  //     "not",
  //     "sad",
  //     "can",
  //     "you",
  //     "wag",
  //     "sit",
  //     "yes",
  //     "go",
  //     "we",
  //     "let",
  //     "it",
  //     "nap",
  //     "and",
  //     "jen",
  //     "love",
  //     "to",
  //     "get",
  //     "fed",
  //     "the",
  //     "end",
  //   ],
  //   link: null,
  //   pdf_name: "book-image-6",
  //   type: "pdf",
  //   youtube_id: null,
  //   storage_link: null,
  //   id: "Big Red\nBig Red is a big red dog.\nHe is not sad.\nCan you wag, big Red?\nCan he sit?\nYes!\nCan he go?\nYes!\nBig Red, we can let it nap.\nBig Red and Jen love to get fed.\nThe end.",
  //   story_id: 1760,
  //   word_count: 26,
  //   all_intersection_count: 0,
  //   new_words_count: 26,
  // },
  // {
  //   row_new_words: [
  //     "beth",
  //     "loves",
  //     "math",
  //     "to",
  //     "do",
  //     "on",
  //     "the",
  //     "bed",
  //     "she",
  //     "does",
  //     "it",
  //     "at",
  //     "this",
  //     "desk",
  //     "by",
  //     "thin",
  //     "path",
  //     "with",
  //     "seth",
  //     "is",
  //     "fun",
  //     "cat",
  //     "sits",
  //     "them",
  //     "also",
  //     "a",
  //     "spot",
  //     "best",
  //     "way",
  //     "end",
  //   ],
  //   words: [
  //     "beth",
  //     "loves",
  //     "math",
  //     "to",
  //     "do",
  //     "on",
  //     "the",
  //     "bed",
  //     "she",
  //     "does",
  //     "it",
  //     "at",
  //     "this",
  //     "desk",
  //     "by",
  //     "thin",
  //     "path",
  //     "with",
  //     "seth",
  //     "is",
  //     "fun",
  //     "cat",
  //     "sits",
  //     "them",
  //     "also",
  //     "a",
  //     "spot",
  //     "best",
  //     "way",
  //     "end",
  //   ],
  //   link: null,
  //   pdf_name: "book-image-17",
  //   type: "pdf",
  //   youtube_id: null,
  //   storage_link: null,
  //   id: "Beth loves math\nBeth loves to do math on the bed.\nShe does it at this desk.\nShe does math by the thin path.\nMath with Seth is fun.\nThe cat sits with them.\nThis is also a fun spot to do math.\nThis is the best way to do math!\nThe end.",
  //   story_id: 1771,
  //   word_count: 30,
  //   all_intersection_count: 0,
  //   new_words_count: 30,
  // },
  // {
  //   row_new_words: [
  //     "the",
  //     "log",
  //     "is",
  //     "in",
  //     "hot",
  //     "sun",
  //     "fog",
  //     "look",
  //     "it",
  //     "so",
  //     "wet",
  //     "a",
  //     "cat",
  //     "has",
  //     "nap",
  //     "she",
  //     "or",
  //     "on",
  //     "top",
  //     "of",
  //     "do",
  //     "you",
  //     "love",
  //     "web",
  //     "can",
  //     "hop",
  //     "he",
  //     "fit",
  //     "yes",
  //     "end",
  //   ],
  //   words: [
  //     "the",
  //     "log",
  //     "is",
  //     "in",
  //     "hot",
  //     "sun",
  //     "fog",
  //     "look",
  //     "it",
  //     "so",
  //     "wet",
  //     "a",
  //     "cat",
  //     "has",
  //     "nap",
  //     "she",
  //     "or",
  //     "on",
  //     "top",
  //     "of",
  //     "do",
  //     "you",
  //     "love",
  //     "web",
  //     "can",
  //     "hop",
  //     "he",
  //     "fit",
  //     "yes",
  //     "end",
  //   ],
  //   link: null,
  //   pdf_name: "book-image-9",
  //   type: "pdf",
  //   youtube_id: null,
  //   storage_link: null,
  //   id: "The log\nThe log is in the hot sun.\nThe log is in the fog.\nLook, it is so wet.\nA cat has a nap.\nIs she in the log or on top of the log?\nDo you love the web on the log?\nLook! It can hop on top of the log!\nCan he fit on the log?\nYes, he can!\nThe end.",
  //   story_id: 1763,
  //   word_count: 30,
  //   all_intersection_count: 0,
  //   new_words_count: 30,
  // },
  // {
  //   row_new_words: [
  //     "the",
  //     "fox",
  //     "look",
  //     "at",
  //     "dad",
  //     "he",
  //     "can",
  //     "run",
  //     "and",
  //     "is",
  //     "in",
  //     "it",
  //     "or",
  //     "on",
  //     "do",
  //     "you",
  //     "love",
  //     "his",
  //     "bed",
  //     "mom",
  //     "she",
  //     "log",
  //     "no",
  //     "top",
  //     "of",
  //     "has",
  //     "a",
  //     "kit",
  //     "loves",
  //     "fun",
  //     "end",
  //   ],
  //   words: [
  //     "the",
  //     "fox",
  //     "look",
  //     "at",
  //     "dad",
  //     "he",
  //     "can",
  //     "run",
  //     "and",
  //     "is",
  //     "in",
  //     "it",
  //     "or",
  //     "on",
  //     "do",
  //     "you",
  //     "love",
  //     "his",
  //     "bed",
  //     "mom",
  //     "she",
  //     "log",
  //     "no",
  //     "top",
  //     "of",
  //     "has",
  //     "a",
  //     "kit",
  //     "loves",
  //     "fun",
  //     "end",
  //   ],
  //   link: null,
  //   pdf_name: "book-image-8",
  //   type: "pdf",
  //   youtube_id: null,
  //   storage_link: null,
  //   id: "The fox\nLook at the dad fox.\nHe can run and run.\nIs he in it or on it?\nDo you love his bed?\nLook at the mom fox.\nIs she in the log?\nNo, she is on top of the log.\nLook! She has a kit!\nShe loves the kit.\nThe kit has fun.\nThe end.",
  //   story_id: 1762,
  //   word_count: 31,
  //   all_intersection_count: 0,
  //   new_words_count: 31,
  // },
  // {
  //   row_new_words: [
  //     "fun",
  //     "in",
  //     "the",
  //     "sun",
  //     "tom",
  //     "and",
  //     "dog",
  //     "hop",
  //     "on",
  //     "log",
  //     "sam",
  //     "jan",
  //     "pat",
  //     "fat",
  //     "cat",
  //     "jed",
  //     "dez",
  //     "pet",
  //     "red",
  //     "hen",
  //     "kip",
  //     "liz",
  //     "sit",
  //     "pit",
  //     "we",
  //     "run",
  //     "to",
  //     "mom",
  //     "it",
  //     "is",
  //     "so",
  //     "end",
  //   ],
  //   words: [
  //     "fun",
  //     "in",
  //     "the",
  //     "sun",
  //     "tom",
  //     "and",
  //     "dog",
  //     "hop",
  //     "on",
  //     "log",
  //     "sam",
  //     "jan",
  //     "pat",
  //     "fat",
  //     "cat",
  //     "jed",
  //     "dez",
  //     "pet",
  //     "red",
  //     "hen",
  //     "kip",
  //     "liz",
  //     "sit",
  //     "pit",
  //     "we",
  //     "run",
  //     "to",
  //     "mom",
  //     "it",
  //     "is",
  //     "so",
  //     "end",
  //   ],
  //   link: null,
  //   pdf_name: "book-image-7",
  //   type: "pdf",
  //   youtube_id: null,
  //   storage_link: null,
  //   id: "Fun in the sun\nTom and the dog hop on the log.\nSam and Jan pat the fat cat.\nJed and Dez pet the red hen.\nKip and Liz sit in the pit.\nWe run to Mom. It is so fun in the sun!\nThe end.",
  //   story_id: 1761,
  //   word_count: 32,
  //   all_intersection_count: 0,
  //   new_words_count: 32,
  // },
  // {
  //   row_new_words: [
  //     "dash",
  //     "and",
  //     "I",
  //     "rush",
  //     "to",
  //     "the",
  //     "pond",
  //     "we",
  //     "see",
  //     "a",
  //     "fish",
  //     "jump",
  //     "up",
  //     "wind",
  //     "picks",
  //     "it",
  //     "smells",
  //     "so",
  //     "fresh",
  //     "set",
  //     "ship",
  //     "in",
  //     "goes",
  //     "fast",
  //     "get",
  //     "as",
  //     "flash",
  //     "will",
  //     "crash",
  //     "smash",
  //     "no",
  //     "got",
  //     "end",
  //   ],
  //   words: [
  //     "dash",
  //     "and",
  //     "I",
  //     "rush",
  //     "to",
  //     "the",
  //     "pond",
  //     "we",
  //     "see",
  //     "a",
  //     "fish",
  //     "jump",
  //     "up",
  //     "wind",
  //     "picks",
  //     "it",
  //     "smells",
  //     "so",
  //     "fresh",
  //     "set",
  //     "ship",
  //     "in",
  //     "goes",
  //     "fast",
  //     "get",
  //     "as",
  //     "flash",
  //     "will",
  //     "crash",
  //     "smash",
  //     "no",
  //     "got",
  //     "end",
  //   ],
  //   link: null,
  //   pdf_name: "book-image-15",
  //   type: "pdf",
  //   youtube_id: null,
  //   storage_link: null,
  //   id: "Dash and I\nDash and I rush to the pond.\nWe see a fish jump up.\nThe wind picks up.\nIt smells so fresh.\nI set a ship in the pond.\nIt goes fast!\nWe rush to get it.\nDash goes as fast as a flash!\nWill it crash?\nWill it smash?\nNo! I got it!\nThe end.",
  //   story_id: 1769,
  //   word_count: 33,
  //   all_intersection_count: 0,
  //   new_words_count: 33,
  // },
  // {
  //   row_new_words: [
  //     "at",
  //     "the",
  //     "well",
  //     "will",
  //     "you",
  //     "fill",
  //     "jugs",
  //     "yes",
  //     "we",
  //     "tess",
  //     "and",
  //     "bill",
  //     "huff",
  //     "puff",
  //     "up",
  //     "big",
  //     "hill",
  //     "his",
  //     "jug",
  //     "has",
  //     "less",
  //     "it",
  //     "is",
  //     "not",
  //     "a",
  //     "loss",
  //     "I",
  //     "can",
  //     "tell",
  //     "did",
  //     "toss",
  //     "ball",
  //     "to",
  //     "end",
  //   ],
  //   words: [
  //     "at",
  //     "the",
  //     "well",
  //     "will",
  //     "you",
  //     "fill",
  //     "jugs",
  //     "yes",
  //     "we",
  //     "tess",
  //     "and",
  //     "bill",
  //     "huff",
  //     "puff",
  //     "up",
  //     "big",
  //     "hill",
  //     "his",
  //     "jug",
  //     "has",
  //     "less",
  //     "it",
  //     "is",
  //     "not",
  //     "a",
  //     "loss",
  //     "I",
  //     "can",
  //     "tell",
  //     "did",
  //     "toss",
  //     "ball",
  //     "to",
  //     "end",
  //   ],
  //   link: null,
  //   pdf_name: "book-image-12",
  //   type: "pdf",
  //   youtube_id: null,
  //   storage_link: null,
  //   id: "At the well\nWill you fill the jugs at the well?\nYes, we will!\nTess and bill huff and puff up the big hill.\nHis jug has less.\nIt is not a big loss.\nTess and Bill, I can tell you did well.\nBill, can you toss the ball?\nYes, I will toss it to you, Tess.\nThe end.",
  //   story_id: 1766,
  //   word_count: 34,
  //   all_intersection_count: 0,
  //   new_words_count: 34,
  // },
  // {
  //   row_new_words: [
  //     "eight",
  //     "pretty",
  //     "sheep",
  //     "we",
  //     "are",
  //     "friends",
  //     "with",
  //     "this",
  //     "is",
  //     "where",
  //     "our",
  //     "stay",
  //     "play",
  //     "around",
  //     "the",
  //     "sky",
  //     "very",
  //     "blue",
  //     "I",
  //     "would",
  //     "love",
  //     "to",
  //     "pet",
  //     "but",
  //     "it",
  //     "runs",
  //     "could",
  //     "all",
  //     "day",
  //     "know",
  //     "should",
  //     "go",
  //     "look",
  //     "sleep",
  //   ],
  //   words: [
  //     "eight",
  //     "pretty",
  //     "sheep",
  //     "we",
  //     "are",
  //     "friends",
  //     "with",
  //     "this",
  //     "is",
  //     "where",
  //     "our",
  //     "stay",
  //     "play",
  //     "around",
  //     "the",
  //     "sky",
  //     "very",
  //     "blue",
  //     "I",
  //     "would",
  //     "love",
  //     "to",
  //     "pet",
  //     "but",
  //     "it",
  //     "runs",
  //     "could",
  //     "all",
  //     "day",
  //     "know",
  //     "should",
  //     "go",
  //     "look",
  //     "sleep",
  //   ],
  //   link: null,
  //   pdf_name: "book-image-21",
  //   type: "pdf",
  //   youtube_id: null,
  //   storage_link: null,
  //   id: "Eight pretty sheep\nWe are friends with eight pretty sheep.\nThis is where our sheep stay.\nWe play around. The sky is very blue.\nI would love to pet this sheep, but it runs.\nWe could play all day, but I know we should go.\nLook where the eight pretty sheep sleep.",
  //   story_id: 1775,
  //   word_count: 34,
  //   all_intersection_count: 0,
  //   new_words_count: 34,
  // },
  {
    row_new_words: ["I", "walking"],
    words: ["I", "am", "running", "I'm", "jumping", "walking"],
    link: "https://www.youtube.com/watch?v=rFdhrR6Dpco",
    pdf_name: null,
    type: "story",
    youtube_id: "rFdhrR6Dpco",
    clip_start: "0",
    duration: "21",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/Present%20Continuous.%20Affirmative%20form.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=N4Lgqe02gGFVNrCr1mG7ykUAaCRDMynvyHfawzP%2FgfJaPEyA7qvzyR4YH%2FX8CwKs3eR1TDDkkmSdOr0sCIF7SwWFs%2F9QtJJlkgi8mLTTZNyh98c8TelntTmupuhKex3rJfw0NIGF3MUTQl8u6c%2BnqA12iW6pKSBm%2F46dF3K4SnWW9r0kEQ0XLUyD9Kmb2SETcaM%2FISorwSBF5XNiLMfxIVttXFvvhAi8BoRKcycCcc1ZZqgK9OoyXWvxIKH8Hau0PYD4JwcpCoTSJLEvU3JRpCmqSimRDxDiPW09kR6COc3MT6dvf0QRcJhFsWOUn%2F%2F%2FIN47QwwGU1WibFJWUILSMQ%3D%3D",
    id: "I am running.\nI'm running.\n\nI am jumping.\nI'm jumping.\n\nI am walking.\nI'm walking.",
    story_id: 952,
    word_count: 6,
    all_intersection_count: 4,
    new_words_count: 2,
  },
  {
    row_new_words: ["I", "dancing", "drawing", "sleeping"],
    words: [
      "what",
      "are",
      "you",
      "doing",
      "I",
      "am",
      "washing",
      "eating",
      "dancing",
      "now",
      "is",
      "he",
      "running",
      "she",
      "drawing",
      "sleeping",
    ],
    link: "https://youtu.be/yZREnbj7_8w?si=oRsb4CBtlNoWPPev",
    pdf_name: null,
    type: "video",
    youtube_id: "yZREnbj7_8w",
    clip_start: "0",
    duration: "87",
    storage_link: "",
    id: "What are you doing?\nI am washing, washing.\nWhat are you doing?\nI am eating, eating.\nWhat are you doing?\nI am dancing, dancing. I am dancing now.\nWhat is he doing?\nHe is running, running.\nWhat is she doing?\nShe is drawing, drawing.\nWhat is she doing?\nShe is sleeping, sleeping. She is sleeping now.",
    story_id: 1942,
    word_count: 16,
    all_intersection_count: 12,
    new_words_count: 4,
  },
  {
    row_new_words: ["tap", "point", "tummy", "wave", "goodbye"],
    words: [
      "one",
      "little",
      "finger",
      "tap",
      "point",
      "your",
      "up",
      "down",
      "put",
      "it",
      "on",
      "ear",
      "mouth",
      "hand",
      "tummy",
      "knee",
      "toe",
      "now",
      "let's",
      "wave",
      "goodbye",
    ],
    link: "erteyzvS9Ds",
    pdf_name: null,
    type: "song",
    youtube_id: "erteyzvS9Ds",
    clip_start: "7",
    duration: "142",
    storage_link: null,
    id: "One little finger, one little finger, one little finger.\nTap tap tap. \nPoint your finger up. \nPoint your finger down. \nPut it on your ear. Ear! \n\nOne little finger, one little finger, one little finger.\nTap tap tap.  \nPoint your finger up. \nPoint your finger down. \nPut it on your mouth. Mouth! \n\nOne little finger, one little finger, one little finger.\nTap tap tap. \nPoint your finger up. \nPoint your finger down. \nPut it on your hand. Hand! \n\nOne little finger, one little finger, one little finger.\nTap tap tap.\nPoint your finger up. \nPoint your finger down. \nPut it on your tummy. Tummy! \n\nOne little finger, one little finger, one little finger.\nTap tap tap. \nPoint your finger up. \nPoint your finger down. \nPut it on your knee. Knee! \n\nOne little finger, one little finger, one little finger.\nTap tap tap.\nPoint your finger up. \nPoint your finger down. \nPut it on your toe. Toe! \nPut it on your knee. Knee! \nPut it on your tummy. Tummy! \nPut it on your hand. Hand! \nPut it on your mouth. Mouth! \nPut it on your ear. Ear! \nNow let's wave goodbye. Goodbye!",
    story_id: 1860,
    word_count: 21,
    all_intersection_count: 16,
    new_words_count: 5,
  },
  {
    row_new_words: ["move", "slowly", "quiet", "the", "leader"],
    words: [
      "line",
      "up",
      "everybody",
      "let's",
      "make",
      "a",
      "move",
      "slowly",
      "hands",
      "by",
      "your",
      "side",
      "quiet",
      "please",
      "follow",
      "the",
      "leader",
      "here",
      "we",
      "go",
    ],
    link: "ciE3JOaiU_k",
    pdf_name: null,
    type: "song",
    youtube_id: "ciE3JOaiU_k",
    clip_start: null,
    duration: null,
    storage_link: null,
    id: "Line up. Line up.\nEverybody line up.\nLine up. Line up.\nLet's make a line.\n\nLine up. Line up.\nEverybody line up.\nLine up. Line up.\nLet's make a line.\n\nMove slowly.\nHands by your side.\nQuiet please.\nFollow the leader!\nHere we go.\n\nLine up. Line up.\nEverybody line up.\nLine up. Line up.\nLet's make a line.\n\nLine up. Line up.\nEverybody line up.\nLine up. Line up.\nLet's make a line.\n\nMove slowly.\nHands by your side.\nQuiet please.\nFollow the leader!\nHere we go!\n\nLine up. Line up.\nEverybody line up.\nLine up. Line up.\nLet's make a line.\n\nLine up. Line up.\nEverybody line up.\nLine up. Line up.\nLet's make a line.",
    story_id: 1853,
    word_count: 20,
    all_intersection_count: 15,
    new_words_count: 5,
  },
  {
    row_new_words: ["walking", "tiptoe", "swimming", "wake", "it's", "ready"],
    words: [
      "walking",
      "hop",
      "running",
      "now",
      "let's",
      "stop",
      "tiptoe",
      "jump",
      "swimming",
      "sleep",
      "wake",
      "up",
      "it's",
      "time",
      "to",
      "go",
      "are",
      "you",
      "ready",
      "fast",
      "okay",
    ],
    link: "fPMjnlTEZwU",
    pdf_name: null,
    type: "song",
    youtube_id: "fPMjnlTEZwU",
    clip_start: "5",
    duration: "90",
    storage_link: null,
    id: "Walking walking. Walking walking.\nHop hop hop. Hop hop hop.\nRunning running running. Running running running.\nNow let's stop. Now let's stop.\n\nWalking walking. Walking walking.\nHop hop hop. Hop hop hop.\nRunning running running. Running running running.\nNow let's stop. Now let's stop.\n\nTiptoe tiptoe. Tiptoe tiptoe.\nJump jump jump. Jump jump jump.\nSwimming swimming swimming.\nNow let's sleep. Now let's sleep.\n\nWake up!\nIt's time to go!\nAre you ready to go fast? \nOkay!\n\nWalking walking. Walking walking.\nHop hop hop. Hop hop hop.\nRunning running running. Running running running.\nNow let's stop. Now let's stop.\n\nWalking walking. Walking walking.\nHop hop hop. Hop hop hop.\nRunning running running. Running running running.\nNow let's stop. Now let's stop.",
    story_id: 1713,
    word_count: 21,
    all_intersection_count: 15,
    new_words_count: 6,
  },
  {
    row_new_words: ["talk", "I", "catch", "train", "chat", "call"],
    words: [
      "we",
      "can't",
      "talk",
      "now",
      "I",
      "have",
      "to",
      "go",
      "work",
      "stop",
      "catch",
      "a",
      "train",
      "chat",
      "make",
      "call",
      "plane",
    ],
    link: "https://www.youtube.com/watch?v=9ozhdznI3xU&list=PL31D7CB49ABAAB4DB&index=21",
    pdf_name: null,
    type: "video",
    youtube_id: "9ozhdznI3xU",
    clip_start: "300",
    duration: "40",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/can.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=grC7BkY%2Fq2JfpUCfLSd7yZddugA2aST93kMXeTXg9nmWbehku8Q2bzwH2mtlVPBs1%2FeDZ9uA%2BOdt6HTIZBygLDpWViG2YQ7qfEYd1SQD2x9AYx6wdx7kp6EpZAK3csUeXAE%2FGv98E0SAAERW5FyAf6O%2BtqVd7DI8hr3DnQ4BAE%2B6yPbx%2BJdsQqkTgBDmNhKhCVJk71oBY1jQEc0g9dTzm0G7ZhtP%2Fw0SpEIXVwNA4Hv4hsC6auuUW3RgikLKOs7owdJ58X7AnofxEqIN7AotRZeNIBhUN3%2Bw%2FZS0ZaVW0YVzdvwiHbHRTfh%2BSF3d0gX2z7uG9r2Z4NZckQ6ZlUNJgA%3D%3D",
    id: "We can't talk now.\nWe can't talk now.\nWe can't talk now.\nWe can't talk now.\nI can't talk now.\nI have to go to work.\nI can't stop now.\nI have to catch a train.\nI can't chat now.\nI have to make a call.\nI can't stop now.\nI have to catch a plane.\nWe can't talk now.\nWe have to go to work.\nWe can't stop now.\nWe have to catch a train.\nWe can't chat now.\nWe have to make a call.\nWe can't stop now.\nWe have to catch a plane.\nWe can't talk now.\nWe can't talk now.\nWe can't talk now.\nWe can't talk now.",
    story_id: 1002,
    word_count: 17,
    all_intersection_count: 11,
    new_words_count: 6,
  },
  {
    row_new_words: ["so", "I", "off", "it's", "beautiful", "English"],
    words: [
      "so",
      "I",
      "have",
      "a",
      "day",
      "off",
      "today",
      "it's",
      "beautiful",
      "winter",
      "and",
      "in",
      "English",
      "when",
      "you",
      "say",
      "it",
      "means",
      "don't",
      "to",
      "go",
      "work",
      "that",
    ],
    link: "(1)https://www.youtube.com/watch?v=yW-YeEc_ULg&list=PLQut38RVINETJDG9hzlMPp62gDRCPKmrH&index=21",
    pdf_name: null,
    type: "video",
    youtube_id: null,
    clip_start: null,
    duration: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/1dayoffbob.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=byuTqmMHNCYMZ2TfDUkmuzR3EMi5ELQ%2FyNsMGf%2BF4l1spnAPwIGJ%2BLbThzMzEKOu2d%2F5EiMOMGC7Bmhx%2FPCRXEGtQFJitkQQu1QpW5y8IFxYX8de5VXUWjNLumW%2BItKdCZejS8Ei0P3uRK%2F6asyQ%2Fkt%2FheXXiKOS%2B44wi%2FEGFLBoflWD2%2FqZxsbw4hLoCrvRcKG7KwyEhWlbgwjkW3%2BUmNWigF6wESohmxlpSRYNcoP%2BCykHryrBztKm5VEsTnTFcYlnvI4whgkZxwX%2BJq3E%2FB37OQsWTiLLKNQUgqCWqgPJlKRwhtqbUJuRO4kRGXrKy0rbK8J6k%2FBX6Bgfw6A1WA%3D%3D",
    id: "So I have a day off today. It's a beautiful winter day, and I have a day off. In English, when you say you have a day off, it means you don't have to go to work that day.",
    story_id: 1391,
    word_count: 23,
    all_intersection_count: 17,
    new_words_count: 6,
  },
  {
    row_new_words: ["angry", "scared", "oh", "no", "sleepy", "take", "nap"],
    words: [
      "if",
      "you're",
      "happy",
      "clap",
      "your",
      "hands",
      "angry",
      "stomp",
      "feet",
      "scared",
      "say",
      "oh",
      "no",
      "sleepy",
      "take",
      "a",
      "nap",
    ],
    link: "wqvQAcloTRQ",
    pdf_name: null,
    type: "song",
    youtube_id: "wqvQAcloTRQ",
    clip_start: "7",
    duration: "113",
    storage_link: null,
    id: "If you're happy happy happy, clap your hands.\nIf you're happy happy happy, clap your hands.\nIf you're happy happy happy, clap your hands, clap your hands.\nIf you're happy happy happy, clap your hands.\n\n\nIf you're angry angry angry, stomp your feet. \nIf you're angry angry angry, stomp your feet.\nIf you're angry angry angry, stomp your feet, stomp your feet.\nIf you're angry angry angry, stomp your feet.\n\n\nIf you're scared scared scared, say, \"Oh no!\"\nIf you're scared scared scared, say, \"Oh no!\"\nIf you're scared scared scared, say, \"Oh no!\" Say, \"Oh no!\"\nIf you're scared scared scared, say, \"Oh no!\"\n\n\nIf you're sleepy sleepy sleepy, take a nap. \nIf you're sleepy sleepy sleepy, take a nap.\nIf you're sleepy sleepy sleepy, take a nap, take a nap.\nIf you're sleepy sleepy sleepy, take a nap.\n\nIf you're happy happy happy, clap your hands. \nIf you're happy happy happy, clap your hands.\nIf you're happy happy happy, clap your hands, clap your hands.\nIf you're happy happy happy, clap your hands.",
    story_id: 1832,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["I", "car", "yes", "often", "washed", "yesterday", "gonna"],
    words: [
      "I",
      "see",
      "you're",
      "washing",
      "your",
      "car",
      "yes",
      "am",
      "do",
      "you",
      "wash",
      "it",
      "very",
      "often",
      "washed",
      "yesterday",
      "I'm",
      "now",
      "and",
      "gonna",
      "tomorrow",
      "really",
      "like",
      "to",
      "sure",
      "every",
      "day",
    ],
    link: "https://www.youtube.com/watch?v=w24IRWsCL48&list=PLD7AA7B1BC72ACC28",
    pdf_name: null,
    type: "video",
    youtube_id: "w24IRWsCL48",
    clip_start: "109",
    duration: "13",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/washing-1.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=jXQWqVbylqSFLbOBYZrQ0rXJyWtYW9K%2BD5hAGr%2FHJ9%2BgseT2aejDb7s6FLx1RG4n590mrXRZw7eYPF1U2VW%2BNgDOzMGUjjEkqkX%2BA%2FunxoNJLgAwSC7k%2BlAR1wymOuQQ4XfN22Dr6YrmxUqwsoFAF0rhscXF6XGFZkf2x5hdZAMMBLZr31Bf9ivqNGAZZ6g4QiYfPd%2Fs2K2atW83%2B7sjdj5H9ud47%2ByjheHW06casNKirM5WBDYDh3PvMfH0SsviFbaMmO6Q6rY4CwXxuH8id5oHMWSnQWc23iFGzEGigXIadb6FFlGb1hEnmiqdc%2Fpj6pJbRexvAPU9tr1UC3UMaQ%3D%3D",
    id: "I see you're washing your car.\nYes. I am.\nDo you wash it very often?\nYes. I washed it yesterday. I'm washing it now, and I'm gonna wash it tomorrow.\nYou really like to wash your car.\nI sure do. I wash it every day.",
    story_id: 1011,
    word_count: 27,
    all_intersection_count: 20,
    new_words_count: 7,
  },
  {
    row_new_words: ["angry", "scared", "oh", "no", "sleepy", "take", "nap"],
    words: [
      "if",
      "you're",
      "happy",
      "clap",
      "your",
      "hands",
      "angry",
      "stomp",
      "feet",
      "scared",
      "say",
      "oh",
      "no",
      "sleepy",
      "take",
      "a",
      "nap",
    ],
    link: "l4WNrvVjiTw",
    pdf_name: null,
    type: "song",
    youtube_id: "l4WNrvVjiTw",
    clip_start: "2",
    duration: "107",
    storage_link: null,
    id: "If you're happy happy happy clap your hands. \nIf you're happy happy happy clap your hands.\nIf you're happy happy happy clap your hands, clap your hands.\nIf you're happy happy happy clap your hands.\n\nIf you're angry angry angry stomp your feet. \nIf you're angry angry angry stomp your feet.\nIf you're angry angry angry stomp your feet, stomp your feet.\nIf you're angry angry angry stomp your feet. \n\nIf you're scared scared scared say, \"Oh no!\" \nIf you're scared scared scared say, \"Oh no!\"\nIf you're scared scared scared say, \"Oh no!\" Say, \"Oh no!\"\nIf you're scared scared scared say, \"Oh no!\"\n\nIf you're sleepy sleepy sleepy take a nap. \nIf you're sleepy sleepy sleepy take a nap.\nIf you're sleepy sleepy sleepy take a nap, take a nap.\nIf you're sleepy sleepy sleepy take a nap.\n\nIf you're happy happy happy clap your hands. \nIf you're happy happy happy clap your hands.\nIf you're happy happy happy clap your hands, clap your hands.\nIf you're happy happy happy clap your hands. ",
    story_id: 1711,
    word_count: 17,
    all_intersection_count: 10,
    new_words_count: 7,
  },
  {
    row_new_words: ["tap", "point", "head", "nose", "chin", "leg", "wave", "goodbye"],
    words: [
      "one",
      "little",
      "finger",
      "tap",
      "point",
      "your",
      "up",
      "down",
      "put",
      "it",
      "on",
      "head",
      "nose",
      "chin",
      "arm",
      "leg",
      "foot",
      "now",
      "let's",
      "wave",
      "goodbye",
    ],
    link: "EqVHR2vIwIE",
    pdf_name: null,
    type: "song",
    youtube_id: "EqVHR2vIwIE",
    clip_start: "7",
    duration: "133",
    storage_link: null,
    id: "One little finger, one little finger, one little finger.\nTap tap tap. \nPoint your finger up. \nPoint your finger down. \nPut it on your head. Head! \n\nOne little finger, one little finger, one little finger.\nTap tap tap.  \nPoint your finger up. \nPoint your finger down. \nPut it on your nose. Nose! \n\nOne little finger, one little finger, one little finger.\nTap tap tap. \nPoint your finger up. \nPoint your finger down. \nPut it on your chin. Chin! \n\nOne little finger, one little finger, one little finger.\nTap tap tap.\nPoint your finger up. \nPoint your finger down. \nPut it on your arm. Arm! \n\nOne little finger, one little finger, one little finger.\nTap tap tap. \nPoint your finger up. \nPoint your finger down. \nPut it on your leg. Leg! \n\nOne little finger, one little finger, one little finger.\nTap tap tap.\nPoint your finger up. \nPoint your finger down. \nPut it on your foot. Foot! \nPut it on your leg. Leg! \nPut it on your arm. Arm! \nPut it on your chin. Chin! \nPut it on your nose. Nose! \nPut it on your head. Head! \nNow let's wave goodbye. Goodbye! ",
    story_id: 1841,
    word_count: 21,
    all_intersection_count: 13,
    new_words_count: 8,
  },
  {
    row_new_words: ["pass", "the", "beanbag", "slower", "other", "way", "super", "ground"],
    words: [
      "pass",
      "the",
      "beanbag",
      "round",
      "and",
      "stop",
      "let's",
      "go",
      "faster",
      "slower",
      "it",
      "other",
      "way",
      "super",
      "fast",
      "put",
      "on",
      "ground",
    ],
    link: "_7NbNZ28srg",
    pdf_name: null,
    type: "song",
    youtube_id: "_7NbNZ28srg",
    clip_start: "7",
    duration: "173",
    storage_link: null,
    id: "Pass the beanbag round and round.\nPass the beanbag... stop!\n\nLet's go faster!\n\nPass the beanbag round and round.\nPass the beanbag... stop!\n\nLet's go slower!\n\nPass the beanbag round and round.\nPass the beanbag... stop!\n\nLet's pass it the other way!\n\nPass the beanbag round and round.\nPass the beanbag... stop!\n\nFaster?\nPass the beanbag round and round.\nPass the beanbag... stop!\n\nSuper fast!\nPass the beanbag round and round.\nPass the beanbag... stop!\n\nPut the beanbag on the ground!",
    story_id: 1840,
    word_count: 18,
    all_intersection_count: 10,
    new_words_count: 8,
  },
  {
    row_new_words: ["where's", "Betty", "the", "what's", "reading", "book", "Betty's", "who's"],
    words: [
      "where's",
      "Betty",
      "she's",
      "in",
      "the",
      "bedroom",
      "what's",
      "she",
      "doing",
      "reading",
      "a",
      "book",
      "Betty's",
      "who's",
    ],
    link: "(2)https://www.youtube.com/watch?v=Ay2D8chFBKw&t=50s",
    pdf_name: null,
    type: "video",
    youtube_id: "Ay2D8chFBKw",
    clip_start: "115",
    duration: "21",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/Present%20Continuous.%20Question%20form%202.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=LBiYqJtVMmoKla6NwsDsvZ2PVeXa1%2BbbQB0TcUcbStgVSt3WGaEQ1ym%2BeITL%2BFa8SMlujvP%2FW%2Bk9abNPFyz2qVIzkldDH7%2FxhERoYeCXtQb4vlf07ZaCKvrPMmRCKbvQnY6uY9pmHiu9LAvYMhQQ5SmDrGPRIt7TMWRxZj2AssXqZhmBnqVxImb7y0t3%2FRsGCsFDG7bAkNeiiiwD1D4bqwsyLx0b%2B25w9kb0NLQpmTKRj9h5NIOEePGT29WEkI1fvqOxKqbDxAw7cq8D75kwhhC2JoLHcYqPD5jkeK2oChv0vRaJTc9gYY%2Bo5VcOkeFddFqSovgR9s5GHWKAsr0jJg%3D%3D",
    id: "Where's Betty? She's in the bedroom.\nWhat's she doing? Reading a book.\nBetty's in the bedroom reading a book.\nBetty's in the bedroom reading a book.\nWho's in the bedroom? Betty's in the bedroom.\nWhat's she doing? Reading a book.",
    story_id: 955,
    word_count: 14,
    all_intersection_count: 6,
    new_words_count: 8,
  },
  {
    row_new_words: ["the", "violin", "yes", "I", "beautiful", "no", "sorry", "just", "alright"],
    words: [
      "can",
      "you",
      "play",
      "the",
      "violin",
      "yes",
      "I",
      "that's",
      "beautiful",
      "thank",
      "no",
      "can't",
      "I'm",
      "sorry",
      "just",
      "alright",
    ],
    link: "(2)https://www.youtube.com/watch?v=RPDHqfX4PZQ&list=PL31D7CB49ABAAB4DB&index=20",
    pdf_name: null,
    type: "video",
    youtube_id: "RPDHqfX4PZQ",
    clip_start: "49.4",
    duration: "27.6",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/play.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=Q1S1YVyKs3r97tCRkv2Kk%2FAkz9aAa%2BcaONNSiIlHUSAusM87MYbtklZ2OTBJ8Ho6c8oDnGdFbSXC0U336PxCodzg2jN3CSTO4Kmey0OuK2sY7WUwJtm7ROy4QpQBNH7eg9XZ%2FfBTrow9LI9bt1GAuzgstiOBuNA8u2fpr2blkK%2BoV%2B%2FOZRfJx2oGSl90eNIM0hKAUJX0fMyMOfZXcVHlb7beIn79qlThax%2BRGtHK9igyR3QbIv5BIdUY0ukRPqjmR0iXg0i1D62HmasUmZ41dKxOJ9MEN0q4YPtwzx77UQzUNVqOGaikhfcby73uv7z3MqURLrUwKY2iKzowWg0%2FIA%3D%3D",
    id: "Can you play the violin?\nYes, I can.\nThat's beautiful.\nThank you.\n\nCan you play the violin?\nNo. I can't.\nI'm sorry. I just can't play the violin.\nThat's alright.",
    story_id: 999,
    word_count: 16,
    all_intersection_count: 7,
    new_words_count: 9,
  },
  {
    row_new_words: [
      "what's",
      "Fran",
      "working",
      "late",
      "why",
      "it's",
      "Monday",
      "always",
      "works",
    ],
    words: [
      "what's",
      "Fran",
      "doing",
      "she's",
      "working",
      "late",
      "why",
      "is",
      "she",
      "that",
      "it's",
      "Monday",
      "always",
      "works",
      "on",
    ],
    link: "(2)https://www.youtube.com/watch?v=SzL6Ww7xUWc&list=PL31D7CB49ABAAB4DB&index=19",
    pdf_name: null,
    type: "video",
    youtube_id: "SzL6Ww7xUWc",
    clip_start: "91",
    duration: "20",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/working.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=UJnlRQ0G%2F%2FiZhfpLweFBgCG%2F%2F%2BvlpwyOp5OYWOaRBZEqqNiO51GpNSlTF3v4edlJix6yExSxeg1dMXyczmXJXo6dzxK157a%2BFCKduYMrhT9hr7x1bJzkU6pH04WLt4ySsm3knvnqk27ZrHshY6nglYCAzI%2FDxPhsOcnmTlNu0NheAcPuIlQQcLc2pnsmcT%2B4kRyW3At%2BHopojksqOr3yUW9UjdKJpH9PaPA0bNHUeRq%2BjStmBN9pf%2F5ML4T8GL71DlKftbX4X83e3A1MXSY%2BEqjGO%2Fc1n%2BcbwE4ptxZCWZEMAFo7dqPpwggmlrATuh7jt%2BsToMr9L0o3CW34t5o%2BfA%3D%3D",
    id: "What's Fran doing?\nShe's working late.\nWorking late? Why is she doing that?\nIt's Monday. She always works late on Monday.",
    story_id: 996,
    word_count: 15,
    all_intersection_count: 6,
    new_words_count: 9,
  },
  {
    row_new_words: [
      "repeat",
      "after",
      "wrists",
      "together",
      "the",
      "jellyfish",
      "elbows",
      "head",
      "back",
    ],
    words: [
      "repeat",
      "after",
      "me",
      "arms",
      "up",
      "wrists",
      "together",
      "the",
      "jellyfish",
      "elbows",
      "knees",
      "feet",
      "head",
      "back",
    ],
    link: "rqhYoUhjook",
    pdf_name: null,
    type: "song",
    youtube_id: "rqhYoUhjook",
    clip_start: null,
    duration: null,
    storage_link: null,
    id: "Repeat after me.\nArms up.\nWrists together.\nThe jellyfish, the jellyfish, the jellyfish, the jellyfish.\n\nArms up.\nWrists together.\nElbows together.\nThe jellyfish, the jellyfish, the jellyfish, the jellyfish.\n\nArms up.\nWrists together.\nElbows together.\nKnees together.\nThe jellyfish, the jellyfish, the jellyfish, the jellyfish.\n\nArms up.\nWrists together.\nElbows together.\nKnees together.\nFeet together.\nThe jellyfish, the jellyfish, the jellyfish, the jellyfish.\n\nArms up.\nWrists together.\nElbows together.\nKnees together.\nFeet together.\nHead back.\nThe jellyfish, the jellyfish, the jellyfish, the jellyfish.",
    story_id: 1856,
    word_count: 14,
    all_intersection_count: 5,
    new_words_count: 9,
  },
  {
    row_new_words: ["the", "way", "face", "early", "comb", "hair", "brush", "teeth", "dressed"],
    words: [
      "this",
      "is",
      "the",
      "way",
      "we",
      "wash",
      "our",
      "face",
      "early",
      "in",
      "morning",
      "comb",
      "hair",
      "brush",
      "teeth",
      "get",
      "dressed",
      "go",
      "to",
      "school",
    ],
    link: "pEx5entLOFQ",
    pdf_name: null,
    type: "song",
    youtube_id: "pEx5entLOFQ",
    clip_start: "7",
    duration: "117",
    storage_link: null,
    id: "This is the way we wash our face,\nwash our face, wash our face.\nThis is the way we wash our face.\nEarly in the morning.\n\nWash wash wash wash.\nWash wash wash.\n\nThis is the way we comb our hair,\ncomb our hair, comb our hair.\nThis is the way we comb our hair.\nEarly in the morning.\n\nComb comb comb comb.\nComb comb comb.\n\nThis is the way we brush our teeth,\nbrush our teeth, brush our teeth.\nThis is the way we brush our teeth.\nEarly in the morning.\n\nBrush brush brush brush.\nBrush brush brush.\n\nThis is the way we get dressed,\nget dressed, get dressed.\nThis is the way we get dressed.\nEarly in the morning.\n\nThis is the way we go to school,\ngo to school, go to school.\nThis is the way we go to school.\nEarly in the morning.",
    story_id: 1837,
    word_count: 20,
    all_intersection_count: 11,
    new_words_count: 9,
  },
  {
    row_new_words: ["jungles", "of", "Brazil", "the", "hello", "snake", "parrot", "monkey", "lion"],
    words: [
      "jungles",
      "of",
      "Brazil",
      "in",
      "the",
      "that's",
      "where",
      "we",
      "go",
      "to",
      "see",
      "animals",
      "say",
      "hello",
      "snake",
      "parrot",
      "monkey",
      "lion",
    ],
    link: "https://learnenglishkids.britishcouncil.org/listen-watch/songs/jungles-brazil",
    pdf_name: null,
    type: "song",
    youtube_id: null,
    clip_start: null,
    duration: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/jungles.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=ZqLTkqDPTuoTyQJ%2Fu%2Fcd9jcdNgO7Jccdv3qDnGLTFHiWWcouQoCQdrZ%2Bkj57ONQ8W2pYt0U2HRGlY%2FSh3sr%2FPvy2hzIM5XOsVyqvYphzsVsqB262XFgcFgT4zMxE%2FIvxPwWQ%2FyTiLj1JOKwH2IWg4dlNrb2c1lGhAV%2FhF76Xb%2Fg3e8MXmyloLPtJ%2F3NshW9kpBnVrDBrKcUk19EDlET6NBXLdrIj5rUmoCYu2YV13HkLMjgPIcXyFffTsUsfs1p9h%2BBhORUOhsBZqFU8CryuWnyVgBq4g56l0%2F5YaKrVFR9aLq%2F5B4LVkhlNB38aeQ31LZolPHoyKgPcx8qinsGYOg%3D%3D",
    id: "In the jungles of Brazil\nThat’s where we go to see the animals\nWe say hello to the snake\nIn the jungles of Brazil.\n\nIn the jungles of Brazil\nThat’s where we go to see the animals\nWe say hello to the parrot\nWe say hello to the snake\nIn the jungles of Brazil.\n\nIn the jungles of Brazil\nThat’s where we go to see the animals\nWe say hello to the monkey\nWe say hello to the parrot\nWe say hello to the snake\nIn the jungles of Brazil.\n\nIn the jungles of Brazil\nThat’s where we go to see the animals\nWe say hello to the lion\nWe say hello to the monkey\nWe say hello to the parrot\nWe say hello to the snake\nIn the jungles of Brazil.\n\nIn the jungles of Brazil.",
    story_id: 874,
    word_count: 18,
    all_intersection_count: 9,
    new_words_count: 9,
  },
  {
    row_new_words: ["hungry", "yes", "I", "mmm", "yum", "an", "grapes", "watermelon", "full"],
    words: [
      "are",
      "you",
      "hungry",
      "yes",
      "I",
      "am",
      "mmm",
      "a",
      "banana",
      "yum",
      "an",
      "apple",
      "grapes",
      "watermelon",
      "I'm",
      "full",
    ],
    link: "azds40V5vZ0",
    pdf_name: null,
    type: "song",
    youtube_id: "azds40V5vZ0",
    clip_start: "7",
    duration: "111",
    storage_link: null,
    id: "Are you hungry?\nYes, I am.\nAre you hungry?\nYes, I am.\nMmm, a banana! \nYum, yum, yum, yum, yum, yum, yum, yum, yum!\n\nAre you hungry?\nYes, I am.\nAre you hungry?\nYes, I am.\nMmm, an apple!\nYum, yum, yum, yum, yum, yum, yum, yum, yum!\n\nAre you hungry?\nYes, I am.\nAre you hungry?\nYes, I am.\nMmm, grapes!\nYum, yum, yum, yum, yum, yum, yum, yum, yum!\n\nAre you hungry? \nYes, I am.\nAre you hungry?\nYes, I am.\nMmm, watermelon! \nYum, yum, yum, yum, yum, yum, yum, yum, yum! \nI'm full!",
    story_id: 1857,
    word_count: 16,
    all_intersection_count: 7,
    new_words_count: 9,
  },
  {
    row_new_words: [
      "were",
      "the",
      "roll",
      "so",
      "rolled",
      "fell",
      "out",
      "four",
      "three",
      "lonely",
    ],
    words: [
      "there",
      "were",
      "six",
      "in",
      "the",
      "bed",
      "and",
      "little",
      "one",
      "said",
      "roll",
      "over",
      "so",
      "they",
      "all",
      "rolled",
      "fell",
      "out",
      "five",
      "four",
      "three",
      "two",
      "was",
      "I'm",
      "lonely",
    ],
    link: "N9mN6ROBJMw",
    pdf_name: null,
    type: "song",
    youtube_id: "N9mN6ROBJMw",
    clip_start: "7",
    duration: "94",
    storage_link: null,
    id: 'There were six in the bed\nand the little one said,\n"Roll over, roll over."\nSo they all rolled over and one fell out.\n\nFive! \nThere were five in the bed\nand the little one said,\n"Roll over, roll over."\nSo they all rolled over and one fell out.\n\nFour! \nThere were four in the bed .\nAnd the little one said,\n"Roll over, roll over."\nSo they all rolled over and one fell out.\n\nThree! \nThere were three in the bed \nand the little one said,\n"Roll over, roll over."\nSo they all rolled over and one fell out.\n\nTwo! \nThere were two in the bed\nand the little one said,\n"Roll over, roll over."\nSo they all rolled over and one fell out.\n\nOne! \nThere was one in the bed\nand the little one said,\n"I\'m lonely".',
    story_id: 1849,
    word_count: 25,
    all_intersection_count: 15,
    new_words_count: 10,
  },
  {
    row_new_words: [
      "star",
      "light",
      "bright",
      "first",
      "I",
      "tonight",
      "wish",
      "may",
      "might",
      "the",
    ],
    words: [
      "star",
      "light",
      "bright",
      "first",
      "I",
      "see",
      "tonight",
      "wish",
      "may",
      "might",
      "have",
      "the",
    ],
    link: "OWip7yvXukI",
    pdf_name: null,
    type: "song",
    youtube_id: "OWip7yvXukI",
    clip_start: "5",
    duration: "202",
    storage_link: null,
    id: "Star light, star bright\nFirst star I see tonight\nWish I may, wish I might\nHave the wish I wish tonight\n\nStar light, star bright\nFirst star I see tonight\nWish I may, wish I might\nHave the wish I wish tonight\n\nStar light, star bright\nFirst star I see tonight\nWish I may, wish I might\nHave the wish I wish tonight\n\nStar light, star bright\nFirst star I see tonight\nWish I may, wish I might\nHave the wish I wish tonight",
    story_id: 1597,
    word_count: 12,
    all_intersection_count: 2,
    new_words_count: 10,
  },
  {
    row_new_words: [
      "wear",
      "prison",
      "the",
      "orange",
      "jumpsuit",
      "careful",
      "I",
      "will",
      "just",
      "saying",
    ],
    words: [
      "you",
      "look",
      "like",
      "know",
      "what",
      "they",
      "wear",
      "in",
      "prison",
      "with",
      "the",
      "orange",
      "jumpsuit",
      "be",
      "careful",
      "please",
      "I",
      "will",
      "but",
      "I'm",
      "just",
      "saying",
    ],
    link: "https://www.youtube.com/watch?v=QrFlNEEpXsM",
    pdf_name: null,
    type: "video",
    youtube_id: null,
    clip_start: null,
    duration: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/lion.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=CPTRe1wk%2BaA3Q3A9mui%2FEnLLsm3uJfV6E%2FgDXmKVqVY2fM5Q0djAJWGqEm9vMgHcUsPJxpU4otdJdECI6Eto61taX2sjg%2BsyIX1eNv0%2FkR%2FFELGH1Zfu3%2F71SDUPGaYDxck1TPQLNJPwiMLKtvFvnHwuVU0yDaIf5MCx3%2BPwxhB1rX7XA8h072%2B%2BbYN%2BP46aZWpMPHu4i5r%2Bo1CN7vvW%2BKprCPQA%2B3MdMvSOlQrTUFr1IyjmDloIA7HEl%2FFxGSql5TihJzRNhXqAnY0L495DvP1Pvh3WehVg5e%2BN%2FUUgmyI0azIvS5F34%2B8C9eCDYJAOLVRzcBOu34pL7812AKkRww%3D%3D",
    id: "You look like, you know what they wear in prison.\nWith the orange jumpsuit.\nBe careful please.\nI will be careful, but I'm just saying you look like what they wear.",
    story_id: 1034,
    word_count: 22,
    all_intersection_count: 12,
    new_words_count: 10,
  },
  {
    row_new_words: [
      "twinkle",
      "star",
      "I",
      "wonder",
      "above",
      "the",
      "world",
      "so",
      "diamond",
      "sky",
    ],
    words: [
      "twinkle",
      "little",
      "star",
      "how",
      "I",
      "wonder",
      "what",
      "you",
      "are",
      "up",
      "above",
      "the",
      "world",
      "so",
      "high",
      "like",
      "a",
      "diamond",
      "in",
      "sky",
    ],
    link: "yCjJyiqpAuU",
    pdf_name: null,
    type: "song",
    youtube_id: "yCjJyiqpAuU",
    clip_start: "6",
    duration: "140",
    storage_link: null,
    id: "Twinkle, twinkle, little star.\nHow I wonder what you are.\nUp above the world so high,\nLike a diamond in the sky.\nTwinkle, twinkle, little star.\nHow I wonder what you are.\n\nTwinkle, twinkle, little star.\nHow I wonder what you are.\nUp above the world so high,\nLike a diamond in the sky.\nTwinkle, twinkle, little star.\nHow I wonder what you are.",
    story_id: 1595,
    word_count: 20,
    all_intersection_count: 10,
    new_words_count: 10,
  },
  {
    row_new_words: [
      "I",
      "always",
      "usually",
      "sometimes",
      "early",
      "never",
      "late",
      "no",
      "gets",
      "rarely",
    ],
    words: [
      "I",
      "always",
      "get",
      "to",
      "work",
      "on",
      "time",
      "I'm",
      "usually",
      "here",
      "eight",
      "sometimes",
      "early",
      "never",
      "late",
      "no",
      "he",
      "gets",
      "he's",
      "rarely",
      "right",
    ],
    link: "https://www.youtube.com/watch?v=16kzXiwYeEo&list=PL31D7CB49ABAAB4DB&index=16",
    pdf_name: null,
    type: "video",
    youtube_id: "16kzXiwYeEo",
    clip_start: "176",
    duration: "34",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/never.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=ZGidhtXumoswmTIxIXSAQEZUwlytx5UG39SYp1H5una%2F%2B2IHkq9i702hUWhswhhr9vcaE6lwsKsAB7M6ub2vM9LVybVIyuwnXzGF%2B9bGr3QsNUNiYygb7RUIRNd3wedNkB37HoWQTmtOBs3jqvNJOU7tbsyLzW%2BqLGSFocl9%2F8xIGFFUTRE21ZxipSz1SUUXNLybzQWoEVBPQcdbVKuWG5AcmiKB1jIDt2Jj3jHRXlryanMomaxRvOi1%2B7pCkyDZWNM1zWisLCgPtt2ctcX%2BX%2BoQFzr%2B%2BI%2FZfIv6qPQe7xGyphp3TFTzmtI6SbG6Q7Q6UiaeNLc3vl1%2BkBfC82%2BcQg%3D%3D",
    id: "I always get to work on time.\nI'm usually here by eight.\nI sometimes get here early.\nI never get here late.\nNo. I never get here late.\n\nHe always gets to work on time.\nHe's usually here by eight.\nHe sometimes gets here early.\nHe rarely gets here late.\n\nNo. I never get here late.\nRight. He never gets here late.",
    story_id: 987,
    word_count: 21,
    all_intersection_count: 11,
    new_words_count: 10,
  },
  {
    row_new_words: [
      "hello",
      "what's",
      "name",
      "my",
      "Elizabeth",
      "name's",
      "teacher",
      "Tom",
      "Canada",
      "the",
      "United States",
    ],
    words: [
      "hello",
      "hi",
      "what's",
      "your",
      "name",
      "my",
      "is",
      "Elizabeth",
      "name's",
      "teacher",
      "Tom",
      "where",
      "are",
      "you",
      "from",
      "I'm",
      "Canada",
      "the",
      "United States",
    ],
    link: "(2)https://www.youtube.com/watch?v=ocqlNUwolUA",
    pdf_name: null,
    type: "video",
    youtube_id: "ocqlNUwolUA",
    clip_start: "162",
    duration: "18",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/name2.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=fdR%2BwQZn8yyseS7FvrHz71%2BFGodbaK06UGPP9NbMKhxkNlTAba03zNgpM5D1MXJGtfLjqDy8KaFEVwQWBv4ArHeeNs6eZMBCi6kwiku3Kfyop0ZE5Jzk6cln9%2BqoaGvIVwoAMhB2v4N5uOvdpsdGqQIvE2xw6zCep0Zl6Tti9PFhEZ21J10PF1llAhvLQKgeyjKwM2Ikfnkb296%2FHvPE%2BFTT4rwEE4TStlbljHvYfFiAXO38JefOLkLNx3qeFWbuokt%2Bi2wIzGtxMMrxt6sVPyVRiLbThw1vZPLlryCBLOWeRB5%2F15%2FkRG7zchD1ievov%2Fc9dBrNoUhwHyrHeKCS8w%3D%3D",
    id: "Hello.\nHi.\nWhat's your name?\nMy name is Elizabeth. What's your name?\nMy name's teacher Tom. Where are you from?\nI'm from Canada. Where are you from?\nI'm from the United__States.",
    story_id: 1036,
    word_count: 19,
    all_intersection_count: 8,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "hello",
      "name",
      "my",
      "Elizabeth",
      "teacher",
      "Tom",
      "I",
      "Canada",
      "the",
      "United States",
      "meet",
    ],
    words: [
      "hello",
      "hi",
      "what",
      "is",
      "your",
      "name",
      "my",
      "Elizabeth",
      "teacher",
      "Tom",
      "where",
      "are",
      "you",
      "from",
      "I",
      "am",
      "Canada",
      "the",
      "United States",
      "it",
      "nice",
      "to",
      "meet",
      "too",
    ],
    link: "https://www.youtube.com/watch?v=ocqlNUwolUA",
    pdf_name: null,
    type: "video",
    youtube_id: "ocqlNUwolUA",
    clip_start: "16",
    duration: "17",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/name.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=T%2F%2FqY8olb6U9eHc0V4zF4%2FsXgyEAcOvIUcC260vt2DiQt%2BTMdE11ZnNw7bJYsd3Bklrs0qcHPvyWqtfIfr%2BZ65p20ch5O2DayOdVwNhH8IsKRQf2zfOo37SzWsAfG6lQiaWsFaBRwofeK5HQW2HlYZhbdy5GhMm0op2H6JdtnMSynuvl68jHA4QpgK72KXvPq%2B3ZburA3TV300Qi3%2BE9KewqgcBVFlN2aaEH3r6x3NfbR1Xblua2gvGzWhSR3RlrBvZU4jxeGuPVJBpnodfQR5DY0KVfCQFgFbrkyMui6wQlEuO6yi0EEG28r2mpHwIIyZVlIa5vsrf8%2FTt%2FcPidWA%3D%3D",
    id: "Hello.\nHi.\nWhat is your name?\nMy name is Elizabeth. What is your name?\nMy name is teacher Tom. Where are you from?\nI am from Canada. Where are you from?\nI am from the United__States.\nIt is nice to meet you.\nIt is nice to meet you too.",
    story_id: 1035,
    word_count: 24,
    all_intersection_count: 13,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "I",
      "think",
      "you'll",
      "my",
      "new",
      "bicycle",
      "liked",
      "old",
      "apartment",
      "large",
      "larger",
    ],
    words: [
      "I",
      "think",
      "you'll",
      "like",
      "my",
      "new",
      "bicycle",
      "liked",
      "your",
      "old",
      "it",
      "was",
      "fast",
      "that's",
      "right",
      "but",
      "is",
      "faster",
      "apartment",
      "large",
      "larger",
    ],
    link: "(3)https://www.youtube.com/watch?v=GSx9o9uZosQ&list=PLD7AA7B1BC72ACC28&index=9",
    pdf_name: null,
    type: "story",
    youtube_id: "GSx9o9uZosQ",
    clip_start: "10",
    duration: "23",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/was1.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=Geq0KZXDpgBOupSib7TNDnHcJPYaCP9wHPBRhLopcTTy%2FFUs5qyi3JnJ83XLTk1O3fne2VpAD4jppCFZKe1v5ZeF%2F1uUK581hZb0H5L4Zvhb5vb6wf1aWxvOxCtLsH39%2F%2FmQ5lHTRgtkNAhKodPn8UPFRSLzTNXpGBX0CL7kYEHGI52eCLM9%2BKdtPHuYGX50i%2FNGZk4ulJegkjjgAZ6ub7ANDkgxYprdbEwD4PQz%2F7IbPPN34iLYvy3NYq6bNsTTiSTTlz4YSIbAL47FZIgrueDgytteM9wLtdV4mZ2VQuLv3XneQqap4kU%2FBF9SEYt7IOU7WNCNlQAKPM2YkFAbeg%3D%3D",
    id: "I think you'll like my new bicycle.\nI liked your old bicycle. It was fast.\nThat's right. But my new bicycle is faster.\n\nI think you'll like my new apartment.\nBut I liked your old apartment. It was large.\nThat's right. But my new apartment is larger.",
    story_id: 1047,
    word_count: 21,
    all_intersection_count: 10,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "evening",
      "I",
      "the",
      "starshine",
      "overhead",
      "daisies",
      "dart",
      "middle",
      "of",
      "daysies",
      "dark",
    ],
    words: [
      "that",
      "evening",
      "when",
      "I",
      "go",
      "to",
      "bed",
      "see",
      "the",
      "starshine",
      "overhead",
      "they",
      "are",
      "little",
      "daisies",
      "white",
      "dart",
      "middle",
      "of",
      "night",
      "at",
      "daysies",
      "dark",
    ],
    link: "MSo_1WRkNf0",
    pdf_name: null,
    type: "song",
    youtube_id: "MSo_1WRkNf0",
    clip_start: null,
    duration: null,
    storage_link: null,
    id: "That evening when I go to bed I see the starshine overhead. They are the little daisies white that dart the middle of the night of the night. At evening when I go to bed I see the starshine overhead. They are the little daysies white that dark the middle of the night of the night.",
    story_id: 1673,
    word_count: 23,
    all_intersection_count: 12,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "hey",
      "diddle",
      "the",
      "fiddle",
      "jumped",
      "moon",
      "laughed",
      "such",
      "sport",
      "dish",
      "spoon",
    ],
    words: [
      "hey",
      "diddle",
      "the",
      "cat",
      "and",
      "fiddle",
      "cow",
      "jumped",
      "over",
      "moon",
      "little",
      "dog",
      "laughed",
      "to",
      "see",
      "such",
      "sport",
      "dish",
      "ran",
      "away",
      "with",
      "spoon",
    ],
    link: "(3)https://www.splashlearn.com/blog/best-kids-poems-to-entice-a-love-for-reading-poetry/",
    pdf_name: null,
    type: "poem",
    youtube_id: null,
    clip_start: null,
    duration: null,
    storage_link: null,
    id: "Hey diddle diddle,\nThe Cat and the fiddle,\nThe Cow jumped over the moon,\nThe little Dog laughed to see such sport,\nAnd the Dish ran away with the Spoon.",
    story_id: 1890,
    word_count: 22,
    all_intersection_count: 11,
    new_words_count: 11,
  },
  {
    row_new_words: [
      "fixing",
      "my",
      "sink",
      "what's",
      "Bob",
      "his",
      "car",
      "Mary",
      "cleaning",
      "apartment",
      "their",
      "homework",
    ],
    words: [
      "hi",
      "what",
      "are",
      "you",
      "doing",
      "I'm",
      "fixing",
      "my",
      "sink",
      "what's",
      "Bob",
      "he's",
      "his",
      "car",
      "Mary",
      "she's",
      "cleaning",
      "her",
      "room",
      "we're",
      "our",
      "apartment",
      "your",
      "children",
      "they're",
      "their",
      "homework",
    ],
    link: "https://www.youtube.com/watch?v=OLieQ0U3TA4&t=181s",
    pdf_name: null,
    type: "video",
    youtube_id: "OLieQ0U3TA4",
    clip_start: "11",
    duration: "37",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/Present%20Continuous.%20Question%20form%204.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=ham8UmHfrF170PgxAC0%2BrVL56xanpD6VhqJueVmvd76C9CEi2xXK0D9HLqOvq14rbLgMtZFgG5O5k4I43lgmcLhRhRaiEGtw4o8Sd3jPRSaejpJRi0YRwfzM1IvO6Vw9sqF6g5iCV6MsvYVaaOvMv0DntjiQlv4qSo03ANhNi9U26DhGcC8YtmiJfIjqjiDj00nbsPywkfixdJBkwjLCHuQT4YpyiAAcOTZ9mCVUeUoJXbNBya8Nf62Y3nwDFEoThxSAGJ7C0TVaeJwwNS6suEevaE4WhQzOg4r8DHBnLryYit2PvURISUTOBTG2KHgEFcjv%2BY66KUPZ9dzIUZjltw%3D%3D",
    id: "Hi.\nWhat are you doing?\nI'm fixing my sink.\nWhat's Bob doing?\nHe's fixing his car.\nWhat's Mary doing?\nShe's cleaning her room.\nWhat are you doing?\nWe're cleaning our apartment.\nWhat are your Children doing?\nThey're doing their homework.",
    story_id: 957,
    word_count: 27,
    all_intersection_count: 15,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "did",
      "I",
      "washed",
      "my",
      "floors",
      "yes",
      "cleaned",
      "painted",
      "porch",
      "fixed",
      "sink",
      "worked",
    ],
    words: [
      "what",
      "did",
      "you",
      "do",
      "today",
      "I",
      "washed",
      "my",
      "floors",
      "your",
      "yes",
      "all",
      "day",
      "cleaned",
      "house",
      "painted",
      "porch",
      "fixed",
      "sink",
      "we",
      "worked",
      "at",
      "home",
    ],
    link: "(2)https://www.youtube.com/watch?v=xyvNu254A-Y&list=PL31D7CB49ABAAB4DB&index=24",
    pdf_name: null,
    type: "video",
    youtube_id: "xyvNu254A-Y",
    clip_start: "223",
    duration: "42",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/doing-1.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=drYWU0J2dhyK32ZJiCY9tB0evxB7VhLTNclDMTT4Bu63Xc5f79rO0KtGQEr3847QGA0GXUQG3bGi0Qt1%2FAoee9tg2a%2BFjyBdqgDfTTZVPW8d4F6xGjCxCilTE7a%2B7mNEJO9qeNoHZzAdLkhTTNBGzfYviqm0nPEhU2XeSU2Wpqa5O2Shnpw5gN%2Fr5leQGkQ%2BBYEvJJVpL4EseQS5qBpqSIZxzBCTvFuH0N%2BHnkMxAOu%2Baj5cu4%2F%2BRWlFnTXoMSYnBC0o7GekEiQ0Icu8%2FbE%2B8u4JBkvUV8pcJ3UrEM20y3E7qOu%2F4kS1ic38B4VBfHcz%2FSCKHLCFJyIIAT2qMM2BYQ%3D%3D",
    id: "What did you do today?\nI washed my floors.\nYour floors?\nYes. I washed my floors all day.\n\nWhat did you do today?\nI cleaned my house.\nYour house?\nYes. I cleaned my house all day.\n\nWhat did you do today?\nI painted my porch.\nYour porch?\nYes. I painted my porch all day.\n\nWhat did you do today?\nI fixed my sink.\nYour sink?\nYes. I fixed my sink all day.\n\nI washed my floors.\nI cleaned my house.\nI painted my porch.\nI fixed my sink.\nWe worked at home all day.",
    story_id: 1010,
    word_count: 23,
    all_intersection_count: 11,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "did",
      "John",
      "yesterday",
      "the",
      "movies",
      "oh",
      "who",
      "nobody",
      "himself",
      "played",
      "cards",
      "herself",
    ],
    words: [
      "what",
      "did",
      "John",
      "do",
      "yesterday",
      "he",
      "went",
      "to",
      "the",
      "movies",
      "oh",
      "who",
      "go",
      "with",
      "nobody",
      "by",
      "himself",
      "she",
      "played",
      "cards",
      "play",
      "herself",
    ],
    link: "(2)https://www.youtube.com/watch?v=Yf5RXW3zAAk&list=PLD7AA7B1BC72ACC28&index=18",
    pdf_name: null,
    type: "story",
    youtube_id: "Yf5RXW3zAAk",
    clip_start: "30.5",
    duration: "20.5",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/did.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=fgVT8XGndaJwI%2BhmMfhxvUlzzFU4FkKE1hBfYUCjZEeJiIgJ%2BuBtfPVNzXCmBOclsvMpedPjS8Ptaza%2FST679RV%2F5QDh5Lyxga21ojHyfkRVjTI8bcilp0XLe3Gr5yLlynbQ3R01srVoB8I05rx2P0U5Zy8FsIsSW5WNjcs%2FXteLKhdYvcqycXMHM39xWzrsf1YC0EVxoB2AujQSoVnxuJ%2BVNzgOjYxY%2BtVvkEshJEAS8bgXiOQBzMo9tfDutr8o3vK6aPlNVRWoad1K1Ddg9FEnnm9nccxheCNPKye%2BewqUNYlv5f7cbUTodUq5Al6XRFSxnDjgNiLKq9RupJuovQ%3D%3D",
    id: "What did John do yesterday?\nHe went to the movies.\nOh, who did he go to the movies with?\nNobody. He went to the movies by himself.\n\nWhat did Tina do yesterday?\nShe played cards.\nOh, who did she play cards with?\nNobody. She played cards by herself.",
    story_id: 1052,
    word_count: 22,
    all_intersection_count: 10,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "the",
      "lion",
      "dwells",
      "waste",
      "head",
      "waist",
      "his",
      "stark",
      "jaws",
      "grim",
      "will",
      "him",
    ],
    words: [
      "the",
      "lion",
      "he",
      "dwells",
      "in",
      "waste",
      "has",
      "a",
      "big",
      "head",
      "and",
      "very",
      "small",
      "waist",
      "but",
      "his",
      "shoulders",
      "are",
      "stark",
      "jaws",
      "they",
      "grim",
      "good",
      "little",
      "child",
      "will",
      "not",
      "play",
      "with",
      "him",
    ],
    link: "https://rainydaypoems.com/poems-for-kids/poems-for-young-children/the-lion-hilaire-belloc/",
    pdf_name: null,
    type: "poem",
    youtube_id: null,
    clip_start: null,
    duration: null,
    storage_link: null,
    id: "The lion, the lion, he dwells in the waste,\nHe has a big head and a very small waist;\nBut his shoulders are stark,\nAnd his jaws they are grim,\nAnd a good little child will not play with him.",
    story_id: 1905,
    word_count: 30,
    all_intersection_count: 18,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "head",
      "of",
      "lettuce",
      "cost",
      "95¢",
      "lot",
      "money",
      "expensive",
      "week",
      "pound",
      "dollar",
      "25",
    ],
    words: [
      "how",
      "much",
      "does",
      "a",
      "head",
      "of",
      "lettuce",
      "cost",
      "95¢",
      "that's",
      "lot",
      "money",
      "you're",
      "right",
      "is",
      "very",
      "expensive",
      "this",
      "week",
      "pound",
      "apples",
      "dollar",
      "25",
      "are",
    ],
    link: "(2)https://www.youtube.com/watch?v=SBG0nsPuI6Q&list=PLD7AA7B1BC72ACC28&index=5",
    pdf_name: null,
    type: "video",
    youtube_id: null,
    clip_start: null,
    duration: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/cost.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=B%2FGx8ZIk2KhoGSA71vIJk7WiUyVgS5UQ%2BWMebAO5DP1DTkgvB%2BxAi6NmkB3KmXh6osoFMPcLYQg%2F3A6e91zym%2FaH4Hg9oMMxm5TRAXN0doR9mNU1J8XGBd8pz%2B6Uv0vlseaRzlrV8G4DRI4kOA5jAN92rDLacKJqs57FeMXqcRPSEV%2BeGIGTkpZlXgon6hhpqfNTxXmBKeN6yXD5C99nDGq%2BPI2IYVafSFUkHGIqf%2FVSy%2B6GlRq54%2Bylne5tvYVWoNGWvqmipCpc4aFkgIXPTiYaN59fwDoYH0d2QFQ2nYHaVstIyunft3uR68sa3TRNcM6hJ9om7rJd9ySNVXdoZw%3D%3D",
    id: "How much does a head of lettuce cost?\n95¢.\n95¢? That's a lot of money.\nYou're right. Lettuce is very expensive this week.\n\nHow much does a pound of apples cost?\nA dollar 25.\nA dollar 25? That's a lot of money.\nYou're right. Apples are very expensive this week.",
    story_id: 1023,
    word_count: 24,
    all_intersection_count: 12,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "should",
      "I",
      "buy",
      "bicycle",
      "motorcycle",
      "think",
      "why",
      "bicycles",
      "safer",
      "than",
      "motorcycles",
      "probably",
    ],
    words: [
      "should",
      "I",
      "buy",
      "a",
      "bicycle",
      "or",
      "motorcycle",
      "think",
      "you",
      "why",
      "bicycles",
      "are",
      "safer",
      "than",
      "motorcycles",
      "you're",
      "probably",
      "right",
    ],
    link: "https://www.youtube.com/watch?v=1dW7V33qLwA&list=PLD7AA7B1BC72ACC28&index=10",
    pdf_name: null,
    type: "video",
    youtube_id: null,
    clip_start: null,
    duration: null,
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/should.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=qvSvHK9xJuw3cC9Pu8Sx2V%2Bzowjr%2BRF6H6kN9zuhTVdPVvx9jzLh7y0zEAfLGtAl8KtLgJ5UJriMv7jLtGfNqpjQnKL0DifdO8xX2iSH4ujjQBqHDWT0IBvHRXGONYtT%2BrwNtxCzMpUODGwFPuIcBQONrGbutXbHS7uObFMzbcc2gFXfdQTsq5Zk7ZNZrPEMQbqaUoI0Zr0RHNCznOftK3tvl0r%2FM%2BzIuqb%2BMFzB43vpt3JaPA%2Bwq2P0Fj6o0HPCWEJvi1KoPTtO4qucUU006Rrgo253HLCpMG6jpHitb0GJJdWn%2FmCd7r3nlvRlIUOlIYG%2Fnp02s6Chfk0btgrkEw%3D%3D",
    id: "Should I buy a bicycle or a motorcycle?\nI think you should buy a bicycle.\nWhy?\nBicycles are safer than motorcycles.\nYou're probably right.",
    story_id: 1040,
    word_count: 18,
    all_intersection_count: 6,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "did",
      "yesterday",
      "I",
      "the",
      "beach",
      "oh",
      "who",
      "nobody",
      "myself",
      "yourself",
      "yes",
      "great",
    ],
    words: [
      "what",
      "did",
      "you",
      "do",
      "yesterday",
      "I",
      "went",
      "to",
      "the",
      "beach",
      "oh",
      "who",
      "go",
      "with",
      "nobody",
      "by",
      "myself",
      "yourself",
      "yes",
      "had",
      "a",
      "great",
      "time",
    ],
    link: "https://www.youtube.com/watch?v=Yf5RXW3zAAk&list=PLD7AA7B1BC72ACC28&index=18",
    pdf_name: null,
    type: "story",
    youtube_id: "Yf5RXW3zAAk",
    clip_start: "12",
    duration: "17",
    storage_link:
      "https://storage.googleapis.com/leeearning.appspot.com/videos/yourself-1.mp4?GoogleAccessId=leeearning%40appspot.gserviceaccount.com&Expires=4102437600&Signature=H45s39J3xVFjoUYQmJOu%2BpxCyj%2FMCEG1pn%2Frk4JXslscMDB4T1xo7R%2B9wLa2xrs9Wd9qxzUDjbmQYIcBBZYOSxfgUIDrP%2B%2Bi4qUZoL%2BVk7LKLgwaWCmslhvRWqxbVd5q%2BtiQLMx55N9dDdwyYZl8jX8d%2FJWzsOG8y4ALt24%2Fl8iiJx75U4pb7eLYaDoBHF8BVl3lNOLfvhEPZ4svTXKsJ4oCg0%2FwJzluWx3v1P4hYBK6tvpg2Wm%2FJwdXQNTfAgx0BMdXqSD2hLHhSzblsFULE224fIy1cQLnlxfbNduoslK3OGOC2qIMNOke7ptmXC4%2BbXog16DR%2BiwUnGVwnXX19Q%3D%3D",
    id: "What did you do yesterday?\nI went to the beach.\nOh, who did you go to the beach with?\nNobody.\nI went to the beach by myself.\nYou went by yourself?\nYes. I had a great time.",
    story_id: 1051,
    word_count: 23,
    all_intersection_count: 11,
    new_words_count: 12,
  },
  {
    row_new_words: [
      "ice",
      "mug",
      "of",
      "chocolate",
      "would",
      "long",
      "dark",
      "nights",
      "kids",
      "bundle",
      "for",
      "snowball",
      "fights",
    ],
    words: [
      "winter",
      "cold",
      "and",
      "ice",
      "a",
      "mug",
      "of",
      "hot",
      "chocolate",
      "would",
      "be",
      "nice",
      "long",
      "dark",
      "nights",
      "kids",
      "bundle",
      "up",
      "for",
      "snowball",
      "fights",
    ],
    link: "",
    pdf_name: null,
    type: "poem",
    youtube_id: null,
    clip_start: null,
    duration: null,
    storage_link: null,
    id: "Winter, winter\ncold and ice\nA mug of hot chocolate\nwould be nice\nWinter, winter\nlong, dark nights\nKids bundle up\nFor snowball fights!",
    story_id: 900,
    word_count: 21,
    all_intersection_count: 8,
    new_words_count: 13,
  },
];

export default storiesData;
