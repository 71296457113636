import convertCoordinatesNumbersToWords from "../../../../../../../common/convertCoordinatesNumbersToWords";

const upperIInstructions = (xOffset: number, yOffset: number): string[][] => [
  [
    `Mark a dot at ${convertCoordinatesNumbersToWords([
      1 + xOffset,
      1 + yOffset,
    ])} and ${convertCoordinatesNumbersToWords(
      [12 + xOffset, 1 + yOffset],
      true,
    )} Draw a horizontal line between these two dots.`,
  ],
  [
    `Mark a dot at ${convertCoordinatesNumbersToWords(
      [1 + xOffset, 4 + yOffset],
      true,
    )} Draw a line from this dot to ${convertCoordinatesNumbersToWords(
      [1 + xOffset, 1 + yOffset],
      true,
    )}`,
  ],
  [
    `Mark a dot at ${convertCoordinatesNumbersToWords([
      4 + xOffset,
      4 + yOffset,
    ])} and draw a horizontal line between this dot and the last line.`,
  ],
  [
    `Mark a dot at ${convertCoordinatesNumbersToWords([
      9 + xOffset,
      4 + yOffset,
    ])} and ${convertCoordinatesNumbersToWords(
      [12 + xOffset, 4 + yOffset],
      true,
    )} Draw a horizontal line between these two dots.`,
  ],
  [
    `Draw a vertical line between ${convertCoordinatesNumbersToWords([
      12 + xOffset,
      1 + yOffset,
    ])} and ${convertCoordinatesNumbersToWords([12 + xOffset, 4 + yOffset], true)}`,
  ],
  [
    `Draw a horizontal line between ${convertCoordinatesNumbersToWords([
      9 + xOffset,
      18 + yOffset,
    ])} and ${convertCoordinatesNumbersToWords([12 + xOffset, 18 + yOffset], true)}`,
  ],
  [
    `Draw a vertical line between ${convertCoordinatesNumbersToWords([
      12 + xOffset,
      21 + yOffset,
    ])} and ${convertCoordinatesNumbersToWords([12 + xOffset, 18 + yOffset], true)}`,
  ],
  [
    `Mark a dot at ${convertCoordinatesNumbersToWords([
      4 + xOffset,
      18 + yOffset,
    ])} and ${convertCoordinatesNumbersToWords(
      [1 + xOffset, 18 + yOffset],
      true,
    )} Draw a line between these two dots.`,
  ],
  [
    `Draw a vertical line from ${convertCoordinatesNumbersToWords([
      1 + xOffset,
      21 + yOffset,
    ])} to ${convertCoordinatesNumbersToWords([1 + xOffset, 18 + yOffset], true)}`,
  ],
  [
    `Draw a horizontal line between ${convertCoordinatesNumbersToWords([
      1 + xOffset,
      21 + yOffset,
    ])} and ${convertCoordinatesNumbersToWords([12 + xOffset, 21 + yOffset], true)}`,
  ],
  ["Draw two lines to connect up and down lines."],
];

export default upperIInstructions;
