const upperI = (step: number) => (
  <svg
    width="23.156cm"
    height="21.138cm"
    preserveAspectRatio="xMidYMid"
    version="1.0"
    viewBox="0 0 656.39 599.19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="matrix(.1 0 0 -.1 0 911)" fill="none" stroke="#000" strokeWidth="50">
      <path
        transform="scale(1.0145 -.98573)"
        d="m3210.7-3351.2v1.8427l-3073 7.6086-0.06773-8.0395"
      />
      <path transform="scale(1.0145 -.98573)" d="m137.64-3349.8-7.3253-869.52 22.973 0.2061" />
      <path transform="scale(1.0145 -.98573)" d="m153.29-4219.1 822.14 7.3713" />
      <path transform="scale(1.0145 -.98573)" d="m2377.6-4211.7 828.63-7.5615" />
      <path transform="scale(1.0145 -.98573)" d="m3206.2-4219.3 4.4618-0.0407v868.1" />
      <path transform="scale(1.0145 -.98573)" d="m3198.1-8236.7h-811.73" />
      <path transform="scale(1.0145 -.98573)" d="m3191.6-9099h19.089v862.34h-12.562" />
      <path transform="scale(1.0145 -.98573)" d="m971.51-8231-0.01043-5.6767h-841.18v-13.805" />
      <path transform="scale(1.0145 -.98573)" d="m130.32-8250.5v-848.38" />
      <path transform="scale(1.0145 -.98573)" d="m130.32-9098.9v-0.1486h3061.3" />
      <path transform="scale(1.0145 -.98573)" d="m975.43-4211.7 3.4654 0.0311-7.3826-4019.3" />
      <path transform="scale(1.0145 -.98573)" d="m2386.4-8236.7h-9.5011v4025l0.693-0.0063" />
    </g>
  </svg>
);

export default upperI;
