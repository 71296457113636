import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, IconButton, Link as MuiLink, Tooltip, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import checkNewWords from "../../../common/checkNewWords";
import { skipWords } from "../../../common/constants";
import isAdmin from "../../../common/isAdmin";
import supabaseClient from "../../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../../store/lessonsStore";
import { EnglishLesson, EnglishStory } from "../../../types/supabase";
import CopyNewWordsForGemini from "../../Admin/CopyNewWordsForGemini";
import WordsCarousel from "../../Grammar/GrammarLesson/tabs/WordsCarousel";
import Stories from "../../Grammar/Stories";
import MenuLayout from "../../Layout/MenuLayout";
import ItalicTitle from "../../Title/ItalicTitle";
import HStack from "../../Unknown/HStack";
import PdfModal from "../../Unknown/PdfModal";
import CheckSentence from "../../Unknown/Root/CheckSentences";
import ControllingStudentPage, {
  createUserLesson,
  getUserLesson,
  updateUserLesson,
} from "../../Unknown/Root/ControllingStudentPage";
import VStack from "../../Unknown/VStack";
import CourseProgram, { Rule, lessons } from "./CourseProgram";
import AdminLearnToRead from "./AdminLearnToRead";
import LearnToReadCarousel from "./LearnToReadCarousel";
import StudentReadCarousel from "./LearnToReadCarousel/StudentReadCarousel";
import AddWordsToCurrentUserLesson from "./AddWordsToCurrentUserLesson";
import DrawingLetter from "../../Unknown/Root/Drawing/DrawingLetter";
import AdminControlUserEnglishLesson from "./AdminControlUserEnglishLesson";
import AdminSyncUserWords from "./AdminSyncUserWords";
import AdminAddReadingWordsToCurrentUserLesson from "./AdminAddReadingWordsToCurrentUserLesson";
import DisplayLessonWords from "./AdminDisplayLessonWords";

// -- valmiki 19c7ac10-110d-43e3-ad86-5e425aef49a5
// -- dayanvita 86523831-4881-4b89-91b5-def185deba6e
// -- grammar-user 7dd6fd1b-a333-4fbf-98a7-245491416070
// -- readding-user 53ef5828-71ce-4fdb-bfa1-80d8c4ebd57b
// -- ira 592d3941-7376-4c08-83c7-a374b2da849c

const EnglishKidsLessonsScreen: React.FC = () => {
  const activeVideoId = useLessonsStore((state) => state.activeVideoId);
  const [story, setStory] = useState<EnglishStory>();
  const [pdfStory, setPdfStory] = useState<EnglishStory>();
  const authUser = useLessonsStore((state) => state.authUser);
  const setActiveVideoId = useLessonsStore((state) => state.setActiveVideoId);
  const [isLoading, setIsLoading] = useState(false);

  useMountedWaitAsyncEffect(async () => {
    const { data } = await supabaseClient.from("admin_control").select().eq("id", 0).single();
    if (data) setActiveVideoId(data.active_video_id);
    console.log("🚀 ~ data activeVideoId:", data.active_video_id, activeVideoId);
  }, []);

  const isTeacherLesson = useLessonsStore((state) => state.isTeacherLesson);
  // const studentUser = useLessonsStore((state) => state.studentUser);

  useMountedWaitAsyncEffect(async () => {
    if (!activeVideoId) return;
    // console.log('0---', studentUser)

    const { data } = await supabaseClient
      .from("english_verses")
      .select()
      .in("id", [activeVideoId])
      .maybeSingle();

    if (data && data.type !== "pdf") setStory(data);
    if (data && data.type === "pdf") setPdfStory(data);
  }, [activeVideoId]);

  const englishLessons = useLessonsStore((state) => state.englishKidsLessons);
  const setEnglishLessons = useLessonsStore((state) => state.setEnglishKidsLessons);

  useMountedWaitAsyncEffect(async () => {
    if (!authUser) return;

    const { data } = await supabaseClient
      .from("english_user_lessons")
      .select()
      .eq("user", authUser.id)
      .order("date", { ascending: false });

    const lessons: EnglishLesson[] = [];
    if (data) lessons.push(...data);
    if (authUser.id === "tef2304") {
      const { data } = await supabaseClient
        .from("user_lessons")
        .select()
        .eq("user", authUser.id)
        .order("date", { ascending: false });
      if (data) lessons.push(...data);
    }

    setEnglishLessons(
      lessons.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()),
    );
  }, [authUser]);

  const studentUser = useLessonsStore((state) => state.studentUser);
  const setStudentUser = useLessonsStore((state) => state.setStudentUser);
  // english_lesson

  useMountedWaitAsyncEffect(async () => {
    if (studentUser && !studentUser.english_lesson) {
      console.log("🚀 ~ studentUser:", studentUser);
      await supabaseClient
        .from("users")
        .update({
          english_lesson: 1,
        })
        .eq("id", studentUser.id);

      const { data } = await supabaseClient
        .from("users")
        .select()
        .eq("id", studentUser.id)
        .single();

      setStudentUser(data?.user);
    }
  }, [studentUser]);

  const newUserLesson = useLessonsStore((state) => state.newUserLesson);
  const setNewUserLesson = useLessonsStore((state) => state.setNewUserLesson);

  // useMountedWaitAsyncEffect(async () => {
  //   if (!studentUser || !isAdmin(authUser?.id)) return;

  //   let userLesson = await getUserLesson(studentUser.id, "english_user_lessons");
  //   // new means current, lessen that was created today
  //   setNewUserLesson(userLesson);
  // }, [studentUser, authUser]);

  const [adminRules, setAdminRules] = useState<Rule[]>([]);

  useMountedWaitAsyncEffect(async () => {
    if (!studentUser || !studentUser.english_lesson || !isAdmin(authUser?.id)) return;

    const { data } = await supabaseClient
      .from("english_rules")
      .select()
      .lte("lesson", studentUser.english_lesson);

    if (data) setAdminRules(data);
  }, [studentUser]);

  const lessonWords: string[] = useMemo(() => {
    if (!studentUser?.english_lesson) return [];
    return lessons[studentUser.english_lesson.toString()]?.words || [];
  }, [studentUser]);

  const [newWords, setNewWords] = useState<string[]>([]);

  useMountedWaitAsyncEffect(async () => {
    if (!isAdmin(authUser?.id) || !lessonWords.length) return;
    {
      const { words: returnWords, newWords } = await checkNewWords(lessonWords);

      setNewWords(newWords.filter((w) => !skipWords.includes(w)));
    }
  }, [lessonWords, authUser]);

  if (!isAdmin(authUser?.id)) {
    return (
      <MenuLayout isFluid>
        <VStack p={5}>
          {/* {isTeacherLesson && (
            <Box>
              <Button
                variant="contained"
                onClick={() => {
                  window.open(
                    `https://excalidraw.com/#room=141e581c97856b4f791b,VEL8E0gLp0DerASDr9InaQ`,
                    "_blank",
                  );
                }}
              >
                Доска для малювання
              </Button>
            </Box>
          )} */}
          <ControllingStudentPage isNested />
          {/* <StudentReadCarousel /> */}
          {isTeacherLesson && pdfStory && <PdfModal story={pdfStory} />}
          {isTeacherLesson && story && (
            <VStack>
              <Stories
                stories={[story]}
                buttonLabel="відео"
                buttonSize="small"
                withoutDrawer
                withoutLink
                // isReadingVideo
              />
            </VStack>
          )}

          {Boolean(englishLessons.length) && (
            <Box>
              <ItalicTitle size={30} text="Уроки" />
              <br />
              {englishLessons.map((lesson) => {
                return (
                  <MuiLink
                    key={lesson.id}
                    color="secondary.dark"
                    sx={{
                      // textDecoration: "none",
                      width: "100%",
                      fontSize: 22,
                    }}
                    component={Link}
                    display="inline-block"
                    // to={`/grammar/grammar-lesson/${lesson.number}/build-words-list`}
                    to={`/english-lessons/${lesson.date}`}
                  >
                    Урок за <strong>{lesson.date}</strong>
                  </MuiLink>
                );
              })}
            </Box>
          )}
        </VStack>
      </MenuLayout>
    );
  }

  return (
    <MenuLayout isFluid>
      <Box
        sx={
          isAdmin(authUser?.id)
            ? {
                width: "calc(100vw)",
                height: "calc(100vh - 100px)",
                overflow: "scroll",
                position: "fixed",
                pt: "100px",
                left: 0,
                top: 0,
                pl: 50,
              }
            : {}
        }
      >
        <Box width="100%">
          <DrawingLetter />
        </Box>
        <Box display="flex" p={5}>
          <Box width="500px" pr={5} overflow="auto">
            <Box>
              <ControllingStudentPage isNested />
              {!!newWords.length && <CopyNewWordsForGemini words={newWords} />}
              {/* <WordsCarousel words={lessonWords} isReadToLearn /> */}
              <LearnToReadCarousel />
              <AddWordsToCurrentUserLesson words={lessonWords} />
              <AdminAddReadingWordsToCurrentUserLesson />
              <AdminControlUserEnglishLesson />
              <AdminSyncUserWords />
              <DisplayLessonWords />
              <CheckSentence />
            </Box>
          </Box>
          <Box
            width="600px"
            pr={5}
            height="calc(100vh - 50px)"
            sx={{
              // overflowY: "auto",
              overflow: "visible",
            }}
          >
            <AdminLearnToRead />
          </Box>
          {/* <Box
            sx={{
              // overflowY: "auto",
              overflowX: "visible",
            }}
            width="600px"
            pr={5}
            height="calc(100vh - 50px)"
            overflow="auto"
          > */}
          {/* <CourseProgram rules={adminRules} /> */}
          {/* </Box> */}
          {/* <Box width="35%" height="calc(100vh - 50px)" overflow="auto" py={5}>
            <LoadVideos />
          </Box> */}
        </Box>
      </Box>
    </MenuLayout>
  );
};

export default EnglishKidsLessonsScreen;
