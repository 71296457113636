import DownloadIcon from "@mui/icons-material/Download";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import StorageIcon from "@mui/icons-material/Storage";
import { IconButton } from "@mui/material";
import isAdmin from "../../../common/isAdmin";
import supabaseClient from "../../../common/supabaseClient";
import supabaseDrawingClient from "../../../common/supabaseDrawingClient";
import { useLessonsStore } from "../../../store/lessonsStore";
import HStack from "../HStack";
import { getDataFromPayloadDrawingStroke } from "./hooks/realtime/useAdminControlRealtime";

const DrawingBoardPanel = () => {
  const authUser = useLessonsStore((state) => state.authUser);
  const excalidrawAPI = useLessonsStore((state) => state.excalidrawAPI);
  const setExcalidrawAPI = useLessonsStore((state) => state.setExcalidrawAPI);
  const showDrawingBoard = useLessonsStore((state) => state.showDrawingBoard);
  const setDrawingRealtimeElements = useLessonsStore((state) => state.setDrawingRealtimeElements);
  const setDrawingLocalElements = useLessonsStore((state) => state.setDrawingLocalElements);

  const clearCanvas = async () => {
    if (!excalidrawAPI) return;

    const randomNumber = Math.floor(Math.random() * Math.random() * 10000000) / 10000000;
    await supabaseClient
      .from("admin_control")
      .update({
        drawing_clear_id: randomNumber,
      })
      .eq("id", 0);

    const sceneData = {
      elements: [],
      appState: { ...excalidrawAPI.getAppState() },
    };
    if (excalidrawAPI.updateScene) {
      excalidrawAPI.updateScene(sceneData);
    }
  };

  if (!showDrawingBoard) return null;

  return (
    <HStack
      sx={{
        backgroundColor: "white",
        padding: 5,
        position: "relative",
        zIndex: 2,
      }}
    >
      <HStack display="flex" alignItems="center" mb={2}>
        {isAdmin(authUser?.id) && excalidrawAPI && (
          <HStack>
            <IconButton
              onClick={async () => {
                const randomNumber =
                  Math.floor(Math.random() * Math.random() * 10000000) / 10000000;
                await supabaseClient
                  .from("admin_control")
                  .update({
                    drawing_student_update_id: randomNumber,
                  })
                  .eq("id", 0);
              }}
            >
              <CloudSyncIcon />
            </IconButton>
            <IconButton
              color="primary"
              onClick={async () => {
                const { data: existingStrokes } = await supabaseDrawingClient
                  .from("drawing_strokes")
                  .select();

                console.log("🚀 ~ existingStrokes:", existingStrokes);
                if (!existingStrokes || existingStrokes.length === 0) {
                  const sceneData = {
                    elements: [],
                  };
                  if (excalidrawAPI.updateScene) {
                    excalidrawAPI.updateScene(sceneData);
                  }
                  setDrawingRealtimeElements([]);
                  return;
                }

                if (existingStrokes) {
                  const elements = existingStrokes
                    .map((s) => getDataFromPayloadDrawingStroke(s.data))
                    .filter(Boolean);
                  const sceneData = {
                    elements,
                  };

                  if (excalidrawAPI.updateScene) {
                    excalidrawAPI.updateScene(sceneData);
                  }
                  setDrawingRealtimeElements(elements);
                }
              }}
            >
              <DownloadIcon />
            </IconButton>
            <IconButton
              color="error"
              onClick={async () => {
                await clearCanvas();

                const { data: existingStrokes } = await supabaseDrawingClient
                  .from("drawing_strokes")
                  .select("id");

                if (existingStrokes && existingStrokes.length) {
                  // setDrawingRealtimeElements([]);
                  const deleteResult = await Promise.all(
                    existingStrokes.map((s) => {
                      return supabaseDrawingClient.from("drawing_strokes").delete().eq("id", s.id);
                    }),
                  );
                  setDrawingRealtimeElements([]);
                  setDrawingLocalElements([]);
                  localStorage.setItem("drawingRealtimeElements", "");
                  console.log("🚀 ~ deleteResult:", deleteResult);
                }

                // const { data: existingStrokesAfterDeletion } = await supabaseClient
                //   .from("drawing_strokes")
                //   .select("id");

                // if (existingStrokesAfterDeletion && !existingStrokesAfterDeletion.length) {
                //   await clearCanvas();
                //   setDrawingRealtimeElements([]);
                // }
              }}
            >
              <StorageIcon />
              <RemoveCircleIcon />
            </IconButton>
            {/* {showDrawingBoard ? "board is ready" : "board is not ready"} */}
          </HStack>
        )}
      </HStack>
    </HStack>
  );
};

export default DrawingBoardPanel;
