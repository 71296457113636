import supabaseClient from "./supabaseClient";

export type UserLesson = {
  id: number;
  ids: number[];
  name: string;
  uuid1: string;
  uuid2: string;
  words: string[];
};

export type RPCUser = {
  id: string;
  uuid: string;
  name: string;
  lessons: Record<string, UserLesson>;
  is_personal_lesson: boolean | null;
  last_personal_lesson: number;
  grammar_lesson?: number;
  read_lesson?: number;
  write_lesson?: number;
  is_teacher_lesson?: boolean;
  prev_video_ids?: number[];
  ignore_video_ids?: number[];
  english_lesson?: number;
  drawing_shapes?: string[];
};

const fetchCurrentUser = async (email: string): Promise<RPCUser> => {
  const { data: userData } = await supabaseClient.rpc("get_user", {
    userprop: email,
  });

  const [user] = userData;

  if (!user) throw new Error("User not found!");

  return user;
};

export default fetchCurrentUser;
