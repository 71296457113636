import { useState } from "react";
import supabaseClient from "../../common/supabaseClient";
import { useLessonsStore } from "../../store/lessonsStore";
import HStack from "../Unknown/HStack";
import AdminComponentIndicator from "../Unknown/AdminComponentIndicator";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  createUserLesson,
  getLastUserLesson,
  getUserLesson,
  updateUserLesson,
} from "../Unknown/Root/ControllingStudentPage";
import getTextFromEditorState from "../../common/getTextFromEditorState";
import TextField from "../Unknown/TextField";
import checkNewWords from "../../common/checkNewWords";
import { skipWords } from "../../common/constants";
import CopyNewWordsForGemini from "../Admin/CopyNewWordsForGemini";
import AdminVerifyWordsAndCopyToAi from "./AdminVerifyWordsAndCopyToAi";

const AdminAddWordsToTheLastLesson = () => {
  const studentUser = useLessonsStore((state) => state.studentUser);
  const [isLoading, setIsLoading] = useState(false);
  const setNewUserLesson = useLessonsStore((state) => state.setNewUserLesson);
  const setEditorState = useLessonsStore((state) => state.setEditorState);
  const editorState = useLessonsStore((state) => state.editorState);
  const studentWordsToRead = useLessonsStore((state) => state.studentWordsToRead);
  const newUserLesson = useLessonsStore((state) => state.newUserLesson);

  const getStudentUser = async () => {
    if (!studentUser) return;
    const { data: studentDbUser } = await supabaseClient
      .from("users")
      .select()
      .eq("id", studentUser.id)
      .single();

    return studentDbUser;
  };

  const [words, setWords] = useState<string[]>([]);

  if (!studentUser) return null;

  return (
    <HStack alignItems="center">
      <AdminComponentIndicator title="AdminAddWordsToTheLastLesson" />
      <TextField
        sx={{
          width: "400px",
        }}
        placeholder="Words"
        onChange={(e) => {
          setTimeout(() => {
            setWords(
              e.target.value
                .split(",")
                .map((w) => w.trim())
                .filter(Boolean),
            );
          });
        }}
      />
      <AdminVerifyWordsAndCopyToAi words={words} isTrim />
      <LoadingButton
        loading={isLoading}
        variant="contained"
        color={"error"}
        onClick={async () => {
          if (!studentUser) {
            alert("no user found");
            return;
          }
          setIsLoading(true);
          const studentDbUser = await getStudentUser();

          if (!studentDbUser) return;

          // today user lesson
          const userLesson = await getUserLesson(studentUser.id, "english_user_lessons");

          if (userLesson) {
            const result = await updateUserLesson(
              {
                user: studentUser.id,
                // video_ids: [...(userLesson.video_ids || []), storyId].map((id) => Number(id)),
                reading_words: Array.from(
                  new Set([...words, ...(userLesson?.reading_words || [])]),
                ),
                words: Array.from(new Set([...words, ...(userLesson?.words || [])])),
              },
              "english_user_lessons",
            );
          } else {
            await createUserLesson(
              {
                user: studentUser.id,
                video_ids: [],
                reading_words: words,
                words: words,
              },
              "english_user_lessons",
            );
          }

          let updatedOrCreatedUserLesson = await getUserLesson(
            studentUser.id,
            "english_user_lessons",
          );
          console.log("🚀 ~ updatedOrCreatedUserLesson:", updatedOrCreatedUserLesson);
          setNewUserLesson(updatedOrCreatedUserLesson);

          setEditorState("");
          setIsLoading(false);
        }}
      >
        Add: today
      </LoadingButton>
      <LoadingButton
        loading={isLoading}
        variant="contained"
        color={"error"}
        onClick={async () => {
          if (!studentUser) {
            alert("no user found");
            return;
          }
          setIsLoading(true);
          const studentDbUser = await getStudentUser();

          if (!studentDbUser) return;

          // today user lesson
          const userLesson = await getLastUserLesson(studentUser.id, "english_user_lessons");
          console.log("🚀 ~ userLesson:", userLesson);
          if (!userLesson) {
            alert("there is no last lesson");
            return;
          }

          if (userLesson) {
            await updateUserLesson(
              {
                user: studentUser.id,
                // video_ids: [...(userLesson.video_ids || []), storyId].map((id) => Number(id)),
                reading_words: Array.from(
                  new Set([...words, ...(userLesson?.reading_words || [])]),
                ),
                words: Array.from(new Set([...words, ...(userLesson?.words || [])])),
              },
              "english_user_lessons",
            );
          }
          let updatedOrCreatedUserLesson = await getLastUserLesson(
            studentUser.id,
            "english_user_lessons",
          );
          console.log("🚀 ~ updatedOrCreatedUserLesson:", updatedOrCreatedUserLesson);
          setNewUserLesson(updatedOrCreatedUserLesson);

          setEditorState("");
          setIsLoading(false);
        }}
      >
        Add: last
      </LoadingButton>
    </HStack>
  );
};

export default AdminAddWordsToTheLastLesson;
