import { produce } from "immer";
import { RPCUser } from "../../common/fetchCurrentUser";
import { Session } from "@supabase/supabase-js";
import { EnglishLesson, EnglishStory, Module, Sentence, Word } from "../../types/supabase";
import { typesOfHomeWork } from "../../types";
import { PoemType } from "../../components/AdminLearnPoems/Poem";
import { Rule } from "../../components/EnglishKidsLessons/EnglishKidsLessonsScreen/CourseProgram";

export interface Stroke {
  id?: number | string;
  userId: string; // Associate each stroke with a user
  tool: "pen" | "eraser";
  color: string;
  points: number[][]; // Each point is [x, y]
  // In this simplified version, path is the same as points.
  path: number[][];
}

export interface LessonsState {
  shareScreenOptions: any;
  setShareScreenOptions: (props: any) => void;
  localStorageVideo: any;
  setLocalStorageVideo: (video: any) => void;
  displayCoordsForUser: boolean;
  setDisplayCoordsForUser: (val: boolean) => void;
  displayMyScreenForUser: boolean;
  setDisplayMyScreenForUser: (val: boolean) => void;
  shareLocalScreen: boolean;
  setShareLocalScreen: (val: boolean) => void;
  readingWordIndex: number;
  setReadingWordIndex: (index: number) => void;
  readingWords: string[];
  setReadingWords: (words: string[]) => void;
  drawingStudentUpdateId: number;
  setDrawingStudentUpdateId: (numb: number) => void;
  drawingClearId: number;
  setDrawingClearId: (numb: number) => void;
  excalidrawAPI: any;
  setExcalidrawAPI: (api: any) => void;
  drawingRealtimeElements: any[];
  setDrawingRealtimeElements: (drawingRealtimeElements: any[]) => void;
  drawingLocalElements: any[];
  setDrawingLocalElements: (drawingLocalElements: any[]) => void;
  drawingStrokes2: Stroke[];
  setDrawingStrokes2: (strokes: Stroke[]) => void;
  drawingStrokes: Stroke[];
  setDrawingStrokes: (strokes: Stroke[]) => void;
  drawingCanvasTool: "pen" | "eraser";
  setDrawingCanvasTool: (tool: string) => void;
  drawingCanvasColor: string;
  setDrawingCanvasColor: (color: string) => void;
  showDrawingBoard: boolean;
  setShowDrawingBoard: (val: boolean) => void;
  englishGrammarCourseSliderPositions: Record<string, number>;
  authUser: RPCUser | null;
  studentWordsToRead: any[];
  setStudentWordsToRead: (words: any) => void;
  isForceReloadInterval: boolean;
  setIsForceReloadInterval: (val: boolean) => void;
  myPoems: PoemType[];
  setMyPoems: (poems: PoemType[]) => void;
  session: Session | null;
  stories: EnglishStory[];
  userRules: Record<string, Record<string, Rule>>;
  setUserRules: (rules: Rule[]) => void;
  setStories: (stories: EnglishStory[]) => void;
  isBoardTextAllowedByAdmin: false;
  setIsBoardTextAllowedByAdmin: (val: boolean) => void;
  newWords: string[];
  textPartVideoByStartAndEndProps: {
    end: number;
    start: number;
    id: string;
    isOpen: boolean;
    isYouTube: boolean;
    endless: boolean;
    image: string;
  };
  setTextPartVideoByStartAndEndProps: (props: any) => void;
  englishKidsLessons: EnglishLesson[];
  setEnglishKidsLessons: (lessons: EnglishLesson[]) => void;
  englishLessons: EnglishLesson[];
  setEnglishLessons: (lessons: EnglishLesson[]) => void;
  isGrammarFeedOpen: boolean;
  setIsGrammarFeedOpen: (isOpen: boolean) => void;
  setNewWords: (words: string[], toReplace?: boolean) => void;
  userWords: string[];
  videosOrderIds: number[];
  setVideosOrderIds: (ids: number[]) => void;
  globalVideoPreparingMap: Record<string, boolean>;
  setGlobalVideoPreparingMap: (id: number | string, val: boolean) => void;
  lessonNumber: number;
  setLessonNumber: (lesson: number) => void;
  setUserWords: (words: string[]) => void;
  isUserBoardOpen: boolean;
  setIsUserBoardOpen: (val: boolean) => void;
  globalWords: Word[];
  newUserLesson: any | null;
  setNewUserLesson: (lesson: any) => void;
  adminControlReadingSentenceIndex: number;
  studentUser: RPCUser | null;
  studentWords: string[];
  editorState: string;
  setEditorState: (st: string) => void;
  setStudentWords: (words: string[]) => void;
  activeVideoId: null | number;
  setActiveVideoId: (id: number) => void;
  setStudentUser: (user: RPCUser | null) => void;
  homeworkDbProgress: {
    day: string;
    progress: number;
  }[];
  setPoemsCount: (count: number) => void;
  setHomeworkDbProgress: (progress: { day: string; progress: number }[]) => void;
  adminControlRepeatingIndex: number;
  setAdminControlRepeatingIndex: (val: number) => void;
  setAdminControlReadingSentenceIndex: (val: number) => void;
  homeWork: {
    readToLearnExplanationLessons?: number[];
    currentDate: string;
    // learn to read lessons
    repeatedWords: string[];
    builtWords: string[];
    poemsCount: null | number;
    wordsToBuild: string[];
    wordsToRepeat: string[];
    tasks: Record<string, any>;
    greetings: Record<string, boolean>;
    rulesToRepeat: string[];
    repeatedRules: string[];
    // grammar lessons
    videosToRepeat: number[];
    repeatedVideos: number[];
    grammarLessonNumber: number | null;
    isGrammarLesson: boolean;
    // total progress
    progress: number;
    isDone: boolean;
    // progress
    initialWordsToRepeat: string[];
    initialWordsToBuild: string[];
    initialRulesToRepeat: string[];
    initialVideosToRepeat: number[];
  };
  userBoardText: string;
  setUserBoardText: (text: string) => void;
  isTeacherLesson: boolean;
  setIsTeacherLesson: (val: boolean) => void;
  lessonModalOpen: typesOfHomeWork | "";
  setLessonModalOpen: (modal: typesOfHomeWork | "") => void;
  updateHomeWork: (props: any) => void;
  grammarModules: Module[];
  lessonData: Record<
    string,
    {
      lessonId: string;
      module: Module;
      sentences: Sentence[];
    }
  >;
  setGlobalWords: (words: Word[]) => void;
  setGrammarModules: (modules: Module[]) => void;
  setLessonData: ({
    lessonId,
    module,
    sentences,
  }: {
    lessonId: string;
    module: Module;
    sentences: Sentence[];
  }) => void;
  explainText: string[];
  setExplainText: (text: string[]) => void;
  sentences: Record<string, Sentence[]>;
  isLoading: boolean;
  currentLessonId: number;
  lastLessonNumber: number;
  isPersonalLesson: null | boolean;
  setEnglishGrammarCourseSliderPositions: (sliderId: string, index: number) => void;
  setIsPersonalLesson: (isPersonalLesson: boolean) => void;
  setRPCUser: (user: RPCUser) => void;
  setSession: (session: Session | null) => void;
  resetPersonalLessons: () => void;
  personalLessonsWords: Record<string, Word[]>;
  setPersonalLessonsWords: (lesson: string, words: Word[]) => void;
  personalLessonsSentences: Record<string, Sentence[]>;
  setPersonalLessonsSentences: (lesson: string, sentences: Sentence[]) => void;
}

export const setLessonNumber = (set: any) => {
  return (lesson: number) => {
    set(
      produce((state: LessonsState) => {
        state.lessonNumber = lesson;
      }),
    );
  };
};
