import convertCoordinatesNumbersToWords from "../../../../../../../common/convertCoordinatesNumbersToWords";

const lowerLInstructions = (xOffset: number, yOffset: number): string[][] => [
  [
    `Let's draw horizontal lines. To draw the first line. Mark a dot at ${convertCoordinatesNumbersToWords(
      [1 + xOffset, 1 + yOffset],
    )} and ${convertCoordinatesNumbersToWords(
      [6 + xOffset, 1 + yOffset],
      true,
    )} Draw a horizontal line between these two dots.`,
  ],
  [
    `Let's draw the second line. You need to mark a dot at ${convertCoordinatesNumbersToWords([
      1 + xOffset,
      21 + yOffset,
    ])} and ${convertCoordinatesNumbersToWords(
      [6 + xOffset, 21 + yOffset],
      true,
    )} Draw a horizontal line between these two dots.`,
  ],
  [
    `Now you need to connect all these lines to form a letter. Draw only vertical lines between dots to form a closed shape.`,
  ],
];

export default lowerLInstructions;
