import { Box, Button, CircularProgress, Container, TextField, Typography } from "@mui/material";
import { Session } from "@supabase/supabase-js";
import ReactGoogleButton from "react-google-button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import supabaseClient from "../../../common/supabaseClient";
import { useLessonsStore } from "../../../store/lessonsStore";
import Footer from "../../Unknown/Footer";
import Logo from "../../Unknown/Logo";
import VStack from "../../Unknown/VStack";
import { useLocalStorage } from "react-use";
import { adminUserId } from "../../../common/constants";
import { SideBarNavigationButton } from "../../Unknown/Navigation";
import LoginForm from "../../Auth/Login/LoginForm";

interface HomeProps {
  session: Session | null;
}

interface NavigationButtonProps {
  to: string;
  label: string;
  onClick?: () => void;
}

export const NavigationButton: React.FC<NavigationButtonProps> = ({ to, label, onClick }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) onClick();
    navigate(to);
  };

  return (
    <Button
      variant="text"
      onClick={handleClick}
      // color={isActive ? "primary" : "inherit"}
      sx={{
        width: "100%",
        fontSize: 17,
        textAlign: "left",
        lineHeight: "1.2",
        textDecorationLine: "underline",
      }}
    >
      {label}
    </Button>
  );
};

const Home: React.FC<HomeProps> = () => {
  const isPersonalLesson = useLessonsStore((state) => state.isPersonalLesson);
  const session = useLessonsStore((state) => state.session);
  console.log("🚀 ~ session:", session);
  const authUser = useLessonsStore((state) => state.authUser);
  console.log("🚀 ~ authUser:", authUser);
  const isAdmin = authUser?.id === adminUserId;
  const location = useLocation();

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between" minHeight="100vh">
      <Box flex={1} display="flex" alignItems="center">
        <Container>
          <VStack my={10}>
            <Logo />
            <Box my={5}>
              {session ? (
                <VStack justifyContent="center" alignItems="center">
                  <NavigationButton to="/sentences" label="Повторення речень" />
                  <NavigationButton to="/words" label="Повторення слів" />
                  {isPersonalLesson === null && (
                    <Box px={8}>
                      <CircularProgress />
                    </Box>
                  )}
                  {/* <NavigationButton to="/grammar" label="Базова граматика англійської мови" /> */}
                  <NavigationButton to="/learn-to-read" label="Вчитися читати" />
                  {isPersonalLesson && (
                    <>
                      <NavigationButton to="/personal-lessons" label="Персональні уроки" />
                      <NavigationButton to="/personal-dialogs" label="Діалоги" />
                      <NavigationButton to="/reading" label="Читання" />
                    </>
                  )}
                  {isAdmin && (
                    <>
                      <NavigationButton to="/admin" label="Admin" />
                      {/* <NavigationButton to="/difference" label="Різниця між словами" /> */}
                      {/* <NavigationButton to="/bangla-sentences" label="Bangla Sentences" /> */}
                      {/* <NavigationButton to="/sanskrit" label="Sanskrit Words" /> */}
                      {/* <NavigationButton to="/bangla-grammar" label="Bengali grammar" /> */}
                    </>
                  )}
                  {(isAdmin || authUser?.uuid === "86523831-4881-4b89-91b5-def185deba6e") && (
                    <>
                      <NavigationButton to="/declarations" label="Декларації" />
                    </>
                  )}
                  {/* <NavigationButton to="/lessons" label="Уроки" /> */}
                  <NavigationButton to="/english-lessons" label="Уроки англійської" />
                </VStack>
              ) : (
                <>
                  <ReactGoogleButton
                    label="Увійти через Google"
                    onClick={async () => {
                      const result = await supabaseClient.auth.signInWithOAuth({
                        provider: "google",
                        options: {
                          redirectTo: "/",
                          queryParams: {
                            access_type: "offline",
                            prompt: "consent",
                          },
                        },
                      });
                      console.log("🚀 ~ result:", result);
                    }}
                  />
                  <Box>
                    <LoginForm />
                  </Box>
                </>
              )}
            </Box>
            {/* <Box mb={5}>
              <Button
                to="https://www.youtube.com/@vitaliienglish"
                color="error"
                target="_blank"
                variant="outlined"
                component={Link}
              >
                YouTube канал
              </Button>
            </Box> */}
            {/* <Box textAlign="center">
            <Typography variant="h3">
              Я працюю над створенням контенту.
              <br />
              Дещо з того що вже почато:
            </Typography>
            <Typography color="primary">Аудіювання</Typography>
            <Typography color="primary">Різниця між словами</Typography>
            <Typography color="primary">Інтервальне повторення</Typography>
            <Typography color="primary">Тренажери для вивчення слів</Typography>
            <Box pt={5}>
              <Typography variant="h3">В планах:</Typography>
              <Typography>Діалоги</Typography>
              <Typography>Вірші, цитати</Typography>
              <Typography>Розбір фільмів</Typography>
              <Typography>Тематичні уроки</Typography>
              <Typography>Фразові дієслова</Typography>
              <Typography>Словник. Пошук слів</Typography>
              <Typography>Всі неправельні дієслова</Typography>
              <Typography>
                Тренажери для вивчення слів по темам (числа, час...)
              </Typography>
            </Box>
          </Box> */}
          </VStack>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};

export default Home;
