import { useCallback, useEffect, useMemo, useState } from "react";
import removePunctuationFromString from "../../../common/removePunctuationFromString";
import { EnglishStory, Word } from "../../../types/supabase";
import HStack from "../../Unknown/HStack";
import Typography from "../../Unknown/Typography";
import WordAudio from "../../Audio/WordAudio";
import HoverDetails from "../../Word/HoverDetails";
import { TextPartVideoByStartAndEndButton } from "../../Unknown/TextPartVideoByStartAndEnd";
import timeToSeconds from "../../../common/timeToSeconds";
import { useLessonsStore } from "../../../store/lessonsStore";
import isAdmin from "../../../common/isAdmin";
import Button from "../../Unknown/Button";

const StoryText: React.FC<{ story: EnglishStory; lazyLoading?: boolean }> = ({
  story,
  lazyLoading,
}) => {
  const [curerntModuleWordsMap, setCurrentModuleWordsMap] = useState<Record<string, Word>>({});
  const globalWords = useLessonsStore((state) => state.globalWords);
  console.log("🚀 ~ globalWords:", globalWords);
  const authUser = useLessonsStore((state) => state.authUser);

  const getWords = () => {
    const words = story.words.map((w) => w.replace("–", "-"));

    const map = globalWords
      .filter(
        (w) =>
          words.includes(w.name) ||
          words.includes(w.name.toLowerCase()) ||
          words.includes(w.name.toUpperCase()),
      )
      .reduce((acm, w) => {
        return {
          ...acm,
          [w.name]: w,
          [w.name.toLowerCase()]: w,
          [w.name.toUpperCase()]: w,
        };
      }, {});

    console.log("🚀 ~ map:", map);
    setCurrentModuleWordsMap(map);
  };

  useEffect(() => {
    if (!lazyLoading) {
      getWords();
    }
  }, [lazyLoading, globalWords, story]);

  const words = useCallback(
    (line: string) => {
      return line.split(" ").map((w: any, index: number) => {
        if (w.trim().startsWith("_time")) {
          const [, start, end] = w.trim().split(".");
          return {
            type: "children",
            children: (
              <TextPartVideoByStartAndEndButton
                story={story}
                start={timeToSeconds(start)}
                end={timeToSeconds(end)}
                endless
              />
            ),
          };
        }
        const cleanWord = removePunctuationFromString(w).replace("__", " ");

        const word =
          curerntModuleWordsMap[cleanWord] || curerntModuleWordsMap[cleanWord.toLowerCase()];

        return {
          ...(word ? word : {}),
          word: word?.name || cleanWord,
          label: w.replace("__", " "),
        };
      }) as any;
    },
    [curerntModuleWordsMap],
  );

  if (lazyLoading) return null;

  return (
    <>
      {/* {isAdmin(authUser?.id) && <Button onClick={getWords}>reload words</Button>} */}
      {((story.verse || story.id) as string).split("\n").map((line, index) => {
        // console.log("line", line, story);
        let audio = "";
        if (story.id === 933) {
          if (line.startsWith("A ")) audio = curerntModuleWordsMap.A?.audio_url;
          if (line.startsWith("B ")) audio = curerntModuleWordsMap.B?.audio_url;
          if (line.startsWith("C ")) audio = curerntModuleWordsMap.C?.audio_url;
          if (line.startsWith("D ")) audio = curerntModuleWordsMap.D?.audio_url;
          if (line.startsWith("E ")) audio = curerntModuleWordsMap.E?.audio_url;
          if (line.startsWith("F ")) audio = curerntModuleWordsMap.F?.audio_url;
          if (line.startsWith("G ")) audio = curerntModuleWordsMap.G?.audio_url;
          if (line.startsWith("H ")) audio = curerntModuleWordsMap.H?.audio_url;
          if (line.startsWith("I ")) audio = curerntModuleWordsMap.I?.audio_url;
          if (line.startsWith("J ")) audio = curerntModuleWordsMap.J?.audio_url;
          if (line.startsWith("K ")) audio = curerntModuleWordsMap.K?.audio_url;
          if (line.startsWith("L ")) audio = curerntModuleWordsMap.L?.audio_url;
          if (line.startsWith("M ")) audio = curerntModuleWordsMap.M?.audio_url;
          if (line.startsWith("N ")) audio = curerntModuleWordsMap.N?.audio_url;
          if (line.startsWith("O ")) audio = curerntModuleWordsMap.O?.audio_url;
          if (line.startsWith("P ")) audio = curerntModuleWordsMap.P?.audio_url;
          if (line.startsWith("Q ")) audio = curerntModuleWordsMap.Q?.audio_url;
          if (line.startsWith("R ")) audio = curerntModuleWordsMap.R?.audio_url;
          if (line.startsWith("S ")) audio = curerntModuleWordsMap.S?.audio_url;
          if (line.startsWith("T ")) audio = curerntModuleWordsMap.T?.audio_url;
          if (line.startsWith("U ")) audio = curerntModuleWordsMap.U?.audio_url;
          if (line.startsWith("V ")) audio = curerntModuleWordsMap.V?.audio_url;
          if (line.startsWith("W ")) audio = curerntModuleWordsMap.W?.audio_url;
          if (line.startsWith("X ")) audio = curerntModuleWordsMap.X?.audio_url;
          if (line.startsWith("Y ")) audio = curerntModuleWordsMap.Y?.audio_url;
          if (line.startsWith("Z ")) audio = curerntModuleWordsMap.Z?.audio_url;
        }

        return (
          <HStack key={index} sx={{ flexWrap: "nowrap", width: "100%" }} pl={6}>
            {audio && (
              <Typography component="span">
                <WordAudio url={audio} small />
              </Typography>
            )}
            <HoverDetails
              // isCenter
              words={words(line)}
            />
          </HStack>
        );
      })}
    </>
  );
};

export default StoryText;
