const upperCaseWords = [
  "I'll",
  "Dan",
  "Kate",
  "John",
  "Jan",
  "Sid",
  "Roger",
  "Julie",
  "Tom",
  "Bob",
  "Charlie",
  "Mary",
  "Susan",
  "Charlie's",
  "Betty's",
  "Matt",
  "England",
  "Velociraptor",
  "Noodle",
  "Blossom",
  "Cheesy",
  "Broccoli",
  "Martha",
  "Kernel",
  "Charlie's",
  "Elizabeth",
  "Japanese",
  "Stanley",
  "Baryonyx",
  "Troodon's",
  "Tina",
  "Brazil",
  "Diplodocus",
  "Giganotosaurus",
  "Brachiosaurus",
  "Mesozoic",
  "Triceratops",
  "Tyrannosaurus",
  "Rex",
  "Iguanodon",
  "Pteranodon",
  "English",
  "Oscar",
  "Harry Potter",
  "Stanley",
  "Japanese",
  "Chinese",
  "Mr.",
  "Mrs.",
  "Dursley",
  "Grunnings",
  "Tuesday",
  "Dursleys",
  "Dudley",
  "Jan",
  "January",
  "Potters",
  "Dursley's",
  "Privet Drive",
  "Miss",
  "Ms.",
  "I",
  "WAN",
  "Sunday",
  "I'm",
  "LAN",
  "March",
  "Fran",
  "Fran's",
  "Mondays",
  "Netherlands",
  "Ukraine",
  "I've",
  "I'll",
  "I'd",
  "Celsius",
  "Monday",
  "Amazon",
  "Denmark",
  "Europe",
  "April",
  "TV",
  "Saturday",
  "Sam",
  "Pam",
  "Friday",
  "Wednesday",
  "Gary",
  "Sundays",
  "Canada",
  "June",
  "USA",
  "Ms. Ann",
  "Mr. Jones",
  "Paris",
  "Miss May",
  "Mr. and Mrs. Dursley",
  "February",
  "Mrs. Jones",
];

export default upperCaseWords;
