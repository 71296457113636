import { memo, useEffect, useState } from "react";
import { useLessonsStore } from "../../../store/lessonsStore";
import Checkbox from "../../Unknown/Checkbox";
import Typography from "../../Unknown/Typography";

/**
 *
 * if checked: do not write anything in the database
 * instead that writes to local storage and display everything in
 * interval in the different localhost page.
 *
 */
const ShareScreenForStudentSwitch = () => {
  // const isBoardTextAllowedByAdmin = useLessonsStore((state) => state.isBoardTextAllowedByAdmin);
  // const setIsBoardTextAllowedByAdmin = useLessonsStore(
  //   (state) => state.setIsBoardTextAllowedByAdmin,
  // );
  // const setIsBoardTextAllowedByAdmin = useLessonsStore(
  //   (state) => state.setIsBoardTextAllowedByAdmin,
  // );
  const drawingLocalElements = useLessonsStore((state) => state.drawingLocalElements);
  const setDrawingLocalElements = useLessonsStore((state) => state.setDrawingLocalElements);
  const editorText = useLessonsStore((state) => state.editorState);
  const shareLocalScreen = useLessonsStore((state) => state.shareLocalScreen);
  const setShareLocalScreen = useLessonsStore((state) => state.setShareLocalScreen);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setIsBoardTextAllowedByAdmin(event.target.checked);
    setShareLocalScreen(event.target.checked);
  };

  return (
    <Typography sx={{ width: "100%" }}>
      Share screen
      <Checkbox checked={shareLocalScreen} onChange={handleChange} />
    </Typography>
  );
};

export default memo(ShareScreenForStudentSwitch);
