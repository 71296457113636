import Box from "@mui/material/Box";

const lesson23: any = {
  letters: [],
  number: 23,
  storyIds: [
    1864, // clean up
    // 1845, // put your scarf
    // 969, // hospital,
    // 970, // tall, short,
    // 1699, // then he had no fan
    // 1753, // then he had no fan
    // 1861, // time to make a circle
    // 1698, // mat, rat, cat
    // 1863, // The dog and the log
    // 1850, // thank you, you're welcome
    // 1846, // spin around
    // 1868, 1751, 1600,
    // 1698, // cat sat
    // 1589, // daddy finger
    // 1725, // jingle bells
    // 1699, // dad had a fan
    // 1753,
    // 1752, // I like to hop
  ],
  poemIds: [
    1864, // clean up
    // 1845, // put your scarf
    // 969, // hospital,
    // 970, // tall, short,
    // 1699, // then he had no fan
    // 1753, // then he had no fan
    // 1861, // time to make a circle
    // 1698, // mat, rat, cat
    // 1863, // The dog and the log
    // 1850, // thank you, you're welcome
    // 1846, // spin around
    // 1861, // make a circle
    // 1752, // I like to hop
    // 1599, // rain, go away
    // 1751,
    // 1868,
    // 1826,
    // 1828,
    // 1589,
    // 1600,
  ],
  newLettersCount: 0,
  newSounsCount: 0,
  title: <Box>Clean up your room.</Box>,
  sounds: [
    "Oo(ɒ)",
    "Nn",
    "Tt",
    "Ee(e)",
    "Bb",
    "Dd",
    "Ll",
    "Ii(i)",
    "Pp",
    "Ss(s)",
    "Ss(z)",
    "Zz",
    "Aa(æ)",
    "Hh",
    "Vv",
    "Kk",
    "Aa(æ)",
    "Hh",
    "Vv",
    "Kk",
    "Mm",
    "Ff",
    "Gg",
    "Mm",
    "Ff",
    "Gg",
    "Cc(k)",
    "Rr",
    "oo(uː)",
    "ee(i:)",
    "th(ð)",
    "oo(ʊ)",
    "ea(i:)",
    "sh(ʃ)",
    "Ww",
    "Oo(əʊ)",
    "Ee(ɪ)",
    "j(dʒ)",
    "Uu(ʌ)",
    "Yy(j)",
    "Yy(i)",
    "oy(ɔɪ)",
    "ay(eɪ)",
  ],
  newSounds: [],
  words: [
    "stomp",
    "said",
    "jog",
    "fog",
    "van",
    "come",
    "try",
    "really",
    "bag",
    "rag",
    "rain",
    "fat",
    "library",
    "clean",
    "want",
    "sure",
    "clean up",
    "everybody",
    "thing",
    "things",
    "pick",
    "pick up",
    "toys",
    "yard",
    "put",
    "her",
    "on",
    "in",
    "at",
    "how",
  ],
  speakingWords: [],
};

export default lesson23;
