import supabaseClient from "../../../common/supabaseClient";
import { useLessonsStore } from "../../../store/lessonsStore";
import AdminComponentIndicator from "../../Unknown/AdminComponentIndicator";
import Button from "../../Unknown/Button";
import HStack from "../../Unknown/HStack";

const AdminControlUserEnglishLesson = () => {
  const studentUser = useLessonsStore((state) => state.studentUser);
  const setStudentUser = useLessonsStore((state) => state.setStudentUser);

  if (!studentUser) return null;

  return (
    <HStack alignItems="center">
      <AdminComponentIndicator title="AdminControlUserEnglishLesson" />
      <Button
        variant="outlined"
        color="error"
        onClick={async () => {
          //  setStudentUser(data?.user);
          const newLessonNumber = (studentUser.english_lesson || 0) + 1;
          await supabaseClient
            .from("users")
            .update({
              english_lesson: newLessonNumber,
            })
            .eq("id", studentUser.id);

          setStudentUser({ ...studentUser, english_lesson: newLessonNumber });
        }}
      >
        Increase user lesson ({studentUser?.english_lesson})
      </Button>
    </HStack>
  );
};

export default AdminControlUserEnglishLesson;
