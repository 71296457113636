import supabaseClient from "../../../common/supabaseClient";
import AddIcon from "@mui/icons-material/Add";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import ScreenShareIcon from "@mui/icons-material/ScreenShare";
import { useLessonsStore } from "../../../store/lessonsStore";
import AdminComponentIndicator from "../../Unknown/AdminComponentIndicator";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import Box from "../../Unknown/Box";
import Button from "../../Unknown/Button";
import HStack from "../../Unknown/HStack";
import IconButton from "../../Unknown/IconButton";
import Typography from "../../Unknown/Typography";
import VStack from "../../Unknown/VStack";
import { lessons } from "./CourseProgram";
import {
  createUserLesson,
  getUserLesson,
  updateUserLesson,
} from "../../Unknown/Root/ControllingStudentPage";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";
import { Tooltip } from "@mui/material";
import ItalicTitle from "../../Title/ItalicTitle";

const AddToUser: React.FC<{ storyId: number; type: "prev" | "ignore" }> = ({ storyId, type }) => {
  const [isLoading, setIsLoading] = useState(false);
  const studentUser = useLessonsStore((state) => state.studentUser);
  const newUserLesson = useLessonsStore((state) => state.newUserLesson);
  const setNewUserLesson = useLessonsStore((state) => state.setNewUserLesson);
  // const [done, setDone] = useState(false)

  // if (done) return null;
  if (newUserLesson?.video_ids?.includes(Number(storyId))) return null;

  return (
    <LoadingButton
      loading={isLoading}
      variant="contained"
      color={type === "prev" ? "primary" : "error"}
      onClick={async () => {
        if (!studentUser) {
          alert("no user found");
          return;
        }

        console.log("type", type);
        setIsLoading(true);
        if (type === "prev") {
          const { data: studentDbUser } = await supabaseClient
            .from("users")
            .select()
            .eq("id", studentUser.id)
            .single();
          console.log("storyId", storyId);
          if (!studentDbUser) return;
          const prevVideosIds = Array.from(
            new Set([...(studentDbUser.prev_video_ids || []), storyId]),
          );

          const resultUpdateUserWihPrevVideoIds = await supabaseClient
            .from("users")
            .update({
              prev_video_ids: prevVideosIds,
            })
            .eq("id", studentUser.id);

          console.log(
            "EnglishKidsLessonScreen resultUpdateUserWihPrevVideoIds",
            resultUpdateUserWihPrevVideoIds,
          );

          // today user lesson
          const userLesson = await getUserLesson(studentUser.id, "english_user_lessons");
          console.log("🚀 ~ userLesson:", userLesson);
          if (userLesson) {
            await updateUserLesson(
              {
                user: studentUser.id,
                video_ids: Array.from(
                  new Set([...(userLesson.video_ids || []), storyId].map((id) => Number(id))),
                ),
                words: [...(userLesson?.words || [])],
              },
              "english_user_lessons",
            );
          } else {
            await createUserLesson(
              { user: studentUser.id, video_ids: [storyId] },
              "english_user_lessons",
            );
          }

          let updatedOrCreatedUserLesson = await getUserLesson(studentUser.id);
          setNewUserLesson(updatedOrCreatedUserLesson);
        } else if (type === "ignore") {
          const { data: studentDbUser } = await supabaseClient
            .from("users")
            .select()
            .eq("id", studentUser.id)
            .single();
          console.log("🚀 ~ studentDbUser:", studentDbUser);
          if (!studentDbUser) return;
          const ignoreVideoIds = Array.from(
            new Set([...(studentDbUser.ignore_video_ids || []), storyId].map((id) => Number(id))),
          );
          console.log("🚀 ~ prevVideosIds:", ignoreVideoIds);
          const updateResult = await supabaseClient
            .from("users")
            .update({
              ignore_video_ids: ignoreVideoIds,
            })
            .eq("id", studentUser.id);

          console.log("updateResult", updateResult);
        }
        setIsLoading(false);
      }}
    >
      {type === "prev" && (
        <Tooltip title="Add to user video ids">
          <PersonAddIcon />
        </Tooltip>
      )}
      {type !== "prev" && (
        <Tooltip title="Add to ignore video ids">
          <PersonRemoveIcon />
        </Tooltip>
      )}
      {/* {type === "prev" ? "Add to user video ids" : "Add to ignore video ids"} */}
    </LoadingButton>
  );
};

const DisplayLessonWords = () => {
  const [isLoading, setIsLoading] = useState(false);
  const studentUser = useLessonsStore((state) => state.studentUser);
  const newUserLesson = useLessonsStore((state) => state.newUserLesson);
  const setNewUserLesson = useLessonsStore((state) => state.setNewUserLesson);

  if (!studentUser) return null;

  return (
    <HStack alignItems="center">
      <Box py={3}>
        {Object.values(lessons)
          .filter(
            (l) =>
              studentUser &&
              studentUser.english_lesson &&
              // l.lesson >= studentUser.english_lesson &&
              l.lesson < studentUser.english_lesson + 5,
          )
          .map((l) => {
            return (
              <VStack alignItems="flex-start" justifyContent="flex-start">
                <Box width="100%">
                  <hr />
                </Box>
                <HStack>
                  <HStack>
                    <AdminComponentIndicator title="DisplayLessonWords" />
                    <Typography
                      sx={{
                        borderBottom:
                          (studentUser?.english_lesson || 0) === +l.lesson
                            ? "5px solid red"
                            : "1px solid black",
                      }}
                    >
                      <ItalicTitle text={`Lesson ${l.lesson}`} />
                    </Typography>
                    <IconButton
                      // variant="contained"
                      onClick={async () => {
                        if (!studentUser) return;

                        const userLesson = await getUserLesson(
                          studentUser.id,
                          "english_user_lessons",
                        );

                        if (userLesson) {
                          const newUserLesson = {
                            user: studentUser.id,
                            words: Array.from(new Set([...(userLesson?.words || []), ...l.words])),
                          };
                          setNewUserLesson(newUserLesson);
                          await updateUserLesson(newUserLesson, "english_user_lessons");
                        } else {
                          const newUserLesson = {
                            user: studentUser.id,
                            video_ids: [],
                            words: [...l.words],
                          };
                          setNewUserLesson(userLesson);
                          await createUserLesson(newUserLesson, "english_user_lessons");
                        }
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </HStack>
                  {studentUser &&
                    l.words.map((word) => {
                      return (
                        <Button
                          color={newUserLesson?.words?.includes(word) ? "primary" : "inherit"}
                          onClick={async () => {
                            const userLesson = await getUserLesson(
                              studentUser.id,
                              "english_user_lessons",
                            );

                            if (userLesson) {
                              const newUserLesson = {
                                user: studentUser.id,
                                words: Array.from(new Set([...(userLesson?.words || []), word])),
                              };
                              setNewUserLesson(newUserLesson);
                              await updateUserLesson(newUserLesson, "english_user_lessons");
                            } else {
                              const newUserLesson = {
                                user: studentUser.id,
                                video_ids: [],
                                words: [word],
                              };
                              setNewUserLesson(userLesson);
                              await createUserLesson(newUserLesson, "english_user_lessons");
                            }
                          }}
                        >
                          {word}
                        </Button>
                      );
                    })}
                </HStack>
                {l.storiesIds.map(({ id, title }) => {
                  return (
                    <HStack>
                      <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        color="info"
                        onClick={async () => {
                          if (!studentUser) {
                            alert("no user found");
                            return;
                          }

                          setIsLoading(true);

                          await supabaseClient
                            .from("admin_control")
                            .update({ active_video_id: id })
                            .eq("id", 0);

                          setIsLoading(false);
                        }}
                      >
                        <Tooltip title="Make it active for user">
                          <AirplanemodeActiveIcon />
                        </Tooltip>
                      </LoadingButton>
                      <AddToUser type="prev" storyId={id} />
                      <AddToUser type="ignore" storyId={id} />
                      <IconButton
                        onClick={async () => {
                          const existingLocalVideo = localStorage.getItem("localVideo");
                          console.log("🚀 ~ existingLocalVideo:", existingLocalVideo);
                          const parsedExistingLocalVideo: any =
                            existingLocalVideo && JSON.parse(existingLocalVideo);

                          const { data: story } = await supabaseClient
                            .from("english_verses")
                            .select()
                            .in("id", [id])
                            .maybeSingle();

                          if (!story) return;
                          console.log("🚀 ~ parsedExistingLocalVideo:", parsedExistingLocalVideo);
                          if (
                            parsedExistingLocalVideo &&
                            parsedExistingLocalVideo.id === story.id
                          ) {
                            localStorage.setItem("localVideo", "");
                          } else {
                            localStorage.setItem("localVideo", JSON.stringify(story));
                          }
                        }}
                      >
                        <ScreenShareIcon />
                      </IconButton>
                      {/* {story && (
                              )} */}
                      <Typography>{title}</Typography>
                    </HStack>
                  );
                })}
              </VStack>
            );
          })}
      </Box>
    </HStack>
  );
};

export default DisplayLessonWords;
