import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Box from "../../Box";
import Typography from "../../Typography";
import { useLessonsStore } from "../../../../store/lessonsStore";
import Checkbox from "../../Checkbox";
import { useState } from "react";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: "rgb(55, 65, 81)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
    ...theme.applyStyles("dark", {
      color: theme.palette.grey[300],
    }),
  },
}));

export default function ShareScreenMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const editorText = useLessonsStore((state) => state.editorState);
  const setEditorText = useLessonsStore((state) => state.setEditorState);
  const drawingLocalElements = useLessonsStore((state) => state.drawingLocalElements);
  const setDrawingLocalElements = useLessonsStore((state) => state.setDrawingLocalElements);
  const excalidrawAPI = useLessonsStore((state) => state.excalidrawAPI);
  const setExcalidrawAPI = useLessonsStore((state) => state.setExcalidrawAPI);

  const displayCoordsForUser = useLessonsStore((state) => state.displayCoordsForUser);
  const setDisplayCoordsForUser = useLessonsStore((state) => state.setDisplayCoordsForUser);
  const displayMyScreenForUser = useLessonsStore((state) => state.displayMyScreenForUser);
  const setDisplayMyScreenForUser = useLessonsStore((state) => state.setDisplayMyScreenForUser);
  const localStorageVideo = useLessonsStore((state) => state.localStorageVideo);
  const setLocalStorageVideo = useLessonsStore((state) => state.setLocalStorageVideo);
  const shareScreenOptions = useLessonsStore((state) => state.shareScreenOptions);
  console.log("🚀 ~ shareScreenOptions:", shareScreenOptions);
  const setShareScreenOptions = useLessonsStore((state) => state.setShareScreenOptions);
  // const [whiteBackground, setWhiteBackground] = useState(false)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ zIndex: 9999999999999999, position: "fixed", top: 0, left: 0 }}>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Options
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        sx={{
          zIndex: 9999999999999999,
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} disableRipple>
          <Button
            sx={
              {
                // position: "fixed",
                // zIndex: 10,
                // top: 110,
                // left: 0,
                // width: "200px",
                // px: 2,
                // backgroundColor: "red",
                // transformOrigin: "20% 100%",
                // transform: "rotate(-90deg)",
              }
            }
            onClick={() => {
              setDisplayMyScreenForUser(!displayMyScreenForUser);
            }}
          >
            Share screen here
          </Button>
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <Button
            sx={
              {
                // position: "fixed",
                // zIndex: 10,
                // top: 280,
                // left: 0,
                // width: "200px",
                // px: 2,
                // // backgroundColor: "red",
                // transformOrigin: "20% 100%",
                // transform: "rotate(-90deg)",
              }
            }
            onClick={() => {
              setShareScreenOptions({
                ...shareScreenOptions,
                showCoords: !shareScreenOptions.showCoords,
              });
            }}
          >
            Show coords
            {/* <Checkbox
              checked={displayCoordsForUser}
              onChange={(event) => {
                // setDisplayCoordsForUser(event.target.checked);
              }}
            /> */}
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            color="error"
            onClick={() => {
              localStorage.setItem("localVideo", "");
              setLocalStorageVideo(null);
            }}
          >
            remove storage video
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            onClick={() => {
              setShareScreenOptions({
                ...shareScreenOptions,
                backgroundColor:
                  shareScreenOptions.backgroundColor === "white" ? "transparent" : "white",
              });
            }}
          >
            toggle white background
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            onClick={() => {
              setShareScreenOptions({
                ...shareScreenOptions,
                drawingBackgroundColor:
                  shareScreenOptions.drawingBackgroundColor === "white" ? "transparent" : "white",
              });
            }}
          >
            toggle drawing white background
          </Button>
        </MenuItem>
        <MenuItem>
          <Button
            onClick={() => {
              setShareScreenOptions({
                ...shareScreenOptions,
                drawingBoard: !shareScreenOptions.drawingBoard,
              });
            }}
          >
            toggle drawing board
          </Button>
        </MenuItem>
        {/* <Divider sx={{ my: 0.5 }} /> */}
        {/* <MenuItem onClick={handleClose} disableRipple>
          <ArchiveIcon />
          Archive
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          <MoreHorizIcon />
          More
        </MenuItem> */}
      </StyledMenu>
    </Box>
  );
}
