import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MailIcon from "@mui/icons-material/Mail";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import useTheme from "@mui/styles/useTheme";
import React from "react";
import { drawerWidth } from "../../../common/constants";
import LogoutButton from "../LogoutButton";
import ItalicTitle from "../../Title/ItalicTitle";
import ForceReloadIntervalSwitch from "../../Unknown/Root/ControllingStudentPage/ForceReloadIntervalSwitch";
import isAdmin from "../../../common/isAdmin";
import { useLessonsStore } from "../../../store/lessonsStore";
import StudentAccentPopupSwitch from "../../Unknown/Root/ControllingStudentPage/StudentAccentPopupSwitch";
import DrawingBoardOpenButton from "../../Unknown/Root/DrawingBoardOpenButton";
import DrawingBoardPanel from "../../Unknown/Root/DrawingBoardPanel";
import supabaseClient from "../../../common/supabaseClient";
import ShareScreenForStudentSwitch from "./ShareScreenForStudentSwitch";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  variants: [
    {
      props: ({ open }) => open,
      style: {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      },
    },
  ],
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme }) => ({
  transition: theme.transitions.create(["left"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        left: `${drawerWidth}px !important`,
        // left: `500px`,
        position: "absolute",
        width: "230px",
        bottom: 0,
        transform: "rotate(-90deg)",
        display: "inline-block",
        transformOrigin: "22px 20px",
        // marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(["left"], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        // width: `calc(100% - ${drawerWidth}px)`,
        // marginLeft: `${drawerWidth}px`,
        // transition: theme.transitions.create(["margin", "width"], {
        //   easing: theme.transitions.easing.easeOut,
        //   duration: theme.transitions.duration.enteringScreen,
        // }),
      },
    },
  ],
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const SettingsDrawer = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const authUser = useLessonsStore((state) => state.authUser);
  const showDrawingBoard = useLessonsStore((state) => state.showDrawingBoard);

  const handleSettingsDrawerOpen = () => {
    setOpen(true);
  };

  const handleSettingsDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AppBar
        position="fixed"
        open={open}
        sx={{
          top: "auto",
          bottom: 0,
          left: 0,
          // left: `500px`,
          position: "fixed",
          width: "240px",
          p: 0,
          transform: "rotate(-90deg)",
          display: "inline-block",
          transformOrigin: "22px 20px",
          backgroundColor: "transparent",
          zIndex: 999999999999,
        }}
      >
        <Box>
          {!open && (
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={handleSettingsDrawerOpen}
              // edge="start"
              sx={[
                {
                  mr: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                  px: 4,
                },
              ]}
            >
              <SettingsIcon />
              <Typography variant="h6" noWrap component="div">
                Налаштування
              </Typography>
            </Button>
          )}
          {open && (
            <Button
              color="primary"
              size="small"
              variant="contained"
              sx={[
                {
                  mr: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                  px: 4,
                  // backgroundColor: "red",
                },
                // open && { display: "none" },
              ]}
              onClick={handleSettingsDrawerClose}
            >
              <KeyboardArrowUpIcon
                sx={{
                  fontSize: 30,
                  ml: -1,
                }}
              />
              <Typography variant="h6" noWrap component="div">
                Налаштування
              </Typography>
            </Button>
          )}
        </Box>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          zIndex: 99999999999,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <Box sx={{ flex: 1 }}></Box>
        <List>
          {/* {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
            <ListItem key={text} disablePadding>
            <ListItemButton>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
            </ListItemButton>
            </ListItem>
          ))} */}
          <Divider />
          {isAdmin(authUser?.id) && showDrawingBoard && (
            <>
              <ListItem>
                <ItalicTitle text="Drawing Admin" />
              </ListItem>
              <ListItem>
                <DrawingBoardPanel />
              </ListItem>
            </>
          )}
          <Divider />
          {isAdmin(authUser?.id) && (
            <>
              <ListItem>
                <ItalicTitle text="Control student Admin" />
              </ListItem>
              <ListItem>
                <ShareScreenForStudentSwitch />
              </ListItem>
              <ListItem>
                <DrawingBoardOpenButton />
              </ListItem>
              <ListItem>
                <StudentAccentPopupSwitch />
              </ListItem>
              <ListItem>
                <ForceReloadIntervalSwitch />
              </ListItem>
            </>
          )}
          <Divider />
          <ListItem>
            <LogoutButton />
          </ListItem>
        </List>
        {/* <DrawerHeader>
          <IconButton onClick={handleSettingsDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader> */}
        {/* <ChevronRightIcon /> */}
        {/* <Divider />
        <List>
          {["All mail", "Trash", "Spam"].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
      </Drawer>
    </>
  );
};

export default SettingsDrawer;
