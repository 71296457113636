const upperE = (step: number) => (
  <svg
    width="36.156cm"
    height="26.138cm"
    preserveAspectRatio="xMidYMid"
    version="1.0"
    viewBox="0 0 1024.9 740.92"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" stroke="#000" stroke-width="3.9394">
      <path d="m14.397 609.99 0.2598 113.36 1.2949 1e-3" />
      <path d="m298.09 608.68 0.01 1.1177-283.71-0.0309 5e-4 0.21826" />
      <path d="m296.26 496.45 0.83057-8.6e-4 1.0003 112.23" />
      <path d="m44.824 496.71 251.44-0.25895" />
      <path d="m42.858 381.55 1.4943 115.16 0.47169-4.9e-4" />
      <path d="m296.09 380.76-8e-3 0.87158-253.23-0.52061 0.0057 0.44091" />
      <path d="m295.49 268.78 1.6055-1e-3 -1.0025 111.98" />
      <path d="m15.15 268.44-0.0028 0.60791 280.34-0.25882" />
      <path d="m17.065 155.72-1.3974 2e-3 -0.51782 112.71" />
      <path d="m440 155.31v-0.33985l-422.94 0.74753" />
      <path d="m440 722.77v-567.46" />
      <path d="m15.952 723.35 424.05 0.48822v-1.0679" />
    </g>
  </svg>
);

export default upperE;
