import numWords from "num-words";

function convertCoordinatesNumbersToWords(tuple: [number, number], withDot?: boolean): string {
  if (withDot) {
    return `(${numWords(tuple[0])}, ${numWords(tuple[1])}).`;
  }
  return `(${numWords(tuple[0])}, ${numWords(tuple[1])})`;
}

export default convertCoordinatesNumbersToWords;
