const ball3 = (step: number) => (
  <svg
    width="36.156cm"
    height="26.138cm"
    preserveAspectRatio="xMidYMid"
    version="1.0"
    viewBox="0 0 1024.9 740.92"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* center */}
    <path
      d="m382.33 381.91c-4.1184 0-7.4883 3.3699-7.4883 7.4883 0 4.1184 3.3699 7.4863 7.4883 7.4863 4.1184 0 7.4863-3.368 7.4863-7.4863 0-4.1184-3.368-7.4883-7.4863-7.4883zm0 2.8106c2.6001 0 4.6777 2.0776 4.6777 4.6777s-2.0776 4.6777-4.6777 4.6777-4.6777-2.0776-4.6777-4.6777 2.0776-4.6777 4.6777-4.6777z"
      color="#000000"
    />
    <g fill="none" stroke="#000">
      <g stroke-width="3.8268">
        <path d="m127.5 184.5 27.75 141.75" />
        <path d="m183.75 552.75 27.75 84.75" />
        <path d="m353.25 666.75 114.75-57.75" />
        <path d="m609 581.25 29.25 29.25" />
        <path d="m636.75 468 57.75-87" />
        <path d="m524.25 439.5v-142.5" />
        <path d="m269.25 354.75 115.5-87" />
        <path d="m581.25 183.75 84.75 58.5" />
        <path d="m495.75 71.25 30-57.75" />
        <path d="m354 129-114-31.5" />
        <path d="m297.75 495.75 141.75 28.5" />
        <path d="m99 439.5h-84" stroke-width="3.685" />
      </g>
    </g>
  </svg>
);

export default ball3;
