const upperL = (step: number) => (
  <svg
    width="36.156cm"
    height="26.138cm"
    preserveAspectRatio="xMidYMid"
    version="1.0"
    viewBox="0 0 1024.9 740.92"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" stroke="#000" stroke-width="4">
      <path transform="scale(-1,1)" d="m-13.516 721.48v1.9819l-511.43-0.75-2.5e-4 -0.24317" />
      <path transform="scale(-1,1)" d="m-13.981 580.52h0.46582v140.96" />
      <path transform="scale(-1,1)" d="m-354.36 580.37-2.1e-4 0.15822h340.38" />
      <path transform="scale(-1,1)" d="m-353.61 14.053-0.74867 566.31" />
      <path transform="scale(-1,1)" d="m-525.7 13.687-5.1e-4 -0.48194h172.09l-0.00112 0.84815" />
      <path transform="scale(-1,1)" d="m-524.95 722.47-0.74924-708.79" />
    </g>
  </svg>
);

export default upperL;

// <svg
//   width="23.156cm"
//   height="21.138cm"
//   preserveAspectRatio="xMidYMid"
//   version="1.0"
//   viewBox="0 0 656.39 599.19"
//   xmlns="http://www.w3.org/2000/svg"
// >
//   <metadata>Created by potrace 1.15, written by Peter Selinger 2001-2017</metadata>
//   <g fill="none" stroke="#000" stroke-width="3">
//     <path transform="scale(-1,1)" d="m-13.516 721.48v1.9819l-511.43-0.75-2.5e-4 -0.24317" />
//     <path transform="scale(-1,1)" d="m-13.981 580.52h0.46582v140.96" />
//     <path transform="scale(-1,1)" d="m-354.36 580.37-2.1e-4 0.15822h340.38" />
//     <path transform="scale(-1,1)" d="m-353.61 14.053-0.74867 566.31" />
//     <path transform="scale(-1,1)" d="m-525.7 13.687-5.1e-4 -0.48194h172.09l-0.00112 0.84815" />
//     <path transform="scale(-1,1)" d="m-524.95 722.47-0.74924-708.79" />
{
  /* {(showFullPath || step > 0) && (
      <path transform="scale(1,-1)" d="m1555.6-4426.1v3.5008l2568.6-15-0.0029 0.4394" />
    )}

    {(showFullPath || step > 1) && (
      <path transform="scale(1,-1)" d="m137.78-8966v-9.3311h1417.9v6.7383" />
    )}
    {(showFullPath || step > 2) && (
      <path transform="scale(1,-1)" d="m4101.6-3295.4-3962.5 7.4692" />
    )}

    {(showFullPath || step > 3) && (
      <>
        <path transform="scale(1,-1)" d="m4124.2-4437.2-7.4971 1141.7-15.088 0.0283" />
        <path transform="scale(1,-1)" d="m1555.6-8968.6v4542.5" />
        <path transform="scale(1,-1)" d="m139.09-3288-1.3037 0.0025v-5678" />
      </>
    )} */
}
{
  /* </g>
</svg> */
}
