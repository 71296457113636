const svgAzerbaijan = (step: number) => (
  <svg
    width="36.156cm"
    height="26.138cm"
    preserveAspectRatio="xMidYMid"
    version="1.0"
    viewBox="0 0 1024.9 740.92"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none">
      <g stroke="#000">
        <path
          className="dots label"
          d="m128.18 722.25-1.9e-4 0.60506h-113.42l-8.25e-4 -1.3535"
          stroke-width="2.7125"
        />
        <path className="dots" d="m129.08 156.43-0.2599 565.89" stroke-width="2.8346" />
        <path
          className="dots label"
          d="m14.582 154.27 112.85 1.8597-1.3e-4 0.37787"
          stroke-width="2.6019"
        />
        <g stroke-width="2.8346">
          <path d="m14.862 721.67-0.48867-567.35 0.098148 0.00122" />
          <path d="m185.25 157.7-0.2599 565.89" />
          <path d="m299.01 158.79-0.2599 565.89" />
          <path d="m354.67 156.72-0.2599 565.89" />
          <path d="m470.3 158.43-0.2599 565.89" />
        </g>
        <path
          className="dots label"
          d="m184.52 155.61 113.91 1.8557-1.3e-4 0.37704"
          stroke-width="2.6113"
        />
        <path
          className="dots label"
          d="m354 156.39 114.98 1.8516-1.3e-4 0.37623"
          stroke-width="2.6206"
        />
        <path
          className="dots label"
          d="m354.8 721.34 114.98 1.8516-1.3e-4 0.37623"
          stroke-width="2.6206"
        />
        <path
          className="dots label"
          d="m185.18 722.41 112.85 1.8597-1.3e-4 0.37787"
          stroke-width="2.6019"
        />
        <path d="m581.77 524.19-58.711-56.576 59.778-57.643 55.508 57.643z" stroke-width="2.8346" />
        <path
          d="m539.19 426.42 87.215-1.0675-1.2976 86.667-84.532 0.43253z"
          stroke-width="2.8346"
        />
      </g>
      <g stroke="#ce101b" stroke-width="2.8346">
        <path d="m539.07 510.32 42.699-101.41" />
        <path d="m623.4 510.32-42.699-102.48" />
        <path d="m538 510.32 99.274-43.766" />
        <path d="m623.4 510.32-102.48-44.834" />
        <path d="m624.47 422.78-43.766 100.34" />
        <path d="m520.92 465.48 102.48-41.631" />
        <path d="m535.87 422.78 100.34 42.699" />
        <path d="m578.57 519.92-43.766-98.207" />
        <rect x="25.619" y="263.66" width="88.6" height="127.03" />
        <rect x="200.68" y="265.8" width="78.992" height="136.64" />
      </g>
      <g stroke="#3fb217">
        <rect x="369.34" y="271.14" width="83.262" height="132.37" stroke-width="2.8346" />
        <g stroke-width="5.3794">
          <path transform="scale(.75)" d="m36.294 436.24v-82.551h113.86v165.1h-113.86z" />
          <path transform="scale(.75)" d="m39.14 436.24v-79.704h108.17v159.41h-108.17z" />
          <path transform="scale(.75)" d="m41.987 436.24v-76.858h102.48v153.72h-102.48z" />
          <path transform="scale(.75)" d="m44.834 436.24v-74.011h96.784v148.02h-96.784z" />
          <path transform="scale(.75)" d="m47.68 436.24v-71.164h91.09v142.33h-91.09z" />
          <path transform="scale(.75)" d="m50.527 436.24v-68.318h85.397v136.64h-85.397z" />
          <path transform="scale(.75)" d="m53.373 436.24v-65.471h79.704v130.94h-79.704z" />
          <path transform="scale(.75)" d="m56.22 436.24v-62.625h74.011v125.25h-74.011z" />
          <path transform="scale(.75)" d="m59.066 436.24v-59.778h68.318v119.56h-68.318z" />
          <path transform="scale(.75)" d="m61.913 436.24v-56.932h62.625v113.86h-62.625z" />
          <path transform="scale(.75)" d="m64.76 436.24v-54.085h56.932v108.17h-56.932z" />
          <path transform="scale(.75)" d="m67.606 436.24v-51.238h51.238v102.48h-51.238z" />
          <path transform="scale(.75)" d="m70.453 436.24v-48.392h45.545v96.784h-45.545z" />
          <path transform="scale(.75)" d="m73.299 436.24v-45.545h39.852v91.09h-39.852z" />
          <path transform="scale(.75)" d="m76.146 436.24v-42.699h34.159v85.397h-34.159z" />
          <path transform="scale(.75)" d="m78.993 436.24v-39.852h28.466v79.704h-28.466z" />
          <path transform="scale(.75)" d="m81.839 436.24v-37.006h22.773v74.011h-22.773z" />
          <path
            transform="scale(.75)"
            d="m34.871 436.24v-83.974h116.71v167.95h-116.71zm68.318 0v-35.582h-19.926v71.164h19.926z"
          />
          <path
            transform="scale(.75)"
            d="m33.447 436.24v-85.397h119.56v170.79h-119.56zm68.318 0v-34.159h-17.079v68.318h17.079z"
          />
          <path
            transform="scale(.75)"
            d="m32.024 436.24v-86.821h122.4v173.64h-122.4zm68.318 0v-32.736h-14.233v65.471h14.233z"
          />
          <path
            transform="scale(.75)"
            d="m30.601 436.24v-88.244h125.25v176.49h-125.25zm68.318 0v-31.312h-11.386v62.625h11.386z"
          />
          <path
            transform="scale(.75)"
            d="m29.177 436.24v-89.667h128.1v179.33h-128.1zm68.318 0v-29.889h-8.5397v59.778h8.5397z"
          />
          <path
            transform="scale(.75)"
            d="m27.754 436.24v-91.09h130.94v182.18h-130.94zm68.318 0c0-27.517-0.09489-28.466-2.8466-28.466-2.7517 0-2.8466 0.94886-2.8466 28.466s0.09489 28.466 2.8466 28.466c2.7517 0 2.8466-0.94886 2.8466-28.466z"
          />
        </g>
      </g>
      <g stroke="#db1e1e" stroke-width="5.3794">
        <path transform="scale(.75)" d="m269.71 445.49v-88.956h101.05v177.91h-101.05z" />
        <path transform="scale(.75)" d="m272.56 445.49v-86.109h95.36v172.22h-95.36z" />
        <path transform="scale(.75)" d="m275.41 445.49v-83.262h89.667v166.52h-89.667z" />
        <path transform="scale(.75)" d="m278.25 445.49v-80.416h83.974v160.83h-83.974z" />
        <path transform="scale(.75)" d="m281.1 445.49v-77.569h78.281v155.14h-78.281z" />
        <path transform="scale(.75)" d="m283.95 445.49v-74.723h72.588v149.45h-72.588z" />
        <path transform="scale(.75)" d="m286.79 445.49v-71.876h66.895v143.75h-66.895z" />
        <path transform="scale(.75)" d="m289.64 445.49v-69.029h61.201v138.06h-61.201z" />
        <path transform="scale(.75)" d="m292.49 445.49v-66.183h55.508v132.37h-55.508z" />
        <path transform="scale(.75)" d="m295.33 445.49v-63.336h49.815v126.67h-49.815z" />
        <path transform="scale(.75)" d="m298.18 445.49v-60.49h44.122v120.98h-44.122z" />
        <path transform="scale(.75)" d="m301.03 445.49v-57.643h38.429v115.29h-38.429z" />
        <path transform="scale(.75)" d="m303.87 445.49v-54.797h32.736v109.59h-32.736z" />
        <path transform="scale(.75)" d="m306.72 445.49v-51.95h27.042v103.9h-27.042z" />
        <path transform="scale(.75)" d="m309.57 445.49v-49.103h21.349v98.207h-21.349z" />
        <path transform="scale(.75)" d="m312.41 445.49v-46.257h15.656v92.514h-15.656z" />
        <path transform="scale(.75)" d="m315.26 445.49v-43.41h9.963v86.821h-9.963z" />
        <path
          transform="scale(.75)"
          d="m323 445.49c6.8e-4 -22.701 0.19719-31.789 0.4367-20.195s0.23895 30.168-1e-3 41.275c-0.24018 11.107-0.43614 1.6209-0.43547-21.081z"
        />
        <path
          transform="scale(.75)"
          d="m322.78 411.33c0.0107-3.914 0.2871-5.3463 0.61425-3.1828s0.3184 5.3659-0.0194 7.1164c-0.33783 1.7506-0.6055-0.0196-0.59482-3.9336z"
        />
        <path
          transform="scale(.75)"
          d="m267.53 535.53c-0.37776-0.98817-0.52081-42.147-0.3179-91.464l0.36893-89.667h105.32v182.18l-52.344 0.3734c-41.112 0.29327-52.491-0.0121-53.031-1.4233zm56.271-55.086c0-3.8678-0.65876-7.4396-1.4639-7.9372-1.9894-1.2295-1.668-64.8 0.33983-67.225 1.1136-1.3454 0.56269-1.7791-2.2599-1.7791h-3.7325v83.974h3.5582c3.3153 0 3.5582-0.48003 3.5582-7.0324z"
        />
        <path
          transform="scale(.75)"
          d="m267.22 537.77c-1.3976-0.56396-1.7791-20.529-1.7791-93.113v-92.395h109.59v186.45l-53.018-0.11237c-29.16-0.0618-53.818-0.43541-54.797-0.83025zm54.473-53.195c1.2481-2.0195-0.68135-12.757-2.2923-12.757-0.714 0-1.2982 3.2024-1.2982 7.1164 0 6.668 1.4996 9.024 3.5905 5.6409z"
        />
        <path
          transform="scale(.75)"
          d="m265.49 537.38c-0.97199-1.8162-1.4734-34.024-1.4734-94.649v-91.896h112.44v189.3h-54.747c-52.67 0-54.802-0.10444-56.22-2.753z"
        />
      </g>
      <g stroke="#1eafdb">
        <g stroke-width="5.3794">
          <path transform="scale(.75)" d="m494.59 449.76v-86.109h106.75v172.22h-106.75z" />
          <path transform="scale(.75)" d="m497.44 449.76v-83.262h101.05v166.52h-101.05z" />
          <path transform="scale(.75)" d="m500.29 449.76v-80.416h95.36v160.83h-95.36z" />
          <path transform="scale(.75)" d="m503.13 449.76v-77.569h89.667v155.14h-89.667z" />
          <path transform="scale(.75)" d="m505.98 449.76v-74.723h83.974v149.45h-83.974z" />
          <path transform="scale(.75)" d="m508.83 449.76v-71.876h78.281v143.75h-78.281z" />
          <path transform="scale(.75)" d="m511.67 449.76v-69.03h72.588v138.06h-72.588z" />
          <path transform="scale(.75)" d="m514.52 449.76v-66.183h66.895v132.37h-66.895z" />
          <path transform="scale(.75)" d="m517.37 449.76v-63.336h61.201v126.67h-61.201z" />
          <path transform="scale(.75)" d="m520.21 449.76v-60.49h55.508v120.98h-55.508z" />
          <path transform="scale(.75)" d="m487.48 449.76v-93.225h120.98v186.45h-120.98z" />
        </g>
        <path
          d="m666.5 579.53-59.195-27.771h-27.771l-57.003 29.963-13.155 56.272 15.347 56.272 57.003 29.963h27.04l59.195-29.232"
          stroke-width="2.8346"
        />
      </g>
    </g>
  </svg>
);

export default svgAzerbaijan;
