import ball1 from "./ball/ball1/svgBall1";
import ball2 from "./ball/ball2/svgBall2";
import ball3 from "./ball/ball3/svgBall3";

export const rowLetterThings = ["Ball1", "Ball2", "Ball3"];

const getLetterThing = (letter: string, step: number) => {
  if (letter === "Ball1") return ball1(step);
  if (letter === "Ball2") return ball2(step);
  if (letter === "Ball3") return ball3(step);
};

export default getLetterThing;
