// @ts-ignore
import type {} from "@redux-devtools/extension"; // required for devtools typing
import { produce } from "immer";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { EnglishLesson, Module, Word } from "../types/supabase";
import { LessonsState, Stroke, setLessonNumber } from "./utils/functions";
import { PoemType } from "../components/AdminLearnPoems/Poem";
import { Rule } from "../components/EnglishKidsLessons/EnglishKidsLessonsScreen/CourseProgram";

export const useLessonsStore = create<LessonsState>()(
  devtools(
    (set) => ({
      // admin poem
      // end admin poem
      shareScreenOptions: {
        showCoords: false,
        backgroundColor: "transparent",
        drawingBackgroundColor: "transparent",
        drawingBoard: false,
      },
      setShareScreenOptions: (props: any) => {
        set(
          produce((state) => {
            state.shareScreenOptions = {
              ...props,
            };
          }),
        );
      },
      localStorageVideo: null,
      setLocalStorageVideo: (video: any) => {
        set(
          produce((state) => {
            state.localStorageVideo = video;
          }),
        );
      },
      displayCoordsForUser: false,
      setDisplayCoordsForUser: (val: boolean) => {
        set(
          produce((state) => {
            state.displayCoordsForUser = val;
          }),
        );
      },
      displayMyScreenForUser: false,
      setDisplayMyScreenForUser: (val: boolean) => {
        set(
          produce((state) => {
            state.displayMyScreenForUser = val;
          }),
        );
      },
      // english lessons reading words
      shareLocalScreen: false,
      setShareLocalScreen: (val: boolean) => {
        set(
          produce((state) => {
            state.shareLocalScreen = val;
          }),
        );
      },
      readingWordIndex: 0,
      setReadingWordIndex: (index: number) => {
        set(
          produce((state) => {
            state.readingWordIndex = index;
          }),
        );
      },
      readingWords: [],
      setReadingWords: (words: string[]) => {
        set(
          produce((state) => {
            state.readingWords = words;
          }),
        );
      },
      // drawing canvas
      drawingStudentUpdateId: 0,
      setDrawingStudentUpdateId: (numb: number) => {
        set(
          produce((state) => {
            state.drawingStudentUpdateId = numb;
          }),
        );
      },
      drawingClearId: 0,
      setDrawingClearId: (numb: number) => {
        set(
          produce((state) => {
            state.drawingClearId = numb;
          }),
        );
      },
      excalidrawAPI: null,
      setExcalidrawAPI: (api: any) => {
        set(
          produce((state) => {
            state.excalidrawAPI = api;
          }),
        );
      },
      drawingRealtimeElements: [],
      setDrawingRealtimeElements: (elm: any[]) => {
        set(
          produce((state) => {
            state.drawingRealtimeElements = elm;
          }),
        );
      },
      drawingLocalElements: [],
      setDrawingLocalElements: (elm: any[]) => {
        set(
          produce((state) => {
            state.drawingLocalElements = elm;
          }),
        );
      },
      drawingStrokes2: [],
      setDrawingStrokes2: (strokes: Stroke[]) => {
        set(
          produce((state) => {
            state.drawingStrokes2 = strokes;
          }),
        );
      },
      drawingStrokes: [],
      setDrawingStrokes: (strokes: Stroke[]) => {
        set(
          produce((state) => {
            state.drawingStrokes = strokes;
          }),
        );
      },
      drawingCanvasTool: "pen",
      setDrawingCanvasTool: (tool: string) => {
        set(
          produce((state) => {
            state.drawingCanvasTool = tool;
          }),
        );
      },
      drawingCanvasColor: "#000000",
      setDrawingCanvasColor: (color: string) => {
        set(
          produce((state) => {
            state.drawingCanvasColor = color;
          }),
        );
      },

      showDrawingBoard: false,
      setShowDrawingBoard: (val: boolean) => {
        set(
          produce((state) => {
            state.showDrawingBoard = val;
          }),
        );
      },
      studentWordsToRead: [],
      setStudentWordsToRead: (words: any) => {
        set(
          produce((state) => {
            state.studentWordsToRead = words || [];
          }),
        );
      },
      userRules: {},
      setUserRules: (rules: Rule[]) => {
        set(
          produce((state) => {
            const [rule] = rules;
            state.userRules = {
              ...state.rules,

              [rule.lesson]: rules.reduce((acm, r) => {
                return {
                  ...acm,
                  [r.question]: r,
                };
              }, {}),
            };
          }),
        );
      },
      isForceReloadInterval: false,
      setIsForceReloadInterval: (val: boolean) => {
        set(
          produce((state) => {
            state.isForceReloadInterval = val;
          }),
        );
      },
      myPoems: [],
      setMyPoems: (poems: PoemType[]) => {
        set(
          produce((state) => {
            state.myPoems = poems;
          }),
        );
      },
      stories: [],
      setStories: (stories) => {
        set(
          produce((state) => {
            state.stories = stories;
          }),
        );
      },
      isBoardTextAllowedByAdmin: false,
      setIsBoardTextAllowedByAdmin: (val: boolean) => {
        set(
          produce((state) => {
            state.isBoardTextAllowedByAdmin = val;
          }),
        );
      },
      globalVideoPreparingMap: {},
      setGlobalVideoPreparingMap: (id, val) => {
        set(
          produce((state) => {
            state.globalVideoPreparingMap = { ...state.globalVideoPreparingMap, [id]: val };
          }),
        );
      },
      textPartVideoByStartAndEndProps: {
        end: 0,
        start: 0,
        id: "",
        isOpen: false,
        isYouTube: false,
        endless: false,
        image: "",
      },
      setTextPartVideoByStartAndEndProps: (props: any) => {
        set(
          produce((state) => {
            state.textPartVideoByStartAndEndProps = {
              ...state.textPartVideoByStartAndEndProps,
              ...props,
            };
          }),
        );
      },
      session: null,
      authUser: null,
      isPersonalLesson: null,
      isGrammarFeedOpen: false,
      englishGrammarCourseSliderPositions: {},
      // lessons: null,
      // words: {},
      sentences: {},
      isLoading: true,
      englishKidsLessons: [],
      setEnglishKidsLessons: (lessons: EnglishLesson[]) => {
        set(
          produce((state) => {
            state.englishKidsLessons = lessons;
          }),
        );
      },
      englishLessons: [],
      setEnglishLessons: (lessons: EnglishLesson[]) => {
        set(
          produce((state) => {
            state.englishLessons = lessons;
          }),
        );
      },
      currentLessonId: 0,
      lastLessonNumber: 0,
      personalLessonsWords: {},
      newWords: [],
      studentWords: [],
      editorState: "",
      setEditorState: (st: string) => {
        set(
          produce((state) => {
            state.editorState = st;
          }),
        );
      },
      videosOrderIds: [],
      setVideosOrderIds: (ids: number[]) => {
        set(
          produce((state) => {
            state.videosOrderIds = ids;
          }),
        );
      },
      setStudentWords: (words: string[]) => {
        set(
          produce((state) => {
            state.studentWords = words;
          }),
        );
      },
      newUserLesson: null,
      setNewUserLesson: (lesson: any) => {
        set(
          produce((state) => {
            state.newUserLesson = lesson;
          }),
        );
      },
      lessonNumber: 0,
      setActiveVideoId: (id: number) => {
        set(
          produce((state) => {
            state.activeVideoId = id;
          }),
        );
      },
      activeVideoId: null,
      homeWork: {
        currentDate: "",
        // learn to read lessons
        repeatedWords: [],
        builtWords: [],
        wordsToBuild: [],
        wordsToRepeat: [],
        tasks: {},
        poemsCount: null,
        greetings: {},
        rulesToRepeat: [],
        repeatedRules: [],
        // grammar lessons
        videosToRepeat: [],
        repeatedVideos: [],
        grammarLessonNumber: null,
        isGrammarLesson: false,
        // total progress
        progress: 0,
        isDone: false,
        // progress
        initialWordsToRepeat: [],
        initialWordsToBuild: [],
        initialRulesToRepeat: [],
        initialVideosToRepeat: [],
      },
      setPoemsCount: (count: number) => {
        set(
          produce((state) => {
            state.homeWork.poemsCount = count;
          }),
        );
      },
      studentUser: null,
      setStudentUser: (user) => {
        set(
          produce((state) => {
            state.studentUser = user;
          }),
        );
      },
      homeworkDbProgress: [],
      setHomeworkDbProgress: (progress) => {
        set(
          produce((state) => {
            state.homeworkDbProgress = progress;
          }),
        );
      },
      lessonModalOpen: "",
      isTeacherLesson: false,
      adminControlRepeatingIndex: 1,
      setAdminControlRepeatingIndex: (val: number) => {
        set(
          produce((state) => {
            state.adminControlRepeatingIndex = val;
          }),
        );
      },
      adminControlReadingSentenceIndex: 1,
      setAdminControlReadingSentenceIndex: (val: number) => {
        set(
          produce((state) => {
            state.adminControlReadingSentenceIndex = val;
          }),
        );
      },
      setIsTeacherLesson: (val: boolean) => {
        set(
          produce((state) => {
            state.isTeacherLesson = val;
          }),
        );
      },
      // board text for students when the admin write something
      userBoardText: "",
      setUserBoardText: (text) => {
        set(
          produce((state) => {
            state.userBoardText = text;
          }),
        );
      },
      // board for students opens when the admin write something
      isUserBoardOpen: false,
      setIsUserBoardOpen: (val: boolean) => {
        set(
          produce((state) => {
            state.isUserBoardOpen = val;
          }),
        );
      },
      setLessonModalOpen: (modal: string) => {
        set(
          produce((state) => {
            state.lessonModalOpen = modal;
          }),
        );
      },
      updateHomeWork: (props: any) => {
        set(
          produce((state) => {
            state.homeWork = {
              ...state.homeWork,
              ...props,
            };
          }),
        );
      },
      setLessonNumber: setLessonNumber(set),
      setIsGrammarFeedOpen: (isOpen) => {
        set(
          produce((state) => {
            state.isGrammarFeedOpen = isOpen;
          }),
        );
      },
      setNewWords: (words, toReplace) => {
        set(
          produce((state) => {
            state.newWords = toReplace
              ? words
              : Array.from(new Set([...state.newWords, ...words].filter(Boolean)));
          }),
        );
      },
      userWords: [],
      setUserWords: (words: string[]) => {
        set(
          produce((state) => {
            state.userWords = Array.from(new Set(...state.userWords, ...words));
          }),
        );
      },
      globalWords: [],
      grammarModules: [],
      setGrammarModules: (modules: Module[]) => {
        set(
          produce((state) => {
            state.grammarModules = modules;
          }),
        );
      },
      lessonData: {},
      setLessonData: ({ lessonId, module, sentences }) => {
        set(
          produce((state) => {
            state.lessonData = {
              ...state.lessonData,
              [lessonId]: {
                lessonId,
                module,
                sentences,
              },
            };
          }),
        );
      },
      setGlobalWords: (words: Word[]) => {
        set(
          produce((state) => {
            const stateWords: Word[] = state.globalWords;
            const currentWordsMap = stateWords.reduce<Record<string, Word>>((prev, curr) => {
              return {
                ...prev,
                [curr.name]: curr,
              };
            }, {});
            const newWordsMap = words.reduce<Record<string, Word>>((prev, curr) => {
              return {
                ...prev,
                [curr.name]: curr,
              };
            }, {});

            state.globalWords = Object.values({
              ...currentWordsMap,
              ...newWordsMap,
            });
          }),
        );
      },
      explainText: [],
      setExplainText: (text) => {
        set(
          produce((state) => {
            state.explainText = text;
          }),
        );
      },
      setIsPersonalLesson: (isPersonalLesson) => {
        set(
          produce((state) => {
            state.isPersonalLesson = isPersonalLesson;
          }),
        );
      },
      setRPCUser: (user) => {
        set(
          produce((state) => {
            state.authUser = user;
          }),
        );
      },
      setSession: (session) => {
        set(
          produce((state) => {
            state.session = session;
          }),
        );
      },
      resetPersonalLessons: () => {
        set(
          produce((state) => {
            state.personalLessonsWords = {};
            state.personalLessonsSentences = {};
          }),
        );
      },
      setPersonalLessonsWords: (lesson, words) => {
        set(
          produce((state) => {
            state.personalLessonsWords = {
              ...state.personalLessonsWords,
              [lesson]: words,
            };
          }),
        );
      },
      personalLessonsSentences: {},
      setPersonalLessonsSentences: (lesson, sentences) => {
        set(
          produce((state) => {
            state.personalLessonsSentences = {
              ...state.personalLessonsSentences,
              [lesson]: sentences,
            };
          }),
        );
      },
      setEnglishGrammarCourseSliderPositions: (sliderId, index) => {
        set(
          produce((state) => {
            if (state.englishGrammarCourseSliderPositions[sliderId] !== index) {
              state.englishGrammarCourseSliderPositions = {
                ...state.englishGrammarCourseSliderPositions,
                [sliderId]: index,
              };
            }
          }),
        );
      },
    }),
    {
      name: "lessons-storage",
    },
  ),
);

// useMaterialStore.subscribe((state, prevState) => {
//   const stateWordsLength = Object.values(state.words).length;
//   const prevStateWordsLength = Object.values(prevState.words).length;

//   if (stateWordsLength !== prevStateWordsLength) {
//     const words = Object.values(state.words).flat();

//     state.wordsMap = words.reduce(
//       (prevWords, word) => ({
//         ...prevWords,
//         [word.word]: word,
//       }),
//       {},
//     );
//   }
// });

useLessonsStore.subscribe(async (state, prevState) => {
  // if (
  //   (state.currentLessonId !== prevState.currentLessonId ||
  //     Object.keys(prevState.lessons).length === 0) &&
  //   state.lessons
  // ) {
  // }
});
