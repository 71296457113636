import convertCoordinatesNumbersToWords from "../../../../../../../../common/convertCoordinatesNumbersToWords";

const ball2Instructions = (xOffset: number, yOffset: number): string[][] => [
  [
    `We need to draw ten different shapes. Let's draw the first shape. It contains five lines. Mark a dot at ${convertCoordinatesNumbersToWords(
      [2 + xOffset, 1 + yOffset],
    )}, ${convertCoordinatesNumbersToWords([
      2 + xOffset,
      3 + yOffset,
    ])}, ${convertCoordinatesNumbersToWords([
      6 + xOffset,
      5 + yOffset,
    ])}, ${convertCoordinatesNumbersToWords([
      11 + xOffset,
      6 + yOffset,
    ])} and ${convertCoordinatesNumbersToWords([16 + xOffset, 1 + yOffset], true)} Make a shape.`,
  ],
  [
    `Let's draw the second shape. It also contains five lines. Mark a dot at ${convertCoordinatesNumbersToWords(
      [2 + xOffset, 5 + yOffset],
    )}, ${convertCoordinatesNumbersToWords([
      3 + xOffset,
      15 + yOffset,
    ])}, ${convertCoordinatesNumbersToWords([
      6 + xOffset,
      14 + yOffset,
    ])}, ${convertCoordinatesNumbersToWords([
      7 + xOffset,
      9 + yOffset,
    ])} and ${convertCoordinatesNumbersToWords([5 + xOffset, 5 + yOffset], true)} Make a shape.`,
  ],
  [
    `Let's draw the third shape. It also has five lines. Mark a dot at ${convertCoordinatesNumbersToWords(
      [20 + xOffset, 1 + yOffset],
    )}, ${convertCoordinatesNumbersToWords([
      22 + xOffset,
      3 + yOffset,
    ])}, ${convertCoordinatesNumbersToWords([
      21 + xOffset,
      6 + yOffset,
    ])}, ${convertCoordinatesNumbersToWords([
      18 + xOffset,
      10 + yOffset,
    ])} and ${convertCoordinatesNumbersToWords([15 + xOffset, 10 + yOffset], true)} Make a shape.`,
  ],
  [
    `Let's draw the fourth shape. It also has five lines. Mark a dot at ${convertCoordinatesNumbersToWords(
      [16 + xOffset, 11 + yOffset],
    )}, ${convertCoordinatesNumbersToWords([
      14 + xOffset,
      13 + yOffset,
    ])}, ${convertCoordinatesNumbersToWords([
      15 + xOffset,
      17 + yOffset,
    ])}, ${convertCoordinatesNumbersToWords([
      17 + xOffset,
      20 + yOffset,
    ])} and ${convertCoordinatesNumbersToWords([20 + xOffset, 19 + yOffset], true)} Make a shape.`,
  ],
  [
    `Let's draw the fifth shape. It also has five lines. Mark a dot at ${convertCoordinatesNumbersToWords(
      [26 + xOffset, 19 + yOffset],
    )}, ${convertCoordinatesNumbersToWords([
      27 + xOffset,
      21 + yOffset,
    ])}, ${convertCoordinatesNumbersToWords([
      29 + xOffset,
      15 + yOffset,
    ])}, ${convertCoordinatesNumbersToWords([
      32 + xOffset,
      13 + yOffset,
    ])} and ${convertCoordinatesNumbersToWords([34 + xOffset, 14 + yOffset], true)} Make a shape.`,
  ],
  [
    `Let's draw the sixth shape. It also has five lines. Mark a dot at ${convertCoordinatesNumbersToWords(
      [21 + xOffset, 20 + yOffset],
    )}, ${convertCoordinatesNumbersToWords([
      17 + xOffset,
      21 + yOffset,
    ])}, ${convertCoordinatesNumbersToWords([
      16 + xOffset,
      24 + yOffset,
    ])}, ${convertCoordinatesNumbersToWords([
      27 + xOffset,
      24 + yOffset,
    ])} and ${convertCoordinatesNumbersToWords([26 + xOffset, 22 + yOffset], true)} Make a shape.`,
  ],
  [
    `Let's draw the seventh shape. This shape has six lines. Mark a dot at ${convertCoordinatesNumbersToWords(
      [7 + xOffset, 15 + yOffset],
    )}, ${convertCoordinatesNumbersToWords([
      3 + xOffset,
      16 + yOffset,
    ])}, ${convertCoordinatesNumbersToWords([
      2 + xOffset,
      21 + yOffset,
    ])}, ${convertCoordinatesNumbersToWords([
      6 + xOffset,
      24 + yOffset,
    ])}, ${convertCoordinatesNumbersToWords([
      10 + xOffset,
      23 + yOffset,
    ])} and ${convertCoordinatesNumbersToWords([11 + xOffset, 18 + yOffset], true)} Make a shape.`,
  ],
  [
    `Let's draw the eighth shape. This shape has six lines. Mark a dot at ${convertCoordinatesNumbersToWords(
      [20 + xOffset, 8 + yOffset],
    )}, ${convertCoordinatesNumbersToWords([
      19 + xOffset,
      13 + yOffset,
    ])}, ${convertCoordinatesNumbersToWords([
      23 + xOffset,
      16 + yOffset,
    ])}, ${convertCoordinatesNumbersToWords([
      25 + xOffset,
      7 + yOffset,
    ])}, ${convertCoordinatesNumbersToWords([
      28 + xOffset,
      10 + yOffset,
    ])} and ${convertCoordinatesNumbersToWords([28 + xOffset, 15 + yOffset], true)} Make a shape.`,
  ],

  // ninth
  [
    `Let's draw the ninth shape. This shape has six lines. Mark a dot at ${convertCoordinatesNumbersToWords(
      [12 + xOffset, 6 + yOffset],
    )}, ${convertCoordinatesNumbersToWords([
      8 + xOffset,
      7 + yOffset,
    ])}, ${convertCoordinatesNumbersToWords([
      14 + xOffset,
      9 + yOffset,
    ])}, ${convertCoordinatesNumbersToWords([
      13 + xOffset,
      14 + yOffset,
    ])}, ${convertCoordinatesNumbersToWords([
      10 + xOffset,
      16 + yOffset,
    ])} and ${convertCoordinatesNumbersToWords([8 + xOffset, 12 + yOffset], true)} Make a shape.`,
  ],
  [
    `Let's draw the last tenth shape. This shape has six lines. Mark a dot at ${convertCoordinatesNumbersToWords(
      [24 + xOffset, 2 + yOffset],
    )}, ${convertCoordinatesNumbersToWords([
      23 + xOffset,
      5 + yOffset,
    ])}, ${convertCoordinatesNumbersToWords([
      27 + xOffset,
      7 + yOffset,
    ])}, ${convertCoordinatesNumbersToWords([
      29 + xOffset,
      1 + yOffset,
    ])}, ${convertCoordinatesNumbersToWords([
      33 + xOffset,
      3 + yOffset,
    ])} and ${convertCoordinatesNumbersToWords([32 + xOffset, 6 + yOffset], true)} Make a shape.`,
  ],

  [`Cut it out. Draw an arrow on each shape. Write the word "light" on each shape.`],
];

export default ball2Instructions;
