import { Typography } from "@mui/material";
import supabaseClient from "../../../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../../../store/lessonsStore";
import Box from "../../../Unknown/Box";
import { useMemo, useState } from "react";
import ItalicTitle from "../../../Title/ItalicTitle";
import LoadingButton from "@mui/lab/LoadingButton";
import { format } from "date-fns";

export type Rule = {
  id: number;
  question: string;
  answer: string;
  lesson: number;
};
const getRules = (rules: Omit<Rule, "id">[]): Rule[] => {
  return rules.map((rule, index) => {
    return {
      id: index,
      ...rule,
    };
  });
};

function splitByQuotes(str: string) {
  const result = [];
  let currentWord = "";
  let beforeOrLastWords = "";
  let insideQuotes = false;

  for (let i = 0; i < str.length; i++) {
    const char = str[i];

    if (char === "'") {
      insideQuotes = !insideQuotes;
      // currentWord += char; // Add the quote to currentWord
      if (beforeOrLastWords) {
        result.push(beforeOrLastWords);
        beforeOrLastWords = "";
      }
    } else if (!insideQuotes) {
      beforeOrLastWords += char;
      if (currentWord) {
        result.push(currentWord); // Push the word to the result array
        currentWord = ""; // Reset currentWord
      }
    } else {
      currentWord += char; // Add the character to currentWord
    }
  }

  // Add the last word if it exists
  if (currentWord) {
    result.push(currentWord);
  }
  if (beforeOrLastWords) {
    result.push(beforeOrLastWords);
  }

  return result;
}

const highlight = (text: string, fontWeight?: number, color?: string) => {
  return splitByQuotes(text).map((part, index) => {
    if (index % 2 !== 0) {
      return (
        <ItalicTitle
          key={index}
          // fontWeight={fontWeight}
          text={part}
          color="green"
          size={18}
          isItalic={false}
          fontFamily="Roboto"
        />
      );
    }
    return (
      <ItalicTitle
        key={index}
        size={18}
        color={color || "black"}
        // fontWeight={fontWeight}
        isItalic={false}
        text={part}
      />
    );
  });
};

export const rules = getRules([]);

export type Lesson = {
  lesson: number;
  letters: string[];
  storiesIds: { id: number; title: string }[];
  words: string[];
  adultWords: string[];
  syllables: string[];
};

const words2 = [
  "one",
  "ten",
  "not",
  "finish",
  "bank",
  "talent",
  "tennis",
  "note",
  "notebook",
  "notes",
  "hello",
  "lone",
  "tone",
];
const words3 = [
  "mat",
  "mom",
  "mark",
  "film",
  "storm",
  "semester",
  "marker",
  "monster",
  "markers",
  "monitor",
  "films",
  "marks",
  "hostel",
];
const words4 = [
  "I",
  "it",
  "am",
  "timer",
  "time",
  "biker",
  "bike",
  "bikes",
  "dollar",
  "dollars",
  "doll",
  "dolls",
  "salad",
  "fire",
  // "hide",
  "site",
  "like",
  "likes",
  // "kite",
  // "kites",
  "nine",
  "line",
  "lines",
  "lime",
  "limes",
  // mile
  // life
  // five
  // mike
  // rice
  // wife
  // wise
  // tiger
  // bible
];
const words5 = ["nose", "visit", "video", "vitamin", "vitamins", "five", "seven", "have"];
const words6 = [
  "see",
  "tree",
  "trees",
  "sheet",
  "sheets",
  "tiger",
  "tigers",
  "and",
  "need",
  "red",
  "good",
  "big",
  "dog",
  "small",
];
const words7 = [
  "y",
  "yellow",
  "we",
  "yes",
  "no",
  "two",
  "my",
  "me",
  "you",
  "for",
  // "day",
  "website",
  // "mommy", "Mary", "hobby",
];
const words8 = [
  "on",
  "off",
  "bus",
  "understand",
  "butter",
  "tunnel",
  "tunnels",
  "us",
  // "run",
  "buy",
];
const words9 = [
  "left",
  "step",
  "up",
  "next",
  "letter",
  "plus",
  "table",
  "horizontal",
  "start",
  "finish",
  "dot",
  "dots",
  "mark",
  "at",
  "draw",
  "between",
  "sun",
  "sky",
  "the",
  "cat",
  "car",
  "cats",
  "camera",
  "cameras",
  "scooter",
  "doctor",
  "hose",
];

export const learnToRead: Record<string, string[][]> = {
  1: [
    ["bet", "heh", "ref", "let", "set"],
    ["test", "best", "felt", "fell", "less"],
    ["lab", "tab", "fat", "hat"],
    ["flat", "last", "mask", "task", "bask", "fast"],
    ["art", "far", "tar", "star"],
    ["tart", "part", "mart", "dart", "bark", "lark", "start"],
    ["fall", "ball", "tall", "hall"],
    ["short", "rash", "hash", "brash", "fresh", "flesh", "shark", "fork"],
    ["he", "be", "she"],
    ["lift", "filter", "sister"],
    ["market", "basket", "basketball"],
    ["book", "roof", "foot", "tool", "football"],
    ["Bill", "Bob"],
    ["darts", "tabs", "labs", "fats", "balls", "stall", "halls", "is"],
    ["he is tall", "she is tall", "it is short", "Bob is short", "Bill is short"],
    ["it is fresh", "test is short", "(моя) sister is short", "(моя) sister is tall"],
    ["is he tall?", "is she tall?", "is it short?", "is Bob short?", "is Bill short?"],
    ["is it fresh?", "is (цей) test short?", "is (моя) sister short?", "is (моя) sister tall?"],
  ],
  2: [words2],
  3: [words3],
  4: [
    words4,
    ["I am short.", "I am tall.", "I like basketball.", "I like football.", "I like tennis."],
  ],
  5: [
    words5,
    [
      "I have one book.",
      "I have have seven books.",
      "I have one notebook.",
      "I have one sister.",
      "I have darts.",
      "I have five dollars.",
      "I have one doll.",
      "I have five dolls.",
      // "I have one live.",
      "I have seven limes.",
      "I have one bike.",
      "I have one marker.",
      "I have nine markers.",
      "I have one mat.",
    ],
  ],
  6: [
    words6,
    [
      "I am good.",
      "I am ok.",
      "I see a dog.",
      "I see a tree.",
      "I see a red line.",
      "It is a good dog.",
      "It is a good video.",
      "It is a good film.",
      "It is a big test.",
      "It is a big dog.",
      "It is a big book.",
      "It is a big market.",
      "It is a big basket.",
      "It is a big monster.",
      "It is a big bike.",
      "It is a big doll.",
      "It is a small market.",
      "It is a small basket.",
      "It is a short film.",
      "I have a big notebook.",
      "I have a sheet.",
      "I need a sheet.",
      "I see five trees.",
      "I need nine sheets",
      "I see seven lines.",
      "I see a short tree.",
      "I see a tall tree.",
      "I see a tall and short tree.",
      "I need a sheet and a marker.",
      "I need a book and nine markers.",
    ],
  ],
  7: [
    words7,
    [
      "It is a dog. It is my dog.",
      "It is not my dog.",
      "I have two dogs.",
      "It is a book. It is my book.",
      "It is not my book.",
      "I have two books.",
      "It is my sister.",
      "It is not my sister.",
      "I have two dolls.",
      "It is a doll. It is my doll.",
      "It is a bike. It is my bike.",
      "I have two bikes.",
      "It is a marker. It is my marker.",
      "It is not my marker.",
      "I have two yellow markers.",
      "I have a red and yellow marker.",
      "It is a notebook. It is my notebook.",
      "It is not my notebook.",
      "It is a mat. It is my mat.",
      "It is not my mat.",
      "My sister is tall.",
      "My sister is not tall.",
      "My sister is short.",
      "My sister is not short.",
      "It is for me.",
      "It is not for me.",
      "It is for you.",
      "It is not for you.",
      "I see you.",
      "You see me.",
    ],
    ["It is me."],
    [
      "We have two notebooks.",
      "We have a dog.",
      "We like basketball.",
      "We like football.",
      "We have yellow markers.",
      "We see five big trees.",
    ],
  ],
  8: [
    words8,
    [
      "A big bus.",
      "A small bus.",
      "I have a bus.",
      "I have a big bus.",
      "A big yellow bus.",
      "A big red bus.",
      "It is my bus.",
      "A big tunnel.",
      "A small tunnel.",
      "It is on.",
      "It is off.",
      "I like butter.",
      "It is for you.",
      "It is for me.",
      "I see a big red bus.",
    ],
    ["I buy butter for you.", "I buy butter for us.", "I buy butter."],
    ["You understand us.", "It is for us."],
    [
      "I understand it.",
      "We understand it.",
      "You understand it.",
      "I understand you.",
      "You understand me.",
      "We understand you.",
    ],
  ],
  9: [
    words9,
    [
      "I see the sun.",
      "The sun is big.",
      "I see a cat.",
      "I see a dog.",
      "I have a car.",
      "I have a cat.",
      "I have two cats.",
      "I have a dog.",
      "I have two dogs.",
      "I have a camera.",
      "I have two cameras.",
      "I have a big dog.",
      "I see a dog. The dog is big.",
      "It is my car.",
      "Connect the hose.",
    ],
    ["I have a scooter.", "I like coffee.", "He is a doctor."],
  ],
};
// "ok",
// "rocket",
// "hello",
// "jacket"

export const lessons: Record<string, Lesson> = {
  1: {
    lesson: 1,
    letters: ["i", "s", "b", "o", "t", "a", "l", "r", "h", "e", "k", "f"],
    storiesIds: [
      {
        id: 970,
        title: "Is Bob short or tall?",
      },
    ],
    syllables: [],
    words: [
      "hello",
      "start",
      "far",
      "ball",
      "tall",
      "short",
      "fresh",
      "hat",
      "lift",
      "mask",
      "football",
      "basket",
      "basketball",
      "ok",
      "test",
      "filter",
      // "rocket",
      "market",
      "is",
      "Bob",
      "Bill",
      "sister",
      "he",
      "she",
      // "roll",
      // "task",
      // "tests",
      // "baseball",
      // "laser",
      // "base",
      // "hotel",
      // "hate",
      // "like",
      // "bottle",
      // "site",
      // "hobbies",
      // "filters",
      // "robot",
      // "tennis racket"
      // "space rocket"
    ],
    adultWords: [
      "trailer",
      "risk",
      "fit",
      "boss",
      "stress",
      "battle",
      "starter",
      "socket",
      "ticket",
      // "sir",
      // "risks",
    ],
  },
  2: {
    lesson: 2,
    letters: ["n"],
    storiesIds: [],
    syllables: [],
    words: ["one", "ten", "not", "finish", "bank", "talent", "tennis", "note", "notes", "notebook"],
    adultWords: [],
  },
  3: {
    lesson: 3,
    letters: [],
    storiesIds: [],
    syllables: [],
    words: [
      "mat",
      "semester",
      "marker",
      "mark",
      "film",
      "monster",
      "storm",
      "markers",
      "monitor",
      "films",
      "mom",
      "marks",
      "hostel",
    ],
    adultWords: [],
  },
  4: {
    lesson: 4,
    letters: ["i (aɪ)"],
    storiesIds: [],
    syllables: [],
    words: words4,
    adultWords: [],
  },
  5: {
    lesson: 5,
    letters: ["v"],
    storiesIds: [],
    syllables: [],
    words: words5,
    adultWords: [],
  },
  6: {
    lesson: 6,
    letters: ["ee", "g"],
    storiesIds: [
      {
        id: 1915,
        title: "Big and small.",
      },
    ],
    syllables: [],
    words: words6,
    adultWords: [],
  },
  7: {
    lesson: 7,
    letters: ["ee"],
    storiesIds: [
      {
        id: 1931,
        title: "Y is for yo-yo.",
      },
    ],
    syllables: [],
    words: words7,
    adultWords: [],
  },
  8: {
    lesson: 8,
    letters: ["o", "u"],
    storiesIds: [
      {
        id: 1923,
        title: "O is for on and off.",
      },
    ],
    syllables: [],
    words: words8,
    adultWords: [],
  },
  9: {
    lesson: 9,
    letters: [],
    storiesIds: [
      {
        id: 1940,
        title: "Connect the hose.",
      },
    ],
    syllables: [],
    words: words9,
    adultWords: [],
  },
};

const CourseProgram: React.FC<{ rules: Rule[] }> = ({ rules: lessonRules }) => {
  const [loading, setLoading] = useState(false);
  const studentUser = useLessonsStore((state) => state.studentUser);
  const setStudentUser = useLessonsStore((state) => state.setStudentUser);
  // const userRules = useLessonsStore((state) => state.setStudentUser);

  const [studentRules, setStudentRules] = useState<string[]>([]);
  console.log("🚀 ~ studentRules:", studentRules);

  const getStudentRules = async () => {
    const [rule] = lessonRules;
    if (!studentUser) return;

    const { data: studentRules } = await supabaseClient
      .from("user_english_rules")
      .select()
      .in(
        "rule",
        lessonRules.map((r) => r.question),
      )
      .eq("user", studentUser.id);

    console.log("🚀 ~ studentRules:", studentRules);
    if (studentRules) setStudentRules(studentRules.map((r) => r.rule));
  };

  useMountedWaitAsyncEffect(async () => {
    await getStudentRules();
  }, []);

  return (
    <Box>
      {Boolean(lessonRules.length) && (
        <Box>
          <Typography>
            {" "}
            Lesson ({studentUser?.english_lesson}) rules ({lessonRules.length})
          </Typography>
          <Box>
            {lessonRules.map((r, index) => {
              return (
                <Box key={index} mb={4}>
                  {!studentRules.includes(r.question) && (
                    <LoadingButton
                      loading={loading}
                      variant="outlined"
                      onClick={async () => {
                        if (!studentUser) return;
                        setLoading(true);

                        const addNewUserRuleResult = await supabaseClient
                          .from("user_english_rules")
                          .insert({
                            id: `${studentUser.id}-${r.question}`,
                            repeat: new Date().toISOString(),
                            rule: r.question,
                            date: format(new Date(), "yyyy-MM-dd"),
                            user: studentUser.id,
                          });
                        console.log("🚀 ~ addNewUserRuleResult:", addNewUserRuleResult);
                        await getStudentRules();

                        setLoading(false);
                      }}
                    >
                      Add
                    </LoadingButton>
                  )}
                  <Typography
                    sx={{
                      display: "block",
                      borderLeft: !studentRules.includes(r.question) ? "none" : "3px green solid",
                      paddingLeft: !studentRules.includes(r.question) ? "0px" : "30px",
                    }}
                  >
                    {highlight(r.question)}
                  </Typography>
                  {!studentRules.includes(r.question) && (
                    <Typography
                      sx={{
                        pl: 4,
                        borderLeft: "3px solid",
                      }}
                    >
                      {highlight(r.answer, 400, "#3e2863")}
                    </Typography>
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CourseProgram;
