import convertCoordinatesNumbersToWords from "../../../../../../../../common/convertCoordinatesNumbersToWords";

const ball1Instructions = (xOffset: number, yOffset: number): string[][] => [
  [
    `Let's mark four dots. Mark dots at ${convertCoordinatesNumbersToWords([
      15 + xOffset,
      2 + yOffset,
    ])}, ${convertCoordinatesNumbersToWords([12 + xOffset, 3 + yOffset])},
    ${convertCoordinatesNumbersToWords([11 + xOffset, 8 + yOffset])}
     and ${convertCoordinatesNumbersToWords(
       [13 + xOffset, 9 + yOffset],
       true,
     )} Draw four lines to form a closed shape.`,
  ],
  [
    `So, we have one shape. Let's draw the next one. We need to mark another four dots. Mark dots at ${convertCoordinatesNumbersToWords(
      [9 + xOffset, 1 + yOffset],
    )}, ${convertCoordinatesNumbersToWords([2 + xOffset, 2 + yOffset])},
    ${convertCoordinatesNumbersToWords([9 + xOffset, 3 + yOffset])}
     and ${convertCoordinatesNumbersToWords(
       [4 + xOffset, 4 + yOffset],
       true,
     )} Draw four lines to form a closed shape.`,
  ],
  [
    `So, we have two shapes. Let's draw the next one. We need to mark another five dots. Mark dots at ${convertCoordinatesNumbersToWords(
      [9 + xOffset, 7 + yOffset],
    )}, ${convertCoordinatesNumbersToWords([5 + xOffset, 5 + yOffset])},
    ${convertCoordinatesNumbersToWords([2 + xOffset, 9 + yOffset])},
    ${convertCoordinatesNumbersToWords([4 + xOffset, 13 + yOffset])}
     and ${convertCoordinatesNumbersToWords(
       [8 + xOffset, 12 + yOffset],
       true,
     )} Draw five lines to form a closed shape.`,
  ],
  [
    `So, we have three shapes now. Let's draw the next one. We need to mark another five dots. Mark dots at ${convertCoordinatesNumbersToWords(
      [10 + xOffset, 9 + yOffset],
    )}, ${convertCoordinatesNumbersToWords([15 + xOffset, 10 + yOffset])},
    ${convertCoordinatesNumbersToWords([9 + xOffset, 12 + yOffset])},
    ${convertCoordinatesNumbersToWords([12 + xOffset, 15 + yOffset])}
     and ${convertCoordinatesNumbersToWords(
       [16 + xOffset, 14 + yOffset],
       true,
     )} Draw five lines to form a closed shape.`,
  ],
  [
    `So, we have four shapes now. Let's draw the next one. We need to mark another five dots. Mark dots at ${convertCoordinatesNumbersToWords(
      [3 + xOffset, 14 + yOffset],
    )}, ${convertCoordinatesNumbersToWords([2 + xOffset, 19 + yOffset])},
    ${convertCoordinatesNumbersToWords([7 + xOffset, 21 + yOffset])},
    ${convertCoordinatesNumbersToWords([10 + xOffset, 17 + yOffset])}
     and ${convertCoordinatesNumbersToWords(
       [8 + xOffset, 13 + yOffset],
       true,
     )} Draw five lines to form a closed shape.`,
  ],
  [
    `So, we have five shapes now. Let's draw the last one. We need to mark another four dots. Mark dots at ${convertCoordinatesNumbersToWords(
      [15 + xOffset, 15 + yOffset],
    )}, ${convertCoordinatesNumbersToWords([12 + xOffset, 16 + yOffset])},
    ${convertCoordinatesNumbersToWords([18 + xOffset, 21 + yOffset])},
     and ${convertCoordinatesNumbersToWords(
       [19 + xOffset, 18 + yOffset],
       true,
     )} Draw four lines to form a closed shape.`,
  ],
  [`Cut it out. Draw an arrow on each shape. Write the word "dark" on each shape.`],
  // [`Connect all lines with vertical lines to form a closed shape.`],
];

export default ball1Instructions;
