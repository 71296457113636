import LoadingButton from "@mui/lab/LoadingButton";
import CopyNewWordsForGemini from "../Admin/CopyNewWordsForGemini";
import { skipWords } from "../../common/constants";
import checkNewWords from "../../common/checkNewWords";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import HStack from "../Unknown/HStack";
import supabaseClient from "../../common/supabaseClient";
import { Word } from "../../types/supabase";
import VStack from "../Unknown/VStack";
import AdminComponentIndicator from "../Unknown/AdminComponentIndicator";

const AdminVerifyWordsAndCopyToAi: React.FC<{
  words: string[];
  isTrim: boolean;
}> = ({ words, isTrim }) => {
  const [newWords, setNewWords] = useState<string[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dbWords, setDbWords] = useState<Word[]>([]);

  return (
    <HStack>
      <AdminComponentIndicator title="AdminVerifyWordsAndCopyToAi" />
      {newWords && !!newWords.length && <CopyNewWordsForGemini words={newWords} />}
      <LoadingButton
        loading={isLoading}
        variant="contained"
        color={newWords && !newWords.length ? "primary" : "error"}
        onClick={async () => {
          setIsLoading(true);
          try {
            const { words: returnWords, newWords } = await checkNewWords(words, isTrim);
            const { data } = await supabaseClient.from("words").select().in("name", words);
            if (data) setDbWords(data);
            setNewWords(newWords.filter((w) => !skipWords.includes(w)));
            console.log("🚀 ~ returnWords:", returnWords);
          } catch (err: any) {
            console.log("🚀 ~ err:", err);
            alert(err.message);
          }

          setIsLoading(false);
          console.log("🚀 ~ newWords:", newWords);
        }}
      >
        {newWords && !newWords.length ? "verified 👌" : "verify"}
      </LoadingButton>
      <VStack alignItems="flex-start">
        {dbWords.map((w) => {
          return (
            <Box>
              <strong>{w.name}</strong>{" "}
              <Box component="span" sx={{ color: "red" }}>
                {w.transcription}:
              </Box>{" "}
              {w.translation}
            </Box>
          );
        })}
      </VStack>
      {/* {newWords && !newWords.length && <Typography fontSize={35}>👌</Typography>} */}
    </HStack>
  );
};

export default AdminVerifyWordsAndCopyToAi;
