const upperT = (step: number) => (
  <svg
    width="36.156cm"
    height="26.138cm"
    preserveAspectRatio="xMidYMid"
    version="1.0"
    viewBox="0 0 1024.9 740.92"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" stroke="#000" stroke-width="3.9901">
      <path d="m129.5 240.49 0.80421-0.00527v167.96" />
      <path d="m16.674 241.23 112.83-0.73943" />
      <path d="m15.865 722.25v-481.02l0.8086-0.0053" />
      <path d="m130.31 723.8v0.38672l-114.44-0.75v-1.1894" />
      <path d="m130.31 553.94v169.87" />
      <path d="m582.41 553.25-452.1-0.74894v1.4312" />
      <path d="m583.04 410.74 1e-5 142.51-0.63283-0.00106" />
      <path d="m130.31 408.44v1.4766l452.73 0.75-1e-5 0.07763" />
    </g>
  </svg>
);

export default upperT;

// <svg
//   width="23.156cm"
//   height="21.138cm"
//   preserveAspectRatio="xMidYMid"
//   version="1.0"
//   viewBox="0 0 656.39 599.19"
//   xmlns="http://www.w3.org/2000/svg"
// >
//   <g fill="none" stroke="#000" stroke-width="3.9282">
//     {(showFullPath || step > 0) && <path d="m495.89 127.64-2.8e-4 0.04248-169.41-0.74898" />}
//     {(showFullPath || step > 1) && <path d="m184.46 127.2v-0.271l-170.39 0.75-0.002077-0.3164" />}
//     {(showFullPath || step > 2) && <path d="m13.318 13.508-2.69e-4 -0.041018h483.24" />}
//     {(showFullPath || step > 3) && <path d="m326.25 579.37v1.333l-141.79 0.75v-0.18019" />}

//     {(showFullPath || step > 4) && (
//       <>
//         <path d="m184.46 581.27v-454.07" />
//         <path d="m326.48 126.93-0.23145-0.00102v452.44" />
//         <path d="m496.56 13.467h0.07911l-0.74972 114.17" />
//         <path d="m14.066 127.36-0.74765-113.86" />
//       </>
//     )}
//   </g>
// </svg>
