import convertCoordinatesNumbersToWords from "../../../../../../../../common/convertCoordinatesNumbersToWords";

const ball3Instructions = (xOffset: number, yOffset: number): string[][] => [
  [
    `Let's draw some lines. Mark dots at ${convertCoordinatesNumbersToWords([
      6 + xOffset,
      13 + yOffset,
    ])}
     and ${convertCoordinatesNumbersToWords(
       [5 + xOffset, 18 + yOffset],
       true,
     )} Draw a line between these two dots.`,
  ],
  [
    `Let's draw the second line. Mark dots at ${convertCoordinatesNumbersToWords([
      8 + xOffset,
      2 + yOffset,
    ])}
     and ${convertCoordinatesNumbersToWords(
       [7 + xOffset, 5 + yOffset],
       true,
     )} Draw a line between these two dots.`,
  ],
  [
    `Let's draw the third line. Mark dots at ${convertCoordinatesNumbersToWords([
      13 + xOffset,
      1 + yOffset,
    ])}
     and ${convertCoordinatesNumbersToWords(
       [17 + xOffset, 3 + yOffset],
       true,
     )} Draw a line between these two dots.`,
  ],
  [
    `Let's draw the forth line. Mark dots at ${convertCoordinatesNumbersToWords([
      22 + xOffset,
      4 + yOffset,
    ])}
     and ${convertCoordinatesNumbersToWords(
       [23 + xOffset, 3 + yOffset],
       true,
     )} Draw a line between these two dots.`,
  ],
  [
    `Let's draw the fifth line. Mark dots at ${convertCoordinatesNumbersToWords([
      23 + xOffset,
      8 + yOffset,
    ])}
     and ${convertCoordinatesNumbersToWords(
       [25 + xOffset, 11 + yOffset],
       true,
     )} Draw a line between these two dots.`,
  ],
  [
    `Let's draw the sixth line. Mark dots at ${convertCoordinatesNumbersToWords([
      19 + xOffset,
      9 + yOffset,
    ])}
     and ${convertCoordinatesNumbersToWords(
       [19 + xOffset, 14 + yOffset],
       true,
     )} Draw a line between these two dots.`,
  ],
  [
    `Let's draw the seventh line. Mark dots at ${convertCoordinatesNumbersToWords([
      10 + xOffset,
      12 + yOffset,
    ])}
     and ${convertCoordinatesNumbersToWords(
       [14 + xOffset, 15 + yOffset],
       true,
     )} Draw a line between these two dots.`,
  ],
  [
    `Let's draw the eight line. Mark dots at ${convertCoordinatesNumbersToWords([
      24 + xOffset,
      16 + yOffset,
    ])}
     and ${convertCoordinatesNumbersToWords(
       [21 + xOffset, 18 + yOffset],
       true,
     )} Draw a line between these two dots.`,
  ],
  [
    `Let's draw the ninth line. Mark dots at ${convertCoordinatesNumbersToWords([
      18 + xOffset,
      22 + yOffset,
    ])}
     and ${convertCoordinatesNumbersToWords(
       [19 + xOffset, 24 + yOffset],
       true,
     )} Draw a line between these two dots.`,
  ],
  [
    `Let's draw the tenth line. Mark dots at ${convertCoordinatesNumbersToWords([
      13 + xOffset,
      20 + yOffset,
    ])}
     and ${convertCoordinatesNumbersToWords(
       [9 + xOffset, 21 + yOffset],
       true,
     )} Draw a line between these two dots.`,
  ],
  [
    `Let's draw the eleventh line. Mark dots at ${convertCoordinatesNumbersToWords([
      16 + xOffset,
      6 + yOffset,
    ])}
     and ${convertCoordinatesNumbersToWords(
       [11 + xOffset, 7 + yOffset],
       true,
     )} Draw a line between these two dots.`,
  ],
  [
    `Let's draw the last twelfth line. Mark dots at ${convertCoordinatesNumbersToWords([
      1 + xOffset,
      9 + yOffset,
    ])}
     and ${convertCoordinatesNumbersToWords(
       [4 + xOffset, 9 + yOffset],
       true,
     )} Draw a line between these two dots.`,
  ],
  [
    `And the last element we need is just a dot. This dot must be at ${convertCoordinatesNumbersToWords(
      [14 + xOffset, 11 + yOffset],
      true,
    )}`,
  ],

  // [`Connect all lines with vertical lines to form a closed shape.`],
];

export default ball3Instructions;
