import letterThingsInstructions from "./letterThings/lettersInstructions";
import lettersInstructions from "./letters/lettersInstructions";

const instructions = (letter: string, xOffset: number, yOffset: number): string[][] => {
  return (
    {
      ...lettersInstructions(letter, xOffset, yOffset),
      ...letterThingsInstructions(letter, xOffset, yOffset),
    }[letter] || []
  );
};

export default instructions;
