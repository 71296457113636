const ball2 = (step: number) => (
  <svg
    width="36.156cm"
    height="26.138cm"
    preserveAspectRatio="xMidYMid"
    version="1.0"
    viewBox="0 0 1024.9 740.92"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" stroke="#000" stroke-width="2.8346">
      <>
        <path d="m70.398 298.01 0.1016-1.0107 86.25 29.25 0.13707 0.66077" />
        <path d="m42.062 579.88 28.336-281.87" />
        <path d="m128.52 579.96-0.27499 0.53927h-86.25l0.062298-0.6197" />
        <path d="m185.77 467.7-57.245 112.26" />
        <path d="m156.89 326.91 29.113 140.34-0.23005 0.45113" />
      </>
      <>
        <path d="m411.72 440.24-0.72364 0.0128 141.75 254.25 0.65598-0.63895" />
        <path d="m495.34 438.76-83.613 1.4799" />
        <path d="m580.72 552.79-84.968-114.04-0.41308 0.00731" />
        <path d="m610.43 638.04-29.179-84.545-0.53161-0.71348" />
        <path d="m553.41 693.86 57.094-55.611-0.07073-0.20493" />
      </>

      <>
        <path d="m438.45 693.45c0.09852 0.0992 0.19706 0.1984 0.29559 0.29759l-396 1.5v-0.63135" />
        <path d="m295.78 552.99 1.2173-0.24474c48.451 45.565 94.591 93.52 141.45 140.7" />
        <path d="m42.846 638.2 112.4-56.951 140.53-28.255" />
        <path d="m42.75 694.62v-56.369l0.09642-0.04885" />
      </>

      <>
        <path d="m554.01 184.97 0.23985-0.47187-85.5-28.5-0.22856 0.33542" />
        <path d="m439.22 409.97 0.28164 0.27793 114.51-225.28" />
        <path d="m382.58 353.69-0.07925 0.3149 56.718 55.972" />
        <path d="m410.95 240.94-28.372 112.74" />
        <path d="m468.52 156.34-57.521 84.415-0.0483 0.1919" />
      </>

      <>
        <path d="m751.17 128.85 0.32529-0.60054 198.75 196.5-0.81556 0.42407" />
        <path d="m722.48 181.82 28.691-52.967" />
        <path d="m809 296.67-86.751-114.42 0.23416-0.43225" />
        <path d="m893.95 353.96-84.697-56.965-0.24889-0.32825" />
        <path d="m949.43 325.17-55.434 28.826-0.0526-0.03537" />
      </>

      <>
        <path d="m439.55 43.5-0.04834 1.16e-4 29.25 84.75" />
        <path d="m752.14 42.752-312.59 0.74783" />
        <path d="m723.28 99.227 29.725-56.477-0.85985 0.002058" />
        <path d="m579.97 155.87 0.52893 0.13134 142.5-56.25 0.27527-0.52301" />
        <path d="m468.75 128.25 111.22 27.619" />
      </>
      <>
        <path d="m297.67 211.85 0.08419 0.39567-114 84.75-0.83343-0.20974" />
        <path d="m267.56 71.198 0.19329 0.051543 29.916 140.6" />
        <path d="m154.63 41.722 0.62381-0.47196 112.31 29.948" />
        <path d="m41.318 127.83-0.068392-0.32968 113.38-85.778" />
        <path d="m70.728 268.56-0.22843-0.05749-29.182-140.67" />
        <path d="m182.92 296.79-112.19-28.233" />
      </>

      <>
        <path d="m693.51 524.79-139.26-29.043-0.077-0.36194" />
        <path d="m781.5 439.28 0.00458 0.21532-87 85.5-0.99165-0.20682" />
        <path d="m778.51 298.83 2.9884 140.45" />
        <path d="m639.96 268.71 138.54 29.794 0.00705 0.33106" />
        <path d="m525.02 354.17 113.98-85.67 0.95813 0.20605" />
        <path d="m554.17 495.39-29.923-140.64 0.7719-0.58018" />
      </>

      <>
        <path d="m270.96 267.89-0.20808-0.14491-60 114 0.01709 1.0825" />
        <path d="m354.8 326.49-0.04675-0.23879-83.792-58.355" />
        <path d="m382.33 468.25 0.16547-0.25492-27.703-141.51" />
        <path d="m326.84 553.46 0.16416 0.04212 55.335-85.245" />
        <path d="m213.4 524.35 113.44 29.106" />
        <path d="m210.77 382.83 2.2329 141.42 0.39714 0.1019" />
      </>

      <>
        <path d="m892.25 552.7 0.25038 0.05034 27.61 84.324" />
        <path d="m751.2 524.34 141.05 28.359" />
        <path d="m639.15 580.79 111.6-56.543 0.4512 0.09072" />
        <path d="m666.75 666-28.5-84.75 0.90112-0.45656" />
        <path d="m807.37 693.68-140.63-27.676" />
        <path d="m920.11 637.07 0.13954 0.42615-112.5 56.25-0.37491-0.0738" />
      </>
    </g>
  </svg>
);

export default ball2;
