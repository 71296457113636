import { memo, useEffect, useMemo, useState } from "react";
import { useLessonsStore } from "../../../../store/lessonsStore";
import Box from "../../Box";
import Checkbox from "../../Checkbox";
import Typography from "../../Typography";
import { Excalidraw } from "@excalidraw/excalidraw";
import { usePrevious } from "react-use";
import VStack from "../../VStack";
import Stories from "../../../Grammar/Stories";
import DrawingLetter from "../Drawing/DrawingLetter";
import ShareScreenMenu from "./ShareScreenMenu";
import DrawingBoard from "../DrawingBoard";

const DisplayScreenForStudent = () => {
  const editorText = useLessonsStore((state) => state.editorState);
  console.log("🚀 ~ editorText:", editorText);
  const setEditorText = useLessonsStore((state) => state.setEditorState);
  const drawingLocalElements = useLessonsStore((state) => state.drawingLocalElements);
  const setDrawingLocalElements = useLessonsStore((state) => state.setDrawingLocalElements);
  const excalidrawAPI = useLessonsStore((state) => state.excalidrawAPI);
  const setExcalidrawAPI = useLessonsStore((state) => state.setExcalidrawAPI);

  const displayCoordsForUser = useLessonsStore((state) => state.displayCoordsForUser);
  const setDisplayCoordsForUser = useLessonsStore((state) => state.setDisplayCoordsForUser);
  const displayMyScreenForUser = useLessonsStore((state) => state.displayMyScreenForUser);
  const setDisplayMyScreenForUser = useLessonsStore((state) => state.setDisplayMyScreenForUser);
  const localStorageVideo = useLessonsStore((state) => state.localStorageVideo);
  const setLocalStorageVideo = useLessonsStore((state) => state.setLocalStorageVideo);
  const shareScreenOptions = useLessonsStore((state) => state.shareScreenOptions);
  const setShareScreenOptions = useLessonsStore((state) => state.setShareScreenOptions);

  useEffect(() => {
    const interval =
      displayMyScreenForUser &&
      setInterval(() => {
        if (displayMyScreenForUser) {
          const editorText = localStorage.getItem("editorText");
          // const drawingElements = localStorage.getItem("drawingRealtimeElements");
          const localVideo = localStorage.getItem("localVideo");
          setLocalStorageVideo(localVideo ? JSON.parse(localVideo) : null);
          if (editorText) setEditorText(editorText);
          // if (drawingElements) setDrawingLocalElements(JSON.parse(drawingElements));
        }
      }, 500);

    if (!displayMyScreenForUser) {
      // localStorage.setItem("drawingRealtimeElements", JSON.stringify(drawingLocalElements));
      // localStorage.setItem("editorText", JSON.stringify(editorText));
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [displayMyScreenForUser]);

  // const drawingChnage = useMemo(
  //   () =>
  //     throttle((changeProps: any) => {
  //       const appState = excalidrawAPI?.getAppState();

  //       // const newDrawingStrokes = excalidrawAPI.getSceneElementsIncludingDeleted().filter(Boolean);

  //       if (appState && !appState?.draggingElement && !appState?.editingElement) {
  //         // pushToHistory();
  //         throttledSaveData(changeProps);
  //         throttledShareLocalData(changeProps);
  //       }
  //     }, 500),
  //   [throttledSaveData],
  // );

  useEffect(() => {
    if (excalidrawAPI) {
      excalidrawAPI.updateScene({
        elements: drawingLocalElements,
        appState: { viewBackgroundColor: "rgba(0, 0, 0, 0)" },
      });
    }
  }, [excalidrawAPI, drawingLocalElements]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setIsBoardTextAllowedByAdmin(event.target.checked);
    setDisplayMyScreenForUser(event.target.checked);
  };

  return (
    <>
      <ShareScreenMenu />
      {shareScreenOptions.drawingBoard && (
        <Box
          sx={{
            position: "relative",
            zIndex: 9999999,
            // top: 0,
            // left: 0,
            // zIndex: 99999999999999,
          }}
        >
          {shareScreenOptions.showCoords && (
            <Box
              sx={{
                // backgroundColor: "red",
                position: "absolute",
                zIndex: -1,
                width: "100vw",
                height: "100vw",
                // top: 100,
                pt: 30,
                pl: 10,
                left: "50%",
                transform: "translateX(-50%)",
                backgroundColor: "white",
              }}
            >
              <DrawingLetter onlyCoords />
            </Box>
          )}
          <Box
            sx={{
              width: "100vw",
              height: "100vh",
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 2,
            }}
          >
            {/* {shareScreenOptions.showCoords && (
            )} */}
            <DrawingBoard local transparent={shareScreenOptions.drawingBackgroundColor} />
          </Box>
        </Box>
      )}

      {displayMyScreenForUser && (
        <Box
          sx={{
            backgroundColor: shareScreenOptions.backgroundColor,
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            zIndex: 999,
          }}
        >
          {localStorageVideo && displayMyScreenForUser && (
            <VStack>
              <Stories
                stories={[localStorageVideo]}
                buttonLabel="відео"
                buttonSize="small"
                withoutDrawer
                withoutLink
                // isReadingVideo
              />
            </VStack>
          )}

          {displayMyScreenForUser && (
            <Box height="100vh" width="100vw" p={5}>
              <Box
                maxWidth={500}
                sx={{
                  position: "relative",
                  pt: 10,
                  left: "50%",
                  transform: "translateX(-50%)",
                  fontSize: 40,
                  "& p": {
                    my: 0,
                  },
                  "& h2": {
                    my: 0,
                  },
                  "& h1": {
                    my: 0,
                  },
                }}
                dangerouslySetInnerHTML={{ __html: editorText.replaceAll('"', "") }}
              />
              {/* {editorText} */}
              {/* <div
            // ref={containerRef}

            style={{
              width: "100vw",
              height: "100vh",
              position: "fixed",
              // opacity: Boolean(drawingLocalElements.length) ? 1 : 0,
              // top: 20,
              left: 0,
              zIndex: 99999,
              // pointerEvents: "none",
              // backgroundColor: "transparent",
            }}
            tabIndex={0}
            onClick={(e) => e.currentTarget.focus()}
          > */}
              {/* <Excalidraw
              excalidrawAPI={(api: any) => setExcalidrawAPI(api)}
              // viewModeEnabled
              // onChange={handleChange}
              // onChange={handleChange}
              initialData={{
                appState: { viewBackgroundColor: "transparent" }, // Set any color
              }}
              UIOptions={{
                canvasActions: {
                  changeViewBackgroundColor: false,
                  clearCanvas: false,
                  saveToActiveFile: false,
                  // changeViewBackground: false, // Забороняє зміну фону
                  // zoom: false, // Вимикає масштабування
                  // pan: false, // Забороняє панорамування
                },
              }}
            /> */}
              {/* </div> */}
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default memo(DisplayScreenForStudent);
