import supabaseClient from "../../../common/supabaseClient";
import { useLessonsStore } from "../../../store/lessonsStore";
import AdminComponentIndicator from "../../Unknown/AdminComponentIndicator";
import Button from "../../Unknown/Button";
import HStack from "../../Unknown/HStack";

// sync user words from english_user_lessons: words

const AdminSyncUserWords = () => {
  const studentUser = useLessonsStore((state) => state.studentUser);

  if (!studentUser) return null;

  return (
    <HStack alignItems="center">
      <AdminComponentIndicator title="AdminSyncUserWords" />
      <Button
        variant="contained"
        color="error"
        onClick={async () => {
          await supabaseClient.rpc("sync_user_words", {
            p_user_uuid: studentUser.uuid,
            p_user_name: studentUser.id,
          });
        }}
      >
        Sync user words to user-words table
      </Button>
    </HStack>
  );
};

export default AdminSyncUserWords;
