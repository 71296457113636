import MenuIcon from "@mui/icons-material/Menu";
import { Box, Button, Container, Divider, Drawer, Grid, IconButton, Paper } from "@mui/material";
import React, { PropsWithChildren, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import StickyBox from "react-sticky-box";
import { drawerWidth } from "../../../common/constants";
import supabaseClient from "../../../common/supabaseClient";
import { useLessonsStore } from "../../../store/lessonsStore";
import { DifferenceMenu } from "../../Difference/Page";
import Footer from "../../Unknown/Footer";
import HStack from "../../Unknown/HStack";
import Logo from "../../Unknown/Logo";
import { SideBarMainNavigation } from "../../Unknown/Navigation";
import VStack from "../../Unknown/VStack";
import WhiteBoxWrap from "../../Unknown/WhiteBoxWrap";
import SettingsDrawer from "../SettingsDrawer";

const MenuLayout: React.FC<
  PropsWithChildren & { withDifferenceMenu?: boolean; isDark?: boolean; isFluid?: boolean }
> = ({ children, withDifferenceMenu, isDark, isFluid }) => {
  const differenceMenu = useMemo(() => {
    if (!withDifferenceMenu) return null;
    return <DifferenceMenu handleDrawerClose={() => setMobileOpen(false)} />;
  }, [withDifferenceMenu]);

  const mainMenu = useMemo(() => {
    return (
      <WhiteBoxWrap isDark={isDark} py={2}>
        <SideBarMainNavigation />
      </WhiteBoxWrap>
    );
  }, [isDark]);

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const drawer = useMemo(
    () => (
      <Box>
        <Box py={2}>
          <Button to="/" component={Link}>
            <Logo fontSize={20} />
          </Button>
        </Box>
        <Divider />
        <SideBarMainNavigation />
        <VStack alignItems="flex-start" p={2}>
          <Divider />
          {differenceMenu}
        </VStack>
      </Box>
    ),
    [differenceMenu],
  );

  return (
    <Box
      minHeight="100vh"
      display="flex"
      flexDirection="column"
      sx={{
        backgroundColor: isDark ? "black" : "white",
      }}
    >
      <SettingsDrawer />
      <Box
        sx={{
          flex: "1",
        }}
      >
        <Container
          sx={{
            width: "100%",
            // ...(isFluid ? { maxWidth: "100vw", width: "100vw" } : {}),
            // backgroundColor: "red",
            // flex: "1",
            position: "relative",
            zIndex: 2,
            maxWidth: {
              lg: 1200,
              md: 900,
            },
          }}
        >
          <Paper
            sx={{
              px: 2,
              width: "100%",
              height: "48px",
              display: "flex",
              alignItems: "center",
              backgroundColor: isDark ? "black" : "white",
            }}
          >
            <HStack>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { md: isFluid ? "block" : "none" } }}
              >
                <MenuIcon
                  sx={{
                    color: isDark ? "white" : "black",
                  }}
                />
              </IconButton>
            </HStack>
            <Box
              sx={{
                position: "relative",
                textAlign: "left",
                left: 0,
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button to="/" component={Link}>
                {/* <Logo fontSize={20} isAccentWhite /> */}
                {!isDark && <Logo fontSize={20} />}
              </Button>
            </Box>
            {/* <hr /> */}
          </Paper>
        </Container>
        <Container
          sx={{
            width: "100%",
            maxWidth: {
              lg: 1200,
              md: 900,
            },
          }}
        >
          <Grid container my={0}>
            <Grid
              item
              md={3}
              display={{
                xs: "none",
                md: isFluid ? "none" : "block",
              }}
              position="relative"
              sx={{
                position: "relative",
                zIndex: 2,
              }}
            >
              <Drawer
                variant="temporary"
                open={mobileOpen}
                onTransitionEnd={handleDrawerTransitionEnd}
                onClose={handleDrawerClose}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                  display: { xs: "block", md: isFluid ? "block" : "none" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                  },
                }}
              >
                {drawer}
              </Drawer>

              <StickyBox offsetTop={0} offsetBottom={20}>
                <Box height="100%" display="flex" overflow="auto" flexDirection="column">
                  {mainMenu}
                  {differenceMenu}
                </Box>
              </StickyBox>
            </Grid>
            <Grid item xs={12} md={isFluid ? 12 : 9}>
              {children}
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};

export default MenuLayout;
