import { Link as MuiLink, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import getRandomGreetings from "../../../common/getRandomGreetings";
import supabaseClient from "../../../common/supabaseClient";
import useMountedWaitAsyncEffect from "../../../hooks/useMountedWaitAsyncEffect";
import { useLessonsStore } from "../../../store/lessonsStore";
import { EnglishLesson, EnglishStory, UserSentence, Word } from "../../../types/supabase";
import WordsCarousel from "../../Grammar/GrammarLesson/tabs/WordsCarousel";
import Stories from "../../Grammar/Stories";
import BuildWords from "../../LearnToRead/BuildWords";
import ItalicTitle from "../../Title/ItalicTitle";
import VStack from "../../Unknown/VStack";
import useGlobalWords from "../../hooks/getGlobalWords";
import useGlobalWordsMap from "../../hooks/useGlobalWordsMap";
import { Rule } from "../EnglishKidsLessonsScreen/CourseProgram";
import isAdmin from "../../../common/isAdmin";
import Rules from "./Rules";
import Loader from "../../Unknown/Loader";
import StudentReadWordsCarousel from "./StudentReadWordsCarousel";
import BuildSentences from "../../Grammar/GrammarLesson/tabs/BuildSentences";

// -- valmiki 19c7ac10-110d-43e3-ad86-5e425aef49a5
// -- dayanvita 86523831-4881-4b89-91b5-def185deba6e
// -- grammar-user 7dd6fd1b-a333-4fbf-98a7-245491416070
// -- readding-user 53ef5828-71ce-4fdb-bfa1-80d8c4ebd57b
// -- ira 592d3941-7376-4c08-83c7-a374b2da849c

const EnglishKidsLessonsLesson: React.FC = () => {
  const { date } = useParams();
  const authUser = useLessonsStore((state) => state.authUser);
  const [currentLesson, setCurrentLesson] = useState<EnglishLesson | null>(null);
  console.log("🚀 ~ currentLesson:", currentLesson);
  const setGlobalWords = useLessonsStore((state) => state.setGlobalWords);

  useMountedWaitAsyncEffect(async () => {
    if (!authUser || !date) return;

    const { data } = await supabaseClient
      .from("english_user_lessons")
      .select()
      .eq("date", date)
      .eq("user", authUser.id)
      .maybeSingle();

    let lesson = data;
    if (authUser.id === "tef2304" && !data) {
      const { data } = await supabaseClient
        .from("user_lessons")
        .select()
        .eq("date", date)
        .eq("user", authUser.id)
        .maybeSingle();
      lesson = data;
    }

    console.log("english_user_lessons data", data);

    if (lesson) setCurrentLesson(lesson);
  }, [authUser, date]);

  useMountedWaitAsyncEffect(async () => {
    if (!currentLesson?.words) return;

    const { data } = await supabaseClient
      .from("words")
      .select()
      .in("name", currentLesson.words)
      .returns<Word[]>();

    if (data) setGlobalWords(data);
  }, [currentLesson]);

  const options = useMemo(() => {
    return { words: currentLesson?.words || [] };
  }, [currentLesson]);

  const currentModuleWordsMap = useGlobalWordsMap(options);
  useGlobalWords(options);
  console.log("🚀 ~ currentModuleWordsMap:", currentModuleWordsMap);

  const wordsGreeting = useMemo(() => {
    return getRandomGreetings("Всі слова складені.");
  }, []);

  const [stories, setStories] = useState<EnglishStory[]>([]);
  console.log("🚀 ~ stories:", stories);

  useMountedWaitAsyncEffect(async () => {
    if (!currentLesson?.video_ids?.length) return;

    let stories = currentLesson.video_ids;
    console.log("🚀 ~ stories ids:", stories);
    if (!stories?.length) return;

    const { data } = await supabaseClient.from("english_verses").select().in("id", stories);

    if (data) setStories(data);
  }, [currentLesson]);

  const areThereStories = Boolean(currentLesson?.video_ids?.length);

  const lessonData = useLessonsStore((state) => state.lessonData);
  const setLessonData = useLessonsStore((state) => state.setLessonData);
  // const { sentences } = useMemo(() => {
  //   const initial = {
  // lessonAllWords: (lessonId && lessonData[lessonId]?.module?.words) || [],
  // lessonWriteWords: (lessonId && lessonData[lessonId]?.module?.write_words) || [],
  //     sentences: (currentLesson && lessonData[currentLesson.date]?.sentences) || [],
  //   };

  //   return initial;
  // }, [lessonData, currentLesson]);

  useMountedWaitAsyncEffect(async () => {
    if (!currentLesson?.sentence_ids?.length) return;

    let sentences;

    if (!lessonData[currentLesson.date]?.sentences) {
      ({ data: sentences } = await supabaseClient
        .from("sentences")
        .select()
        .in("id", currentLesson.sentence_ids));
    }

    if (sentences) {
      setLessonData({
        lessonId: currentLesson.date,
        sentences: sentences,
      } as any);
    }
  }, [currentLesson]);

  const [userSentences, setUserSentences] = useState<string[]>([]);
  console.log("🚀 ~ userSentences:", userSentences);

  // const getUserSentences = useCallback(async () => {
  //   if (!sentences.length) return;

  //   const { data: userExistingSentences } = await supabaseClient
  //     .from("user-sentences")
  //     .select("en")
  //     .in("id", Array.from(new Set(sentences.map((s) => `${authUser!.uuid}-${s.en}`))))
  //     .returns<UserSentence[]>();

  //   if (userExistingSentences) {
  //     setUserSentences(userExistingSentences.map(({ en }) => en));
  //   }
  // }, [sentences, authUser]);

  // useMountedWaitAsyncEffect(async () => {
  //   if (authUser) await getUserSentences();
  // }, [sentences]);

  const [lessonRules, setLessonRules] = useState<Rule[]>([]);
  console.log("🚀 ~ lessonRules:", lessonRules);

  const { sentences } = useMemo(() => {
    const initial = {
      // lessonAllWords: (lessonId && lessonData[lessonId]?.module?.words) || [],
      // lessonWriteWords: (lessonId && lessonData[lessonId]?.module?.write_words) || [],
      sentences: (currentLesson && lessonData[currentLesson.date]?.sentences) || [],
    };

    return initial;
  }, [lessonData, currentLesson]);

  useMountedWaitAsyncEffect(async () => {
    if (!authUser) return;

    const { data } = await supabaseClient
      .from("user_english_rules")
      .select("*, rule(*)")
      .eq("date", date)
      .eq("user", authUser.id);

    if (data) setLessonRules(data.map((r) => ({ ...r, ...r.rule })));
  }, [authUser]);

  console.log("🚀 ~ currentLesson:", currentLesson);
  if (!currentLesson) {
    return <Loader />;
  }

  if (!date)
    return (
      <VStack my={10}>
        <ItalicTitle size={30} text="Урок не знайдено" />
        <MuiLink color="secondary.dark" component={Link} to={`/english-lessons`}>
          <Typography>Усі уроки</Typography>
        </MuiLink>
      </VStack>
    );

  return (
    <VStack my={10}>
      <MuiLink color="secondary.dark" component={Link} to={`/english-lessons`}>
        <ItalicTitle size={20} text="Усі уроки" />
      </MuiLink>

      <VStack mt={10}>
        {currentLesson?.reading_words && (
          <StudentReadWordsCarousel words={currentLesson.reading_words || []} />
        )}
        {currentLesson?.words && <WordsCarousel words={currentLesson?.words || []} />}
        {currentLesson?.words && (
          <BuildWords
            curentModuleWordsMap={currentModuleWordsMap}
            wordsGreeting={wordsGreeting}
            moduleWords={currentLesson?.words || []}
            wordsLength={(currentLesson?.words || [])?.length}
          />
        )}
        {areThereStories && (
          <Stories
            stories={stories}
            buttonLabel="відео"
            buttonSize="large"
            withoutLink
            // isReadingVideo
          />
        )}
        {Boolean(sentences?.length) && (
          <BuildSentences
            lessonSentences={sentences}
            userSentences={userSentences}
            curerntModuleWordsMap={currentModuleWordsMap}
          />
        )}
        {Boolean(lessonRules.length) && <Rules rules={lessonRules} />}
        {/* <BuildSentences
          lessonSentences={sentences}
          userSentences={userSentences}
          curerntModuleWordsMap={currentModuleWordsMap}
        />
        <GrammarHomework sentences={sentences} userSentences={userSentences} /> */}
      </VStack>
    </VStack>
  );
};

export default EnglishKidsLessonsLesson;
