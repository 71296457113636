const lowerL = (step: number) => (
  <svg
    width="36.156cm"
    height="26.138cm"
    preserveAspectRatio="xMidYMid"
    version="1.0"
    viewBox="0 0 1024.9 740.92"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" stroke="#000" stroke-width="3.752">
      <path d="m154.36 154.71 1.4868 0.00155 0.60176 567.84-0.19628 6.3e-4" />
      <path d="m13.559 154.56 140.81 0.14657" />
      <path d="m13.442 721.56v-567l0.11719 1.2e-4" />
      <path d="m156.26 722.55-142.81 0.44409v-1.4356" />
    </g>
  </svg>
);

export default lowerL;

// <svg
//   width="23.156cm"
//   height="21.138cm"
//   preserveAspectRatio="xMidYMid"
//   version="1.0"
//   viewBox="0 0 656.39 599.19"
//   xmlns="http://www.w3.org/2000/svg"
// >
//   <g transform="matrix(.1 0 0 -.1 0 911)" fill="none" stroke="#000" stroke-width="50">
//     {/* {(showFullPath || step > 0) && (
//       <path transform="scale(1,-1)" d="m1558.8-3314.5v2.0067l-1412.5 7.4527" />
//     )}
//     {(showFullPath || step > 1) && <path transform="scale(1,-1)" d="m153.64-8970h1402" />}
//     {(showFullPath || step > 2) && (
//       <>
//         <path transform="scale(1,-1)" d="m1555.6-8970h3.1494v5655.5" />
//         <path transform="scale(1,-1)" d="m146.3-3305-8.9648 0.0473 7.5-5665h8.8036" />
//       </>
//     )} */}
//   </g>
// </svg>
