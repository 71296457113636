import AdminComponentIndicator from "../../../Unknown/AdminComponentIndicator";
import HStack from "../../../Unknown/HStack";
import StudentReadCarousel from "./StudentReadCarousel";

const LearnToReadCarousel = () => {
  return (
    <HStack>
      <StudentReadCarousel />
    </HStack>
  );
};

export default LearnToReadCarousel;
