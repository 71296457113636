const svgAustria = (step: number) => (
  <svg
    width="36.156cm"
    height="26.138cm"
    preserveAspectRatio="xMidYMid"
    version="1.0"
    viewBox="0 0 1024.9 740.92"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" stroke="#000" stroke-width="2.8346">
      <path d="m155.82 722.33-2.4e-4 0.53174h-140.95l-0.001025-1.1895" />
      <path d="m156.08 156.43-0.2599 565.89" />
      <path d="m14.472 154.32 141.6 1.7591-1.6e-4 0.35742" />
      <path d="m14.862 721.67-0.48867-567.35 0.098148 0.00122" />
      <path d="m185.25 157.7-0.2599 565.89" />
      <path d="m326.76 156.65-0.2599 565.89" />
      <path d="m354.67 156.72-0.2599 565.89" />
      <path d="m496.99 156.73-0.2599 565.89" />
      <path d="m184.41 155.66 141.6 1.7591-1.6e-4 0.35742" />
      <path d="m353.89 156.44 141.6 1.7591-1.6e-4 0.35742" />
      <path d="m354.69 721.39 141.6 1.7591-1.6e-4 0.35742" />
      <path d="m185.07 722.46 141.6 1.7591-1.6e-4 0.35742" />
    </g>
  </svg>
);

export default svgAustria;
